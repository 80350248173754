import React, { useState, useEffect } from 'react'
import file_icon from './img/bx-file-blank.svg'
import download_icon from './img/bx-download.svg'
import dayjs from 'dayjs'
import { ButtonActionDrop } from '../Buttons/ButtonActionDrop/ButtonActionDrop'
import './FileItemComponent.scss'

const FileItemComponent = ({
    eventId,
    created_at,
    file_size_kb,
    new_filename,
    url,
    authorEmail,
    filesdata,
    setFilesData,
    isEdit,
    positionDrop,
    fetchDeleteFiles,
    fetchGetFiles,
    type,
    comment_id,
    fetchComments,
    update,
    saveFile,
    setSaveFile,
    ind,
    isDelete = true,
    setCurrentFile,
    files = false,
    setIsModalEditFile
}) => {

    const email = localStorage.getItem('email')
    const btns_files = files ? [
        {
            btn: 'Копировать ссылку',
            func: (e) => {
                navigator.clipboard.writeText(url)
                alert(`Ссылка скопирована ${url}`)
            },
        },
        {
            btn: 'Переименовать файл',
            func: (e) => {
                setCurrentFile({
                    id: comment_id, "Название": [
                        new_filename, url
                    ]
                })
                setIsModalEditFile(true)
            },
        },
        {
            btn: 'Изменить тип доступа',
            func: (e) => {
                setCurrentFile({
                    id: comment_id, "Название": [
                        new_filename, url
                    ]
                })
                setIsModalEditFile(true)
            },
        }
    ] : []

    const btns_author = [
        ...btns_files,
        {
            btn: 'Удалить',
            func: () => {
                if (type === 'comments') {
                    if (!saveFile || saveFile?.length < 1) {
                        fetchDeleteFiles(eventId, url, type, comment_id)
                        setTimeout(() => { fetchGetFiles(type) }, 500)
                    } else {
                        setSaveFile((prev) => {
                            const curr_files_arr = prev.filter((el, indx) => indx !== ind)
                            return curr_files_arr
                        })
                    }

                } else {
                    fetchDeleteFiles(eventId, url, type, comment_id)
                    setTimeout(() => { fetchGetFiles(type) }, 500)
                }
            },

        },
    ];



    return (
        <div className='file-item-component' key={created_at + url}
            onClick={(e) => {
                if (setCurrentFile) {
                    setCurrentFile({ id: comment_id })
                }
            }}
        >
            <p className='file-item-component__link-file'>
                <img className='file-item-component__link-file-img' src={file_icon} />
                <span>{new_filename}</span>
            </p>

            <div className='file-item-component__hide-box'>
                <a className='file-item-component__link-btn'
                    target='_blank'
                    href={url}
                    download={'download'}
                // onClick={(e) => e.stopPropagation()}
                >
                    <img className='file-item-component__link-file-img' src={download_icon} /> Скачать файл
                </a>

                {(email == authorEmail && isDelete) && <ButtonActionDrop
                    id={url}
                    btns={btns_author}
                    color={'#F3F4F6'}
                    size={'m'}
                    positionDrop={positionDrop}
                />}
            </div>

            <div className='file-item-component__link-info'>
                <span>{(file_size_kb / 1024).toFixed(2)} МБ</span>
                <span>{dayjs(created_at).format('DD.MM.YY HH:mm')}</span>
            </div>
        </div>
    )
}

export { FileItemComponent }