import { MoonLoader } from 'react-spinners';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { DropDownSelector, ButtonBasic, InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../../../store/general';
import customHeaders, { getSpace } from '../../../../common/headers';
import { ButtonCopy } from '../../../ButtonCopy/ButtonCopy';
import './ModalAddToken.scss';

const ModalAddToken = ({ setIsModal, fetchGetTokens }) => {
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const [load, setLoad] = useState(false)
    const [scopesData, setScopesData] = useState([])
    const [selectedScopes, setSelectedScopes] = useState([])
    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [isGenerate, setIsGenerate] = useState(false)

    // Получение scopes из пространства
    const fetchGetScopes = (status) => {
        const abortController = new AbortController();

        const url = `https://apps0.ecomru.ru:4430/web/users/api-tokens/scopes`;

        setLoad(true);

        fetch(url, { headers, signal: abortController.signal })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then((json) => {
                const { scopes } = json
                if (scopes) {
                    setScopesData(
                        scopes.map((el) => {
                            return {
                                label: el,
                                value: el
                            }
                        })
                    )
                }
            })
            .catch((err) => console.error(err))
            .finally((e) => setLoad(false));
        return abortController;
    };

    // Сгенерировать токен
    const fetchAddToken = (client_id) => {
        const url = `https://apps0.ecomru.ru:4430/web/users/api-tokens`;

        const body = JSON.stringify({
            scopes: [selectedScopes?.value],
            name: name
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res.json());
                }
            })
            .then((json) => {
                setToken(json?.token)
                setIsGenerate(true)
                fetchGetTokens()
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        fetchGetScopes()
    }, []);

    return (
        <div
            className="blackout blackout-2"
            onClick={(e) => {
                e.stopPropagation()
                let el = e.target;
                if (el.classList.contains('blackout')) {
                    setIsModal(false);
                }
            }}
        >
            <div className="modal-add-token">
                <h1 className="modal-add-token__title">Добавить токен</h1>

                <InputDinamycPlaceholder
                    placeholder="Название"
                    onChange={(e) => setName(e)}
                    size='s'
                />

                <DropDownSelector
                    options_prop={scopesData}
                    state={selectedScopes}
                    setState={(e) => setSelectedScopes(e)}
                    placeholder="Выберите вариант"
                    width='100%'
                />

                {token && <p className='modal-add-token__token'>
                   {token}
                </p>}

                <ButtonBasic
                    green
                    text={isGenerate ? 'Копировать' : 'Сгенерировать'}
                    disabled={!name || !selectedScopes?.value}
                    onClick={(e) => {
                        if (isGenerate) {
                            navigator.clipboard.writeText(token)
                        } else {
                            fetchAddToken()
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { ModalAddToken };
