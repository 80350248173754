import React, { ReactNode } from 'react';
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack';
import './FilterContainer.scss';

type FilterContainerProps = {
  children: ReactNode;
  onReset: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const FilterContainer: React.FC<FilterContainerProps> = ({ children, onReset }) => (
  <div className='filters-container'>
    {children}
    <TooltipBlack
      child={<button className='btn-reset-filters' onClick={onReset} />}
      text='Сбросить все'
      top='6px'
      left='calc(100% + 4px)'
    />
  </div>
);

export { FilterContainer };