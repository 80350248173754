import customHeaders, { getSpace } from '../../common/headers';
import { TableBox } from '@lk-gtcom/ecomlab-components';
import { Link, useParams } from 'react-router-dom';
import {
    usePagination,
    ButtonBasic
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import { FileItemComponent } from '../../components/FileItemComponent/FileItemComponent';
import './FilesPage.scss';
import { DropZoneDirectory } from '../../components/DropZoneDirectory/DropZoneDirectory';
import { useState, useEffect } from 'react';
import { MoonLoader } from 'react-spinners';
import { useShallow } from 'zustand/react/shallow';
import { useRef } from 'react';
import { useDataTable } from '../../common/hooks';
import { ModalEditFile } from './ModalEditFile/ModalEditFile';

const FilesPage = ({
    selectView,
    search,
    sortingRef
}) => {

    const {
        setIsOpenSnackbar,
        setContentSnackbar
    } = useGeneralStore(
        useShallow((state) => ({
            setIsOpenSnackbar: state.setIsOpenSnackbar,
            setContentSnackbar: state.setContentSnackbar,
        }))
    );
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const path = useParams()['*'];
    const [saveFile, setSaveFile] = useState()
    const [filesData, setFilesData] = useState()
    const [save, setSave] = useState(null)
    const current_directory = path?.split('/').at(-2)
    const [idsFiles, setIdsFiles] = useState([])
    const [listFiles, setListFiles] = useState([])
    const [load, setLoad] = useState(false)
    const [loadList, setLoadList] = useState([])

    const paginatorRef = useRef();
    const paginatorCallback = useRef();
    const [setTable, tableProps, setTableFuncs] = useDataTable(
        paginatorRef.current?.setTotal,
        paginatorRef
    );
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs;
    const [currentFile, setCurrentFile] = useState(null)
    const [isModalEditFile, setIsModalEditFile] = useState('')

    const [
        page,
        limit,
        total,
        setTotal,
        Paginator,
        resetPaginator,
        paginatorTransition,
    ] = usePagination(
        paginatorCallback.current,
        [],
        paginatorRef.current?.params
    );

    const contextMenuArr = [
        {
            label: 'Копировать ссылку',
            onClick: (e) => {
                navigator.clipboard.writeText(e?.['Название']?.[1])
                alert(`Ссылка скопирована ${e?.['Название']?.[1]}`)
            },
        },
        {
            label: 'Переименовать файл',
            onClick: (e) => {
                setCurrentFile(e)
                setIsModalEditFile(true)
            },
        },
        {
            label: 'Изменить тип доступа',
            onClick: (e) => {

            },
        },
        {
            label: 'Удалить',
            onClick: (e) => {
                fetchDeleteFile(e?.id);
                setTimeout(() => fetchGetFileList(), 500)
            },
        },
    ];

    // Сохранить файл по id в директории
    const fetchSaveFile = (id) => {
        let url = 'https://apps2.ecomru.ru:4460/api/v1/file/add'

        const body = JSON.stringify({
            directory_id: current_directory,
            file_id: id
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res.json());
                }
            })
            .then((json) => {
                fetchGetFileList()
            })
            .catch((err) => console.error(err));
    };

    // Получить таблицу файлов по id директории 
    const fetchGetFileList = (params, abortController = new AbortController()) => {
        setLoad(true)

        let sort;
        let filtersParam;
        let sorting = null;

        if (params) {
            [sort, filtersParam] = params;
        }

           const orderParam = sortingRef.current
                    ? sortingRef.current.param
                    : 'Дата создания';

        const orderParamDirection = sortingRef.current
                    ? sortingRef.current.direction
                    : 'DESC';

        let url = `https://apps1.ecomru.ru:4450/api/v3/directory/files${
            '?order_param=' + orderParam + '&order_param_direction=' + orderParamDirection
            }`

        const body = JSON.stringify({
            search_param: search,
            page: page,
            limit: limit ?? 10,
            directory_id: parseInt(current_directory),
            filters: filtersParam,
        });

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res.json());
                }
            })
            .then((json) => {
                setTable(json);
                const { data, sort } = json
                const current_list = data.map((el, ind) => {
                    return {
                        id: el?.[0],
                        created_at: el?.[1]?.[0],
                        file_size_kb: parseInt(el?.[5]),
                        new_filename: el?.[3]?.[0],
                        url: el?.[3]?.[1]
                    }
                })
                setListFiles(current_list)
                setTotal(json.total)
                if (sort) {
                    setSort(sort);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setLoad(false)
                if (abortController.signal.aborted) return
            });
    };

    // Удаление файла по id
    const fetchDeleteFile = (id) => {
        let url = `https://file.ecomru.ru:4453/api/v1/file/remove/${id}`

        fetch(url, { method: 'DELETE', headers })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res.json());
                }
            })
            .then((json) => {
                console.log(JSON)
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setLoad(false)
            });
    };

    useEffect(() => {
        fetchSaveFile(save?.id)
    }, [save]);

    useEffect(() => {
        resetPaginator()
        setIdsFiles([])
        setListFiles([])
        setLoadList([])
        setContentSnackbar('')
        setIsOpenSnackbar(false)
    }, [current_directory]);

    useEffect(() => {
        const abortController = new AbortController()
        resetPaginator()
        if (page === 1) {
            fetchGetFileList([], abortController)
        }
        return () => abortController.abort()
    }, [search]);

    useEffect(() => {
        if (!isModalEditFile) {
            fetchGetFileList([])
        }
    }, [page, limit, current_directory, isModalEditFile, sortingRef?.current]);

    useEffect(() => {
        console.log(sortingRef)
    }, [sortingRef?.current]);

    useEffect(() => {
        if (loadList?.length) {
            const content = loadList?.map(({ name, status }) => {
                return <div className='files-page__snackbar-row'>
                    <span>{name}</span>
                    {status == 'true' && <span>✓</span>}
                    {status == 'error' && <span style={{ color: 'red' }}>error</span>}
                    {status == 'false' &&
                        <MoonLoader
                            color="#fff"
                            size={16}
                            speedMultiplier={0.5}
                        />
                    }
                </div>
            })
            setContentSnackbar(content)
            setIsOpenSnackbar(true)
        }
    }, [loadList]);

    return (
        <>
            {isModalEditFile && <ModalEditFile
                setIsModal={setIsModalEditFile}
                currentFile={currentFile}
                setCurrentFile={setCurrentFile}
            />}

            <div className="files-page">
                <div className="files-page__drop-zone">
                    <ButtonBasic
                        green
                        text='Добавить файл +'
                    />
                    <DropZoneDirectory
                        update={fetchGetFileList}
                        setSave={setSave}
                        setLoadList={setLoadList}
                    />
                </div>

                <h2 className="files-page__title">Список файлов:</h2>
                <div className="files-page__content">
                    {
                        (selectView != 'Table'
                            ?
                            <>
                                {load ? <MoonLoader
                                    color="#7b7b7b"
                                    speedMultiplier={0.5}
                                />
                                    :
                                    listFiles?.length > 0 ?
                                        listFiles?.map((el, ind) => {
                                            return <FileItemComponent
                                                ind={ind}
                                                comment_id={el.id}
                                                // type={type}
                                                // eventId={eventId}
                                                fetchDeleteFiles={(e) => fetchDeleteFile(el.id)}
                                                fetchGetFiles={fetchGetFileList}
                                                isEdit={true}
                                                created_at={el?.created_at}
                                                file_size_kb={el.file_size_kb}
                                                new_filename={el.new_filename}
                                                url={el.url}
                                                authorEmail={localStorage.getItem('email')}
                                                filesdata={saveFile}
                                                setFilesData={(e) => setFilesData(e)}
                                                positionDrop={'top'}
                                                saveFile={saveFile}
                                                setSaveFile={setSaveFile}
                                                setCurrentFile={setCurrentFile}
                                                setIsModalEditFile={setIsModalEditFile}
                                                files={true}
                                                
                                            />
                                        })
                                        :
                                        <span className='files-page__no-file'
                                        >Здесь пока нет ни одного файла</span>
                                }
                            </>
                            :

                            <TableBox
                                {...tableProps}
                                ref={paginatorRef}
                                loading={load}
                                // fetchCallback={(e) => {
                                //     resetPaginator();
                                //     fetchGetFileList(e)
                                // }}
                                // sortingFunc={(e) => fetchGetFileList(e)}
                                hideSorting={true}
                                fetchCallback={fetchGetFileList}
                                contextMenuArr={contextMenuArr}
                            />
                        )
                    }
                    <>{Paginator}
                    </>
                </div>
            </div>
        </>
    );
};

export { FilesPage };
