import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { fetchApiFilterVer3 } from '../../common/filtersFunc';
import { useState, useEffect, useRef } from 'react';
import { TableBox, ButtonBasic, DropDownSelector, InputDinamycPlaceholder, Tabs, Checkbox } from "@lk-gtcom/ecomlab-components";
import { TabsGreen } from '../../components/TabsGreen/TabsGreen';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { ModalSettingMasterProduct } from './ModalSettingMasterProduct/ModalSettingMasterProduct';
import './SettingMasterProduct.scss'
import InputListArray from '../../components/InputListArray/InputListArray';

const btn_tab = [
    {
        label: 'Установка себестоимости',
        value: 'setting'
    },
    {
        label: 'Параметры себестоимости',
        value: 'parameters'
    }
]

const btns = [
    { label: 'Общее для всех', value: 'total' },
    { label: 'По товарам', value: 'product' }
]

const title_list = [
    {
        label: 'Дополнительные настройки',
        path: `additional_settings`
    },
    {
        label: 'Загрузка товаров',
        path: `loading_goods`
    }
]


const SettingMasterProduct = ({ }) => {

    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };

    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [status, setStatus] = useState(btns?.[0]?.value)
    const [currentRow, setСurrentRow] = useState(null)
    const [currentTitle, setCurrentTitle] = useState(title_list[0])
    const [stores, setStores] = useState([])
    const [selectedStore, setSelectedStore] = useState({})
    const [isAddPrice, setIsAddPrice] = useState(false)

    const [apiData, setApiData] = useState([]);

    const [selectedMp, setSelectedMp] = useState([]);
    const [selectedApi, setSelectedApi] = useState([]);

    const [apiPage, setApiPage] = useState(1);

    const [apiQuery, setApiQuery] = useState('');

    const activeTab = useParams()['*']

    const paginatorRef = useRef();
    const [setTable, tableProps, setTableFuncs] = useDataTable(
        paginatorRef.current?.setTotal,
        paginatorRef
    );
    const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
    const { total, loading } = tableProps;

    const [tableUrl, setTableUrl] = useState('');
    const [tableBody, setTableBody] = useState(null);
    const [isPersent, setIsPersent] = useState(false)

    const [isModalAddNewField, setIsModalAddNewField] = useState(false)

    const apiFilter = () => {
        fetchApiFilterVer3({
            selectedSpace: selectedSpace?.value,
            page: apiPage,
            setApiData: setApiData,
            set: setApiData,
            searchParam: apiQuery,
            selectedApi,
            selectedShop: selectedMp,
            filter: 'api_id',
        });
    };

    useEffect(() => {
        apiFilter();
    }, [apiPage, apiQuery]);

    const handleSaveOnePrice = (e) => {
        const { inputEvent, actionEvent, setStatus } = e;
        const offerId = inputEvent?.id;
        const apiId = actionEvent?.[0]?.api_id;
        let newPrice = +inputEvent?.value;

        if (newPrice) {
            setStatus('loading');
            fetchSetCostPrice(apiId, offerId, newPrice, setStatus);
        } else {
            setStatus('error');
        }
    };

    const onDelete = (e) => {
        const { attribute_id } = e
        fetchDelete(attribute_id)
    }

    const onEdit = (e) => {
        setСurrentRow(e)
        setIsModalAddNewField(true)
    }

    const contextMenuArr = [
        { label: 'Редактировать', onClick: onEdit },
        { label: 'Удалить', onClick: onDelete },
    ];


    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort
        let filtersParam

        if (params) {
            [sort, filtersParam] = params
        }

        setLoading(true)

        let url = `https://apps1.ecomru.ru:4463/custom_attrs/table${sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''}`

        const body = JSON.stringify({
            limit: paginatorRef?.current?.limit,
            page: paginatorRef?.current?.page,
            filters: filtersParam
        })

        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setTable(json)
                setTableUrl(json?.tableUrl)
                setTableBody(json?.tableBody)
            })
            .catch(err => console.error(err))
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })

    }

    const fetchSetCostPrice = (apiId, offerId, newPrice, setStatus) => {
        let url = 'https://apps0.ecomru.ru:4449/mp-products/tables/set-cost-price'

        const body = JSON.stringify({
            price: [
                {
                    offer_id: offerId,
                    cost: newPrice,
                    api_id: apiId,
                }
            ]
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => {
                if (res.ok) {
                    setStatus('success')
                    return res.json();
                } else {
                    throw Error(JSON.stringify(res.json()));
                }
            })
            .then((json) => {
                console.log(json)
            })
            .catch((err) => {
                setStatus('error');
                console.error(err);
            });
    };

    // Удаление атрибута
    const fetchDelete = (id) => {
        let url = `https://apps1.ecomru.ru:4463/custom_attrs/delete`

        const body = JSON.stringify({
            attribute_id: id
        });

        fetch(url, { body, method: 'DELETE', headers })
            .then((res) => {
                if (res.ok) {
                    fetchTableData()
                    return res.json();
                } else {
                    throw Error(JSON.stringify(res.json()));
                }
            })
            .then((json) => {
                fetchTableData()
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // Получение магазинов ozon
    const fetchGetStores = (filter) => {
        let url = 'https://apps0.ecomru.ru:4546/ozon_stores?space_id=3459'

        fetch(url, { method: 'GET', headers })
            .then((res) => res.json())
            .then((json) => {
                const { stores } = json
                const options = stores.map(({ api_id, name }) => ({ value: api_id, label: name }))
                setStores(options);
            })
            .catch((err) => console.error(err));
    };

    const fetchCreateMasterProduct = () => {
        let url = 'https://apps0.ecomru.ru:4546/create_master_products_from_ozon?space_id=3459'

        const body = JSON.stringify({
            mp_api_id: selectedStore?.value,
            add_prices: isAddPrice
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => {
                if (res.ok) {
                    setStatus('success')
                    return res.json();
                } else {
                    throw Error(JSON.stringify(res.json()));
                }
            })
            .then((json) => {
                alert('Мастер товары добавлены')
            })
            .catch((err) => {
                setStatus('error');
                console.error(err);
            });
    };

    useEffect(() => {
        fetchGetStores()
    }, []);

    useEffect(() => {
        if (!isModalAddNewField) {
            setTimeout(() => fetchTableData(), 500)
        }
    }, [isModalAddNewField]);

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    return (
        <>
            {isModalAddNewField && <ModalSettingMasterProduct
                status={status}
                selectedApi={selectedApi}
                setIsModal={setIsModalAddNewField}
                currentRow={currentRow}
                setСurrentRow={setСurrentRow}
            />}

            <div className='setting-cost-price'>
                <div className='tabs-title'>
                    {title_list?.map((el) =>
                        <h1 className={currentTitle.label == el.label ? 'title_active' : 'title'}
                            key={el.label}
                            onClick={() => {
                                setCurrentTitle(el)
                            }}
                        >{el.label}</h1>
                    )}
                </div>

                {currentTitle?.path == 'additional_settings' && <TableBox
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    ref={paginatorRef}
                    paginator={true}
                    {...tableProps}
                    fetchCallback={fetchTableData}
                    tableHeaders={headers}
                    onInputAction={(e) => handleSaveOnePrice(e)}
                    contextMenuArr={contextMenuArr}
                    child={
                        <ButtonBasic
                            whiteGreen
                            width='auto'
                            size='32px'
                            text={'+ Новая характеристика'}
                            onClick={(e) => {
                                setIsModalAddNewField(true)
                            }}
                        />
                    }
                />}

                {currentTitle?.path === 'loading_goods' &&
                    <div className='setting-cost-price__load-product'>
                        <DropDownSelector
                            options_prop={stores}
                            state={selectedStore}
                            setState={(e) => setSelectedStore(e)}
                            placeholder="Выберите магазин"
                        />

                        <Checkbox
                            value={isAddPrice}
                            onChange={(checked) => setIsAddPrice(checked)}
                        >Добавить цены с Озона мастер товарам </Checkbox>

                        <ButtonBasic
                            disabled={!selectedStore?.value}
                            green
                            text='+ Cоздать мастер товары'
                            onClick={() => {
                                fetchCreateMasterProduct()
                            }}
                        />
                    </div>}
            </div>
        </>
    )
}

export { SettingMasterProduct }