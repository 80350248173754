import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import SettingsConnection from '../../components/SettingsConnection/';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { InformationForBilling } from '../../components/InformationForBilling/InformationForBilling';
// import { Account } from '../../components/Account/Account';
import { OfferAndContract } from '../../components/OfferAndContract/OfferAndContract';
import AddBalanceModal from '../../components/AddBalanceModal';
import BalanceIncrease from '../../components/BalanceIncrease/';
import { DownloadHistory } from '../../components/DownloadHistory/DownloadHistory';
import { NotActive } from '../../components/NotActive/NotActive';
import { PaymentHistory } from '../../components/PaymentHistory/PaymentHistory';
import { AccountManagement } from '../../components/AccountManagement/AccountManagement';
import { SpacePage } from '../SpacePage/SpacePage';
import { Page404 } from '../Page404/Page404';


import "./Settings.scss"
import { Summary } from '../Summary/Summary';
import { Payment } from '../Payment/Payment';
import { TariffPlan } from '../../components/TariffPlan/TariffPlan';
import { FinanceByPeriod } from '../FinanceByPeriod/FinanceByPeriod';
import { FinanceForServices } from '../FinanceForServices/FinanceForServices';
import { ReferralProgram } from '../ReferralProgram/ReferralProgram';

const sidebarItemArr = [
    {
        label: 'Пространства',
        path: 'space',
        subItems: [],
        // subItems: [
        //     {
        //         label: 'Пространства',
        //         path: 'space'
        //     },
        //     {
        //         label: 'Подключения',
        //         path: 'connections',
        //         subItems: [],
        //     },
        //     {
        //         label: 'Сотрудники',
        //         path: 'employees',
        //     },
        // ],
    },

    {
        label: 'Управление аккаунтом',
        subItems: [],
        path: 'account-management/my-details'
    },
    {
        label: 'Финансы',
        // path: 'finance',
        subItems: [
            {
                label: 'Оплата',
                path: 'payment',
                subItems: [],
            },
            {
                label: 'Сводная',
                subItems: [],
                path: 'summary'
            },
            {
                label: 'По периодам',
                path: 'by-period',
                subItems: [],
            },
            {
                label: 'По услугам',
                path: 'for-services',
                subItems: [],
            },
            {
                label: 'Тарифы',
                path: 'tariff',
                subItems: [],
            }
        ],
    },
    {
        label: 'Реквизиты и договора',
        subItems: [
            {
                label: 'Информация для выставления счетов и актов',
                black: true,
                path: 'information-for-billing'
            },
            {
                label: 'Договора и отчетные документы',
                black: true,
                path: 'offer-and-contract/act'
            },
        ],
    },
    {
        label: 'Настройка ЛК',
        subItems: [],
    },
    {
        label: 'Реферальная программа',
        subItems: [],
        path: 'referral-program/accruals'
    },
    {
        label: 'История загрузок',
        path: 'download-history',
        subItems: [],
    },
]


const Settings = ({
    setIsModalActive,
    isModalActive,
    setConnectedModal,
    setIsModalFeedback,
    isModalFeedback,
    setIsModalCreatingNewSpace,
    setIsEditSpace,
    isModalCreatingNewSpace,
    fetchGetSpace,
    spaceList,
    setCurrentSpace,
    currentSpace,
    setSelectedSpace
}) => {
    const pathMain = useParams()
    const navigate = useNavigate()
    const [hideMenu, setHideMenu] = useState(true)
    const [isBalanceModalActive, setIsBalanceModalActive] = useState(false)
    const [isActive, setIsActive] = useState('')
    const [isActiveItems, setIsActiveItems] = useState('')
    const [sidebarItems, setSidebarItems] = useState(sidebarItemArr);

    useEffect(() => {

        // После перезагрузки страницы, посвечиваем корректные пункты меню

        sidebarItems?.forEach(({ label, subItems, path }) => {
            const title = label
            const comparePaths = path && (pathMain['*'].split('/')[0] == path.split('/')[0])
            if (comparePaths) {
                setIsActive(null)
                setIsActiveItems(label)
            } else {
                subItems.forEach(({ label, path }) => {
                    if (comparePaths) {
                        setIsActive(title + label)
                        setIsActiveItems(null)
                    }
                })
            }
        })

        if (pathMain['*'].split('/')[0] == 'not-active') {
            navigate('space')
            setIsActive('')
            setIsActiveItems('Пространства')
        }
    }, [])

    useEffect(() => {
        if (pathMain['*'].split('/')[0] == 'download-history') {
            setIsActive('')
            setIsActiveItems('История загрузок')
        }

        if (pathMain['*'].split('/')[0] == 'space') {
            setIsActive('')
            setIsActiveItems('Пространства')
        }
    }, [pathMain]);


    return (
        <div className='settings__content'>
            <div className='flex'>
                <div className='title-and-nav-box'>
                    <div className='sidebar-inside'>
                        <nav className='sidebar-inside__nav'>
                            {sidebarItems.map((el, idx, { label }) => <SidebarMenu {...el} isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} setActive={e => setIsActive(e)} active={isActive} key={label + '' + idx} />)}
                        </nav>
                    </div>
                </div>
                <div className='settings__main-box'>
                    <Routes>
                        <Route path='space/*' element={<SpacePage
                            spaceList={spaceList}
                            setIsEditSpace={(e) => setIsEditSpace(e)}
                            isModalCreatingNewSpace={isModalCreatingNewSpace}
                            fetchGetSpace={(e) => fetchGetSpace(e)}
                            setCurrentSpace={(e) => setCurrentSpace(e)}
                            currentSpace={currentSpace}
                            setSelectedSpace={setSelectedSpace}
                            setIsModalActive={(e) => setIsModalActive(e)}
                            setIsModalCreatingNewSpace={(e) => setIsModalCreatingNewSpace(e)} />} />

                        <Route path='connections' element={<SettingsConnection
                            isModalFeedback={isModalFeedback}
                            setIsModalFeedback={(e) => setIsModalFeedback(e)}
                            setConnectedModal={e => setConnectedModal(e)}
                            currentSpace={currentSpace}
                            setIsModalActive={e => setIsModalActive(e)}
                            isModalActive={isModalActive} />} />
                       
                        {/* Финансы */}
                        <Route path='summary' element={<Summary />} />
                        <Route path='payment' element={<Payment />} />
                        <Route path='tariff' element={<TariffPlan />} />
                        <Route path='by-period' element={<FinanceByPeriod />} />
                        <Route path='for-services' element={<FinanceForServices />} />

                        <Route path='balance-increase' element={<BalanceIncrease />} />
                        <Route path='information-for-billing' element={<InformationForBilling />} />
                        {/* <Route path='account' element={<Account />} /> DEPRECATED */}
                        <Route path='offer-and-contract/*' element={<OfferAndContract />} />
                        <Route path='download-history' element={<DownloadHistory />} />
                        <Route path='payment-history/*' element={<PaymentHistory />} />
                        <Route path='account-management/*' element={<AccountManagement setIsModalActive={e => setIsModalActive(e)} isModalActive={isModalActive} />} />
                        <Route path='referral-program/*' element={<ReferralProgram />} />
                        <Route path='not-active' element={<NotActive />} />
                        <Route path='*' element={< Page404 />} />
                    </Routes>
                </div>
            </div>
            {isBalanceModalActive && <AddBalanceModal setIsModalActive={e => setIsBalanceModalActive(e)} />}
        </div>
    )
}

export { Settings }