import { useState, useEffect } from "react"
import "./BtnMore.scss"

const BtnMore = ({  btns = [], label = '',  id = 1 }) => {

    const [isDropShare, setIsDropShare] = useState(false)

    // Обработчик на клик вне элемента
    useEffect(() => {
        const isDrop = (e) => {
            let el = e.target
            if (!el.closest(`#context-more-${label + id}`)) {
                setIsDropShare(false)
            }
        }
        document.addEventListener('click', isDrop)

        return () => {
            document.addEventListener('click', isDrop)
        }
    }, [])


    return (
        <div className='context-more' id={`context-more-${label + id}`}
            onClick={(e) => {
                e.stopPropagation()
                setIsDropShare(!isDropShare)
            }}
        >
            <button className='btn-more'></button>

            {isDropShare &&
                <div className={'drop-menu_active'}>
                    {btns.map(({ btn, func }) =>
                        <button className='drop-menu__btn'
                            onClick={(e) => {
                                // e.stopPropagation()
                                func(id)
                                setIsDropShare(false)
                            }}
                        >{btn}</button>
                    )}
                </div>}
        </div>
    )
}

export { BtnMore }