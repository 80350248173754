import "./TabsGreen.scss"

const TabsGreen = ({
    title = '',
    btns = [],
    currentBtn,
    setCurrentBtn,
    checkValue = false,
    returnEvent,
    icon
}) => {

    return (
        <div className='tabs-green'>
            {title && <p className='text'>{title}</p>}
            <div className='tabs-green__content'>
                {
                    btns.map((el, ind) => {
                        const { label, value, count, icon } = el
                        return (
                            <button className={checkValue ? currentBtn == value ? 'btn_active' : 'btn' : currentBtn == label ? 'btn_active' : 'btn'}
                                onClick={(e) => {
                                    if (returnEvent) {
                                        setCurrentBtn(el)
                                    }
                                    checkValue ?
                                        setCurrentBtn(value)
                                        :
                                        setCurrentBtn(label)
                                }}
                            >
                                {icon && <img src={icon}/>}
                                {label}
                                {(count || count === 0) && 
                                <div className="count">{`${(count || count === 0) ? count : ''}`}</div>}
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export { TabsGreen }