import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import "./TooltipBlack.scss"

const TooltipBlack = ({ 
    child = <></>, 
    text = '', 
    top = '', 
    left = '', 
    right = '', 
    position = false, 
    maxWidth = false, 
    hideTooltip = false, 
    style = {}
 }) => {

    return (
        <div className='tooltip-container' style={{position: position === 'absolute' ? 'static' : 'relative', ...style}}>
            {child}
            {
                !hideTooltip &&
                <div className='tooltip-black'
                    style={{
                        top: top ? top : '',
                        left: left ? left : '15px',
                        right: right ? right : '',
                        maxWidth: maxWidth ? maxWidth : '',
                        position: position ? position : ''
                    }}
                >{text}</div>
            }
        </div>
    )
}

export { TooltipBlack }