import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useGeneralStore from '../../store/general';
import { useShallow } from 'zustand/react/shallow';

const SnackbarMui = () => {
    const { 
        isOpenSnackbar, 
        setIsOpenSnackbar,
        contentSnackbar,
        setContentSnackbar
     } =
        useGeneralStore(
            useShallow((state) => ({
                isOpenSnackbar: state.isOpenSnackbar,
                setIsOpenSnackbar: state.setIsOpenSnackbar,
                contentSnackbar: state.contentSnackbar,
                setContentSnackbar: state.setContentSnackbar,
            }))
        );

    const handleClose = () => {
        setIsOpenSnackbar(false);
        setContentSnackbar('')
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={isOpenSnackbar}
            onClose={handleClose}
            message={contentSnackbar[0]?.$$typeof && contentSnackbar}
            // key={contentSnackbar}
            // autoHideDuration={5000}
            action={action}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
    );
}
export { SnackbarMui }