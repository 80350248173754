import React, { useEffect, useState } from 'react';
import customHeaders, { getSpace } from '../../common/headers';
import add_icon from './img/add.svg';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { ArtemBilling2Https } from '../../fetchUrls';
import AddBalanceModal from '../../components/AddBalanceModal';
import useGeneralStore from '../../store/general';
import { TitlePage } from '../../components/TitlePage/TitlePage';
import './Payment.scss';

interface BalanceResponse {
  balance: number;
  currency: string;
}

const Payment: React.FC = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [isModalBalance, setIsModalBalance] = useState<boolean>(false);
  const [balance, setBalance] = useState<string>('0');
  const [currency, setCurrency] = useState<string>('₽');

  const fetchBalanceCount = async (): Promise<void> => {
    const url = `${ArtemBilling2Https}/balance`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(JSON.stringify(error));
      }

      const data: BalanceResponse = await response.json();
      setBalance(data.balance?.toLocaleString());
      setCurrency(data.currency === 'RUB' ? '₽' : data.currency);
    } catch (error) {
      console.error('Failed to fetch balance:', error);
    }
  };

  useEffect(() => {
    fetchBalanceCount();
  }, [selectedSpace]);

  const handleAddBalanceClick = (): void => {
    setIsModalBalance(true);
  };

  const handleModalClose = (): void => {
    setIsModalBalance(false);
  };

  return (
    <div className="payment">
      {isModalBalance && (
        <AddBalanceModal setIsModalActive={handleModalClose} />
      )}

      <TitlePage title="Оплата" />

      <div className="payment__content">
        <div className="payment__balance">
          <TitlePage
            medium
            title='Баланс'
          />
          <div className="payment__balance-content">
            <p className="payment__sum">
              {balance} {currency}
            </p>
            <ButtonBasic
              green
              width="190px"
              text={
                <div className="content-btn">
                  <img src={add_icon} alt="Add balance" />
                  Пополнить баланс
                </div>
              }
              onClick={handleAddBalanceClick}
            />
          </div>
        </div>

        <div className="payment__promocode">
          <TitlePage
            medium
            title='Есть промокод?'
          />
          <div className="payment__promocode-content">
            <span className="payment__placeholder">Введите промокод</span>
            <div className="payment__input-box">
              <input className="payment__input" />
              <ButtonBasic
                width="150px"
                lilac
                size="32px"
                text={
                  <div className="content-btn">
                    <img src={add_icon} alt="Activate promo" />
                    Активировать
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Payment };