import { Link } from 'react-router-dom';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import minus_icon from '../img/bx-minus.svg'
import useGeneralStore from '../../../../store/general';
import { ConnectionsContext } from '../../../../App'
import customHeaders, { getSpace } from '../../../../common/headers';
import { ButtonActionDrop } from '../../../Buttons/ButtonActionDrop/ButtonActionDrop';
import { OlegAccountHttps, OlegAccountHttp } from '../../../../fetchUrls';
import { setAccountId, setName, setServiceData } from '../../../SettingsConnection/actions';
import useModal from '../../../../store/modal';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import { ModalAddToken } from '../ModalAddToken/ModalAddToken';
import { useContext } from 'react';
import './TokenTab.scss'
import { useState, useEffect } from 'react';

const TokenTab = ({ }) => {
    const { dispatch } = useContext(ConnectionsContext);
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const [load, setLoad] = useState(false)
    const [isModalAddToken, setIsModalAddToken] = useState(false)
    const [listTokens, setListTokens] = useState([])


    // Получение токенов из пространства
    const fetchGetTokens = (status) => {
        const abortController = new AbortController();

        const url = `https://apps0.ecomru.ru:4430/web/users/api-tokens?space_id=${selectedSpace?.value}`;

        setLoad(true);

        fetch(url, { headers, signal: abortController.signal })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then((json) => {
                setListTokens(json)
            })
            .catch((err) => console.error(err))
            .finally((e) => setLoad(false));
        return abortController;
    };

    // Удаление токена
    const fetchDeleteToken = (token) => {
        const url = `https://apps0.ecomru.ru:4430/web/users/api-tokens/${token}`;

        fetch(url, { method: 'DELETE', headers })
            .then((res) => {
                if (res.ok) {
                    fetchGetTokens();
                    return res.json();
                } else {
                    throw new Error(res.json());
                }
            })
            .then((json) => { })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        fetchGetTokens()
    }, []);

    return (
        <>
            {isModalAddToken && <ModalAddToken
                fetchGetTokens={fetchGetTokens}
                setIsModal={setIsModalAddToken}
            />}

            <div className='token-tab'>
                <ButtonBasic
                    width="200px"
                    green
                    text="Добавить токен +"
                    onClick={(e) => {
                        setIsModalAddToken(true)
                    }}
                    size="32px"
                />

                {
                    listTokens.map(({ id, scopes, issue_datetime, name }) => {
                        return <div className='tokens-row'>
                            <p className='tokens-row__name'>{name}</p>
                            <p className='tokens-row__date'>{(dayjs(issue_datetime)).format('DD.MM.YYYY')}</p>

                            <ButtonBasic
                                grey
                                width='40px'
                                minWidth='40px'
                                text={<img src={minus_icon} />}
                                onClick={(e) => fetchDeleteToken(id)}
                            />
                        </div>
                    })
                }
            </div>
        </>
    )
}

export { TokenTab }
