import { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalAlert.scss'

const ModalAlert = ({
    setIsModal,
    title,
    description,
    copy = false
}) => {

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            {<div className='modal-alert'>
                {title && <h1 className='modal-alert__title'>{title}</h1>}
                {description && <p className='modal-alert__description'>{description}</p>}
                {copy ?
                    <ButtonBasic
                        green
                        width='100%'
                        size='30px'
                        text='Копировать'
                        onClick={(e) => {
                            navigator.clipboard.writeText(title)  
                            setIsModal(false)
                        }}
                        style={{
                            marginTop: '6px'
                        }}
                    />
                    :
                    <ButtonBasic
                        green
                        width='100%'
                        size='30px'
                        text='ОК'
                        onClick={(e) => setIsModal(false)}
                        style={{
                            marginTop: '6px'
                        }}
                    />}
            </div>}
        </div>
    )
}

export { ModalAlert }