import nodata_icon from './img/nodata_icon.svg'
import info_icon from './img/info_icon.svg'
import banner_basic from './img/banner_basic.svg'
import done_violet from './img/done_violet.svg'
import date_icon from './img/date_icon.svg'
import customHeaders, { getSpace } from '../../common/headers'
import plus_icon from './img/plus_icon.svg'
import chat_big_icon from './img/chat_big_icon.svg'
import user_icon from './img/user_icon.svg'
import { Loader } from '../../common/components/Loader/Loader'
import { useDebounce } from '../../hooks/useDebounce'
import { useDataTable } from '../../common/hooks'
import { RavshanHttps } from '../../fetchUrls'
import { fromSnakeCase } from '../../common/mappers'
import { ModalAddProject } from '../../components/Modal/ModalAddProject/ModalAddProject'
import { ModalListExecutor } from '../../components/Modal/ModalListExecutor/ModalListExecutor'
import { ReactComponent as ArrowDown } from './img/arrow_down.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { ButtonBasic, TableBox, ViewSwitch, InputDynamicWidth } from "@lk-gtcom/ecomlab-components";
import ProjectCard from './ProjectCard/ProjectCard.tsx'
import useGeneralStore from '../../store/general'
import { AuthorAvatar } from '../ArticleComponents/AuthorAvatar/AuthorAvatar'
import { ButtonsSort } from '../../components/Buttons/BurronsSort/ButtonsSort'
import "./ProjectForTasks.scss"

const btn_sort = [
    {
        label: 'A-Z',
        value: 'name'
    },
    {
        label: 'По дате создания',
        value: 'date'
    },
    {
        label: 'Задач',
        value: 'tasksCount'
    },
]

const arrNames = (arr_names, author) => {
    return arr_names?.map(({ name, login, color, id }, ind) => {
        return <AuthorAvatar
            size='s'
            group
            key={color + login}
            author={[login, color]}
        />
    })
}

const ProjectForTasks = ({ setSelectedProject, selectedProject }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const navigate = useNavigate()
    const path = useParams()['*']

    const email = localStorage.getItem('email')
    const [loading, setLoading] = useState(true)
    const [projects, setProjects] = useState([])
    const [isModalAddProject, setIsModalAddProject] = useState(false)
    const [isSelectedProjectLS, setIsSelectedProjectLS] = useState(false)
    const [isInfoProject, setIsInfoProject] = useState(false)
    const [view, setView] = useState('Tile-short')
    const [isShowAllExecutor, setIsShowAllExecutor] = useState(false)
    const [authorName, setAuthorName] = useState(email)
    const [selectedProjects, setSelectedProjects] = useState([])
    const [allExecutors, setAllExecutors] = useState([])
    const [selectedExecutor, setSelectedExecutor] = useState([])
    const [isModalListExecutor, setIsModalListExecutor] = useState(false)
    const [currentSort, setCurrentSort] = useState(null)
    const [sort, setSort] = useState(null)
    const [search, setSearch] = useState('')
    const tableRef = useRef()
    const [setTable, tableProps, tableFuncs] = useDataTable(tableRef?.current?.setTotal, tableRef)

    const fetchProjects = () => {
        setLoading(true)

        const url = `${RavshanHttps}/api/v2/tasks/get_project`

        const body = JSON.stringify({
            executor: selectedExecutor,
            search,
            order_param: currentSort?.value ? currentSort?.value : 'name',
            order_param_direction: sort ? sort : 'ASC',
            table_type: false
        })

        const options = {
            body,
            method: 'POST',
            headers
        }

        fetch(url, options)
            .then(res => res.json())
            .then(json => {
                setProjects(json.map(p => fromSnakeCase(p)))
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    const fetchAllExecutor = () => {
        const url = `${RavshanHttps}/api/v2/tasks/get_all_attendee`

        fetch(url, { headers })
            .then(res => res.json())
            .then(json => {
                setAllExecutors(json)
            })
            .catch(err => console.error(err))
    }

    const handleSearch = useDebounce(fetchProjects, 1000)

    useEffect(() => {
        if (!isModalAddProject) {
            handleSearch()
            setSelectedProject(null)
        }
    }, [selectedSpace, search, selectedExecutor, isModalAddProject, sort, currentSort])

    useEffect(() => {
        if (view === 'Table') {
            handleSearch()
            setSelectedProject(null)
        }
    }, [view])

    useEffect(() => {
        const selectedProjectLS = sessionStorage.getItem('selectedProject')
        if (selectedProjectLS) {
            setSelectedProject(JSON.parse(selectedProjectLS))
        } else {
            if (path) {
                const project = projects?.filter(el => el?.id === path)
                if (project?.length > 0) {
                    sessionStorage.setItem('selectedProject', JSON.stringify(...project))
                    setSelectedProject(...project)
                }
            }
        }
    }, [projects])

    useEffect(() => {
        if (selectedProject) {
            setIsInfoProject(true)
            setIsModalAddProject(true)
        }
    }, [selectedProject])

    useEffect(() => {
        fetchAllExecutor()
    }, [selectedSpace]);

    return (
        <>

            {isModalAddProject && <ModalAddProject
                isInfoProject={isInfoProject}
                setIsInfoProject={(e) => setIsInfoProject(e)}
                selectedProject={selectedProject}
                setIsModal={(e) => setIsModalAddProject(e)}
                isSelectedProjectLS={isSelectedProjectLS}
                setIsSelectedProjectLS={e => setIsSelectedProjectLS(e)}
            />}

            {isModalListExecutor &&
                <ModalListExecutor
                    setIsModal={(e) => setIsModalListExecutor(e)}
                    selectedExecutor={selectedExecutor}
                    setSelectedExecutor={e => setSelectedExecutor(e)}
                    executors={allExecutors}
                />
            }

            <div className='project-for-tasks'>

                {(projects?.length > 0) &&
                    <>

                        <div className='toolbar-bottom'>
                            <div className='btn-group'>
                                <ButtonBasic
                                    green
                                    width='220px'
                                    onClick={(e) => setIsModalAddProject(true)}
                                    text={<div className='btn-executor-content'><img src={plus_icon} />Добавить новый проект</div>}
                                />

                                <ButtonBasic
                                    grey
                                    width='220px'
                                    onClick={(e) => {
                                        const project = projects?.filter(el => el?.taskId === -1)
                                        const { taskId, name } = project[0]
                                        setSelectedProject(project)
                                        sessionStorage.setItem('taskProjectName', name)
                                        sessionStorage.setItem('taskProjectId', taskId)
                                        navigate(`/tasks/task-list/${taskId}?filter=my_project`)
                                    }}
                                    text={<div className='btn-executor-content'><img src={user_icon} />Посмотреть мои задачи</div>}
                                />

                                <ButtonBasic
                                    grey
                                    width='220px'
                                    text={<div className='btn-executor-content'><img src={user_icon} />Просмотр по сотрудникам</div>}
                                    onClick={(e) => {
                                        const project = projects?.filter(el => el?.taskId === -1)
                                        const { taskId, name } = project[0]
                                        setSelectedProject(project)
                                        sessionStorage.setItem('taskProjectName', name)
                                        sessionStorage.setItem('taskProjectId', taskId)
                                        navigate(`/tasks/task-list/${taskId}`)
                                    }}
                                    disabled={projects?.length === 0}
                                />
                            </div>

                            <div className='toolbar-bottom__right'>

                                <InputDynamicWidth
                                    position='left'
                                    maxWidth='100%'
                                    value={search}
                                    onChange={e => setSearch(e)}
                                />

                                <ButtonsSort 
                                btnSort={btn_sort}
                                sort={sort}
                                setSort={setSort}
                                currentSort={currentSort}
                                setCurrentSort={setCurrentSort}
                                />

                                <ViewSwitch
                                    selectView={view}
                                    setSelectView={(e) => setView(e)}
                                />
                            </div>
                        </div>
                    </>
                }


                <div className='project-for-tasks__content'>
                    {
                        loading
                            ?
                            <Loader />
                            :
                            (
                                (projects?.length < 1) ?
                                    <div className='project-for-tasks__box-card'>
                                        <div className='no-data-content'>
                                            <ButtonBasic
                                                violet
                                                onClick={(e) => setIsModalAddProject(true)}
                                                text='Создать проект'
                                                width='150px'
                                                size='56px'
                                            />
                                            <h3 className='title'>У Вас еще нет проектов</h3>
                                            <p className='text'>Создайте новый проект и формируйте в нем задачи</p>
                                            <img src={nodata_icon} />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {(view == 'Tile-short') && <div className='project-for-tasks__box-card'>
                                            {projects
                                                ?.sort((a, b) => b.isFavorite - a.isFavorite)
                                                .map((project) => (
                                                    <ProjectCard
                                                        key={project.taskId}
                                                        project={project}
                                                        setSelectedProject={setSelectedProject}
                                                        setIsInfoProject={setIsInfoProject}
                                                        setIsModalAddProject={setIsModalAddProject}
                                                        isShowAllExecutor={isShowAllExecutor}
                                                        arrNames={arrNames}
                                                        authorName={authorName}
                                                    />
                                                ))}
                                        </div>}

                                        {(view == 'Tile-long') && <div className='project-for-tasks__list-card'>
                                            {projects?.sort((a, b) => b.isFavorite - a.isFavorite).map((project) => {
                                                const { attendee, taskId, name, startTime, endTime, isFavorite } = project
                                                const dateFrom = new Date(startTime)?.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit' })
                                                const dateTo = new Date(endTime)?.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit' })
                                                return (
                                                    <div className='list-item' key={taskId}
                                                        style={{ border: isFavorite ? '2px solid #1890FF' : '' }}
                                                        onClick={(e) => {
                                                            setSelectedProject(project)
                                                            sessionStorage.setItem('taskProjectName', name)
                                                            sessionStorage.setItem('taskProjectId', taskId)
                                                            navigate(`/tasks/task-list/${taskId}`)

                                                        }}>
                                                        <div className='list-item__header'>
                                                            <div className='img-box'>
                                                                <img className='img' src={banner_basic} />
                                                            </div>
                                                            <h3 className='title'>{name}</h3>
                                                            <img src={info_icon} onClick={(e) => {
                                                                e.stopPropagation()
                                                                setSelectedProject(project)
                                                                setIsInfoProject(true)
                                                                setIsModalAddProject(true)
                                                            }} />
                                                        </div>

                                                        <div className='list-item__content'>
                                                            <div className='sum-and-date'>
                                                                <div className='sum-task'>
                                                                    <img src={done_violet} />
                                                                    <p className='text'>18 задач</p>
                                                                </div>
                                                                <div className='date-box'>
                                                                    <img src={date_icon} />
                                                                    <p className='text'>{dateFrom + '-' + dateTo}</p>
                                                                </div>
                                                            </div>

                                                            <div className='employees-container'>
                                                                {isShowAllExecutor ? arrNames(attendee, authorName) : arrNames(attendee, authorName)?.splice(0, 6)}
                                                                {(!isShowAllExecutor && attendee?.length > 6) && <p className='sum_executor'>{'+' + (attendee?.length - 6)}</p>}
                                                            </div>
                                                            <div className='date-of-creation'>
                                                                <p className='text'>Cоздан 10.10.2023</p>
                                                            </div>
                                                            <button className='btn-delete'></button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>}

                                        {(view == 'Table') &&
                                            <TableBox
                                                {...tableProps}
                                                ref={tableRef}
                                                paginator={true}
                                            />
                                        }
                                    </>
                            )
                    }
                </div>

                <button className='widget-chat'
                    onClick={() => {
                        window.scrollTo(0, 0)
                        navigate('/chat')
                    }}
                >
                    <img src={chat_big_icon} />
                </button>
            </div >
        </>
    )
}

export { ProjectForTasks }