import customHeaders, {getSpace} from '../../../common/headers'
import close_icon from './img/close_icon.svg'
import { Loader } from '../../../common/components/Loader/Loader'
import { CarouselSlick } from '../../CarouselSlick/CarouselSlick'
import { ModalCardCreated } from '../ModalCardCreated/ModalCardCreated'
import { useState, useEffect } from 'react'
import { KonstantinCreateProductHttps } from '../../../fetchUrls'
import useGeneralStore from '../../../store/general'
import './ModalProductPreview.scss'

const parse = require('html-react-parser')

const ModalProductPreview = ({ setIsModal, selectedCategories, currentProductId, isEdit = false, productTemporary }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    // const [productTemporary, setProductTemporary] = useState({})
    const [name, setName] = useState('')
    const [basic, setBasic] = useState([])
    const [basicPrice, setBasicPrice] = useState([])
    const [offerId, setOfferId] = useState([])
    const [description, setDescription] = useState([])
    const [required, setRequired] = useState([])
    const [additional, setAdditional] = useState([])
    const [media, setMedia] = useState([])

    const [loading, setLoading] = useState(false)
    const [isModalSuccessful, setIsModalSuccessful] = useState(false)

    // const fetchProduct = (product_id) => {
    //     setLoading(true)
    //     const url = `${KonstantinCreateProductHttps}/master_products/temporary/${product_id}?used_to=${isEdit ? 'update' : 'create'}`

    //     fetch(url, { method: 'GET', headers })
    //         .then(async res => {
    //             if (res.ok) {
    //                 return res.json()
    //             }
    //             else {
    //                 const text = await res.json()
    //                 throw new Error(text?.detail)
    //             }
    //         })
    //         .then(data => {
    //             if (data?.attributes) {
    //                 setProductTemporary(data?.attributes)
    //             }
    //         })
    //         .catch(err => console.error(err))
    //         .finally(() => setLoading(false))
    // }

    // useEffect(() => {
    //     if (currentProductId) {
    //         fetchProduct(currentProductId)
    //     }
    // }, [selectedSpace])

    console.log(productTemporary)

    useEffect(() => {
            // Basic
            let basicPrice = []
            setBasic(
                [...productTemporary?.basic?.filter(el => {
                    const attr_id = el?.attribute_id
                    if (attr_id === "name") {
                        setName(el)
                    } else if (attr_id === "old_price" || attr_id === "price") {
                        basicPrice.push(el)
                    } else if (attr_id === "offer_id") {
                        setOfferId(el)
                    } else {
                        return el
                    }
                })]
            )
            setBasicPrice(basicPrice)

            // Description
            setDescription(productTemporary?.description)

            // Required
            setRequired(productTemporary?.required)

            // Additional
            setAdditional(productTemporary?.additional)

            // Media
            const PTImages = productTemporary?.images?.value
            let images = PTImages?.length > 0 ? PTImages : []

            const PTVideos = productTemporary?.videos?.value
            let videos = PTVideos?.length > 0 ? PTVideos : []

            setMedia([...images, ...videos])
        
    }, [productTemporary, selectedSpace])


    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            {isModalSuccessful && <ModalCardCreated setIsModal={(e) => setIsModalSuccessful(e)} />}

            <div className='modal-product-preview'>
                <button className='btn-close'
                    onClick={(e) => setIsModal(false)}
                ><img src={close_icon} /></button>
                {
                    loading
                        ?
                        <Loader />
                        :
                        <>
                            <div className='modal-product-preview__header'>
                                <h2 className='title'>{name?.value ? name?.value : 'Название не указано'}</h2>

                                <div className='breadcrumbs-and-article'>
                                    {selectedCategories?.length > 0 &&
                                        <div className='category-breadcrumbs__content'>
                                            {selectedCategories?.map(l => <p className='category-breadcrumbs__item'
                                                key={l.value.id}>{l.label}</p>)}
                                        </div>}
                                    <p className='text_article'>Артикул: <span>{offerId?.value ? offerId?.value : 'Отсутвует'}</span></p>
                                </div>

                                {/* <ButtonBasic
                                    green
                                    text='Создать карточку'
                                    size='56px'
                                    width='163px'
                                    onClick={(e) => )}
                                /> */}
                            </div>

                            <div className='modal-product-preview__content'>
                                <div className='data-content'>
                                    <div className='slider-and-price'>
                                        <div className='img-content'>
                                            <CarouselSlick imgArr={media} />
                                        </div>

                                        <div className='price-box'>
                                            <div className='price-box_price'>
                                                <p className='text'>{basicPrice[0]?.value ? (parseInt(basicPrice[0]?.value)).toLocaleString() : 0} <span>₽</span></p>
                                            </div>
                                            <div className='price-box_old-price'>
                                                <p className='text_old-price'>Старая цена</p>
                                                <p className='text'>{basicPrice[1]?.value ? (parseInt(basicPrice[1]?.value)).toLocaleString() : 0} <span>₽</span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='characteristics-group'>
                                        <div className='description-content'>

                                            <h4 className='title-medium'>Общие данные</h4>
                                            <div className='description-content__item'>
                                                <div className='characteristics-items'>
                                                    {
                                                        basic?.map(el => {
                                                            const { front_name, value } = el
                                                            return (
                                                                <div className='characteristics-item' key={JSON.stringify(el)}>
                                                                    <p className='text_grey'>{front_name}</p>
                                                                    <div className='border'></div>
                                                                    <p className='text'>{value ? value : 'Отсутствует'}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            <h4 className='title-medium'>Обязательные характеристики</h4>
                                            <div className='description-content__item'>
                                                <div className='characteristics-items'>
                                                    {
                                                        required?.map(el => {
                                                            const { attribute_name, value } = el
                                                            return (
                                                                <div className='characteristics-item'>
                                                                    <p className='text_grey'>{attribute_name}</p>
                                                                    <div className='border'></div>
                                                                    <p className='text'>{value ? value : 'Отсутвует'}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            <h4 className='title-medium'>Описание</h4>
                                            <div className='description-content__item'>
                                                <p className='text_description'>{description?.value ? parse(description?.value) : 'Отсутвует'}</p>
                                            </div>

                                        </div>

                                        <div className='description-content'>
                                            <h4 className='title-medium'>Дополнительные характеристики</h4>
                                            <div className='characteristics-items'>
                                                {
                                                    additional?.map(el => {
                                                        let { attribute_name, attribute_id, value } = el

                                                        if (Array.isArray(value)) {
                                                            if (!value?.length > 0) {
                                                                value = ''
                                                            }
                                                        }

                                                        if (attribute_id === "22073") {
                                                            value = value ? 'Да' : 'Нет'
                                                        }

                                                        return (
                                                            <div className='characteristics-item'>
                                                                <p className='text_grey'>{attribute_name}</p>
                                                                <div className='border'></div>
                                                                <p className='text'>{value || value?.length > 0 ? value : 'Отсутвует'}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className='info-block'>
                                                <p className='text_info'>Что можно улучшить?</p>

                                                <div className='info-block__content'>
                                                    <div className='info-block__item'>Сделать развёрнутое описание товара используя макси-мальное количество символов и ключевых фраз</div>
                                                    <div className='info-block__item'>Сделать развёрнутое описание товара используя макси-мальное количество символов и ключевых фраз</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div >
        </div>
    )
}

export { ModalProductPreview }