import { useState } from 'react'
import { TableBox, LineChart, ButtonBasic } from '@lk-gtcom/ecomlab-components'
import { useCustomizationStore } from '../../store/customization'
import { useShallow } from 'zustand/react/shallow'
import './Customization.scss'

const Customization = () => {
    const [currentItem, setCurrentItem] = useState({})
    const [currentItemRowId, setCurrentItemRowId] = useState(-1)
    const { data, setData } = useCustomizationStore(useShallow(state => ({
        data: state.data,
        setData: state.setData
    })))


    const onDeleteItem = (e, id, rowId) => {
        e.stopPropagation()

        const newData = [...data]
            .map(el => {
                if (el.rowId === rowId) {
                    const res = el.items.filter(el => el?.id !== id)
                    return { rowId: el.rowId, items: res }
                } else {
                    return el
                }
            })
        setData(newData)
    }

    const onAddItemAtRow = (rowId) => {
        const newData = [...data]
            .map(el => el.rowId === rowId ? { rowId, items: [...el?.items, currentItem] } : el)
            .map(el => {
                if (el.rowId === currentItemRowId) {
                    const res = el.items.filter(el => el?.id !== currentItem?.id)
                    return { rowId: el.rowId, items: res }
                } else {
                    return el
                }
            })
        setData(newData)
    }

    const onResize = (elemData) => {

        if (elemData) {
            const data1 = [...data]?.map(el => {
                if (Array.isArray(el)) {
                    return el?.map(elem => elem?.id === elemData?.id ? elemData : elem)
                } else {
                    return el?.id === elemData?.id ? elemData : el
                }
            })

            setData(data1)
        }
    }

    const isArrRow = (item, place) => {
        if (item?.rowId) {
            const { rowId, items } = item

            if (Array.isArray(items)) {
                return <div
                    data-id={rowId}
                    className='row'
                    onDragOver={e => {
                        e.preventDefault()
                        e.target.classList = 'row over'
                    }}
                    onDragLeave={e => {
                        e.preventDefault()
                        e.target.classList = 'row'
                    }}
                    onDrop={e => {
                        e.preventDefault()
                        onAddItemAtRow(rowId)
                        e.target.classList = 'row'
                    }}
                >
                    {items.map((elem, place) => typeChecker(elem, place, rowId))}
                </div>
            }
        }
    }

    const onDrop = (e, index) => {
        e.preventDefault()
        e.stopPropagation()
        // const prev = [...data]
        // const newArr = prev.filter(({id}) => id !== currentItem.id)        
        // setData([...newArr.slice(0, index), currentItem, ...newArr.slice(index, newArr.length)])

        const rowItem = [...data].filter(({ rowId }) => rowId === currentItemRowId)
        const newArr = [...data].filter(({ rowId }) => rowId !== currentItemRowId)

        setData([...newArr.slice(0, index), ...rowItem, ...newArr.slice(index, newArr.length)])
    }

    const typeChecker = (elem, place, rowId) => {
        if (elem?.id) {
            const { type, id, height, width } = elem
            switch (type) {
                case 'chart':
                    return (
                        <div
                            key={id}
                            draggable={true}
                            onDragStart={e => {
                                setCurrentItem(elem)
                                setCurrentItemRowId(rowId)
                            }}
                            onDrop={e => onDrop(e, place)}
                            onDragOver={e => e.preventDefault()}
                            style={{
                                border: '1px solid grey', position: 'relative',
                                resize: 'both', overflow: 'auto',
                                height, width

                            }}
                            onMouseUp={e => {
                                onResize({ type, id, height: e.target.offsetHeight, width: e.target.offsetWidth })
                            }}
                        >

                            <div
                                style={{
                                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                    background: 'rgba(0,0,0,.2)', zIndex: 100,
                                }}
                            ></div>
                            <ButtonBasic
                                text='X'
                                onClick={e => onDeleteItem(e, id, rowId)}
                                style={{ zIndex: 101, position: 'absolute', width: 35, minWidth: 35 }}
                            />
                            <LineChart

                            />
                        </div>
                    )
                case 'table':
                    return (
                        <div
                            key={id}
                            draggable={true}
                            onDragStart={e => {
                                setCurrentItem(elem)
                                setCurrentItemRowId(rowId)
                            }}
                            onDrop={e => onDrop(e, place)}
                            onDragOver={e => e.preventDefault()}
                            style={{
                                border: '1px solid grey', position: 'relative',
                                resize: 'both', overflow: 'auto',
                                height, width
                            }}
                            onMouseUp={e => {
                                onResize({ type, id, height: e.target.offsetHeight, width: e.target.offsetWidth })
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                    background: 'rgba(0,0,0,.2)', zIndex: 100,
                                }}
                            ></div>
                            <ButtonBasic
                                text='X'
                                onClick={e => onDeleteItem(e, id, rowId)}
                                style={{ zIndex: 101, position: 'absolute', width: 35, minWidth: 35 }}
                            />
                            <TableBox

                            />
                        </div>
                    )
            }
        }
    }

    const createItem = (type) => {
        const size = {
            chart: {
                height: 400,
                width: 500
            },
            table: {
                width: 900,
                height: 800
            }
        }
        const newData = [
            ...data,
            {
                rowId: new Date().getTime() * Math.round(Math.random(0, 1) * 1000),
                items: [{
                    id: new Date().getTime(),
                    type,
                    ...size[type]
                }]
            }
        ]

        setData(newData)
    }


    return (
        <div
            className='customizaton'
        >
            <div className='btn-group' >
                <ButtonBasic
                    text='Добавить таблицу'
                    onClick={e => createItem('table')}
                />

                <ButtonBasic
                    text='Добавить график'
                    onClick={e => createItem('chart')}
                />
            </div>
            {data?.map((elem, place) => isArrRow(elem, place))}
        </div>
    )
}

export { Customization } 