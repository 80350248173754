import React from 'react';
import './CardDiscount.scss';

interface CardDiscountProps {
  key?: string | number; // key is actually not needed here since it's not used as a prop
  header?: [string, string] | null;
  data?: [string | number, string | number] | null;
  title?: string;
}

const CardDiscount: React.FC<CardDiscountProps> = ({ header, data, title }) => {
  // Extract percentage parts safely
  const [mainPercentage, additionalText] = title?.split('%') || ['-', ''];

  return (
    <div className='card-discount'>
      <h3 className='card-discount__title-card'>Скидка</h3>

      <div className='card-discount__content'>
        {header?.[0] ? (
          <p className='card-discount__text-header'>
            {header[0]} - <span>{data?.[0] ?? '-'}</span>
          </p>
        ) : (
          <p className='card-discount__text-header'>-</p>
        )}

        {header?.[1] ? (
          <p className='card-discount__text-header'>
            {header[1]} - <span>{data?.[1] ?? '-'}</span>
          </p>
        ) : (
          <p className='card-discount__text-header'>-</p>
        )}
      </div>

      <div className='card-discount__discount-content'>
        <p className='card-discount__text'>
          {mainPercentage}
          <span>%</span>
        </p> 
        {additionalText && <span className='card-discount__text-min'>{additionalText}</span>}
      </div>
    </div>
  );
};

export { CardDiscount };