import { useEffect, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import "./SendMessage.scss"

const SendMessage = ({
    disabled,
    frequentAnswers = false,
    message,
    value,
    onChange,
    onSendAction,
    isEdit = false,
    setIsEdit = false,
    actionBtnText,
    placeholder,
    showVariables = false,
    varaibles,
    btn
}) => {

    const [isVarModal, setIsVarModal] = useState(false)
    const [currentVar, setCurrentVar] = useState('')
    const [currentVarInd, setCurrentVarInd] = useState(-1)

    useEffect(() => {
        if (isEdit) {
            onChange(message)
        } else {
            onChange('')
        }
    }, [isEdit]);

    return (
        <div className='send-message_container'>

            <div className='send-message'>
                {/* <div className='send-message__btn-group'>
                    <button className='btn-smile'>
                        <img src={smile_icon} />
                    </button>
                    <button className='btn-attach'>
                        <img src={attach_icon} />
                        <input className='input-file' type='file' />
                    </button>
                </div> */}


                <div className='field-and-changed-message'>
                    {isEdit && <div className='changed-message'>
                        <span>Редактирование сообщение</span>
                        <button className='btn-close' onClick={(e) => setIsEdit(false)}></button>
                    </div>}
                    {
                        isVarModal &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                varaibles &&
                                Object.keys(varaibles)
                                    ?.filter(key => key?.toLocaleLowerCase()?.indexOf(currentVar?.replace(' ', '')?.toLocaleLowerCase()) !== -1)
                                    ?.map(el => {
                                        return <span
                                            onClick={e => {
                                                setIsVarModal(false)
                                                const isSpace = currentVar?.indexOf(' ') !== -1
                                                const textArr = value.split(' ')

                                                onChange(
                                                    textArr?.map((elem, ind) => {
                                                        if (ind === currentVarInd) {
                                                            return isSpace ? ` ${varaibles[el]?.value}` : varaibles[el]?.value
                                                        } else {
                                                            return elem
                                                        }
                                                    }).join(' ')
                                                )
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {el}: {varaibles[el]?.ui}
                                        </span>
                                    })
                            }
                        </div>
                    }

                    <TextareaAutosize
                        height='40px'
                        style={{ backgroundColor: (disabled && !isEdit) ? '#e0e0e0' : '', overflow: 'auto' }}
                        placeholder={placeholder ? placeholder : 'Напишите Ваш ответ здесь'}
                        value={value}
                        disabled={disabled && !isEdit}
                        onChange={e => {
                            const text = e.target.value
                            const textArr = text.split(' ')
                            const lastValue = textArr?.[textArr?.length - 1]
                            const match = lastValue.match(/(^\@[a-zA-Z]+|[^@]\@[a-zA-Z]+|@$)/)

                            if (showVariables) {
                                if (match?.[0]) {
                                    setCurrentVar(lastValue)
                                    setCurrentVarInd(textArr?.length - 1)
                                } else {
                                    setIsVarModal(false)
                                }
                            }
                            onChange(text)
                        }}
                        onKeyDown={e => {
                            if (showVariables) {
                                if (e.key === '@') {
                                    setIsVarModal(true)
                                }
                            }
                        }}
                    />
                </div>

                {btn}

                <ButtonBasic
                    green
                    disabled={disabled && !isEdit}
                    minWidth='110px'
                    width='110px'
                    maxWidth='110px'
                    text={actionBtnText ? actionBtnText : 'Отправить'}
                    onClick={e => onSendAction()}
                />
            </div>
        </div>

    )
}

export { SendMessage }