import React, { useState, useEffect } from 'react';
import plus_icon from './img/plus_icon.svg';
import drop_icon from './img/drop_icon.svg';
import "./ButtonDrop.scss";

interface ButtonItem {
  label: string;
  func?: (value: boolean) => void;
}

interface ButtonDropProps {
  list_btn: ButtonItem[];
  style?: React.CSSProperties;
}

const ButtonDrop: React.FC<ButtonDropProps> = ({ list_btn, style }) => {
  const [isDrop, setIsDrop] = useState<boolean>(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    const hideMenu = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest('.btn-drop')) {
        setIsDrop(false);
      }
    };

    document.addEventListener('click', hideMenu);

    return () => {
      document.removeEventListener('click', hideMenu);
    };
  }, []);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDrop(prev => !prev);
  };

  const handleOptionClick = (func: ((value: boolean) => void) | undefined) => (e: React.MouseEvent) => {
    e.stopPropagation();
    func?.(true);
    setIsDrop(false);
  };

  return (
    <div
      className='btn-drop'
      style={style || {}}
      onClick={handleButtonClick}
    >
      <button className='btn-drop__button'>
        <img src={plus_icon} alt="Add" />
      </button>
      <img src={drop_icon} alt="Dropdown" />

      {isDrop && (
        <div className='btn-drop__drop-container'>
          {list_btn.map(({ label, func }, index) => (
            <button
              className='btn-drop__btn-drop'
              key={`${label}-${index}`}
              onClick={handleOptionClick(func)}
            >
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export { ButtonDrop }