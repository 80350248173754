import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './NicheSearchVer2.scss';
import { Tabs } from '@lk-gtcom/ecomlab-components';
import { SearchComponent } from '@lk-gtcom/ecomlab-components';
import { LineChart } from '../../charts/LineChart/LineChart';
import { fetchCompetitorsBrandFilter } from '../../common/filtersFunc';
import { CardStatistics } from '@lk-gtcom/ecomlab-components';
import { ModalNiche } from '../Modal/ModalNiche/ModalNiche';
import useGeneralStore from '../../store/general';

const list_statistic = [
  {
    label: 'Выручка',
    value: 86,
  },
  {
    label: 'Средняя оценка',
    value: 86,
  },
  {
    label: 'Количество фото в отзывах',
    value: 86,
  },
  {
    label: 'Среднее количество отзывов в день',
    value: 86,
  },
  {
    label: 'Кол-во товаров, по которым были отзывы',
    value: 86,
  },
  {
    label: 'Кол-во неоотвеченных отзывов',
    value: 86,
  },
];

const btn_tab = [
  {
    label: 'Сводка',
    value: 'summary',
  },
  {
    label: 'Товары',
    value: 'product',
  },
  {
    label: 'Бренды',
    value: 'brand',
  },
  {
    label: 'Продавцы',
    value: 'seller',
  },
  {
    label: 'Выбор ниши',
    value: 'choosing-niche',
  },
  {
    label: 'Готовые подборки',
    value: 'ready-made-selections',
  },
];

const NicheSearchVer2 = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const [pageValue, setPageValue] = useState('parameters');
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const navigate = useNavigate();
  const currentPath = useParams()['*'];
  const [mp, setMp] = useState('wildberries');
  const activeTab = useParams()['*'];
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [errSearch, setErrSearch] = useState(false);
  const [isSearchLastPage, setIsSearchLastPage] = useState(false);

  const fetchFilter = ([searchParam, page = 1]) => {
    setSearchLoading(true);
    fetchCompetitorsBrandFilter({
      selectedSpace: selectedSpace?.value,
      searchParam,
      setData: setSearchData,
      errorCallback: () => setErrSearch(),
      cleanupCallback: () => setSearchLoading(false),
      emptyResponseCallback: () => setIsSearchLastPage(true),
      limit: 10,
      page,
    });
  };

  const resetFilter = () => {
    setIsSearchLastPage(false);
    setSearchData([]);
  };

  useEffect(() => {
    setMp(selectedMarketplace.value);
  }, [selectedMarketplace]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  return (
    <>
      <div className="niche-search-ver2">
        <h1 className="title_main">Поиск ниши</h1>

        <div className="toolbar-top">
          <SearchComponent
            fetchCallback={fetchFilter}
            fetchData={() => {}}
            loading={searchLoading}
            data={searchData}
            resetData={resetFilter}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            errSearch={errSearch}
            setErrSearch={(e) => setErrSearch(e)}
            placeholder="Введите название бренда, например ADIDAS"
            errText="Неверное название бренда "
            isSearchLastPage={isSearchLastPage}
          />
        </div>

        <Tabs tabs={btn_tab} pageValue={pageValue} />

        <div className="charts-and-cards-box">
          <div className="cards-box">
            {list_statistic.map(({ value, label }, ind) => {
              return (
                <CardStatistics key={ind + label} title={label} value={value} />
              );
            })}
          </div>

          <div className="cards-box">
            {list_statistic.map(({ value, label }, ind) => {
              return (
                <CardStatistics key={ind + label} title={label} value={value} />
              );
            })}
          </div>

          <div className="charts-group">
            <LineChart
              direction="Y"
              legendPosition="bottom"
              datasets_prop={[]}
              labels_prop={[]}
              loading={null}
              nameChart="Выручка и продажи"
              maxHeight="300px"
            />

            <LineChart
              direction="Y"
              legendPosition="bottom"
              datasets_prop={[]}
              labels_prop={[]}
              loading={null}
              nameChart="Кол-во продавцов и выручка на продавца"
              maxHeight="300px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { NicheSearchVer2 };
