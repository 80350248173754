import { useState, useEffect, useRef } from 'react'
import calendar_img from './img/calendar.svg'
import DatePicker from 'react-datepicker'
import "./DateBox.scss"
import ru from 'date-fns/locale/ru'

const DateBox = ({ startDate, setStartDate, disabled, description }) => {

    const [dropDirection, setDropDirection] = useState(false)

    useEffect(() => {
        const directionDrop = () => {
            const popup_el = document.querySelector('.calendar_wrapper')
            if (popup_el) {
                setDropDirection(true)
            } else setDropDirection(false)
        }
        document.addEventListener('click', directionDrop)

        return () => document.removeEventListener('click', directionDrop)
    });

    return (
        <div className={`date-box-single ${description ? 'date-box-single__description-box' : ''}`} style={{ border: dropDirection ? '2px solid #1890FF' : '' }} >
            <DatePicker
                disabled={disabled}
                selected={startDate}
                startDate={startDate}
                onChange={(e) => setStartDate(e)}
                locale={ru}
                dateFormat='dd.MM.yy'
            />
            {description && <p className='date-box-single__description'>{description}</p>}
            <img className='calendar-img' src={calendar_img} />
        </div>
    )
}

export { DateBox }