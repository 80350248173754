import customHeaders, { getSpace } from '../../common/headers';
import add_icon from './img/add_icon.svg';
import rules_tab from './img/rules_tab.svg';
import percent_tab from './img/percent_tab.svg';
import refresh_icon from './img/refresh_icon.svg';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
  fetchMasterBrandFilter,
  fetchMasterCategoryFilter,
} from '../../common/filtersFunc';
import {
  DropDownSelector,
  SearchComponent,
  Tabs,
  ButtonBasic,
  TableBox,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import {
  KonstantinCreateProductHttps,
  KonstantinMasterHttp,
  KonstantinMasterHttps,
  OlegHttps,
} from '../../fetchUrls';
import './SetPriceAndMarginMasterProduct.scss';
import useGeneralStore from '../../store/general';
import { ModalRules } from '../Modal/ModalRules/ModalRules';
const btn_tab = [
  {
    label: 'Установка цен',
    value: 'price_setting',
    icon: percent_tab,
  },
  {
    label: 'Правила установки цен',
    value: 'rules',
    icon: rules_tab,
  },
];

const SetPriceAndMarginMasterProduct = () => {
  const activeTab = useParams()['*'];
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState(activeTab || 'price_setting');

  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [productPage, setProductPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [productQuery, setProductQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { total } = tableProps;
  const { loading, sort } = tableProps;

  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const [checkZero, setCheckZero] = useState(false);

  const [isModal, setIsModal] = useState(false);
  const service_id = 3;
  const [rule_id, setRuleId] = useState(1);
  const [ruleInfo, setRuleInfo] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [ruleName, setRuleName] = useState('');
  const [isClear, setIsClear] = useState(false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [dateFrom, dateTo] = date;

  const navigate = useNavigate();

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  const filtersMasterCategory = () => {
    const abortController = new AbortController();
    fetchMasterCategoryFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
    return () => abortController.abort();
  };

  const filtersMasterBrand = () => {
    const abortController = new AbortController();
    fetchMasterBrandFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
    return () => abortController.abort();
  };

  useEffect(() => {
    filtersMasterCategory();
  }, [categoryPage, categoryQuery, dateFrom, dateTo]);

  useEffect(() => {
    filtersMasterBrand();
  }, [dateFrom, dateTo, brandPage, brandQuery]);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/tables/prices${
        sort?.length > 0
          ? '?order_param=' +
            sort[0] +
            '&order_param_direction=' +
            sort[1] +
            '&without_zero_stock=' +
            checkZero
          : '?without_zero_stock=' + checkZero
      }`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/tables/prices${
        sort?.length > 0
          ? '?order_param=' +
            sort[0] +
            '&order_param_direction=' +
            sort[1] +
            '&without_zero_stock=' +
            checkZero
          : '?without_zero_stock=' + checkZero
      }`;
    }

    let brand_id_list;
    let category_id_list;
    let offer_id_name_search = searchValue;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam,
      offer_id_name_search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`;
    }

    let brand_id_list;
    let category_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setSearchData(json.map(({ id, name }) => ({ label: name, value: id })));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchUpdatePrices = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    } else {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    }

    const prices = checkedProducts?.map((el) => {
      const { product_id, Артикул, Цена } = el;
      let old_price = +el?.['Цена до скидки'];

      return {
        product_id,
        offer_id: Артикул,
        price: Цена,
        old_price,
        currency: 'RUB',
      };
    });

    const body = JSON.stringify({
      prices,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        const abortController = new AbortController();
        fetchTableData([], abortController);
        return () => abortController.abort();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSaveOnePrice = async (e) => {
    const { inputEvent, actionEvent, setStatus } = e;
    const offerId = inputEvent.id?.[0];
    const productId = actionEvent?.[0]?.product_id;
    const colName = actionEvent?.[2];
    let newPrice;

    newPrice = +inputEvent?.value;

    if (newPrice) {
      setStatus('loading');
      fetchOnePrice(productId, offerId, newPrice, setStatus, colName);
    } else {
      setStatus('error');
    }
  };

  const fetchOnePrice = (productId, articul, newPrice, setStatus, colName) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    } else {
      url = `${KonstantinCreateProductHttps}/master_products/prices`;
    }

    const prices = [
      {
        obj_to_change: colName === 'Цена' ? 'price' : 'old_price',
        product_id: productId,
        offer_id: articul,
        ...(colName === 'Цена' ? { price: newPrice } : { old_price: newPrice }),
        currency: 'RUB',
      },
    ];

    const body = JSON.stringify({ prices });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          setStatus('success');
          return res.json();
        } else {
          throw new Error('Failed to save price');
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        setStatus('error');
        console.error(err);
      });
  };

  const fetchTableRules = (params) => {
    let sort, filters;
    if (params) {
      [sort, filters] = params;
    }
    setLoading(true);

    const url = `${OlegHttps}/api/v1/${service_id}/client_rule/table`;

    const body = JSON.stringify({
      filters,
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
        setLoading(false);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onActionHandler = (prop) => {
    const name = prop?.['Название правила'];
    const status = prop?.['Статус'];
    const rule_id = prop?.Rule_id?.[0];
    setRuleInfo({ name, status, rule_id, isEdit: true, isCreate: false });
    setIsModal(true);
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (pageValue === 'price_setting') {
      resetTable();
      fetchTableData([], abortController);
    } else {
      resetTable();
      fetchTableRules();
    }

    return () => abortController.abort();
  }, [checkZero, pageValue]);

  const handleSearch = useCallback((e) => {
    const abortController = new AbortController();
    fetchSearch(e, abortController);
    return () => abortController.abort();
  });

  const handleSaveSuccess = () => {
    fetchTableRules();
  };

  return (
    <>
      {isModal && (
        <ModalRules
          isEdit={isEdit}
          setIsEdit={(e) => setIsEdit(false)}
          isCreate={!isEdit}
          {...ruleInfo}
          rule_id={rule_id}
          setRuleIdPar={setRuleId}
          ruleName={ruleName}
          service_id={service_id}
          setRuleInfo={(e) => setRuleInfo(e)}
          setIsModal={setIsModal}
          handleSaveSuccess={handleSaveSuccess}
        />
      )}
      <div className="set-price-and-margin-master-product">
        <h1 className="title_main">
          {pageValue == 'rules'
            ? 'Цены мастер товаров'
            : 'Установка цен мастер товаров'}
        </h1>

        <div className="toolbar-top">
          <FilterContainer
            onReset={(e) => {
              setSelectedProduct([]);
              setSelectedCategory([]);
              setSelectedBrand([]);
              setIsClear(!isClear);
              resetTable();
            }}
          >
            <DropDownSelector
              options_prop={categoryData}
              state={selectedCategory}
              setState={(e) => setSelectedCategory(e)}
              placeholder="Категория"
              multi={true}
              fetchCallback={(e) => setCategoryPage(e)}
              setInputChange={(e) => setCategoryQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              onBlur={() => {
                const abortController = new AbortController();
                filtersMasterBrand();
                if (pageValue === 'price_setting') {
                  resetTable();
                  fetchTableData([], abortController);
                } else {
                  resetTable();
                  fetchTableRules();
                }
              }}
            />

            <DropDownSelector
              options_prop={brandData}
              state={selectedBrand}
              setState={(e) => setSelectedBrand(e)}
              placeholder="Бренд"
              multi={true}
              fetchCallback={(e) => setBrandPage(e)}
              setInputChange={(e) => setBrandQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              onBlur={() => {
                const abortController = new AbortController();
                if (pageValue === 'price_setting') {
                  resetTable();
                  fetchTableData([], abortController);
                } else {
                  resetTable();
                  fetchTableRules();
                }
              }}
            />

            {pageValue == 'rules' && (
              <DropDownSelector
                options_prop={productData}
                state={selectedProduct}
                setState={(e) => setSelectedProduct(e)}
                className="connections_page_selector"
                placeholder="Бренд"
                fetchCallback={(e) => setProductPage(e)}
                setInputChange={(e) => setProductQuery(e)}
                multi
              />
            )}
            {pageValue != 'rules' && (
              <SearchComponent
                searchValue={searchValue}
                errSearch={false}
                placeholder="Искать по артикулу или названию"
                errText="Неверный артикул или название"
                setSearchValue={setSearchValue}
                fetchData={fetchTableData}
                fetchCallback={handleSearch}
                data={searchData}
                onClearDependence={[isClear]}
              />
            )}
            <Checkbox value={checkZero} onChange={(e) => setCheckZero(e)}>
              Без нулевого остатка
            </Checkbox>
          </FilterContainer>

          <ButtonBasic
            violet
            // disabled={isDisabledBtn}
            text={
              pageValue != 'rules' ? (
                <div className="content-btn" onClick={fetchUpdatePrices}>
                  Обновить цены
                  <img src={refresh_icon} />
                </div>
              ) : (
                <div className="content-btn">
                  Добавить правило <img src={add_icon} />
                </div>
              )
            }
            onClick={(e) => {
              if (pageValue == 'rules') {
                setIsModal(true);
              }
            }}
            width="auto"
            size="40px"
          />
        </div>

        <div className="toolbar-medium">
          <Tabs tabs={btn_tab} pageValue={pageValue} />
        </div>
        {pageValue === 'price_setting' ? (
          <TableBox
            {...tableProps}
            paginator={true}
            ref={paginatorRef}
            onCheck={(e) => setCheckedProducts(e)}
            fetchCallback={fetchTableData}
            tableUrl={tableUrl}
            tableBody={tableBody}
            // onInputAction={(e) => handleSaveOnePrice(e)}
            onInputAction={(e) => handleSaveOnePrice(e)}
          />
        ) : (
          <TableBox
            tableUrl={tableUrl}
            tableBody={tableBody}
            onAction={(e) => {
              const ruleId = e['Идентификатор правила']
                ? e['Идентификатор правила']
                : null;
              onActionHandler(e);
              setRuleId(Number(ruleId));
            }}
            ref={paginatorRef}
            paginator={true}
            fetchCallback={(e) => fetchTableRules(e)}
            {...tableProps}
            statistic={[
              {
                label: 'Правил',
                sum: total,
              },
            ]}
          />
        )}
      </div>
    </>
  );
};

export { SetPriceAndMarginMasterProduct };
