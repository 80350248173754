import React, { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import customHeaders, { getSpace } from "../../common/headers";
import { RavshanHttps } from "../../fetchUrls";
import useGeneralStore from "../../store/general";
import { useShallow } from "zustand/react/shallow";
import { TabsGreen } from "../../components/TabsGreen/TabsGreen";
import { ButtonActionDrop } from "../../components/Buttons/ButtonActionDrop/ButtonActionDrop";
import { TitlePage } from '../../components/TitlePage/TitlePage';
import './SpacePage.scss';

// Импорт изображений
import userIcon from './img/bx-user.svg';
import userCircleIcon from './img/bx-user-circle.svg';
import groupIcon from './img/bx-group.svg';
import pinIcon from './img/bx-pin.svg';
import starIcon from './img/bxs-star.svg';

// Типы данных
interface SpaceData {
  id: string;
  name: string;
  is_admin: boolean;
  author?: {
    id: string;
  };
  employees?: any[];
}

interface TabButton {
  label: string;
  count: number;
  value: string;
  icon: string;
}

interface CardSpaceProps {
  spaceData: SpaceData;
  setIsEditSpace: (value: boolean) => void;
  setIsModal: (value: boolean) => void;
  deleteFunc: (id: string) => void;
  refreshFetch: () => void;
  setCurrentSpace: (space: SpaceData) => void;
}

interface SpacePageProps {
  setIsModalCreatingNewSpace: (value: boolean) => void;
  setIsEditSpace: (value: boolean) => void;
  isModalCreatingNewSpace: boolean;
  fetchGetSpace: () => void;
  spaceList: SpaceData[];
  setIsModalActive: (value: boolean) => void;
}

const client_id = localStorage.getItem('client_id');

const CardSpace: React.FC<CardSpaceProps> = ({
  spaceData,
  setIsEditSpace,
  setIsModal,
  deleteFunc,
  refreshFetch,
  setCurrentSpace
}) => {
  const { setPageValue1 } = useGeneralStore();

  const is_admin = spaceData?.is_admin;
  const btns = [
    {
      btn: 'Редактировать',
      func: () => { },
    },
    {
      btn: 'В избранное',
      func: () => { },
    },
    {
      btn: 'Закрепить',
      func: () => { },
    },
    {
      btn: 'Удалить',
      func: () => {
        deleteFunc(spaceData.id);
        setTimeout(() => refreshFetch(), 500);
      },
    },
  ];

  const handleCardClick = () => {
    setCurrentSpace(spaceData);
    setIsEditSpace(true);
    setPageValue1('employee');
    setIsModal(true);
  };

  const handleButtonClick = (pageValue: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentSpace(spaceData);
    setIsEditSpace(true);
    setPageValue1(pageValue);
    setIsModal(true);
  };

  return (
    <div className='card-space' onClick={handleCardClick}>
      <div className='card-space__header'>
        <div className='card-space__header-panel'>
          {is_admin ? (
            <div className="card-space__admin-badge">
              <img src={userCircleIcon} alt="Админ" /> Админ
            </div>
          ) : (
            <div className="card-space__user-badge">
              <img src={userIcon} alt="Сотрудник" /> Сотрудник
            </div>
          )}

          <img src={starIcon} alt="Избранное" />
          <img src={pinIcon} alt="Закрепить" />

          {is_admin && (
            <ButtonActionDrop
              id={spaceData.id}
              btns={btns}
              size={'s'}
            />
          )}
        </div>
        <h3 className='card-space__title'>{spaceData.name}</h3>
      </div>
      <div className='card-space__content'>
        <button className="card-space__btn" onClick={handleButtonClick('employee')}>
          Сотрудники
        </button>
        <button className="card-space__btn" onClick={handleButtonClick('settings')}>
          Подключения
        </button>
        <button className="card-space__btn" onClick={handleButtonClick('roles')}>
          Роли
        </button>
        {is_admin && (
          <button className="card-space__btn" onClick={handleButtonClick('get_token')}>
            Получить токен
          </button>
        )}
      </div>
    </div>
  );
};

const SpacePage: React.FC<SpacePageProps> = ({
  setIsModalCreatingNewSpace,
  setIsEditSpace,
  isModalCreatingNewSpace,
  fetchGetSpace,
  spaceList,
  setIsModalActive
}) => {
  const { currentSpace, setCurrentSpace } = useGeneralStore(
    useShallow((state) => ({
      currentSpace: state.currentSpace,
      setCurrentSpace: state.setCurrentSpace
    }))
  );

  const [isModal, setIsModal] = useState(false);
  const selectedSpace = useGeneralStore(state => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [status, setStatus] = useState<string | null>(null);

  const currentSpaceList = (): SpaceData[] => {
    if (!status) return spaceList;

    switch (status) {
      case 'admin':
        return spaceList.filter(({ author }) => author?.id == client_id);
      case 'employee':
        return spaceList.filter(({ author }) => author?.id != client_id);
      default:
        return spaceList;
    }
  };

  const btns: TabButton[] = [
    {
      label: 'Все',
      count: spaceList.length,
      value: 'all',
      icon: groupIcon
    },
    {
      label: 'Админ',
      count: spaceList.filter(({ author }) => author?.id == client_id)?.length,
      value: 'admin',
      icon: userCircleIcon
    },
    {
      label: 'Сотрудник',
      count: spaceList.filter(({ author }) => author?.id != client_id)?.length,
      value: 'employee',
      icon: userIcon
    }
  ];

  useEffect(() => {
    setStatus(btns[0]?.value);
  }, []);

  const fetchDeleteSpace = (id: string) => {
    const url = `${RavshanHttps}/api/space/`;
    const body = JSON.stringify({ id });

    fetch(url, { body, method: 'DELETE', headers })
      .then(res => res.json())
      .then((json) => {
        console.log(json);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchGetSpace();
  }, [selectedSpace]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchGetSpace();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [isModalCreatingNewSpace]);

  return (
    <div className='space-page'>
      <TitlePage
        title='Пространства'
      />

      <ButtonBasic
        green
        text='Создать пространство'
        width='210px'
        onClick={() => setIsModalCreatingNewSpace(true)}
      />

      <TabsGreen
        btns={btns}
        currentBtn={status || ''}
        setCurrentBtn={(value: string) => setStatus(value)}
        checkValue={true}
        returnEvent={() => { }}
        icon={null}
      />

      <div className='space-page__content'>
        {currentSpaceList()?.map((el, index) => (
          <CardSpace
            key={`${el.id}-${index}`}
            spaceData={el}
            setCurrentSpace={setCurrentSpace}
            setIsEditSpace={setIsEditSpace}
            setIsModal={setIsModalCreatingNewSpace}
            deleteFunc={fetchDeleteSpace}
            refreshFetch={fetchGetSpace}
          />
        ))}
      </div>
    </div>
  );
};

export { SpacePage };