import React, { useState, useEffect } from 'react';
import customHeaders, { getSpace } from '../../common/headers';
import {
  InputDinamycPlaceholder,
  ButtonBasic,
} from '@lk-gtcom/ecomlab-components';
import { ModalChangeOfLegalEntities } from '../Modal/ModalChangeOfLegalEntities/ModalChangeOfLegalEntities';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful';
import { ButtonCopy } from '../ButtonCopy/ButtonCopy';
import './InformationForBilling.scss';
import { ArtemBusinessData2Https } from '../../fetchUrls';
import { TitlePage } from '../TitlePage/TitlePage';
import useGeneralStore from '../../store/general';

interface BusinessData {
  org_type?: string;
  НаимСокр?: string;
  НаимПолн?: string;
  ИНН?: string;
  КПП?: string;
  [key: string]: any;
}

interface FieldItem {
  label: string;
  value: string | undefined;
  copy?: boolean;
}

const InformationForBilling: React.FC = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);

  const [data, setData] = useState<BusinessData | null>(null);
  const [inn, setInn] = useState<string | null>(null);
  const [haveData, setHaveData] = useState<boolean>(false);
  const [innData, setInnData] = useState<BusinessData | null>(null);
  const [listField, setListField] = useState<FieldItem[]>([]);

  const fetchGetBusinessData = (): void => {
    const url = `${ArtemBusinessData2Https}/api/v2/business-data/get`;

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setData(json.data);
        console.log(json);
      })
      .catch((err) => console.error(err));
  };

  const fetchGetPermission = (): void => {
    const url = `${ArtemBusinessData2Https}/api/v2/business-data/permission`;

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => console.error(err));
  };

  const fetchGetDataByInn = (): void => {
    const url = `${ArtemBusinessData2Https}/api/v2/business-data/search`;

    const body = JSON.stringify({
      org_type: 'org',
      tax_num: inn,
    });

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
        setInnData(json?.data);
        setHaveData(true);
      })
      .catch((err) => console.error(err));
  };

  const fetchSaveDataByInn = (): void => {
    const url = `${ArtemBusinessData2Https}/api/v2/business-data/save?tax_num=${inn}`;

    fetch(url, {
      method: 'POST',
      headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
        setHaveData(false);
        setEditable(false);
        fetchGetBusinessData();
      })
      .catch((err) => console.error(err));
  };

  const fetchDeleteBusinessData = (): void => {
    const url = `${ArtemBusinessData2Https}/api/v2/business-data/delete`;

    fetch(url, {
      headers,
      method: 'DELETE',
    })
      .then(async (res) => {
        if (res.status === 204) {
          setHaveData(false);
          setEditable(false);
          fetchGetBusinessData();
          console.log('Бизнес-данные успешно удалены');
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchGetPermission();
    fetchGetBusinessData();
  }, []);

  return (
    <>
      {showModal &&
        <ModalChangeOfLegalEntities
          setShowModal={(e: boolean) => setShowModal(e)}
        />
      }

      {showModalSuccess &&
        <ModalSuccessful
          title="Ваш запрос успешно отправлен"
          setIsModal={(e: boolean) => setShowModalSuccess(e)}
        />
      }
      <div className="information-for-billing">
        <TitlePage
          title='Информация для выставления счетов и актов'
        />

        <ButtonBasic
          lilac
          width="280px"
          text={!editable ? 'Редактировать юридические данные' : 'Назад'}
          size="56px"
          onClick={() => {
            setEditable(!editable);
            setHaveData(false);
          }}
        />
        
        <div className="information-for-billing__content">
          <h3 className="information-for-billing__title-main">
            {data?.org_type === 'org' ? 'Юридическое лицо' : 'Физическое лицо'}
          </h3>
          {editable ? (
            <>
              <InputDinamycPlaceholder
                onChange={(e: string) => setInn(e)}
                type="text"
                classname="autorization__input-field"
                placeholder="Введите ИНН организации*"
                autoComplete={false}
              />

              <ButtonBasic
                blue
                text='Найти по ИНН'
                onClick={fetchGetDataByInn}
              />
            </>
          ) : (
            <>
              <div className="information-for-billing__info-card">
                {listField.map(({ label, value, copy }) => (
                  <div className="information-for-billing__row" key={label}>
                    <div className="information-for-billing__value-box">
                      <span className="information-for-billing__placeholder">{label}</span>
                      <p className="information-for-billing__value">{value}</p>
                      {copy && <ButtonCopy value={value || ''} />}
                    </div>
                  </div>
                ))}

                <div className="information-for-billing__row">
                  <div className="information-for-billing__value-box">
                    <span className="information-for-billing__placeholder">
                      {'Название организации'}
                    </span>
                    <p className="information-for-billing__text">{data?.['НаимСокр'] || '-'}</p>
                    {data?.['НаимСокр'] && <ButtonCopy value={data?.['НаимСокр']} />}
                  </div>
                </div>
                <div className="information-for-billing__row">
                  <div className="information-for-billing__value-box">
                    <span className="information-for-billing__placeholder">
                      {'Полное название организации'}
                    </span>
                    <p className="information-for-billing__text">{data?.['НаимПолн'] || '-'}</p>
                    {data?.['НаимПолн'] && <ButtonCopy value={data?.['НаимПолн']} />}
                  </div>
                </div>

                <div className="information-for-billing__group">
                  <div className="information-for-billing__row">
                    <div className="information-for-billing__value-box">
                      <span className="information-for-billing__placeholder">ИНН организации</span>
                      <p className="information-for-billing__text">
                        {data?.['ИНН'] || '-'}
                      </p>
                      {data?.['ИНН'] && <ButtonCopy value={data?.['ИНН']} />}
                    </div>
                  </div>
                  <div className="information-for-billing__row">
                    <div className="information-for-billing__value-box">
                      <span className="information-for-billing__placeholder">КПП организации</span>
                      <p className="information-for-billing__text">
                        {data?.['КПП'] || '-'}
                      </p>
                      {data?.['КПП'] && <ButtonCopy value={data?.['КПП']} />}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {editable && haveData && innData && (
        <div className="information-for-billing">
          <InputDinamycPlaceholder
            type="text"
            placeholder="Страна организации*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            changeValue={innData.НаимСокр}
            type="text"
            placeholder="Название организации*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            changeValue={innData.НаимПолн}
            type="text"
            placeholder="Полное название организации*"
            autoComplete={false}
            disabled={true}
          />

          <div className="information-for-billing__input-group">
            <InputDinamycPlaceholder
              changeValue={innData.ИНН}
              type="text"
              placeholder="ИНН организации*"
              autoComplete={false}
              disabled={true}
            />

            <InputDinamycPlaceholder
              changeValue={innData.КПП}
              type="text"
              placeholder="КПП организации*"
              autoComplete={false}
              disabled={true}
            />
          </div>

          <InputDinamycPlaceholder
            changeValue={''}
            type="text"
            placeholder="Юридический адрес*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            type="text"
            placeholder="Почтовый адрес*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            type="text"
            placeholder="Телефон"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            type="text"
            placeholder="E-mail бухгалтера"
            autoComplete={false}
            disabled={true}
          />

          <div className="information-for-billing__group-btn">
            <button className="btn__white" onClick={fetchDeleteBusinessData}>
              Удалить данные
            </button>
            <button
              className="btn__blue"
              onClick={fetchSaveDataByInn}
            >
              Сохранить данные
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export { InformationForBilling };