import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor';
import './CardExecutor.scss'

const CardExecutor = ({ key, name, login, id, color, isAdmin = true, addNewMember, status = false, btn = false }) => {

    return (
        <div className='card-executor' key={key}>
            <div className='executor-absolute-box'>
                <ExecutorPlaceholder
                    name={name}
                    color={color}
                />
            </div>

            <div className='card-executor__content'>
                <h3 className='card-executor__name'>{name}</h3>
            </div>

            <p className='card-executor__email'>{login}</p>
            {
            isAdmin ?
                status ?
                    <button
                        className='card-executor__btn-red'
                        onClick={(e) => addNewMember([id])}
                    >
                        Убрать
                    </button>
                    :
                    <button
                        className='card-executor__btn-green'
                        onClick={(e) => addNewMember([id])}
                    >
                        Добавить
                    </button>
                :
                    null
            }
            {btn && btn}
        </div>
    )
}

export { CardExecutor }
