import customHeaders, { getSpace } from '../../common/headers';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ArtemBillingHttps, ArtemBillingHttp } from '../../fetchUrls';
import { icon_add_balance } from '../../common/svg_img';
import useGeneralStore from '../../store/general';
import { TitlePage } from '../TitlePage/TitlePage';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import './AddBalanceModal.scss';

const AddBalanceModal = ({ setIsModalActive }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [balanceValue, setBalanceValue] = useState('');
  const [errorVisability, setErrorVisability] = useState(false);

  const balanceIncreaseRegister = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemBillingHttps}/register-order`;
    } else {
      url = `${ArtemBillingHttp}/register-order`;
    }

    const body = JSON.stringify({
      amount: +balanceValue * 100,
      currency: 'RUB',
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const { orderId, formUrl, errorCode } = json;
        if (formUrl) {
          window.location.assign(formUrl);
        }
        if (errorCode) {
          setErrorVisability(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setErrorVisability(true);
      });
  };

  return (
    <div className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModalActive(false);
        }
      }}
    >
      <div className="add-balance-modal">

        <img
          alt='icon'
          className='add-balance-modal__img'
          src={icon_add_balance} />
        <TitlePage
          title='Пополнить баланс'
        />
        {errorVisability && <span
          style={{ color: 'red' }}
        >
          Что-то пошло не так
        </span>}

        <span className="text">Сумма пополнения:</span>
        <div className="input-box">
          <input
            className="add-balance-modal__input-field"
            type="number"
            placeholder="0.00"
            value={balanceValue}
            onChange={(e) => setBalanceValue(e.target.value)}
          ></input>
        </div>

        <ButtonBasic
          green
          width='240px'
          text='Пополнить баланс'
          onClick={(e) => balanceIncreaseRegister(e)}
        />
      </div>
    </div>
  );
};

export { AddBalanceModal };
