import { useState, useEffect, userRef } from 'react'
import { DropDownSelector } from "@lk-gtcom/ecomlab-components";
import { KonstantionAuthHttp, KonstantionAuthHttps } from '../../../fetchUrls'
import { validationEmail } from '../../../common/validationFunc'
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import banner_min from './img/banner_min.svg'
import './ModalChangeOfLegalEntities.scss'
import Cookies from 'universal-cookie'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";

const ModalChangeOfLegalEntities = ({ setShowModal, fetchGetEmployees = false }) => {

    const email = localStorage.getItem('email')
    const auth = new Cookies().get('auth')

    const [loginExecutor, setLoginExecutor] = useState('')
    const [name, setName] = useState('')
    const [сause, setСause] = useState('')

    const [errorEmail, setErrorEmail] = useState(false)
    const [errName, setErrName] = useState(false)
    const [errСause, setErrСause] = useState(false)
    const [selected, setSelected] = useState('Администратор')

    const fetchAddEmployee = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/web/users/employees`
        } else {
            url = `${KonstantionAuthHttp}/web/users/employees`
        }

        const body = JSON.stringify({
            client_executor_login: loginExecutor,
            role: selected
        })

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(`${url}`, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                fetchGetEmployees()
            })
            .catch(err => console.error(err))
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setShowModal(false)
            }
        }}>
            <div className='modal-change-of-legal-entities'>
                <h3 className='title'>Смена юридических данных</h3>
                <p className='text_grey-blue'>Если Вам потребовалась смена юридических данных, заполните форму ниже и укажите причину. Вы получите уведомление о статусе запроса.</p>
                <img src={banner_min} />

                <InputDinamycPlaceholder
                    classname='autorization__input-field'
                    err={errName}
                    placeholder='Ваше имя*'
                    textErr='Неверное имя'
                    type="e-mail"
                    autoComplete={false}
                    onChange={(e) => {
                        setErrName(false)
                        setName(e)
                    }}
                />

                <InputDinamycPlaceholder
                    classname='autorization__input-field'
                    err={errorEmail}
                    placeholder='E-mail почта*'
                    textErr='Неверный email'
                    type="e-mail"
                    autoComplete={false}
                    onChange={(e) => {
                        setErrorEmail(false)
                        setLoginExecutor(e)
                    }}
                />

                <InputDinamycPlaceholder
                    classname='autorization__input-field'
                    err={errСause}
                    placeholder='Причина смены данных*'
                    textErr='Неверный email'
                    type="e-mail"
                    autoComplete={false}
                    multiline={true}
                    rows='3'
                    onChange={(e) => {
                        setErrСause(false)
                        setСause(e)
                    }}
                />

                <div className='group-btn'>
                    <ButtonBasic
                        whiteBlue
                        text='Отмена'
                        size='48px'
                        width='50%'
                        onClick={(e) => setShowModal(false)}
                    />

                    <ButtonBasic
                        blue
                        text='Отправить запрос'
                        size='48px'
                        width='50%'
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalChangeOfLegalEntities }