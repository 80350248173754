import customHeaders, { getSpace } from '../../common/headers'
import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox } from "@lk-gtcom/ecomlab-components";
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack';
import { ModalDeleteTask } from '../Modal/ModalDeleteTask/ModalDeleteTask';
import { user_light_grey } from '../../common/svg_img';
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor';
import { PRIORITY_TO_COLOR_MAP } from '../Modal/ModalAddTask2/ModalAddTask2';
import { RavshanHttps, RavshanHttp } from '../../fetchUrls';
import users_icon from './img/bx-user-check.svg';
import { ReactComponent as Low } from './img/Low.svg';
import { ReactComponent as Medium } from './img/medium.svg';
import { ReactComponent as High } from './img/hight.svg';
import useGeneralStore from '../../store/general';
import { ButtonActionDrop } from '../Buttons/ButtonActionDrop/ButtonActionDrop';
import "./TaskItem.scss";

type Priority = 'Высокий' | 'Средний' | 'Низкий' | 'Не назначено';
type Status = 'НА СТОПЕ' | 'НОВЫЕ ЗАДАЧИ' | 'В РАБОТЕ' | 'НА ПРОВЕРКЕ' | 'ГОТОВО' | string;

interface User {
  name?: string;
  login?: string;
  [key: string]: any;
}

interface TaskItemProps {
  link?: string;
  author?: User;
  projectName?: string;
  title: string;
  priority: Priority;
  status: Status;
  executor?: User;
  time: string;
  style?: React.CSSProperties;
  id: number;
  done: boolean;
  openTask?: () => void;
  deleteTask: (id: number) => void;
  fetchProjects: (e?: any) => void;
  isOpenActiveMenu?: boolean;
  setIsOpenActiveMenu?: (value: boolean) => void;
  isShowAuthor?: boolean;
  taskId?: number;
  count_unread_comments?: number;
  isVisibleCheckbox?: boolean;
  setListPrint?: React.Dispatch<React.SetStateAction<number[]>>;
  listPrint?: number[];
}

const TaskItem: React.FC<TaskItemProps> = ({
  author,
  title,
  priority,
  status,
  executor,
  time,
  style,
  id,
  done = false,
  deleteTask,
  fetchProjects,
  isShowAuthor,
  taskId,
  count_unread_comments = 0,
  isVisibleCheckbox,
  setListPrint,
  listPrint = [],
  setIsOpenActiveMenu
}) => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [isDone, setIsDone] = useState(done);
  const [isModalDeleteTask, setIsModalDeleteTask] = useState(false);
  const email = localStorage.getItem('email');
  const taskProjectId = taskId ?? Number(sessionStorage.getItem('taskProjectId'));

  const priorityColor = PRIORITY_TO_COLOR_MAP[priority];

  const colorTime = useCallback((priority: Priority) => {
    switch (priority) {
      case 'Высокий':
        return <High />;
      case 'Средний':
        return <Medium />;
      case 'Низкий':
        return <Low />;
      default:
        return <Low />;
    }
  }, []);

  const colorStatus = useCallback((status: Status) => {
    switch (status) {
      case 'НА СТОПЕ':
        return 'info-status-color_red';
      case 'НОВЫЕ ЗАДАЧИ':
        return 'info-status-color_grey';
      case 'В РАБОТЕ':
        return 'info-status-color_blue';
      case 'НА ПРОВЕРКЕ':
        return 'info-status-color_yellow';
      case 'ГОТОВО':
        return 'info-status-color_green';
      default:
        return 'info-status-color_grey';
    }
  }, []);

  const fetchUpdateTask = useCallback(async (done: boolean) => {
    const url = window.location.hostname.match('localhost') 
      ? `${RavshanHttp}/api/tasks/event/done`
      : `${RavshanHttps}/api/tasks/event/done`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { ...headers,
          //  'from-preview': true
           },
        body: JSON.stringify({
          done,
          event_id: id
        })
      });

      if (response.ok) {
        setIsDone(done);
      } else {
        setIsDone(!done);
      }
    } catch (error) {
      console.error('Error updating task status:', error);
      setIsDone(!done);
    }
  }, [id, headers]);

  const handlePrintCheckboxChange = useCallback((checked: boolean) => {
    setListPrint?.((prevItems) => 
      checked 
        ? [...prevItems, id] 
        : prevItems.filter((prevItem) => prevItem !== id)
    );
  }, [id, setListPrint]);

  useEffect(() => {
    const hideMenu = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      if (!el.closest('.context-more')) {
        setIsOpenActiveMenu?.(false);
      }
    };

    document.addEventListener('click', hideMenu);
    return () => document.removeEventListener('click', hideMenu);
  }, [setIsOpenActiveMenu]);

  const btns = [
    {
      btn: 'Редактировать',
      func: () => {},
    },
    {
      btn: 'В избранное',
      func: () => {},
    },
    {
      btn: 'Закрепить',
      func: () => {},
    },
    {
      btn: 'Удалить',
      func: () => setIsModalDeleteTask(true),
    }
  ];

  return (
    <>
      {isModalDeleteTask && (
        <ModalDeleteTask
          deleteTask={() => deleteTask(id)}
          taskName={title}
          setIsModal={setIsModalDeleteTask}
          fetchProjects={fetchProjects}
        />
      )}

      <div 
        className='task-item' 
        style={isDone ? { ...style, background: 'rgba(0,0,0,.1)' } : style}
      >
        <div className='task-item__top-content'>
          <p className='task-item__id'>
            {isShowAuthor && (
              <div className='members-list-box'>
                {author?.name 
                  ? <ExecutorPlaceholder {...author} email={author?.login} /> 
                  : <img src={user_light_grey} alt="User" />}
              </div>
            )}
            {`ID-${id}`}
          </p>
          {count_unread_comments > 0 && (
            <span className='task-item__count'>{count_unread_comments}</span>
          )}
          {author?.login === email && (
            <ButtonActionDrop id={id} btns={btns} size='s' />
          )}
        </div>

        <div className='task-item__content'>
          <div className='info-container'>
            <p 
              className='text' 
              style={isDone ? { textDecoration: 'line-through' } : {}}
            >
              {title || 'Нет названия'}
            </p>

            <div className='task-item__bottom-content'>
              {taskProjectId === -1 && (
                status.length > 11 ? (
                  <TooltipBlack
                    text={status}
                    top='18px'
                    left='0px'
                    child={
                      <div className={colorStatus(status)}>
                        <p>{status}</p>
                      </div>
                    }
                  />
                ) : (
                  <div className={colorStatus(status)}>
                    <p>{status}</p>
                  </div>
                )
              )}

              <div className='task-item__bottom-info'>
                <div className='task-item__executors-list'>
                  <img src={users_icon} alt="Users" />
                  {executor?.name 
                    ? <ExecutorPlaceholder {...executor} email={executor?.login} /> 
                    : <img className='task-item__img-user' src={user_light_grey} alt="User" />}
                </div>

                <div className='task-item__bottom-info-right'>
                  {priority !== 'Не назначено' && (
                    <div className='priority'>
                      {colorTime(priority)}
                    </div>
                  )}
                  <p className='task-item__date'>{time.split('г.')[0]}</p>
                  <Checkbox 
                    green 
                    onChange={fetchUpdateTask} 
                    value={isDone} 
                  />
                </div>
              </div>

              {isVisibleCheckbox && (
                <div className='task-item__bottom-info'>
                  <Checkbox
                    blue
                    value={listPrint.includes(id)}
                    onChange={handlePrintCheckboxChange}
                  >
                    Печать
                  </Checkbox>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskItem;