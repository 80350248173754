import React from 'react';
import rightArrowAlt from './img/bx-right-arrow-alt.svg';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { useState, useEffect, useRef, Ref } from 'react';
import { BordCard } from '../BordCard/BordCard';
import { ModalCreateBoard } from '../ModalCreateBoard/ModalCreateBoard';
import customHeaders, { getSpace } from '../../../common/headers';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls';
import useGeneralStore from '../../../store/general';
import './BordTask.scss';

interface Board {
  id?: string | number;
  name: string;
  sum_task?: number;
  date?: string;
  start_time?: string;
  end_time?: string;
}

interface BordTaskProps {
  ref?: Ref<HTMLDivElement>;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  taskId?: string;
  setCurrentBordTask: (board: Board | null) => void;
}

const BordTask = ({
  ref,
  setIsOpen,
  isOpen,
  taskId,
  setCurrentBordTask
}: BordTaskProps) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [isModal, setIsModal] = useState(false);
  const [boards, setBoards] = useState<Board[]>([]);
  const [currentBoard, setCurrentBoard] = useState<Board | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const boardRef = useRef<HTMLDivElement>(null);

  // Get boards
  const fetchGetBord = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/board/task_boards`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/board/task_boards`;
    }

    const body = JSON.stringify({
      task_id: taskId
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        setBoards(json);
      })
      .catch((err) => console.error(err));
  };


  useEffect(() => {
    if (taskId) {
      fetchGetBord();
    }
  }, [taskId]);

  useEffect(() => {
    setCurrentBordTask(currentBoard);
  }, [currentBoard]);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      {isModal && (
        <ModalCreateBoard
          fetchGetBord={fetchGetBord}
          currentBord={currentBoard}
          setIsModal={setIsModal}
          taskId={taskId}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}

      <div
        className={isOpen ? 'bord-task_open' : 'bord-task'}
        ref={ref || boardRef}
      >
        <div className='bord-task__btn-group'>
          <button className='bord-task__btn' onClick={toggleOpen}>
            <img
              src={rightArrowAlt}
              style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
              alt="Toggle panel"
            />
          </button>
          <ButtonBasic
            green
            width='166px'
            minWidth='166px'
            text='Добавить'
            size='26px'
            style={{
              minHeight: '26px',
              borderRadius: '4px'
            }}
            onClick={() => setIsModal(true)}
          />
        </div>

        <div className={isOpen ? 'bord-task__content_open' : 'bord-task__content'}>
          {boards?.map((board) => (
            <BordCard
              key={board.id}
              id={board.id}
              name={board.name}
              sum_task={board.sum_task}
              date={board.date}
              start_time={board.start_time}
              end_time={board.end_time}
              fetchGetBord={fetchGetBord}
              setIsModal={setIsModal}
              setCurrentBord={setCurrentBoard}
              currentBord={currentBoard}
              setIsEdit={setIsEdit}
            />
          ))}
        </div>

        <div className={isOpen ? 'bord-task__content-btns' : 'bord-task__content-btns_open'}>
          <button className='bord-task__btn' onClick={toggleOpen}>12</button>
          <button className='bord-task__btn' onClick={toggleOpen}>ДН</button>
        </div>
      </div>
    </>
  );
};

export { BordTask };