import { MoonLoader } from 'react-spinners';
import { CardExecutor } from '../../CardExecutor/CardExecutor';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import {
  InputDinamycPlaceholder,
  Tabs,
  ButtonBasic,
  TextareaDynamicPlaceholder,
  TableBox,
  InputSearch,
  SelectExecutor
} from '@lk-gtcom/ecomlab-components';
import copy_icon from './img/copy_icon.svg';
import useGeneralStore from '../../../store/general';
import { RavshanHttps } from '../../../fetchUrls';
import { OlegHttps, OlegHttp } from '../../../fetchUrls';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../../fetchUrls';
import customHeaders, { getSpace } from '../../../common/headers';
import { TabsGreen } from '../../TabsGreen/TabsGreen';
import { ReactComponent as StarBkg } from './img/bxs-star.svg';
import { ReactComponent as Star } from './img/bx-star.svg';
import { ButtonCopy } from '../../ButtonCopy/ButtonCopy';
import fix from './img/fix.svg'
import close from './img/close.svg'
import { ButtonActionDrop } from '../../Buttons/ButtonActionDrop/ButtonActionDrop';
import { EcexutorRow } from './EcexutorRow/EcexutorRow';
import { SettingsRow } from './SettingsRow/SettingsRow';
import { ModalDelete } from '../ModalDelete/ModalDelete';
import { TokenTab } from './TokenTab/TokenTab';
import './ModalCreatingNewSpace.scss';
import { update } from 'lodash';



const btn_tab_2 = [
  {
    label: 'Добавить',
    value: 'true',
  },
  {
    label: 'Добавленные',
    value: 'false',
  },
];



const ModalCreatingNewSpace = ({
  setIsModal,
  isEdit = false,
  setIsEditSpace,
  currentSpace,
  setCurrentSpace,
  setIsModalActive,
  isModalActive,
  setSelectedSpace,
  setIsModalRole,
  setIsModalAddEmployees,
  isModalAddEmployees,
  setConnectedModal,
  spaceListCurrent
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const {
    spaceList,
    // currentSpace,
    // setCurrentSpace,
    accounList,
    setAccountList,
    employeesList,
    setEmployeesList,
    availableEmployeesList,
    setAvailableEmployeesList,
    pageValue1,
    setPageValue1
  } = useGeneralStore();

  const btn_tab_1 = [
    {
      label: 'Сотрудники',
      value: 'employee',
    },
    {
      label: 'Подключения',
      value: 'settings',
    },
    {
      label: 'Роли',
      value: 'roles',
    },
    ...(currentSpace?.is_admin
      ? [
        {
          label: 'Получить токен',
          value: 'get_token',
        }
      ]
      : []),
  ];

  const [nameSpace, setNameSpace] = useState([]);
  const [descriptionSpace, setDescriptionSpace] = useState('');
  const [load, setLoad] = useState(false);
  // const [pageValue1, setPageValue1] = useState(btn_tab_1[0].value);
  const [pageValue2, setPageValue2] = useState(btn_tab_2[1].value);
  const [isFavorite, setIsFavorite] = useState(false);
  const [executorSearchValue, setExecutorSearchValue] = useState('')
  const [settingsSearchValue, setSettingsSearchValue] = useState('')
  const [executors, setExecutors] = useState([])
  const [isModalDelete, setIsModalDelete] = useState(false)
  const [currentAccount, setCurrentAccont] = useState(null)

  // Добавления пространства
  const fetchAddSpace = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/space/`;
    } else {
      url = `${RavshanHttps}/api/space/`;
    }

    const body = JSON.stringify({
      name: nameSpace,
      // "author": "111"
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Изменить пространство
  const fetchChangeSpace = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/space/`;
    } else {
      url = `${RavshanHttps}/api/space/`;
    }

    const body = JSON.stringify({
      name: nameSpace,
      id: currentSpace?.id,
    });

    fetch(url, { body, method: 'PUT', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Получение аккаунтов из пространства
  const fetchGetAccountSpace = () => {
    if (!currentSpace?.id && pageValue2 == 'false') return;

    const abortController = new AbortController();
    setLoad(true);
    const url = `${RavshanHttps}/api/space/account/${currentSpace?.id ? currentSpace?.id : -1
      }?all=${false}`;

    fetch(url, { headers, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (json) {
          setAccountList(json);
        }
      })
      .catch((err) => console.error(err))
      .finally((e) => {
        setLoad(false);
      });
    return abortController;
  };

  // Получение аккаунтов из пространства
  const fetchGetClientSpace = (status) => {
    // if (!currentSpace?.id) return;
    const abortController = new AbortController();

    const url = `${RavshanHttps}/api/space/client/${currentSpace?.id ? currentSpace?.id : -1
      }?all=${status}`;

    setLoad(true);

    fetch(url, { headers, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (json) {
          if (status) {
            setEmployeesList(json);
          } else {
            setAvailableEmployeesList(json);
          }
        }
      })
      .catch((err) => console.error(err))
      .finally((e) => {
        setLoad(false);
      });
    return abortController;
  };

  // Добавление аккаунта в пространство
  const fetchAddAccountInSpace = (acc_id) => {
    const url = `${RavshanHttps}/api/space/account/add`;

    const body = JSON.stringify({
      id: currentSpace?.id,
      accounts: [acc_id],
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Добавление аккаунта в пространство
  const fetchAddClientInSpace = (client_id) => {
    const url = `${RavshanHttps}/api/space/client/add`;

    const body = JSON.stringify({
      id: currentSpace?.id,
      clients: client_id,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Удаление аккаунта в пространство
  const fetchDeleteAccountInSpace = (acc_id) => {
    const url = `https://apps0.ecomru.ru:4242/api/v2/account/${acc_id}`;

    fetch(url, { method: 'DELETE', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Удаление сотрудника из пространство
  const fetchDeleteClientInSpace = (client_id) => {
    const url = `${RavshanHttps}/api/space/client/delete`;

    const body = JSON.stringify({
      id: currentSpace?.id,
      clients: client_id,
    });

    fetch(url, { body, method: 'DELETE', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Удаление пространства
  const fetchDeleteSpace = (id) => {
    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/space/`
    } else {
      url = `${RavshanHttps}/api/space/`
    }

    const body = JSON.stringify({
      id: id
    })

    fetch(url, { body, method: 'DELETE', headers })
      .then(res => res.json)
      .then((json) => {
        console.log(json)
      })
      .catch(err => {
        console.error(err)
      })

  }

  const btns = [
    {
      btn: 'Удалить',
      func: () => {
        fetchDeleteSpace(currentSpace?.id)
        setIsModal(false)
      },
    },
  ];

  useEffect(() => {
    if (pageValue1 === 'employee') {
      fetchGetClientSpace();
    } else {
      fetchGetAccountSpace();
    }
  }, [currentSpace, pageValue1, pageValue2, selectedSpace]);

  const [selectedArrExecutor, setSelectedArrExecutor] = useState([]);
  const [selectedArrAccount, setSelectedArrAccount] = useState([]);

  const currentListExecutor = () => {
    if (pageValue2 == 'true') {
      const list = employeesList.filter((el) => {
        return !selectedArrExecutor.includes(el.id);
      });
      return list;
    } else {
      const list = employeesList.filter((el) => {
        return selectedArrExecutor.includes(el.id);
      });
      return list;
    }
  };

  const currentListAccount = () => {
    if (pageValue2 == 'true') {
      const list = accounList.filter((el) => {
        return !selectedArrAccount.includes(el.id);
      });
      return list;
    } else {
      const list = accounList.filter((el) => {
        return selectedArrAccount.includes(el.id);
      });
      return list;
    }
  };

  // let list_executor = selectedArrExecutor;
  // let current_list_executor = list_executor.filter(
  //   (item) => item.id != el.id
  // );

  useEffect(() => {
    fetchGetClientSpace(true)
    fetchGetClientSpace(false)
  }, []);

  useEffect(() => {
    if (executors?.at(-1)?.id) {
      fetchAddClientInSpace([executors?.at(-1)?.id])
      setTimeout(() => {
        fetchGetClientSpace(true)
        fetchGetClientSpace(false)
      }, 1000)
    }

  }, [executors]);

  useEffect(() => {
    if (!isModalActive) {
      fetchGetAccountSpace()
    }
  }, [isModalActive]);

  useEffect(() => {
    if (!isModalAddEmployees) {
      fetchGetClientSpace(false);
    }
  }, [isModalAddEmployees])

  useEffect(() => {
    if (spaceListCurrent) {
      const filter_space = spaceListCurrent?.filter((el) => el?.id == currentSpace?.id)
      const current_space = filter_space?.map((el) => {
        return {
          author: el.author,
          labelValue: el.name,
          value: el.id
        }
      })
      setSelectedSpace(current_space?.[0])
    }
  }, []);



  return (
    <>
      {isModalDelete && <ModalDelete
        title='Удалить подключение'
        fetchDelete={() => {
          fetchDeleteAccountInSpace(currentAccount.id);
          setTimeout(() => fetchGetAccountSpace(), 1000);
        }}
        update={() => {
          setCurrentAccont(null)
        }}
        setIsModal={setIsModalDelete}
      />}

      <div
        className="blackout"
        onClick={(e) => {
          let el = e.target;
          if (el.classList.contains('blackout')) {
            setIsEditSpace(false);
            setCurrentSpace(null);
            setAccountList([]);
            setEmployeesList([])
            setAvailableEmployeesList([])
            setIsModal(false);
          }
        }}
      >
        <div className="modal-creating-new-space">
          <div className="modal-creating-new-space__header">
            {currentSpace?.id && <div className='modal-creating-new-space__id'>
              <ButtonCopy
                value={currentSpace?.id}
                style={{ position: 'relative' }}
                top={'200%'}
                icon={copy_icon}
              />
              {`ID-${currentSpace?.id}`}
            </div>}
            <h1 className="title">

              {isEdit
                ?
                (currentSpace?.is_admin ? `Редактирование пространства ` : currentSpace?.name)
                :
                'Создание нового пространства'
              }
            </h1>

            {(currentSpace?.is_admin || !isEdit)
              &&
              <InputDinamycPlaceholder
                changeValue={currentSpace?.name}
                placeholder="Название"
                onChange={(e) => setNameSpace(e)}
                size='s'
              />}

            <ButtonBasic
              grey
              text={isFavorite ? <StarBkg /> : <Star />}
              onClick={(e) => setIsFavorite(!isFavorite)}
              minWidth={'40px'}
              width='40px'
              style={{ marginLeft: 'auto' }}
            />

            <ButtonBasic
              grey
              text={<img src={fix} />}
              minWidth={'40px'}
              width='40px'
            />

            {(currentSpace?.is_admin || !isEdit) &&
              <ButtonActionDrop id={1} btns={btns} />}

            <button className='modal-creating-new-space__btn-close'
              onClick={(e) => {
                setIsEditSpace(false);
                setCurrentSpace(null);
                setAccountList([]);
                setEmployeesList([])
                setAvailableEmployeesList([])
                setIsModal(false);
              }}
            >
              <img src={close} />
            </button>
          </div>

          <TextareaDynamicPlaceholder
            changeValue={descriptionSpace}
            width={'100%'}
            placeholder={'Введите описание для пространства'}
            onChange={(e) => setDescriptionSpace(e)}
          />

          <TabsGreen
            btns={btn_tab_1}
            currentBtn={pageValue1}
            setCurrentBtn={(e) => {
              setPageValue1(e)
            }}
            checkValue={true}
          />


          {load ? (
            <div
              className="center"
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MoonLoader size={40} color="#7b7b7b" speedMultiplier={0.5} />
            </div>
          ) : (
            <div className="modal-creating-new-space__content">

              {(pageValue1 == 'employee') && <>
                {/* {(currentSpace?.is_admin || !isEdit) && <SelectExecutor
                executors={executors}
                setExecutors={(e) => {
                  setExecutors(e);
                  fetchAddClientInSpace(e)
                  fetchGetClientSpace(true)
                  fetchGetClientSpace(false)
                }}
                executorOptions={employeesList}
                title="Участники"
                placeholder="Добавить участников"
                hideTooltip={false}
                multi
                hideExecutor
              />} */}

                {(currentSpace?.is_admin && isEdit) && <ButtonBasic
                  green
                  text="Добавить сотрудника +"
                  onClick={(e) => {
                    setIsModalAddEmployees(true)
                  }}
                  width="200px"
                  size="32px"
                />
                }


                <InputSearch
                  placeholder="Найти сотрудника"
                  value={executorSearchValue}
                  onChange={(e) => setExecutorSearchValue(e)}
                />
              </>

              }

              {pageValue1 == 'settings' &&
                <>
                  {(currentSpace?.is_admin && isEdit) &&
                    <ButtonBasic
                      green
                      text="Добавить подключение +"
                      onClick={(e) => {
                        setIsModalActive(true)
                      }}
                      width="200px"
                      size="32px"
                    />}

                  <InputSearch
                    placeholder="Поиск..."
                    value={settingsSearchValue}
                    onChange={(e) => setSettingsSearchValue(e)}
                  />
                </>
              }

              {pageValue1 == 'employee' && <div className="modal-creating-new-space__content-executor">
                {availableEmployeesList.map(
                  (el) => {
                    return (
                      <EcexutorRow
                        id={el.id}
                        isAdmin={currentSpace?.is_admin}
                        login={el.login}
                        name={el.name}
                        color={el.color}
                        status={false}
                        addNewMember={(e) => {
                          if (currentSpace?.id) {
                            fetchDeleteClientInSpace(e);
                            setTimeout(() => {
                              fetchGetClientSpace(true)
                              fetchGetClientSpace(false)
                            }, 1000);
                          }
                        }}
                      />
                    );
                  }
                )}
              </div>}


              {pageValue1 == 'settings' && <div className="modal-creating-new-space__content-settings">
                {accounList.map(
                  (el) => {
                    return (
                      <SettingsRow
                        id={el.id}
                        setConnectedModal={setConnectedModal}
                        setIsModal={setIsModalActive}
                        icon={el.group_by_portal[0]}
                        name={el.name}
                        mp_name={el.mp_name}
                        mp_group={el.mp_group}
                        isAdmin={currentSpace?.is_admin}
                        btnFunc={() => {
                          if (currentSpace?.id) {
                            setCurrentAccont(el)
                            setIsModalDelete(true)
                          } else {
                            let list_executor = selectedArrAccount;
                            let current_list_executor =
                              list_executor.filter(
                                (item) => item.id != el.id
                              );
                            setSelectedArrAccount(current_list_executor);
                          }
                        }}
                      />
                    );
                  }
                )}
              </div>}

              {pageValue1 == 'roles' &&
                <>
                  {currentSpace?.is_admin &&
                    <ButtonBasic
                      green
                      text="Добавить роль +"
                      onClick={(e) => {
                        setIsModalRole(true)
                      }}
                      width="200px"
                      size="32px"
                    />}
                </>
              }

              {pageValue1 == 'roles' &&
                <TableBox
                  name='СПИСОК КАТЕГОРИЙ'
                  loading={null}
                  fetchedData={[]}
                  headers={[]}
                  paginator={true}
                  maxHeight={'350px'}
                />
              }

              {pageValue1 == 'get_token' &&
                <TokenTab />
              }
            </div>
          )}
          {(currentSpace?.is_admin || !isEdit) && <div className="modal-creating-new-space__btn-box">
            <ButtonBasic
              green
              onClick={(e) => {
                if (isEdit) {
                  fetchChangeSpace();
                } else {
                  fetchAddSpace();
                }
                setCurrentSpace(null);
                setAccountList([]);
                setEmployeesList([]);
                setIsModal(false);
              }}
              text={isEdit ? 'Изменить' : 'Создать'}
              size='56px'
            />
          </div>}
        </div>
      </div>
    </>
  );
};

export { ModalCreatingNewSpace };
