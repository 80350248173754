import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import customHeaders, { getSpace } from '../../common/headers';
import { Post } from '../../components/Post/Post';
import { Page404 } from '../Page404/Page404';
import { NotActive } from '../../components/NotActive/NotActive';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { RavshanHttps } from '../../fetchUrls';
import { NetworkGroups } from '../../components/NetworkGroups/NetworkGroups';
import useGeneralStore from '../../store/general';
import './SocialNetwork.scss';

interface FilterItem {
  label: string;
  value: string | number;
}

interface FiltersResponse {
  statuses: FilterItem[];
  platforms: FilterItem[];
}

interface SidebarSubItem {
  label: string;
  path?: string;
  icon?: React.ReactNode;
  black?: boolean;
}

interface SidebarItem {
  label: string;
  path?: string;
  icon?: React.ReactNode;
  subItems: SidebarSubItem[];
}

const SocialNetwork: React.FC = () => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace);
  const navigate = useNavigate();
  const path = useParams();

  // State
  const [isActive, setIsActive] = useState<string>('Группы сетей');
  const [isActiveItems, setIsActiveItems] = useState<string>('');
  const [platforms, setPlatforms] = useState<FilterItem[]>([]);
  const [statuses, setStatuses] = useState<FilterItem[]>([]);
  const [hideMenu, setHideMenu] = useState<boolean>(true);
  const [hover, setHover] = useState<string>('');
  const [activeItems, setActiveItems] = useState<string>('');

  // Memoized headers
  const headers = useMemo(() => ({ 
    ...customHeaders, 
    ...getSpace(selectedSpace) 
  }), [selectedSpace]);

  // Sidebar configuration
  const sidebarItems: SidebarItem[] = useMemo(() => [
    {
      label: 'Социальные сети',
      subItems: [
        {
          label: 'Группы сетей',
          path: 'network-groups'
        },
        {
          label: 'Посты',
          path: 'post',
        },
        {
          label: 'Аналитика'
        },
      ],
    },
    {
      label: 'Уведомления',
      subItems: [
        {
          label: 'Настроить уведомления',
          black: true
        }
      ],
    },
  ], []);

  const fetchFilters = useCallback(async (signal?: AbortSignal): Promise<void> => {
    const url = `${RavshanHttps}/api/social/filters_group`;

    try {
      const response = await fetch(url, { method: 'GET', headers, signal });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: FiltersResponse[] = await response.json();
      const { statuses: rawStatuses, platforms: rawPlatforms } = data[0];

      setPlatforms(rawPlatforms);
      setStatuses(rawStatuses.map(({ label, value }) => ({
        label: String(label),
        value
      })));
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        console.error('Error fetching filters:', error);
      }
    }
  }, [headers]);

  useEffect(() => {
    if (path['*'] === '') {
      navigate('network-groups', { replace: true });
    }

    const abortController = new AbortController();
    fetchFilters(abortController.signal);

    return () => abortController.abort();
  }, [fetchFilters, navigate, path]);

  return (
    <div className='settings__content'>
      <div className='flex'>
        <div className={hideMenu ? 'sidebar-inside' : 'sidebar-inside_active'}>
          <nav className='sidebar-inside__nav'>
            {sidebarItems.map((item, index) => (
              <SidebarMenu
                key={`${item.label}-${index}`}
                label={item.label}
                path={item.path}
                subItems={item?.subItems}
                active={isActive}
                setActive={setIsActive}
                activeItems={activeItems}
                setActiveItems={setActiveItems}
                isActiveItems={isActiveItems}
                setIsActiveItems={setIsActiveItems}
              />
            ))}
          </nav>
        </div>

        <div className='settings__main-box'>
          <Routes>
            <Route 
              path='network-groups/*' 
              element={
                <NetworkGroups
                  fetchFilters={fetchFilters}
                  platforms={platforms}
                  statuses={statuses}
                />
              } 
            />
            <Route 
              path='post' 
              element={
                <Post
                  platforms={platforms}
                  statuses={statuses}
                />
              } 
            />
            <Route path='not-active' element={<NotActive />} />
            <Route path='*' element={<Page404 />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export { SocialNetwork };