import { useState, useEffect, useRef } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ArticleMain } from '../../components/ArticleMain/ArticleMain';
import { ArticleWiki } from '../../pages/ArticleComponents/ArticleWiki'
import {
  ViewSwitch,
  DropDownSelector,
  InputDynamicWidth,
  usePagination,
  Tabs
} from '@lk-gtcom/ecomlab-components';
import Breadcrumb from '../ArticleComponents/ArticleTypesComponent/Breadcrumb/Breadcrumb';
import { RavshanArticlesHttps } from '../../fetchUrls';
import { BASE_ROUTE } from '../../App';
import customHeaders, { getSpace } from '../../common/headers';
import { useResetOnBaseURL } from '../../common/useResetOnBaseURL';
import { ModalChatBot } from '../../components/Modal/ModalChatBot/ModalChatBot';
import { ModalAddChatBot } from '../../components/Modal/ModalAddChatBot/ModalAddChatBot';
import { ModalMiltilevelSidebarAdd } from '../../components/Modal/ModalMiltilevelSidebarAdd/ModalMiltilevelSidebarAdd';
import { ModalMiltilevelSidebarSettings } from '../../components/Modal/ModalMiltilevelSidebarSettings/ModalMiltilevelSidebarSettings';
import useGeneralStore from '../../store/general';
import { Link } from 'react-router-dom';
import './Main.scss';

const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const SORTING_OPTIONS = [
  { label: 'По дате: сначала новые', headerField: 'date', direction: SORT_DIRECTIONS.DESC },
  { label: 'По дате: сначала старые', headerField: 'date', direction: SORT_DIRECTIONS.ASC },
  { label: 'По фамилии автора: От а до я', headerField: 'author', direction: SORT_DIRECTIONS.DESC },
  { label: 'По фамилии автора: От я до а', headerField: 'author', direction: SORT_DIRECTIONS.ASC },
  { label: 'По названию: От а до я', headerField: 'name', direction: SORT_DIRECTIONS.ASC },
  { label: 'По названию: От я до а', headerField: 'name', direction: SORT_DIRECTIONS.DESC },
];

const STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Redact', value: 'redact' },
  { label: 'Update', value: 'update' },
];

const articleTabs = [
  { label: 'Статья', value: 'article' },
  { label: 'Настройка SEO', value: 'seo-setup' },
  { label: 'Проверка SEO', value: 'seo' },
  { label: 'Ключевики', value: 'keyword' },
  { label: 'Реклама', value: 'adv' }
];


const Main = ({
  activeLang,
  pathBread,
  setCurrentArticle,
  currentArticle,
  pathsWebName,
  setPathsWebName,
  setTitle,
  setDescription,
  title,
  description,
  paths,
  setPaths,
  directory = false
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const paginatorRef = useRef();
  const paginatorCallback2 = useRef();

  const wiki_id = localStorage.getItem('wiki_id');
  const wiki_name = localStorage.getItem('wiki_name');

  const [
    page,
    limit,
    total,
    setTotal,
    Paginator,
    resetPaginator,
    paginatorTransition,
  ] = usePagination(
    paginatorCallback2.current,
    [],
    paginatorRef.current?.params
  );

  const navigate = useNavigate();
  const path = useParams()['*'];
  const [hideMenu, setHideMenu] = useState(true);
  // const [currentArticle, setCurrentArticle] = useState({})
  const [initialRender, setInitialRender] = useState(true);

  const [selectedTopLevelValue, setSelectedTopLevelValue] = useState(0);
  const [selectedEndLevelValue, setSelectedEndLevelValue] = useState(0);

  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [activePopularTags, setActivePopularTags] = useState(new Set([]));
  const [total2, setTotal2] = useState(0);

  const [selectView, setSelectView] = useState('Tile-short');
  const [sidebarState, setSidebarState] = useState([]);

  const [sortingOption, setSortingOption] = useState(null);
  const sortingRef = useRef();
  const [search, setSearch] = useState('');

  const [selectedState, setSelectedState] = useState([]);
  const [isArticle, setIsArticle] = useState(false);

  const [isModalAddChatBot, setIsModalAddChatBot] = useState(false);
  const [isModalChatBot, setIsModalChatBot] = useState(false);
  const [pageValue, setPageValue] = useState('article')
  const [statusText, setStatusText] = useState("")

  // Крошки
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const fetchGetBreadcrumbs = (level_id, article_id) => {

    const level_id_params = level_id ? `?level_id=${level_id}` : ''
    const article_id_params = article_id ? `&article_id=${article_id}` : ''
    let url = `https://apps0.ecomru.ru:4447/api/v2/article/get_bread_crumbs${level_id_params}${article_id_params}`

    fetch(url, { method: 'GET', headers })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('bad response')
        }
      })
      .then(json => {
        setBreadcrumbs(json)
      })
      .catch(err => console.error(err))
  }

  //   useEffect(() => {
  //     const newUrl = breadcrumbs.map((el) => el.label).join('/')
  // console.log(newUrl)
  // navigate(newUrl)
  //   }, [breadcrumbs]);

  const getLabelFromUrl = () => {
    const label = window.location.pathname.split('/').at(-2);
    return label;
  };


  // useEffect(() => {
  //   setPaths(['actualPath'.replace(/%20/g, ' ')]);
  // }, [actualPath]);

  const fetchSidebar = () => {
    setSidebarState([]);
    const url = `${RavshanArticlesHttps}/get_portals_by_lang`;

    const body = JSON.stringify({
      language: activeLang.toLowerCase(),
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (json?.length > 0) {
          setSidebarState((prev) => [...prev, ...json]);
        }
      })
      .catch((err) => console.error(err));
  };

  useResetOnBaseURL(BASE_ROUTE, () => {
    setSelectedEndLevelValue(0);
    setSelectedTopLevelValue(0);
  });

  useEffect(() => {
    if (initialRender) {
      fetchSidebar();
      setInitialRender(false);
    }
  }, []);

  // useEffect(() => {
  //   if (initialRender) return;
  //   navigate(BASE_ROUTE);
  //   fetchSidebar();
  //   setSelectedEndLevelValue(0);
  //   setSelectedTopLevelValue(0);
  // }, [activeLang]);

  useEffect(() => {
    setTotal(null);
    if (typeof +path?.at(-1) === 'number' && !isNaN(+path?.at(-1))) {
      setIsArticle(true);
    } else {
      setIsArticle(false);
    }
  }, [path]);

  const saveWidthColumn = () => {
    if (selectView == 'Table') {
      const colWidth = [...document.querySelectorAll('.col-th-border')];
      if (colWidth.length > 0) {
        const arrWidth = colWidth.map((el) =>
          el.style.width ? el.style.width : '247px'
        );
        sessionStorage.setItem('col-width', arrWidth);
      }
    }
  };

  useEffect(() => {
    saveWidthColumn();
  }, [selectedEndLevelValue]);

  // const [paths, setPaths] = useState([])
  // const [pathsWebName, setPathsWebName] = useState([])
  // const [title, setTitle] = useState('')
  // const [description, setDescription] = useState('')
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [isModalSettings, setIsModalSettings] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState();

  return (
    <div className='article-main-container__breadcrumb-and-content'>
      {isModalAddChatBot && (
        <ModalAddChatBot setIsModal={(e) => setIsModalAddChatBot(e)} />
      )}

      {isModalChatBot && (
        <ModalChatBot setIsModal={(e) => setIsModalChatBot(e)} />
      )}

      <div className='article-main-container__breadcrumb-and-tabs'>

        <Breadcrumb
          breadcrumbs={breadcrumbs}
          setSelectedItemId={setSelectedItemId}
          setTitle={(e) => setTitle(e)}
          onPortalClick={(id) => {
            setSelectedTopLevelValue(id);
            setSelectedEndLevelValue(null);
          }}
        />

        {(path.includes('/article/') || path.includes('/new')) && <div className='article-and-tabs__top-panel'>
          {/* <div className='action-panel-top'>
            <span className='text status'>{statusText}</span>
            <button onClick={() => navigate(-1)} className='btn__white'>Вернуться </button>
                          <button ref={publishButtonRef} onClick={publish} disabled={publishInProgress || readonly} className='btn-publish btn__green'>Опубликовать</button>
          </div> */}
          <div className='article-and-tabs__tabs'>
            <Tabs
              tabs={articleTabs}
              stopRedirect
              pageValue={pageValue}
              hideBorder={false}
              setPageValue={e => setPageValue(e)}
            />
            <div style={{ minWidth: '230px' }}>
              {/* {
                path.includes('/new') &&
                <ButtonBasic
                  width='230px'
                  whiteGreen
                  text='Создать из файла'
                />
              } */}
            </div>
          </div>
        </div>}
      </div>



      <div className="article-main-container">
        {isModalAdd && (
          <ModalMiltilevelSidebarAdd setIsModal={(e) => setIsModalAdd(e)} />
        )}
        {isModalSettings && (
          <ModalMiltilevelSidebarSettings
            fetchGetBreadcrumbs={fetchGetBreadcrumbs}
            data={sidebarState}
            setIsModal={(e) => setIsModalSettings(e)}
          />
        )}



        <div className='article-main-container'>
          <ModalMiltilevelSidebarSettings
            directory={directory}
            breadcrumbs={breadcrumbs}
            fetchGetBreadcrumbs={fetchGetBreadcrumbs}
            setTitle={(e) => {
              setTitle(e);
            }}
            setPathsWebName={(e) => setPathsWebName(e)}
            onPortalClick={(id) => {
              setSelectedTopLevelValue(id);
              setSelectedEndLevelValue(null);
            }}
            onLevelClick={(id) => {
              setSelectedEndLevelValue(id);
              setSelectedTopLevelValue(null);
            }}
            setPaths={(e) => setPaths(e)}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
          />


          <div className="flex">
            <div className="main-content">
              {path == 'main' ||
                path == 'Kontakty' ||
                path == 'contacts' ||
                Object.keys(currentArticle)?.length > 0 ||
                path == 'level/sublevel/cpu/new' ? null : (
                <div className="toolbar-info">
                  {path == 'articles' ? (
                    <h1 className="title_main">Подборки</h1>
                  ) : (
                    <h1 className="title_main">
                      {path == 'results-found'
                        ? 'Результат поиска'
                        : title}
                    </h1>
                  )}
                  {path != 'articles/Вопрос-ответ' &&
                    path != 'articles' &&
                    !path.includes('article/') && (
                      <ViewSwitch
                        selectView={selectView}
                        setSelectView={(e) => setSelectView(e)}
                      />
                    )}
                </div>
              )}

              {path == 'main' ||
                path == 'Kontakty' ||
                path == 'contacts' ||
                Object.keys(currentArticle)?.length > 0 
                // ||
                // path == 'level/sublevel/cpu/new'
                ? null
                : selectView !== 'Table' &&
                path != '/contacts' && (
                  <div className="article-main__header">
                    {selectView !== 'Table' && (
                      <DropDownSelector
                        style={{ maxWidth: '360px' }}
                        isClearable={false}
                        options_prop={SORTING_OPTIONS.map((so) => ({
                          label: so.label,
                          value: {
                            headerField: so.headerField,
                            direction: so.direction,
                          },
                        }))}
                        state={sortingOption}
                        setState={(e) => {
                          setSortingOption(e);
                          sortingRef.current = {
                            param: e.value?.headerField,
                            direction: e.value?.direction,
                          };
                        }}
                        defaultValue={{
                          label: SORTING_OPTIONS[0].label,
                          value: {
                            param: SORTING_OPTIONS[0].headerField,
                            direction: SORTING_OPTIONS[0].direction,
                          },
                        }}
                      />
                    )}

                    <DropDownSelector
                      isClearable={false}
                      options_prop={STATUS_OPTIONS}
                      multi={false}
                      state={selectedState}
                      setState={(e) => setSelectedState(e)}
                      defaultValue={STATUS_OPTIONS[0]}
                    />

                    <InputDynamicWidth
                      onChange={(e) => setSearch(e)}
                      position="left"
                      maxWidth="100%"
                      drop={false}
                      isOpen={true}
                    />
                    {
                      selectedEndLevelValue || selectedTopLevelValue ?
                        <div className="toolbar-top">
                          <div className="new-article-btn">
                            <Link
                              to={`../level/sublevel/cpu/new`}
                              onClick={(e) => setIsArticle(true)}
                            >
                              <button role="link" className="btn__green">
                                Новая статья
                              </button>
                            </Link>
                          </div>
                        </div>
                        :
                        null
                    }
                  </div>
                )}


              <div
                className="article-main-container__content"
                style={{
                  overflow: selectView == 'Table' ? 'hidden' : 'visible',
                }}
              >



                <Routes>
                  {!isArticle && (
                    <Route
                      path={`/:level/:sublevel?/:sublevel2?/:sublevel3?/:sublevel4?/:sublevel5?`}
                      element={
                        <ArticleMain
                          search={search}
                          fetchGetBreadcrumbs={fetchGetBreadcrumbs}
                          selectedState={selectedState}
                          sortingRef={sortingRef}
                          setSelectedTags={(e) => setSelectedTags(e)}
                          selectedTags={selectedTags}
                          setTags={(e) => setTags(e)}
                          activeLang={activeLang}
                          selectedEndLevelValue={selectedEndLevelValue}
                          selectedPortalValue={selectedTopLevelValue}
                          pathBread={pathBread}
                          selectView={selectView}
                          setTotalParent={setTotal2}
                          paths={paths}
                          setIsArticle={(e) => setIsArticle(e)}
                          setSelectedEndLevelValue={setSelectedEndLevelValue}
                        />
                      }
                    />
                  )}

                  <Route
                    path={`/:level/:sublevel?/:sublevel2?/:sublevel3?/:sublevel4?/:sublevel5?/:cpu?/new`}
                    element={
                      <ArticleWiki
                        // <Article
                        isNew={true}
                        pathBread={wiki_name}
                        activeLang={activeLang}
                        setCurrentArticle={setCurrentArticle}
                        setTags={(e) => setTags(e)}
                        tags={tags}
                        pathsWebName={pathsWebName}
                        setPathsWebName={(e) => setPathsWebName(e)}
                        setTitle={(e) => setTitle(e)}
                        setDescription={(e) => setDescription(e)}
                        selectedItemId={selectedItemId}
                        parent_id={
                          selectedEndLevelValue
                            ? selectedEndLevelValue
                            : selectedTopLevelValue
                        }
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                        statusText={statusText}
                        setStatusText={setStatusText}
                        fetchGetBreadcrumbs={fetchGetBreadcrumbs}
                        setSelectedItemId={setSelectedItemId}
                      />
                    }
                  />

                  <Route
                    path="/:lang/:level/:cpu/:id"
                    element={
                      <ArticleWiki
                        isNew={false}
                        activeLang={activeLang}
                        pathBread={wiki_name}
                        setCurrentArticle={setCurrentArticle}
                        setTags={(e) => setTags(e)}
                        tags={tags}
                        setPaths={(e) => setPaths(e)}
                        pathsWebName={pathsWebName}
                        setPathsWebName={(e) => setPathsWebName(e)}
                        setTitle={(e) => setTitle(e)}
                        setDescription={(e) => setDescription(e)}
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                        statusText={statusText}
                        setStatusText={setStatusText}
                        fetchGetBreadcrumbs={fetchGetBreadcrumbs}
                        setSelectedItemId={setSelectedItemId}
                      />
                    }
                  />

                  <Route
                    path="/:lang/:level/:id"
                    element={
                      <ArticleWiki
                        isNew={false}
                        activeLang={activeLang}
                        pathBread={wiki_name}
                        setCurrentArticle={setCurrentArticle}
                        setTags={(e) => setTags(e)}
                        tags={tags}
                        setPaths={(e) => setPaths(e)}
                        pathsWebName={pathsWebName}
                        setPathsWebName={(e) => setPathsWebName(e)}
                        setTitle={(e) => setTitle(e)}
                        setDescription={(e) => setDescription(e)}
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                        statusText={statusText}
                        setStatusText={setStatusText}
                        fetchGetBreadcrumbs={fetchGetBreadcrumbs}
                        setSelectedItemId={setSelectedItemId}
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Main };
