import React from 'react'
import customHeaders, { getSpace } from '../../common/headers'
import add_icon from './img/add_icon.svg'
import { RavshanHttps } from '../../fetchUrls'
import { useState, useEffect } from 'react'
import { ButtonBasic, InputDynamicWidth } from "@lk-gtcom/ecomlab-components";
import { ReactComponent as ArrowDown } from './img/arrow_down.svg'
import { ModalAddNewWiki } from '../../components/Modal/ModalAddNewWiki/ModalAddNewWiki'
import { ModalDeleteWiki } from '../../components/Modal/ModalDeleteWiki/ModalDeleteWiki'
import useGeneralStore from '../../store/general'
import { ButtonActionDrop } from '../../components/Buttons/ButtonActionDrop/ButtonActionDrop'
import { ModalAlert } from '../../components/Modal/ModalAlert/ModalAlert'
import { AuthorAvatar } from '../ArticleComponents/AuthorAvatar/AuthorAvatar'
import { ButtonsSort } from '../../components/Buttons/BurronsSort/ButtonsSort'
import { TitlePage } from '../../components/TitlePage/TitlePage'
import "../../components/NetworkGroups/NetworkGroups.scss"
import './WikiList.scss'
import { useNavigate } from 'react-router-dom'

const btn_sort2 = [
    {
        label: <span>A<br />Z</span>,
        value: 'name'
    },
    {
        label: 'По дате создания',
        value: 'created_at'
    }
]

const CardGroup = ({ ...props }) => {
    const {
        name,
        created_at,
        id,
        onClick,
        setIsModalDelete,
        isDrop,
        setIsDrop,
        setId,
        setIsModalAlert,
        setTokenWiki,
        setIsModal,
        setCurrentWiki,
        author
    } = props

    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const btns = [
        {
            btn: 'Редактировать',
            func: () => {
                setCurrentWiki(props)
                setId(id)
                setIsModal(true)
            },
        },
        {
            btn: 'Получить токен',
            func: () => {
                fetchProductIds(id)
            },
        },
        {
            btn: 'Удалить',
            func: () => {
                setIsModalDelete(true)
                setIsDrop(-1)
                setId(id)
            },
        }
    ];

    // Получение токена для wiki
    const fetchProductIds = (id) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki_token?wiki_id=${id}`
        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                }
                else {
                    const text = await res.json()
                    throw new Error(text?.detail)
                }
            })
            .then(json => {
                if (json) {
                    setIsModalAlert(true)
                    setTokenWiki(json)
                }
            })
            .catch(err => console.error(err))
    }

    return (
        <div className='card-item'
            onClick={onClick}
            style={{ minHeight: 'auto' }}
        >
            <div className='card-item__top-content'>
                <div className='card-item__header'>
                    <div className='statistic-tasks'>
                        <div className='statistic-tasks__item'>
                            Дата создания - <span>
                                {created_at ? new Date(created_at)?.toLocaleDateString('ru-RU') : ' неизвестная дата'}
                            </span>
                        </div>
                    </div>
                    <ButtonActionDrop
                        size='s'
                        id={id}
                        btns={btns}
                    />
                </div>
            </div>

            <div className='card-item__medium-content'>
                <h3 className='title'>{name}</h3>
                <AuthorAvatar
                    show_name={true}
                    author={author ? [author?.login, author?.color] : []}
                />
            </div>
        </div>
    )
}

const WikiList = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const { platforms = [], statuses = [], fetchFilters } = props
    const portalId = 'add-new-connection-portal'
    const [isModal, setIsModal] = useState(false)
    const [isModalDelete, setIsModalDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [wikiList, setWikiList] = useState([])
    const [isDrop, setIsDrop] = useState(-1)
    const [id, setId] = useState(-1)
    const navigate = useNavigate()
    const [isModalAlert, setIsModalAlert] = useState(false)
    const [tokenWiki, setTokenWiki] = useState('')

    const [currentWiki, setCurrentWiki] = useState({})

    const [currentSort, setCurrentSort] = useState(null)
    const [sort, setSort] = useState(null)
    const [searchInput, setSearchInput] = useState('')

    const fetchWikiList = (signal = new AbortController(), delay = false) => {
        setLoading(true)
        const request = () => {
            const url = `${RavshanHttps}/api/v2/article/wiki/get`

            fetch(url, { method: 'GET', headers, signal })
                .then(res => {
                    if (!res?.ok) { throw new Error('bad response') }
                    return res.json()
                })
                .then(json => {
                    setWikiList(json)

                })
                .catch(e => console.log(e))
                .finally(() => {
                    if (signal.aborted) return
                    setLoading(false)
                })
        }
        if (delay) {
            setTimeout(() => { request() }, delay)
        } else {
            request()
        }

    }

    const editWiki = (name, wiki_id) => {
        const url = `${RavshanHttps}/api/v2/article/wiki/create`

        const body = JSON.stringify({
            name,
            wiki_id
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                fetchWikiList()
            })
            .catch(e => console.log(e))
    }

    const onDeleteHandler = (id) => {
        const url = `${RavshanHttps}/api/v2/article/wiki/delete/${id}`

        // const body = JSON.stringify({
        //     wiki_id: id
        // })

        fetch(url, { method: 'DELETE', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(() => {
                const abortController = new AbortController()
                fetchWikiList(abortController.signal, 100)
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        if (!isModal) {
            const abortController = new AbortController()

            fetchWikiList(abortController.signal, 100)

            return () => {
                abortController.abort()
            }
        }
    }, [isModal, selectedSpace, sort, currentSort, searchInput])

    useEffect(() => {
        const closeMenu = (e) => {
            let el = e.target
            if (!el.closest('.drop-and-btn')) {
                setIsDrop(null)
            }
        }
        document.addEventListener('click', closeMenu)

        return () => {
            document.addEventListener('click', closeMenu)
        }
    }, [])

    useEffect(() => {
        fetchWikiList()
    }, [selectedSpace]);

    useEffect(() => {
        if (!isModalAlert) {
            setTokenWiki('')
        }
    }, [isModalAlert]);

    return (
        <>
            {isModalAlert && <ModalAlert
                title={tokenWiki}
                copy={true}
                setIsModal={(e) => setIsModalAlert(e)}
            />}

            {isModal && <ModalAddNewWiki
                setIsModal={(e) => setIsModal(e)}
                currentWiki={currentWiki}
                setCurrentWiki={setCurrentWiki}
                fetchWikiList={fetchWikiList}
                setIsModalDelete={setIsModalDelete}
                onDeleteHandler={onDeleteHandler}
                id={id}
                setId={setId}
            />}


            {isModalDelete && <ModalDeleteWiki
                setIsModal={(e) => setIsModalDelete(e)}
                id={id}
                setId={(e) => setId(e)}
                onDeleteHandler={(e) => onDeleteHandler(e)}
            />}

            <div id={portalId} className='wiki-list'>
                <TitlePage
                    title={'Мои wiki'}
                />
                <div className='wiki-list__toolbar'>
                    <ButtonBasic
                        width='150px'
                        onClick={(e) => setIsModal(true)}
                        text={<div className='wiki-list__btn-content'>Создать wiki <img src={add_icon} /></div>}
                    />

                    <ButtonsSort
                        btnSort={btn_sort2}
                        sort={sort}
                        setSort={setSort}
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                    />

                    <InputDynamicWidth
                        position='left'
                        maxWidth='100%'
                        isOpen={true}
                        onChange={value => {
                            setSearchInput(value)
                        }}
                        placeholder='Поиск'
                    />
                </div>

                <div className='wiki-list__card-group'>
                    {wikiList?.map((props, ind) => {
                        const { id, name, author } = props
                        return (
                            <CardGroup
                                key={id}
                                {...props}
                                onClick={e => {
                                    localStorage.setItem('wiki_id', id)
                                    localStorage.setItem('wiki_name', name)
                                    navigate(`/wiki/articles/wiki-${id}`)
                                }}
                                setId={setId}
                                setIsDrop={setIsDrop}
                                isDrop={isDrop}
                                setIsModal={setIsModal}
                                setIsModalDelete={e => setIsModalDelete(true)}
                                setIsModalAlert={setIsModalAlert}
                                setTokenWiki={setTokenWiki}
                                setCurrentWiki={setCurrentWiki}
                            />
                        )
                    }
                    )}
                </div>

            </div >
        </>

    )
}

export default WikiList 