import { useEffect } from 'react';
import notificationIcon from './img/notification.svg'
import close from './img/close.svg'
import "./TooltipYellow.scss"

const TooltipYellow = ({ text, showTooltip, setShowTooltip, title, staticTooltip = false }) => {

    useEffect(() => {
        if (!staticTooltip) {
            const timer = setTimeout(() => {
            setShowTooltip(false)
        }, 10000);
        return () => clearTimeout(timer);
        }
    }, []);

    return (
        <div className={showTooltip ? 'tooltip-yellow_active' : 'tooltip-yellow'}>
            <img src={notificationIcon} />
            <div className='tooltip-yellow__content'>
                {title && <h5 className='title'>{title}</h5>}
                {text && <p className='text_grey-yellow'>{text}</p>}
            </div>
            <img className='close' src={close} onClick={(e) => setShowTooltip(false)} />
        </div>
    )
}

export { TooltipYellow }