import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LogoBox } from '../Logo/Logo';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import done from './img/done-green.svg';
import { processTokenResponse } from '../../common/authHelper';
import './AcceptMail.scss';

const AcceptMail = () => {
  const params = useParams(); // Деструктуризация и переименование параметра
  const device_info = window.navigator.userAgent;

  useEffect(() => {
    const isLocalhost = window.location.hostname.match('localhost');
    const baseUrl = isLocalhost ? KonstantionAuthHttp : KonstantionAuthHttps;
    const url = `${baseUrl}/web/verify-email`;

    const headers = {
      'Content-type': 'application/json',
      'portal-id': '4',
    };

    const body = JSON.stringify({
      token: params.id,
      device_info,
    });

    const verifyEmail = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers,
          body,
        });

        const json = await response.json();

        if (json.detail === 'VERIFY_USER_BAD_TOKEN') {
          window.location.assign('/auth');
        } else {
          processTokenResponse(json, false, true);
        }
      } catch (err) {
        console.error('Error verifying email:', err);
        window.location.assign('/auth');
      }
    };

    verifyEmail();
  }, [params.id, device_info]);

  return (
    <div className="autorization__content">
      <header className="autorization__header">
        <LogoBox />
      </header>

      <div className="accept-mail">
        <img src={done} alt="Done" />
        <h2 className="title">Спасибо за регистрацию</h2>
        <p className="text_grey-blue">
          Теперь Вы можете пользоваться личным кабинетом
        </p>
        <Link className="link__blue" to="/landing">
          Перейти на главную
        </Link>
      </div>
    </div>
  );
};

export { AcceptMail };