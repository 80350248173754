import customHeaders, { getSpace } from '../../common/headers';
import Cookies from 'universal-cookie';
import user_icon from './img/user.svg'
import { TabsGreyBtn } from '../../components/TabsGreyBtn/TabsGreyBtn';
import { ModalCreateGroup } from '../../components/Modal/ModalCreateGroup/ModalCreateGroup';
import { useEffect, useRef, useState } from 'react';
import { ButtonBasic, InputSearch } from "@lk-gtcom/ecomlab-components";
import { CommentItem } from '../../components/CommentItem/CommentItem';
import { Comment } from '../../components/Comment/Comment';
import { MoonLoader } from 'react-spinners';
import { ChatPreview } from './ChatPreview/ChatPreview';
import useGeneralStore from '../../store/general';
import "./ChatMessagePage.scss"

const btns = [
    {
        label: 'Чаты',
        // count: 2,
        value: 'Все'
    },
    {
        label: 'Группы',
        // count: 4,
        value: 'Группы'
    }
]

const btns_2 = [
    {
        label: 'Все',
        value: 'all'
    },
    {
        label: 'Закрепленные',
        value: 'pin'
    },
    {
        label: 'Избранные',
        value: 'favorite'
    }
]


const MessageItem = (props, ind) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const {
        data,
        text,
        author,
        client,
        created_at,
        message_id,
        is_read,
        chat_id, project_id,
        updateUnreadCount
    } = props
    const client_id = +localStorage.getItem('client_id')


    const readMessageStatus = () => {
        let url = `https://apps1.ecomru.ru:4444/project/${project_id}/chat/${chat_id}/message/read`

        const body = JSON.stringify({
            message_id_list: [message_id]
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                updateUnreadCount()
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (project_id >= 0 && chat_id >= 0) {
            if (!is_read && client?.id !== client_id) {
                readMessageStatus()
            }
        }
    }, [])

    return <div className={client_id === client?.id ? 'message_item_user_v1' : 'message_item_v1'} key={message_id}>
        {author && <h3 className='message_item_v1__title'>{author}</h3>}
        {text && <p className='message_item_v1__text'>{text}</p>}
        {/* {img && <img src={img} className='message_item_v1__img' />} */}
        {/* {link && <a href={link} className='message_item_v1__link' target='_blank'>{link}</a>} */}
        {created_at && <p className='message_item_v1__date'>{new Date(created_at)?.toLocaleDateString('ru-RU')} {new Date(created_at)?.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}</p>}
    </div>
}

const ChatMessagePage = ({ setIsModal }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const cookie = new Cookies()
    const auth = cookie?.get('auth')
    const bottomRef = useRef()

    // Информация о юзере
    const email = localStorage.getItem('email');
    const currentUserColor = localStorage.getItem('currentUserColor');
    const currentUserName = localStorage.getItem('currentUserName');

    const [answerValue, setAnswerValue] = useState('')
    const [answer, setAnswer] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [isEditGroup, setIsEditGroup] = useState(false)
    const [isModalCreateGroup, setIsModalCreateGroup] = useState(false)
    const [messageList, setMessageList] = useState([])
    const [messageItems, setMessageItems] = useState([])

    const [projectList, setProjectList] = useState([])
    const [chatList, setChatList] = useState([])

    const [usersList, setUsersList] = useState([])
    const [chatId, setChatId] = useState(-1)

    const [status, setStatus] = useState(btns?.[0]?.value)
    const [status2, setStatus2] = useState(btns_2?.[0]?.value)
    const [currentProject, setCurrentProject] = useState(-1)
    const [currentMessage, setCurrentMessage] = useState({})
    const [isCreateChat, setIsCreateChat] = useState(false)
    const socket = useRef()
    const chatListHeight = document.querySelector('.chat_message_page__content_message')

    const [saveFileMessage, setSaveFileMessage] = useState([]);
    const [saveFileComment, setSaveFileComment] = useState([]);
    const [comments, setComments] = useState([]);
    const [currentMessageAnswer, setCurrentMessageAnswer] = useState(null);

    const [commentPageHeight, setCommentPageHeight] = useState(0);
    const [commentIsSearchLastPage, setCommentIsSearchLastPage] = useState(false);
    const [commentPageScrollTop, setCommentPageScrollTop] = useState(-1);
    const [commentPage, setCommentPage] = useState(1);

    const [pinPageHeight, setPinPageHeight] = useState(0);
    const [pinIsSearchLastPage, setPinIsSearchLastPage] = useState(false);
    const [pinPageScrollTop, setPinPageScrollTop] = useState(-1);
    const [pinPage, setPinPage] = useState(1);

    const [favoritePageHeight, setFavoritePageHeight] = useState(0);
    const [favoriteIsSearchLastPage, setFavoriteIsSearchLastPage] = useState(false);
    const [favoritePageScrollTop, setFavoritePageScrollTop] = useState(-1);
    const [favoritePage, setFavoritePage] = useState(1);

    const [pinMessage, setPinMessage] = useState([])
    const [favoriteMessage, setFavoriteMessage] = useState([])

    const [loadingMessage, setLoadingMessage] = useState(false)
    const [loadingMessageItem, setLoadingMessageItem] = useState(false)
    ////////////////////////////////////////////////////////////

    const [isLoadingPreview, setIsLoadingPreview] = useState(false)

    const onScroll = (listRef, setPageHeight, setScrollTop) => {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        const topHeight = scrollTop; // Теперь отслеживаем верхнюю границу скролла
        if (topHeight === 0) {
            setPageHeight(scrollHeight); // Обновляем высоту страницы
        }
        setScrollTop(scrollTop); // Обновляем значение scrollTop
    };

    const [isUpdateComment, setIsUpdateComment] = useState(false)

    const curren_massage_list = () => {
        if (status2 == 'pin') return pinMessage
        if (status2 == 'favorite') return favoriteMessage
        return messageItems
    }

    useEffect(() => {
        updateScroll()
    }, [status2]);

    // Загрузка сообщений при изменении страницы
    useEffect(() => {
        if (status2 == 'all') {
            if (commentPage > 1) {
                fetchGetMessageHistory();
            }
        }
    }, [commentPage, isUpdateComment, currentMessage]);

    useEffect(() => {

    }, [status2]);

    // Загрузка закрепленных сообщений при изменении страницы
    useEffect(() => {
        if (pinPage > 1) {
            fetchGetPinned();
        }
    }, [pinPage]);

    // Загрузка избранных сообщений при изменении страницы
    useEffect(() => {
        if (favoritePage > 1) {
            fetchGetFavorite();
        }
    }, [favoritePage]);

    useEffect(() => {
        if (status2 == 'pin') fetchGetPinned()
        if (status2 == 'favorite') fetchGetFavorite()
    }, [status2, currentMessage]);

    useEffect(() => {
        //bottomRef?.current?.scrollTo(10000000, 10000000);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (
                !commentIsSearchLastPage &&
                commentPageScrollTop <= 0 && // Когда скролл в верхней части
                messageItems?.length > 0
            ) {
                setCommentPage((prev) => prev + 1); // Подгрузка новых сообщений
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [commentPageScrollTop, commentPageHeight, commentIsSearchLastPage]);

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (
    //             !pinIsSearchLastPage &&
    //             pinPageScrollTop <= 0 && // Когда скролл в верхней части
    //             pinMessage?.length > 0
    //         ) {
    //             setPinPage((prev) => prev + 1); // Подгрузка новых сообщений
    //         }
    //     }, 500);

    //     return () => clearTimeout(timeout);
    // }, [pinPageScrollTop, pinPageHeight, pinIsSearchLastPage]);

    useEffect(() => {
        setCommentPage(1);
    }, []);

    const onSendMessage = (comment) => {
        // fetchPostMessage(comment)
        socket.current.send(JSON.stringify({
            message_text: comment,
            parent_message_id: null,
            files: saveFileMessage
        }))
        setAnswerValue('')
        // setTimeout(() => fetchGetMessageHistory(), 500)
    }

    useEffect(() => {
        if (status2 == 'all' && currentMessage && currentProject) {
            setMessageList([])
            setLoadingMessage(true)

            if (typeof socket.current !== 'undefined') {
                console.log('Close request');
                socket.current.close(1000, 'Request for a new connection')
            }

            if (currentMessage?.chat?.chat_id && currentProject?.project_id) {
                socket.current = new WebSocket(`wss://apps1.ecomru.ru:4444/socket/project/${currentProject?.project_id}/chat/${currentMessage?.chat?.chat_id}?authorization=${auth}&space=${selectedSpace?.value}&page=1&limit=20`)
            }

            if (socket.current) {
                socket.current.onopen = () => {
                    console.log('Соединение установлено');
                }

                socket.current.onmessage = e => {
                    const data = JSON.parse(e.data)
                    const { messages, total } = data
                    if (data?.messages) {
                        setMessageItems((prev) => [...messages])
                    } else {
                        setMessageItems((prev) => [...prev, data])
                    }
                    setLoadingMessage(false)
                    const y = chatListHeight?.scrollHeight;
                    chatListHeight?.scrollTo(0, y)
                }

                socket.current.onclose = (e) => {
                    console.log(`Соединение закрыто: ${e}`);
                }

                socket.current.onerror = error => {
                    console.log(`Ошибка ${error.message}`);
                    setMessageList([])
                }
            }
        }
    }, [currentMessage, currentProject, status2])

    const updateScroll = () => {
        setCommentPageHeight(0);
        setCommentIsSearchLastPage(false);
        setCommentPageScrollTop(-1);
        setCommentPage(1);

        setPinPageHeight(0);
        setPinIsSearchLastPage(false);
        setPinPageScrollTop(-1);
        setPinPage(1);

        setFavoritePageHeight(0);
        setFavoriteIsSearchLastPage(false);
        setFavoritePageScrollTop(-1);
        setFavoritePage(1);
        // setCommentIsSearchLastPage(false);
    }

    // Получить список проектов текущего юзера
    // done
    const fetchProjects = () => {
        let url = 'https://apps1.ecomru.ru:4444/project/'

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setProjectList(json)
            })
            .catch(err => console.error(err))
    }

    // Получить чаты
    const fetchChatList = (id) => {
        setIsLoadingPreview(true)
        let url = `https://apps1.ecomru.ru:4444/project/${id}/chats`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setChatList(json)
                setCurrentMessage(json?.[0])
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoadingPreview(false))

        // setChatList(json.map(el => ({ ...el, name: el?.chat_name?.name })))

    }

    // Получить группы
    const fetchGroup = (id) => {
        setIsLoadingPreview(true)
        let url = `https://apps1.ecomru.ru:4444/project/${id}/group`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setChatList(json)
                setCurrentMessage(json?.[0])
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoadingPreview(false))
    }

    // Получить список юзеров в выбранном проекте
    // done
    const fetchUsers = (id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/users`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setUsersList(json)
            })
            .catch(err => console.error(err))
    }

    const fetchChatUsers = (id, chat_id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${id}/chats/${chat_id}/users`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                setUsersList(json)
            })
            .catch(err => console.error(err))
    }

    // Создать чат
    const createChat = (id) => {
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/chat`

        const body = JSON.stringify({
            client_id: id
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                if (isCreateChat) {
                    fetchChatList(currentProject?.project_id)
                } else {
                    fetchGroup(currentProject?.project_id)
                }
            })
            .catch(err => console.error(err))
    }

    // Создать групповой чат групп
    const createGroup = (ids, group_name, new_name) => {
        setIsModalCreateGroup(false)
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/group`

        const body = JSON.stringify({
            client_ids: ids,
            group_name
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                if (isCreateChat) {
                    fetchChatList(currentProject?.project_id)
                } else {
                    fetchGroup(currentProject?.project_id)
                }
            })
            .catch(err => console.error(err))
    }

    //Изменение названия группы
    const editGroup = (chat_id, group_name) => {
        setIsModalCreateGroup(false)
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/group/${chat_id}/update/change-group-name`


        const body = JSON.stringify({
            group_name: group_name
        })

        fetch(url, { method: 'PATCH', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                fetchGroup(currentProject?.project_id)
            })
            .catch(err => console.error(err))
    }

    // Добавить участника 
    const fetchAddUsers = (chat_id, client_ids) => {
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/group/${chat_id}/update/add-interlocutors`

        const body = JSON.stringify({
            client_ids: [client_ids]
        })

        fetch(url, { method: 'PATCH', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                fetchGroup(currentProject?.project_id)
            })
            .catch(err => console.error(err))
    }

    // Удалить участника 
    const fetchDeleteUsers = (chat_id, client_ids) => {
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/group/${chat_id}/update/delete-user`

        const body = JSON.stringify({
            client_ids: [client_ids]
        })

        fetch(url, { method: 'DELETE', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                fetchGroup(currentProject?.project_id)
            })
            .catch(err => console.error(err))
    }

    // Отпраить сообщение в группу
    const fetchPostMessage = (message) => {
        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/send-message`

        const body = JSON.stringify({
            message_text: message,
            parent_message_id: null,
            files: saveFileMessage
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                fetchGetMessageHistory()
                // fetchGetMessage()
            })
            .catch(err => console.error(err))
    }

    // Получить сообщения в чате 
    const fetchGetMessage = (message) => {
        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/get-message`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                const { messages, total } = json
                setMessageItems((prev) => [...prev, ...messages]);
            })
            .catch(err => console.error(err))
    }

    // Получить историю сообщений
    const fetchGetMessageHistory = (abortController = new AbortController()) => {

        if (commentPage === 1) setLoadingMessage(true)
        if (commentPage > 1) setLoadingMessageItem(true)
        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/get-history?page=${commentPage}&limit=20`

        fetch(url, { method: 'GET', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                const { messages, total } = json
                if (commentPage > 1) {
                    setMessageItems((prev) => [...messages, ...prev]);
                } else {
                    setMessageItems(messages);
                }
                setCommentIsSearchLastPage(total < commentPage * 20);
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoadingMessageItem(false)
                setLoadingMessage(false)
                if (abortController.signal.aborted) {
                    return;
                }
            })
    }

    // Добавить сообщение в закреп
    const fetchPinMessage = (id) => {
        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/add-to_pinned`

        const body = JSON.stringify({
            message_id_list: [id]
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                fetchGetPinned()
                // fetchGroup(currentProject?.project_id)
            })
            .catch(err => console.error(err))
    }

    // Удалить сообщение из закрепа
    const fetchUnpinMessage = (id) => {
        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/remove_from_pinned`

        const body = JSON.stringify({
            message_id_list: [id]
        })

        fetch(url, { method: 'DELETE', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                fetchGetPinned()
                // fetchGroup(currentProject?.project_id)
            })
            .catch(err => console.error(err))
    }

    // Получить закрепленые сообщения
    const fetchGetPinned = () => {

        setLoadingMessage(true)
        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/get_pinned`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                const { messages, total } = json
                setPinMessage(messages)
            })
            .catch(err => console.error(err))
            .finally(() => setLoadingMessage(false))
    }

    // Получить избранные сообщения
    const fetchGetFavorite = () => {

        setLoadingMessage(true)
        let url = `https://apps1.ecomru.ru:4444/project/user/get-favourite`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                const { messages, total } = json
                setFavoriteMessage(messages);
            })
            .catch(err => console.error(err))
            .finally(() => setLoadingMessage(false))
    }

    // Добавить сообщение в избранное
    const fetchDeleteFavorite = (id) => {
        let url = `https://apps1.ecomru.ru:4444/project/user/delete-favourite`

        const body = JSON.stringify({
            message_id_list: [id]
        })

        fetch(url, { method: 'DELETE', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {
                fetchGetFavorite()
            })
            .catch(err => console.error(err))
    }

    // Удалить из избранного
    const fetchFavorite = (id) => {
        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/add-in-favourite`

        const body = JSON.stringify({
            message_id_list: [id]
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(json => {

            })
            .catch(err => console.error(err))
    }

    // useEffect(() => {
    //     fetchGetMessageHistory()
    //     fetchGetMessage()
    // }, [currentMessage]);

    // Пометить как прочитанное
    const fetchChangeStaticViewed = (id) => {

        const isGroup = status == 'Все' ? 'chat' : 'group'
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/read-message`
        } else {
            url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/${isGroup}/${currentMessage?.chat?.chat_id}/read-message`
        }

        const body = JSON.stringify({
            message_id_list: [id]
        })

        fetch(url, { method: 'PUT', body, headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    const getUnreadCount = async () => {
        setIsModalCreateGroup(false)
        let url = `https://apps1.ecomru.ru:4444/project/${currentProject?.project_id}/chat/${currentMessage?.chat_id}/message/unread`


        const res = await fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad resp')
                }
            })
            .then(({ unread_messages }) => {
                return unread_messages
            })
            .catch(err => console.error(err))
        return res
    }

    const updateUnreadCount = () => {
        const count = getUnreadCount()
        const chat_id = currentMessage?.chat_id ? currentMessage?.chat_id : currentMessage?.id ? currentMessage?.id : -1
        setChatList(prev => prev?.map(el => el?.chat_id === chat_id ? { ...el, unread_messages_count: count } : el))
    }

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }

    useEffect(() => {
        fetchProjects()
    }, [selectedSpace])

    useEffect(() => {
        if (status === btns?.[0]?.value) {
            fetchChatList(currentProject?.project_id)
        }
        if (status === btns?.[1]?.value) {
            fetchGroup(currentProject?.project_id)
        }
        fetchUsers()
    }, [currentProject, status, selectedSpace])

    useEffect(() => {
        setMessageItems([])
        setFavoriteMessage([])
        setPinMessage([])
    }, [currentMessage]);

    // Скролл вниз
    useEffect(() => {
        if (commentPage === 1) {
            const y = chatListHeight?.scrollHeight;
            chatListHeight?.scrollTo(0, y)
        }
    }, [messageItems, favoriteMessage, pinMessage, status2])

    useEffect(() => {
        const y = chatListHeight?.scrollHeight;
        chatListHeight?.scrollTo(0, y)
    }, [favoriteMessage, pinMessage, status2]);

    return (
        <>
            {isModalCreateGroup && <ModalCreateGroup
                status={status}
                setIsModal={(e) => setIsModalCreateGroup(e)}
                isEdit={isEditGroup}
                setIsEdit={(e) => setIsEditGroup(e)}
                createGroup={createGroup}
                createChat={createChat}
                type={isCreateChat}
                usersList={usersList}
                currentMessage={currentMessage}
                editGroup={editGroup}
                fetchAddUsers={fetchAddUsers}
                fetchDeleteUsers={fetchDeleteUsers}
            />}

            <div className='chat_message_page' onClick={e => e.stopPropagation()}>
                <aside className='chat_message_page__side_panel'>
                    <h2 className='chat_message_page__title'>Проекты</h2>
                    <div className='chat_message_page__project_list'>
                        {projectList?.map((el) =>
                            <div
                                className={currentProject.project_id == el.project_id ? 'chat_message_page__project_item_active' : 'chat_message_page__project_item'}
                                key={el.project_id}
                                onClick={(e) => {
                                    setChatList([])
                                    setCurrentProject(el)
                                }}
                            >
                                {el.project_name}
                                <span className='unread_count'>{el?.unread_messages > 0 ? el?.unread_messages : ''}</span>
                            </div>
                        )}
                    </div>
                </aside>

                <div className='chat_message_page__list_message'>
                    <header className='chat_message_page__header'>
                        <div className='chat_message_page__btn_group'>
                            <ButtonBasic
                                grey
                                width='auto'
                                text='+ Новая группа'
                                onClick={(e) => {
                                    setIsModalCreateGroup(true)
                                    setIsCreateChat(false)

                                }}
                            />

                            <ButtonBasic
                                grey
                                width='auto'
                                text='+ Новый чат'
                                onClick={e => {
                                    setIsModalCreateGroup(true)
                                    setIsCreateChat(true)
                                }}
                            />
                        </div>

                        <InputSearch />

                        <TabsGreyBtn
                            btns={btns}
                            currentBtn={status}
                            setCurrentBtn={(e) => {
                                setChatList([])
                                setMessageItems([])
                                setStatus(e)
                            }}
                            checkValue={true}
                        />
                    </header>

                    <div className='chat_message_page__container_list_message' >
                        {
                            isLoadingPreview ?
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}
                                >
                                    <MoonLoader />
                                </div>
                                :
                                (chatList?.length > 0 ? chatList?.map((props) => {

                                    if (status == 'Все') {
                                        return <ChatPreview
                                            type={'chat'}
                                            {...props}
                                            currentMessage={currentMessage}
                                            setCurrentMessage={(e) => {
                                                setLoadingMessage(true)
                                                setMessageItems([])
                                                setFavoriteMessage([])
                                                setPinMessage([])
                                                setCurrentMessage(e)
                                            }}
                                            updateScroll={updateScroll}
                                        />
                                    }
                                    else {
                                        return <ChatPreview
                                            {...props}
                                            setIsEditGroup={(e) => setIsEditGroup(e)}
                                            setIsModalCreateGroup={(e) => setIsModalCreateGroup(e)}
                                            currentMessage={currentMessage}
                                            setCurrentMessage={(e) => {
                                                setLoadingMessage(true)
                                                setMessageItems([])
                                                setFavoriteMessage([])
                                                setPinMessage([])
                                                setCurrentMessage(e)
                                            }}
                                            updateScroll={updateScroll}
                                        />
                                    }
                                })
                                    :
                                    <div
                                        className='text_grey'
                                        style={{
                                            margin: '0 auto',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 14
                                        }}
                                    >
                                        Нет доступных чатов
                                    </div>
                                )
                        }
                    </div>
                </div>

                <div className='chat_message_page__current_chat'>
                    <header className='chat_message_page__header_message'>
                        <img className='chat_message_page__user_icon' src={user_icon} />
                        <h2 className='chat_message_page__name_chat'>{currentMessage?.name ? currentMessage?.name : currentMessage?.chat_name?.name}</h2>
                        {
                            setIsModal &&
                            <div className="chat_message_page_close_btn" onClick={e => setIsModal(false)}>X</div>
                        }
                    </header>
                    <header className='chat_message_page__header_message'>
                        <TabsGreyBtn
                            btns={btns_2}
                            currentBtn={status2}
                            setCurrentBtn={(e) => {
                                setStatus2(e)
                            }}
                            checkValue={true}
                        />
                    </header>

                    <div className='chat_message_page__content_message_send'>
                        <div className='chat_message_page__content_message'
                            onScroll={(e) => {
                                onScroll(bottomRef, setCommentPageHeight, setCommentPageScrollTop)
                            }}
                            ref={bottomRef}
                        >
                            {loadingMessage ?
                                <div className='chat_message_page__load'>
                                    <MoonLoader
                                        color="#7b7b7b"
                                        speedMultiplier={0.5}
                                    />
                                </div>
                                :
                                curren_massage_list()?.length < 1 ?
                                    <div
                                        className='text_grey'
                                        style={{ margin: '0 auto', height: '100%', display: 'flex', alignItems: 'center', fontSize: 14 }}
                                    >
                                        В этом чате еще нет сообщений
                                    </div>
                                    :
                                    <>
                                        {loadingMessageItem &&
                                            <div className='chat_message_page__load-item'>
                                                < MoonLoader
                                                    size='40'
                                                    color="#7b7b7b"
                                                    speedMultiplier={0.5}
                                                />
                                            </div>
                                        }

                                        {(curren_massage_list()?.map(({ created_at, text, author, files, message_id }) => {
                                            return <CommentItem
                                                key={created_at + text + message_id}
                                                id={message_id}
                                                setSaveFileComment={setSaveFileComment}
                                                saveFile={saveFileComment}
                                                setSaveFile={setSaveFileComment}
                                                files={files}
                                                date={created_at}
                                                text={text}
                                                author={author}
                                                chat_id={currentMessage?.chat_id}
                                                project_id={currentProject?.project_id}
                                                updateUnreadCount={e => updateUnreadCount()}
                                                fetchChangeViewed={fetchChangeStaticViewed}
                                                fetchUnpinComment={fetchUnpinMessage}
                                                fetchPinComment={fetchPinMessage}
                                                fetchFavorite={fetchFavorite}
                                                fetchDeleteFavorite={fetchDeleteFavorite}
                                                is_pinned={status2 === 'pin'}
                                                is_favorite={status2 === 'favorite'}
                                            />
                                        }))
                                        }
                                    </>

                            }
                        </div>
                        <Comment
                            saveFile={saveFileMessage}
                            setSaveFile={setSaveFileMessage}
                            type={'comments'}
                            setComments={setAnswerValue}
                            createFunc={onSendMessage}
                            // eventId={eventId}
                            fetchDeleteFiles={() => {
                                // setComments([])
                                // bottomRef?.current?.scrollTo(10000000, 10000000);
                                // setCommentIsSearchLastPage(false);
                                // //setCommentPageScrollTop(-1)
                                // setCommentPageHeight(0)
                                // setCommentPage(1)
                                // setIsUpdateComment(!isUpdateComment)
                                // fetchDeleteFiles()
                            }}
                            // fetchGetFiles={fetchGetFiles}
                            // setIsChanges={(e) => setIsChanges(e)}
                            currentMessageAnswer={currentMessageAnswer}
                            setCurrentMessageAnswer={(e) => setCurrentMessageAnswer(e)}
                            // disabled={!infoTask}
                            name={currentUserName}
                            email={email}
                            color={currentUserColor}
                            // event_id={eventId}
                            fetchUpdateComms={() => {
                                // setComments([])
                                // bottomRef?.current?.scrollTo(10000000, 10000000);
                                // setCommentIsSearchLastPage(false);
                                // //setCommentPageScrollTop(-1)
                                // setCommentPageHeight(0)
                                // setCommentPage(1)
                                // setIsUpdateComment(!isUpdateComment)
                            }}
                        />
                        {/* <SendMessage
                            frequentAnswers
                            isEdit={isEdit}
                            setIsEdit={(e) => setIsEdit(e)}
                            message={answer}
                            placeholder={'Напишите Ваше сообщение здесь'}
                            value={answerValue}
                            onChange={e => setAnswerValue(e)}
                            onSendAction={e => onSendMessage(e)} /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export { ChatMessagePage }