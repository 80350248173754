import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./ButtonCopy.scss";

interface ButtonCopyProps {
  value: string;
  icon?: string;
  style?: React.CSSProperties;
  top?: string | number;
}

const ButtonCopy: React.FC<ButtonCopyProps> = ({
  value,
  icon,
  style = {},
  top
}) => {
  const [currentText, setCurrentText] = useState<string>('Скопировать');

  const handleCopy = () => {
    navigator.clipboard.writeText(value)
      .then(() => {
        setCurrentText('Cкопировано');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
        setCurrentText('Ошибка');
      });
  };

  const handleBlur = () => {
    setCurrentText('Скопировать');
  };

  const buttonStyle = {
    backgroundImage: icon ? `url(${icon})` : '',
    ...style
  };

  const tooltipStyle = {
    top: top || ''
  };

  return (
    <div className='button-copy'>
      <button
        className='btn-copy'
        onBlur={handleBlur}
        onClick={handleCopy}
        style={buttonStyle}
        aria-label="Copy to clipboard"
      />
      <div
        className='tooltip_black'
        style={tooltipStyle}
      >
        {currentText}
      </div>
    </div>
  );
};

export { ButtonCopy }