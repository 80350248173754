import useGeneralStore from '../../../../store/general';
import customHeaders, { getSpace } from '../../../../common/headers';
import './CardItemAccount.scss'
import { useState } from 'react';

const CardItemAccount = ({
    id,
    name,
    selected,
    setSelected
}) => {
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };

    return (
        <div className={id == selected ? 'card-item-account_active' : 'card-item-account'}
            key={id}
            onDoubleClick={e => {
                if (id) {
                    setSelected(id)
                }
            }}
            onClick={e => {
                setSelected(id)
            }}
        >
            <div className='tooltip_light-blue'>{name}</div>
        </div>
    )
}

export { CardItemAccount }
