import customHeaders, { getSpace } from '../../common/headers'
import show_icon from './img/show_icon.svg'
import copy_icon from './img/copy_icon.svg'
import { TabsTop } from '../TabsTop/TabsTop';
import { useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners';
import { useDebounce } from '../../hooks/useDebounce';
import { AddMediaProduct } from '../AddMediaProduct/AddMediaProduct';
import { setDataFromJson } from '../../common/filtersFunc';
import { ModalAddingMedia } from '../Modal/ModalAddingMedia/ModalAddingMedia';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { FiltersBreadCrumbs } from '../FiltersBreadCrumbs/FiltersBreadCrumbs'
import { useState, useEffect, useLayoutEffect, memo } from 'react'
import { KonstantinCreateProductHttps } from '../../fetchUrls'
import { ButtonBasic, DropDownSelector, InputDinamycPlaceholder, Checkbox, TextareaDynamicPlaceholder } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from '../../store/general';
import { SkeletonRight } from './SkeletonRight';
import { SkeletonLeft } from './SkeletonLeft';
import InputListArray from '../InputListArray/InputListArray';
import './ProductData.scss'

// data state

const btn_tab_2 = [
    {
        label: 'Обязательные',
        value: `required`
    },
    {
        label: 'Медиа',
        value: `media`
    },
    {
        label: 'Описание',
        value: `description`
    },
    {
        label: 'Дополнительные',
        value: `additional`
    },
    {
        label: 'Пользовательские',
        value: `custom`
    }
]

const ProductData = memo(({
    ...props
}) => {
    const {
        selectedCategories,
        setSelectedCategories,
        setPreviewPhoto,
        arrImg,
        setArrImg,
        setIsModalPreview,
        currentProductId,
        fetchProductIds,
        productTemporary,
        setProductTemporary,
        setCurrentProductId,
        name,
        setName,
        setCurrOfferId,
        isEdit,
        errField,
        setErrField,
        fetchProductAttr,
        searchParams,
        pageValue,
        setPageValue,
        isGeneralloading,
        isRightPanelLoading,
        initialLoad,
        setInitialLoad,
        setIsGeneralloading,
        setIsRightPanelLoading,
        setProductTemporaryList,
        productTemporaryList,
        currentProduct
    } = props

    const [showCategoriesMenu, setShowCategoriesMenu] = useState(false)
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const btn_tab_1 = [
        {
            label: <p className='tab-copy'>
                <p className='text_name'>{productTemporary.attributes?.basic?.[0]?.value || 'Товар'}</p>
                <TooltipLightBlue
                    child={<button className='btn-copy-product' onClick={e => {
                        setProductTemporaryList((prev) => [...prev, {
                            ...productTemporary,
                            used_to: "create",
                            attributes: {
                                ...productTemporary.attributes,
                                basic: productTemporary.attributes?.basic?.map(attr => attr.attribute_id === 'offer_id' ? { ...attr, value: '' } : attr)
                            }

                        }])
                    }}>
                        <img src={copy_icon} />
                    </button>}
                    text='Дублировать товар'
                    top='10px'
                    left='24px'
                />
            </p>,
            value: `product_1`
        }
    ]
    const [initialUpdate, setInitialUpdate] = useState(true)

    useEffect(() => {
        if ((productTemporaryList?.length < 1) && productTemporary?.product_id && initialUpdate) {
            setProductTemporaryList((prev) => [...prev, productTemporary])
            setInitialUpdate(false)
        }
    }, [productTemporary]);

    const [isModalAddPhoto, setIsModalAddPhoto] = useState(false)
    const [arrVideo, setArrVideo] = useState([])

    const [basicList, setBasicList] = useState([])
    const [choosedList, setChoosedList] = useState([])


    const handleInputChange = (valueComponent, productTemporary, type) => {
        // const productData = Array.isArray(productTemporary?.value) ?
        //     productTemporary?.value
        //     :
        //     typeof +productTemporary?.value === 'number' && !isNaN(+valueComponent) ?
        //         +productTemporary?.value
        //         :
        //         productTemporary?.value

        const value = Array.isArray(productTemporary?.value) ?
            valueComponent
            :
            typeof +valueComponent === 'number' && !isNaN(+valueComponent) ?
                +valueComponent
                :
                valueComponent

        // console.log({ ...productTemporary, value: value })        

        // if ((currentProductId?.length > 0) & (JSON.stringify(productTemporary)?.length > 0)) {
        //     if (JSON.stringify(productData) !== JSON.stringify(value)) {
        //         const attr_id = productTemporary?.attribute_id
        //         if (attr_id === 'name') setName(value)
        //         if (attr_id === 'offer_id') setCurrOfferId(value)
        //         debouncePost(currentProductId, { ...productTemporary, value: value })
        //     }
        // }

        setProductTemporary(prevState => ({
            ...prevState,
            attributes: {
                ...prevState.attributes,
                [type]: prevState.attributes?.[type]?.map(attr =>
                    attr.attribute_id === productTemporary.attribute_id ? { ...attr, value: value } : attr
                )
            }
        }));

        if (productTemporary?.attribute_id === 'name') setName(value)
        if (productTemporary?.attribute_id === 'offer_id') setCurrOfferId(value)
    }


    useEffect(() => {
        setProductTemporaryList(prevList =>
            prevList.map((item, index) => {
                return index === currentProduct ? productTemporary : item
            })
        );
    }, [productTemporary]);

    const fetchCategories = async (parentId, page = 1) => {
        const limit = 25
        const offset = (page - 1) * limit

        const url = `${KonstantinCreateProductHttps}/categories${(parentId ? `?parent_id=${parentId}` : '')}`

        const body = JSON.stringify({
            limit,
            offset
        })

        return fetch(url, { body, headers, method: 'POST' })
    }

    const fetchBreadCrumbsSearch = (query, page = 1, abortController = new AbortController()) => {
        let _page = page, limit = 25
        let url = `${KonstantinCreateProductHttps}/category-search`

        const body = JSON.stringify({
            limit,
            page: _page,
            filter_value: query,
        })

        let options = { body, method: 'POST', headers, signal: abortController.signal }


        return fetch(url, options)
    }

    const fetchDictValues = (page, query, setOptions, data, signal) => {
        if (data) {
            const { attribute_id } = data
            const categoryId = productTemporary?.category_id
            const limit = 25
            const offset = page ? (page - 1) * limit : 0
            const url = `${KonstantinCreateProductHttps}/categories/${categoryId}/attributes/${attribute_id}/dictionary_values`
            const body = JSON.stringify({
                limit,
                offset,
                filter_value: query
            })
            fetch(url, { method: 'POST', headers, body, signal })
                .then(res => res.json())
                .then(json => setDataFromJson(json, page, setOptions, false, el => ({ label: el, value: el })))
                .catch(e => console.error(e))
        }
    }

    const DropDownAdapter = ({ data, type }) => {
        const [options, setOptions] = useState([])
        const [selectedValue, setSelectedValue] = useState([])
        const [query, setQuery] = useState("")
        const [page, setPage] = useState(1)

        useEffect(() => {
            const abortSignal = new AbortController()
            const timeoutId = setTimeout(() => {
                fetchDictValues(page, query, setOptions, data, abortSignal.signal)
            }, 700)

            return () => {
                clearTimeout(timeoutId)
                abortSignal.abort()
            }
        }, [query, page])

        if (data) {
            const { attribute_id, attribute_name, front_name, value, description, is_array } = data

            const onSelectHandler = (e) => {
                setSelectedValue(e)

                if (is_array) {
                    if ((e?.length > 0)) {
                        const currData = e?.map(el => el?.value)
                        if (!(JSON.stringify(currData) !== JSON.stringify(value))) return
                        handleInputChange(currData, data, type)
                    }
                } else {
                    if (e?.value && (e?.value !== value)) {
                        handleInputChange(e?.value, data, type)
                    }
                }
            }

            return (
                <div key={attribute_id}>
                    <DropDownSelector
                        width='100%'
                        key={attribute_id}
                        placeholder={front_name ? front_name : attribute_name}
                        options_prop={options}
                        queryValue={query}
                        setInputChange={e => {
                            setPage(1)
                            setQuery(e)
                        }}
                        fetchCallback={e => setPage(e)}
                        noUpwardPaging
                        defaultValue={
                            is_array ?
                                value?.length > 0 ? value?.map(el => ({ label: el, value: el })) : false
                                :
                                value ? { label: value, value: value } : false
                        }
                        setState={e => onSelectHandler(e)}
                        state={selectedValue}
                        multi={is_array}
                        tooltip={description}
                        err={errField && (is_array ? (selectedValue?.length < 1) : !selectedValue)}
                    />
                </div>
            )
        }

    }

    const typeChecker = (data, type) => {

        if (data) {
            if (Array.isArray(data)) {
                const arr = data.map((el, ind) => {
                    let { attribute_id, attribute_name, front_name, data_type, description, required, value, is_dict_value } = el
                    return <InputDinamycPlaceholder
                        key={attribute_id}
                        placeholder={
                            front_name ?
                                (type == 'basic') ? ((front_name == 'Цена до скидки' || front_name == 'Цена') ? front_name + ', ₽ *' : front_name + ' *') : front_name + ', ₽ *'
                                : attribute_name}
                        changeValue={value ? `${value}` : ''}
                        onChange={(e) => {
                            handleInputChange(e, el, type)
                        }}
                        showClearIndicator={true}
                        type={data_type}
                        info={description}
                        autoComplete={false}
                        size='s'
                        required={required}
                        err={required && errField && (value?.length < 1 || !value)}
                        setErr={(e) => setErrField(e)}
                    />

                }

                )
                return <div className='field-group'>{arr}</div>

            } else {
                const { attribute_id, attribute_name, front_name, data_type, description, required, value, is_dict_value, is_array } = data

                if (attribute_id === '4191') {
                    return (
                        <TextareaDynamicPlaceholder
                            tooltip={description}
                            width='100%'
                            key={attribute_id}
                            changeValue={value}
                            placeholder={front_name ? front_name : attribute_name}
                            onChange={(e) => handleInputChange(e, data, type)}
                        />
                    )
                }

                if (attribute_id === 'name') {
                    return (
                        <TextareaDynamicPlaceholder
                            width='100%'
                            key={attribute_id}
                            placeholder={front_name ? ((type == 'basic') ? front_name + ' *' : front_name) : attribute_name}
                            changeValue={value ? `${value?.toString()}` : ''}
                            onChange={(e) => {
                                handleInputChange(e, data, type)
                            }}
                        />
                    )
                }

                if (is_dict_value) {
                    return (
                        <DropDownAdapter key={attribute_id} data={data} type={type} />
                    )
                }

                if (data_type === 'bool') {
                    return (
                        <label className='checkbox-content'>
                            <Checkbox
                                key={attribute_id}
                                id={attribute_id}
                                value={value}
                                onChange={(e) => handleInputChange(e, data, type)}
                            >
                                <>
                                    {front_name ?
                                        (type == 'basic') ? ((front_name == 'Цена до скидки' || front_name == 'Цена') ? front_name + ', ₽ *' : front_name + ' *') : front_name + ', ₽ *'
                                        : attribute_name}

                                    <p className='text_description'>{description}</p>
                                </>
                            </Checkbox>
                        </label>

                    )
                }

                if (is_array) {
                    return (
                        <InputListArray
                            placeholder={
                                front_name ?
                                    (type == 'basic') ? ((front_name == 'Цена до скидки' || front_name == 'Цена') ? front_name + ', ₽ *' : front_name + ' *') : front_name + ', ₽ *'
                                    : attribute_name}
                            changeValue={value ? value : []}
                            onChange={(e) => {
                                handleInputChange(e, data, type)
                            }}
                            key={attribute_id}
                            err={required && errField && (value?.length < 1 || !value)}
                            setErr={(e) => setErrField(e)}
                            disabled={attribute_id === "offer_id" && productTemporary?.used_to == 'update'}
                            info={'Введите значения через ENTER'}
                            showClearIndicator={true}
                            tooltipStatic={description}
                        />
                    )
                }

                if ((data_type === 'str' || data_type === 'int' || data_type === 'float')) {
                    return (
                        <InputDinamycPlaceholder
                            key={attribute_id}
                            className='autorization__input-field'
                            placeholder={
                                front_name ?
                                    (type == 'basic') ? ((front_name == 'Цена до скидки' || front_name == 'Цена') ? front_name + ', ₽ *' : front_name + ' *') : front_name + ', ₽ *'
                                    : attribute_name}
                            changeValue={value ? `${value}` : ''}
                            onChange={(e) => {
                                handleInputChange(e, data, type)
                            }}
                            showClearIndicator={true}
                            type={data_type == 'str' ? 'string' : 'number'}
                            tooltipStatic={description}
                            autoComplete={false}
                            size='s'
                            required={required}
                            err={required && errField && (value?.length < 1 || !value)}
                            setErr={(e) => setErrField(e)}
                            disabled={attribute_id === "offer_id" && productTemporary?.used_to == 'update'}
                            info={data_type === 'float' ? 'Введите целое число или дробное число, через точку' : (
                                data_type === 'int' ? 'Введите целое число, без точки' : ''
                            )}
                        />
                    )
                }
            }
        }
    }

    const parseAttributes = (productTemporary, pageValue, typeChecker) => {

        if (pageValue == 'basic') {
            let arr;
            const new_product_arr = productTemporary?.[pageValue]?.map((el, ind) => {
                if (ind < 3) {
                    return el
                }
                if ((ind % 2 == 0)) {
                    if (ind > 2) {
                        return [productTemporary[pageValue][ind - 1], el]
                    } else return

                }
            })
            return new_product_arr?.map((el) => {
                return typeChecker(el, pageValue)
            })

        } else {
            return productTemporary?.[pageValue]?.map((el) => {
                return typeChecker(el, pageValue)
            })
        }
    }

    useEffect(() => {
        const attributes = productTemporary?.attributes
        const dataImg = productTemporary?.attributes?.images.value

        if (Array.isArray(dataImg)) {
            setArrImg(dataImg)
            setPreviewPhoto(dataImg[0])
        } else {
            setArrImg([])
            setPreviewPhoto([])
        }

        setBasicList(parseAttributes(attributes, 'basic', typeChecker));
        setInitialLoad(false)
    }, [currentProductId, productTemporary])


    useLayoutEffect(() => {
        const attributes = productTemporary?.attributes
        setChoosedList(parseAttributes(attributes, pageValue, typeChecker));
    }, [errField, pageValue, currentProduct]);

    useLayoutEffect(() => {
        const attributes = productTemporary?.attributes
        const dataImg = productTemporary?.attributes?.images.value

        if (dataImg?.length > 0) {
            setArrImg(dataImg)
            setPreviewPhoto(dataImg[0])
        }

        if (initialLoad && attributes?.basic?.length > 0) {

            setBasicList(parseAttributes(attributes, 'basic', typeChecker));
            setInitialLoad(false)
        }

    }, [productTemporary, errField]);


    const saveMedia = () => {
        const dataImg = productTemporary?.attributes?.images
        const dataVideo = productTemporary?.attributes?.videos
        if (arrImg?.length > 0 || dataImg?.value?.length > 0) {
            if (JSON.stringify(arrImg) !== JSON.stringify(dataImg?.value)) {
                setTimeout(() => {
                    handleInputChange(arrImg, dataImg)
                    setProductTemporary((prevState) => ({
                        ...prevState,
                        attributes: {
                            ...prevState.attributes,
                            images: {
                                ...prevState.attributes?.images, // сохраняем другие свойства images, если они есть
                                value: arrImg, // новое значение для images.value
                            },
                        },
                    }));
                }, 100)
            }
        }

        if (arrVideo?.length > 0 || dataVideo?.value?.length > 0) {
            if (JSON.stringify(arrVideo) !== JSON.stringify(dataVideo?.value)) {
                setTimeout(() => {
                    setProductTemporary((prevState) => ({
                        ...prevState,
                        attributes: {
                            ...prevState.attributes,
                            videos: {
                                ...prevState.attributes?.videos, // сохраняем другие свойства images, если они есть
                                value: arrImg, // новое значение для images.value
                            },
                        },
                    }));
                }, 200)
            }
        }
    }

    useEffect(() => {
        saveMedia()
    }, [arrImg, arrVideo]);

    return (
        <>
            {isModalAddPhoto && <ModalAddingMedia
                fetchProductAttr={(e) => fetchProductAttr(e)}
                arrVideo={arrVideo}
                productTemporary={productTemporary}
                arrImg={arrImg}
                setArrImg={(e) => setArrImg(e)}
                setArrVideo={(e) => setArrVideo(e)}
                setIsModal={(e) => setIsModalAddPhoto(e)} />}

            <div className='product-card'>

                {/* Общая информация : левый экран */}

                <div className='total-information'>
                    {
                        isGeneralloading ?
                            <SkeletonLeft />
                            :
                            <>
                                <ButtonBasic
                                    grey
                                    width='150px'
                                    text={<p className='content-btn'>Предпросмотр<img src={show_icon} /></p>}
                                    size='40px'
                                    style={{
                                        position: 'absolute',
                                        top: '15px',
                                        right: '24px'
                                    }}
                                    onClick={(e) => setIsModalPreview(true)}
                                />


                                {/* Доделать логику */}
                                <TabsTop tabs={btn_tab_1} stopRedirect />

                                <div className='total-information__header'>
                                    <h2 className='title'>Общие данные</h2>

                                    <FiltersBreadCrumbs
                                        isPagination
                                        createProduct
                                        showMenu={showCategoriesMenu}
                                        selectedBreadcrumbsValue={selectedCategories}
                                        productTemporary={productTemporary}
                                        setProductTemporary={setProductTemporary}
                                        fetchCallback={fetchCategories}
                                        callbackPromise={e => {
                                            fetchProductIds()
                                            setCurrentProductId(e?.product_id)
                                        }}
                                        setSelectedBreadcrumbsValue={e => {
                                            setSelectedCategories(e)
                                        }}
                                        fetchSearch={fetchBreadCrumbsSearch}
                                        setShowMenu={(e) => setShowCategoriesMenu(e)}
                                        mapFunc={c => ({ label: c.category_name, value: { id: c.category_id, hasChildren: c.has_children } })}
                                        confirmLength={3}
                                        setIsGeneralloading={setIsGeneralloading}
                                        setIsRightPanelLoading={setIsRightPanelLoading}
                                        disabled={productTemporaryList?.length > 0}
                                    />
                                </div>

                                <div className='total-information__content'>
                                    {basicList}
                                </div>
                            </>
                    }
                </div>


                {/* Характеристики : правый экран */}

                <div className='characteristics-container'>
                    <TabsTop
                        tabs={btn_tab_2}
                        pageValue={pageValue}
                        setPageValue={(e) => setPageValue(e)}
                        stopRedirect />

                    {
                        isRightPanelLoading ?
                            <SkeletonRight />
                            :
                            selectedCategories.length > 0 && <>
                                {/* Характеристики */}
                                {pageValue == 'required' && <div className='characteristics-container__header'>
                                    <h2 className='title'>Характеристики</h2>
                                </div>}

                                {/* Медиа */}
                                {pageValue == 'media' ?
                                    <div className='characteristics-container__content'>
                                        <AddMediaProduct
                                            fetchProductAttr={(e) => fetchProductAttr(e)}
                                            productTemporary={productTemporary}
                                            arrImg={arrImg}
                                            setArrImg={(e) => setArrImg(e)}
                                            arrVideo={arrVideo}
                                            setArrVideo={(e) => setArrVideo(e)}
                                            onOpenMediaModal={e => setIsModalAddPhoto(e)}
                                            images={productTemporary?.attributes?.images?.value} videos={productTemporary?.attributes?.images?.value} />
                                    </div>
                                    :
                                    <div className='characteristics-container__content'>
                                        {choosedList}
                                    </div>
                                }
                            </>
                    }
                </div>
            </div>
        </>
    );
})

export { ProductData }

