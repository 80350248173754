import Cookies from 'universal-cookie'
import { KonstantinAddImgHttps, KonstantinAddImgHttp } from '../../fetchUrls';
import drop_icon from './drop_icon.svg'
import "./DropZone.scss"

const DropZone = ({ setSave, accept = false }) => {
    const cookies = new Cookies()
    const token = cookies.get('auth')

    const fetchPostImg = (file) => {
        const fd = new FormData()
        fd.append('images', file)
        const url = `${KonstantinAddImgHttps}/clients/images`

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                if (json.images) {
                    setSave(json.images[0].url)
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleFileInput = (e) => {
        if (e.target.files.length === 0) {
            e.preventDefault()
            return
        }
        if(e.target.files[0]) {
            fetchPostImg(e.target.files[0])
        }
    }
    const acceptObj = accept ? accept : null
    return (
        <div className='drop-zone'>
            <img src={drop_icon} alt='icon' />
            <p>Перетащите файлы сюда или нажмите, чтобы <span>загрузить.</span></p>
            <input
                onChange={(e) => handleFileInput(e)}
                className='input-file'
                type='file' 
                {...acceptObj}
            />
        </div>
    )
}

export { DropZone }