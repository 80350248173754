import { useState, useEffect, userRef } from 'react'
import { useRef } from 'react'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { DropInformation } from '../../DropInformation/DropInformation'
import Cookies from 'universal-cookie'
import add_icon from './img/add_icon.svg'
import { DropZoneProductImg } from '../../DropZoneProductImg/DropZoneProductImg'
import { DropZoneProductVideo } from '../../DropZoneProductVideo/DropZoneProductVideo'
import './ModalAddingMedia.scss'


const ModalAddingMedia = ({ setIsModal, arrImg, arrVideo, setArrImg, setArrVideo, productTemporary, fetchProductAttr }) => {

    const [selectedItem, setSelectedItem] = useState(-1)
    const [droppedItem, setDroppedItem] = useState(-1)
    const [localArr, setLocalArr] = useState([])

    const currentArrVideo = () => {
        const res = productTemporary?.attributes?.videos?.value
        if (res) {
            return res
        } else {
            return arrVideo
        }
    }

    // Сохраняем все ссылки на картинки
    const saveImgs = async () => {
        const arr_img = [...document.querySelectorAll('.image')]
        const arr_src = arr_img.map((el) => el.src)
        await setArrImg(arr_src)
    }

    // Сохраняем все ссылки на Видео
    const saveVideos = async () => {
        const arr_video = [...document.querySelectorAll('.video')]
        const arr_src = arr_video.map((el) => el.src)
        await setArrVideo(arr_src)
    }

    const handleChangeItemPlace = () => {
        if(selectedItem !== -1 && droppedItem !== -1){
            const current = arrImg[selectedItem]
            const dropped = arrImg[droppedItem]
    
    
            setArrImg(prev => prev.map((el, ind) => {
                if(ind === selectedItem){
                    return dropped ? dropped : undefined
                }
                if(ind === droppedItem){
                    return current ? current : undefined
                }
                return el
            }))
            setSelectedItem(-1)
            setDroppedItem(-1)
        }
    }


    useEffect(() => {
        setLocalArr(
            [...Array(15)].map((el, ind) => {
                return <DropZoneProductImg
                    fetchProductAttr={(e) => fetchProductAttr(e)}
                    setArrImg={(e) => setArrImg(e)}
                    arrImg={arrImg}
                    ind={ind}
                    key={JSON.stringify(el) + "" + ind}
                    setSelectedItem={e => setSelectedItem(e)}
                    handleChangeItemPlace={e => setDroppedItem(e)}
                />
            })
        )
    }, [arrImg])

    useEffect(() => {
        handleChangeItemPlace()
    }, [droppedItem])


    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>

            <div className='modal-adding-media'>
                <h2 className='title'>Добавление медиафайлов</h2>

                <div className='modal-adding-media__content'>
                    <div className='photo-container'>
                        <h5 className='title'>Фотографии</h5>
                        <div className='photo-container__content'>
                            {/* Класс для отображения ошибки не загруженной фотографии/видео file-box_err */}
                            {localArr}
                        </div>
                    </div>

                    <div className='video-container'>
                        <h5 className='title'>Видео</h5>
                        <div className='video-container__content'>
                            <div className='photo-container__content'>
                                {/* Класс для отображения ошибки не загруженной фотографии/видео file-box_err */}

                                {[...Array(5)].map((el, ind) => {
                                    return <DropZoneProductVideo
                                        arrVideo={currentArrVideo()}
                                        setArrVideo={(e) => setArrVideo(e)}
                                        ind={ind}
                                        key={Math.random(0, 1) * 10 * 100 + "" + ind}
                                    />
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='drop-content'>

                        <DropInformation
                            title='Требования к фото'
                            text={<p>Формат: JPEG, JPG, PNG;<br />
                                Разрешение: от 200px до 7680px по большей стороне;<br />
                                Размер: не более 10 МБ;<br />
                                Фон: белый или светлый, для белых или прозрачных товаров — чёрный, для категории Одежда, обувь и аксессуары — серый #f2f3f5;<br />
                                Соотношение сторон: 3:4 для категорий Одежда, обувь и аксессуары,1:1 — для остальных.</p>}
                        />

                        <DropInformation
                            title='Требования к видео'
                            text={<p>Формат: JPEG, JPG, PNG;<br />
                                Разрешение: от 200px до 7680px по большей стороне;<br />
                                Размер: не более 10 МБ;<br />
                                Фон: белый или светлый, для белых или прозрачных товаров — чёрный, для категории Одежда, обувь и аксессуары — серый #f2f3f5;<br />
                                Соотношение сторон: 3:4 для категорий Одежда, обувь и аксессуары,1:1 — для остальных.</p>}
                        />
                    </div>
                </div>

                <div className='btn-group'>
                    <ButtonBasic
                        lilac
                        text='Отмена'
                        size='56px'
                        minWidth='93px'
                        maxWidth='93px'
                        width='93px'
                        onClick={(e) => setIsModal(false)}
                    />
                    <ButtonBasic
                        violet
                        text='Сохранить'
                        onClick={async (e) => {
                            await saveImgs()
                            await saveVideos()
                            setIsModal(false)
                        }}
                        size='56px'
                        width='163px'
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalAddingMedia }