import React from 'react';
import { useNavigate } from 'react-router-dom';
import info_icon from '../img/info_icon.svg';
import banner_basic from '../img/banner_basic.svg';
import './ProjectCard.scss';

interface Project {
    taskId: number;
    name: string;
    previewImage?: string;
    isFavorite?: boolean;
    attendee?: string[];
}

interface ProjectCardProps {
    project: Project;
    setSelectedProject: (project: Project) => void;
    setIsInfoProject: (value: boolean) => void;
    setIsModalAddProject: (value: boolean) => void;
    isShowAllExecutor?: boolean;
    arrNames?: (attendee?: string[], authorName?: string) => string[];
    authorName?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
    project,
    setSelectedProject,
    setIsInfoProject,
    setIsModalAddProject,
    isShowAllExecutor = false,
    arrNames = () => { return []},
    authorName
}) => {
    const navigate = useNavigate();
    const { attendee, taskId, name, previewImage, isFavorite } = project;
    
    const handleCardClick = () => {
        setSelectedProject(project);
        sessionStorage.setItem('taskProjectName', name);
        sessionStorage.setItem('taskProjectId', taskId.toString());
        navigate(`/tasks/task-list/${taskId}`);
    };

    const handleInfoClick = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
        setSelectedProject(project);
        setIsInfoProject(true);
        setIsModalAddProject(true);
        navigate(`/tasks/project-for-tasks/${taskId}`);
        sessionStorage.setItem('selectedProject', JSON.stringify(project));
    };
    
    return (
        <div
            className='project-card'
            style={{ border: isFavorite ? '2px solid #1890FF' : undefined }}
            onClick={handleCardClick}
        >
            <div className='project-card__top-content'>
                <div className='project-card__header'>
                    <div className='project-card__statistic-tasks'></div>
                    {taskId !== -1 && (
                        <img src={info_icon} onClick={handleInfoClick} alt='Info' />
                    )}
                </div>
                <div className='project-card__img-box'>
                    <img
                        className='project-card__img'
                        alt='project preview'
                        src={previewImage || banner_basic}
                        onError={(e) => (e.currentTarget.src = banner_basic)}
                    />
                </div>
            </div>

            <div className='project-card__medium-content'>
                <h3 className='project-card__title'>{name}</h3>
            </div>

            <div className='project-card__bottom-content'>
                <div className='project-card__employees-container'>
                    {isShowAllExecutor ? arrNames(attendee, authorName) : arrNames(attendee, authorName)?.slice(0, 6)}
                    {!isShowAllExecutor && attendee?.length && attendee.length > 6 && (
                        <p className='project-card__sum-executor'>+<span>{attendee.length - 6}</span></p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
