import customHeaders, { getSpace } from '../../common/headers';
import not_img from './img/not-img.svg';
import star_icon from './img/star_icon.svg';
import description_tab from './img/description_tab.svg';
import brand_icon from './img/brandIcon.svg';
import seller_icon from './img/categoryIcon.svg';
import category_icon from './img/category_icon.svg';
import marketplace_icon from './img/marketplace_icon.svg';
import { Page404 } from '../../pages/Page404/Page404';
import { BarCharts } from '../../charts/BarCharts/BarCharts';
import { MoonLoader } from 'react-spinners';
import { ButtonCopy } from '../ButtonCopy/ButtonCopy';
import { CardDynamic } from '../CardDynamic/CardDynamic';
import { useChartData } from '../../common/hooks';
import { useDataTable } from '../../common/hooks';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { CardStatisticsVer2 } from '../CardStatisticsVer2/CardStatisticsVer2';
import { ProductCardExtended_Stocks } from '../../pages/ProductCardExtended_Stocks/ProductCardExtended_Stocks';
import { ProductCardExtended_Mapping } from '../../pages/ProductCardExtended_Mapping/ProductCardExtended_Mapping';
import { ProductCardExtended_Position } from '../../pages/ProductCardExtended_Position/ProductCardExtended_Position';
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { ProductCardExtended_Feedbacks } from '../../pages/ProductCardExtended_Feedbacks/ProductCardExtended_Feedbacks';
import { ProductCardExtended_Information } from '../../pages/ProductCardExtended_Information/ProductCardExtended_Information';
import { ProductCardExtended_Description } from '../../pages/ProductCardExtended_Description/ProductCardExtended_Description';
import { ProductCardExtended_Competitors } from '../../pages/ProductCardExtended_Competitors/ProductCardExtended_Competitors';
import { ProductCardExtended_StockBalances } from '../../pages/ProductCardExtended_StockBalances/ProductCardExtended_StockBalances';
import { ProductCardExtended_OrdersAndSales } from '../../pages/ProductCardExtended_OrdersAndSales/ProductCardExtended_OrdersAndSales';
import { ProductCardExtended_RelatedProducts } from '../../pages/ProductCardExtended_RelatedProducts/ProductCardExtended_RelatedProducts';
import { ProductCardExtended_ProductVariants } from '../../pages/ProductCardExtended_ProductVariants/ProductCardExtended_ProductVariants';
import { ProductCardExtended_AdvertisingPlace } from '../../pages/ProductCardExtended_AdvertisingPlace/ProductCardExtended_AdvertisingPlace';
import { ProductCardExtended_GeneralIndicators } from '../../pages/ProductCardExtended_GeneralIndicators/ProductCardExtended_GeneralIndicators';
import { ProductCardExtended_FinancialAnalysis } from '../../pages/ProductCardExtended_FinancialAnalysis/ProductCardExtended_FinancialAnalysis';
import { ProductCardExtended_ProductImprovement } from '../../pages/ProductCardExtended_ProductImprovement/ProductCardExtended_ProductImprovement';
import { ProductCardExtended_AdvertisingCompanies } from '../../pages/ProductCardExtended_AdvertisingCompanies/ProductCardExtended_AdvertisingCompanies';
import { useState, useEffect, useRef, createContext } from 'react';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import { OlegHttps, OlegHttp } from '../../fetchUrls';
import {
  Tabs,
  TableBox,
  RangeBox,
  DropDownSelector,
  CheckboxToggleBlue,
} from '@lk-gtcom/ecomlab-components';
import './ProductCardExtended.scss';
import { fetchCategoryFilter } from '../../common/filtersFunc';
import { addFiltersByDataObj, addFiltersToUrlQuery, getDataObjByFilters, getFiltersByUrlQuery } from '../../common/utils';
import useGeneralStore from '../../store/general';

const btn_tab_competitors = [
  {
    label: 'Общие показатели',
    value: 'general',
  },
  {
    label: 'Описание и характеристики',
    value: 'description',
  },
  {
    label: 'Позиция товара',
    value: 'position',
  },
  {
    label: 'Отзывы',
    value: 'feedbacks',
  },
  {
    label: 'Варианты товара',
    value: 'product_variants',
  },
  {
    label: 'Остатки по складам',
    value: 'stock_balances',
  },
  {
    label: 'Рекламное место',
    value: 'advertisin_place',
  },
  {
    label: 'Финансовый анализ',
    value: 'financial_analysis',
  },
  {
    label: 'Заказы и продажи',
    value: 'orders_and_sales',
  },
  {
    label: 'Доступность товара',
    value: '',
  },
];

const btn_tab_master_sku = [
  {
    label: 'Общие показатели',
    value: 'general',
  },
  {
    label: 'Описание и характеристики',
    value: 'description',
  },
  // {
  //   label: 'Позиция товара',
  //   value: 'position',
  // },
  {
    label: 'Отзывы',
    value: 'feedbacks',
  },
  {
    label: 'Варианты товара',
    value: 'product_variants',
  },
  {
    label: 'Остатки по складам',
    value: 'stock_balances',
  },
  // {
  //   label: 'Маппинг',
  //   value: 'mapping',
  // },
  // {
  //   label: 'Улучшение товара',
  //   value: 'product_improvement/emoji',
  // },
  // {
  //   label: 'Финансовый анализ',
  //   value: 'financial_analysis',
  // },
  {
    label: 'Заказы и продажи',
    value: 'voronka',
  },
  {
    label: 'Акции',
    value: 'sales',
  },
  {
    label: 'Рекламные компании',
    value: 'ad_campaigns',
  },
  // {
  //   label: 'Доступность товара',
  //   value: '',
  // },
];

const btn_tab_master_product = [
  {
    label: 'Общие показатели',
    value: 'general',
  },
  {
    label: 'Описание и характеристики',
    value: 'description',
  },
  {
    label: 'Варианты товара',
    value: 'product_variants',
  },
  {
    label: 'Остатки по складам',
    value: 'stock_balances',
  },
  {
    label: 'Маппинг',
    value: 'mapping',
  },
  {
    label: 'Улучшение товара',
    value: 'product_improvement/emoji',
  },
];

const btn_tab_2 = [
  {
    label: 'Категории',
    value: 'categories',
    icon: description_tab,
  },
  {
    label: 'Поисковые запросы',
    value: 'search',
    icon: description_tab,
  },
];

const btn_tab_3 = [
  {
    label: 'Продажи',
    value: 'sales',
  },
  {
    label: 'Цена',
    value: 'price',
  },
  {
    label: 'Остатки',
    value: 'stock',
  },
  {
    label: 'Выручка',
    value: 'revenue',
  },
];

const options_period = [
  {
    label: 'дням',
    value: 'day',
  },
  {
    label: 'неделям',
    value: 'week',
  },

  {
    label: 'месяцам',
    value: 'month',
  },
];

const metrics_names = ['7days', '30days', '60days', '90days'];

const metrics_names_info = [
  'first_discovered',
  'sales_revenue',
  'price_stock_reviews',
];

export const DataContext = createContext({});

export const dataActionTypes = Object.seal({
  createProduct: 'create product',
  done: 'done',
});

const ProductCardExtended = ({ title, content }) => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = { ...customHeaders, ...getSpace(selectedSpace) }
  const currentTab = () => {
    if (content == 'mp_product') {
      return btn_tab_master_sku;
    } else if (content == 'master-product') {
      return btn_tab_master_product;
    } else return btn_tab_competitors;
  };

  const [query, setQuery] = useState(null);
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [pageValue, setPageValue] = useState(currentTab().value);
  const [pageValue2, setPageValue2] = useState(btn_tab_2[0].value);
  const [pageValue3, setPageValue3] = useState(btn_tab_3[0].value);

  const [fetchedMetrics, setFetchedMetrics] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');

  const [selectedPeriod, setSelectedPeriod] = useState(options_period[0]);
  const [stateSlider, setStateSlider] = useState([]);
  const activeTab = useParams()['*'];
  const [searchParams, setSearchParams] = useSearchParams();

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const [generalData, setGeneralData] = useState([]);
  const [generalDataLoading, setGeneralDataLoading] = useState(false);
  const [description, setDescription] = useState('');

  // const [chartData, setChartData] = useState([])
  const [chartDataName, setChartDataName] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [checkFbs, setCheckFbs] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [product, setProduct] = useState({});
  const [nmid, setNmid] = useState('');
  const [apiId, setApiId] = useState('');
  const [productId, setProductId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [productMetrics, setProductMetrics] = useState([]);
  const [productDesc, setProductDesc] = useState({});
  const [setChartData, chartData, chartActions] = useChartData();

  const [selectedTableMetric, setSelectedTableMetric] = useState(null);
  const [selectedSearchQuery, setSelectedSearchQuery] = useState('');

  const [positionPageValue, setPositionPageValue] = useState('by_keywords');
  const [metricsList, setMetricsList] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('');

  function getCategoryPosition(state) {
    const categories = {
      by_keywords: 'keywords',
      by_category: 'category',
      by_brand: 'brand',
      by_seller: 'seller',
    };
    return categories[state] || 'keywords';
  }

  const categoryPosition = getCategoryPosition(positionPageValue);

  const dataState = {
    pageValue3,
    setPageValue3,
    fetchedMetrics,
    chartData,
    labels,
    chartDataName,
    chartLoading,
  };

  useEffect(() => {
    if (pageValue !== 'position') {
      setPositionPageValue('by_keywords');
    }
  }, [pageValue]);

  useEffect(() => {
    setPageValue(activeTab);
    const nmid_filter = searchParams.get('nmid');
    const product_id_filter = searchParams.get('productId');
    const brand_id_filter = searchParams.get('brandId');
    const api_id_filter = searchParams.get('apiId');


    if (nmid_filter) {
      setNmid(nmid_filter);
    } else {
      if (nmid) {
        setSearchParams({ nmid, productId, brandId, apiId });
      }
    }

    if (api_id_filter) {
      setApiId(api_id_filter);
    } else {
      if (apiId) {
        setSearchParams({ nmid, productId, brandId, apiId });
      }
    }

    if (product_id_filter) {
      setProductId(product_id_filter);
    } else {
      if (productId) {
        setSearchParams({ nmid, productId, brandId, apiId });
      }
    }

    if (brand_id_filter) {
      setBrandId(brand_id_filter);
    } else {
      if (productId) {
        setSearchParams({ nmid, productId, brandId, apiId });
      }
    }
  }, [activeTab, searchParams]);

  const resetTable = () => {
    paginatorRef?.current?.reset()
    paginatorRef?.current?.setTotal(0)
    // setCheckedProducts([])
    setFetchedData([])
    setHeaders([])
    setSort({})
    setFilters([])
  }

  const fetchProductCardGeneral = (abortController = new AbortController()) => {
    const api_id = `?api_id=${apiId}`
    const offer_id = `&offer_id=${nmid}`
    const fbs = `&fbs=${checkFbs}`



    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analysis/product/card/general${api_id + offer_id + fbs}`;
    } else {
      url = `${OlegHttp}/api/v1/analysis/product/card/general${api_id + offer_id + fbs}`;
    }

    const [date_from, date_to] = date;

    const body = JSON.stringify({
      date_from: new Date(date_from + 10)?.toISOString()?.split('T')?.[0],
      date_to: new Date(date_to)?.toISOString()?.split('T')?.[0],
      limit: paginatorRef.current?.limit || 10,
      page: paginatorRef.current?.page || 1,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setProductMetrics(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const fetchProductMetrics = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/sku/general/metrics/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/sku/general/metrics/`;
    }

    let [date_from, date_to] = date;
    date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0];
    date_to = new Date(date_to)?.toISOString().split('T')?.[0];

    const body = JSON.stringify({
      nm_id_filter: nmid,
      brand_id_filter: brandId,
      fbofbs_filter: checkFbs,
      date_from,
      date_to,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setProductMetrics(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const fetchProductDescription = (abortController = new AbortController()) => {
    const offer_id = `?offer_id=${nmid}`
    const api_id = apiId ? `&api_id=${apiId}` : ''

    // content == 'mp_product' 
    // content == 'master-product'
    const isMaster = content == 'master-product' ? '/master' : ''

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analysis${isMaster}/product/description${offer_id}${api_id}`;
    } else {
      url = `${OlegHttp}/api/v1/analysis${isMaster}/product/description${offer_id}${api_id}`;
    }

    fetch(url, {
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setProductDesc(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const fetchTableData2 = (params, abortController = new AbortController()) => {
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }

    setLoading(true);
    let url;
    if (!window.location.hostname.match('localhost')) {
      if (pageValue === 'position' && categoryPosition) {
        url = `${AndreySkuHttps}/api/v1/ecomru/sku/${pageValue}/${categoryPosition}/table/`;
      } else {
        url = `${AndreySkuHttps}/api/v1/ecomru/sku/${pageValue}/table/`;
      }
    } else {
      if (pageValue === 'position' && categoryPosition) {
        url = `${AndreySkuHttp}/api/v1/ecomru/sku/${pageValue}/${categoryPosition}/table/`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/sku/${pageValue}/table/`;
      }
    }

    const [date_from, date_to] = date;

    const metric_name =
      pageValue === 'advertisin_place'
        ? { metric_name: selectedTableMetric }
        : {};

    const body = JSON.stringify({
      nm_id_filter: nmid,
      brand_id_filter: brandId,
      limit: paginatorRef.current?.limit,
      page: paginatorRef.current?.page,
      fbofbs_filter: checkFbs,
      date_from: new Date(date_from + 10)?.toISOString()?.split('T')?.[0],
      date_to: new Date(date_to)?.toISOString()?.split('T')?.[0],
      reverse: false,
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      filters,
      ...metric_name,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const fetchTableData = (params, abortController = new AbortController()) => {

    let sort
    let filtersParam

    if (params) {
      [sort, filtersParam] = params
    }

    setLoading(true);
    let url;

    const sort_params = `${sort?.length > 0
      ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1]
      : ''
      }`

    const api_id = `?api_id=${apiId}`
    const offer_id = `&offer_id=${nmid}`
    const fbs = `&fbs=${checkFbs}`

    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analysis/product/table/${pageValue}${api_id + offer_id + sort_params + fbs}`;
    } else {
      url = `${OlegHttp}/api/v1/analysis/product/table/${pageValue}${api_id + offer_id + sort_params + fbs}`;
    }

    const [date_from, date_to] = date;

    const metric_name =
      pageValue === 'advertisin_place'
        ? { metric_name: selectedTableMetric }
        : {};

    const body = JSON.stringify({
      nm_id_filter: nmid,
      brand_id_filter: brandId,
      limit: paginatorRef.current?.limit || 10,
      page: paginatorRef.current?.page || 1,
      fbofbs_filter: checkFbs,
      date_from: new Date(date_from + 10)?.toISOString()?.split('T')?.[0],
      date_to: new Date(date_to)?.toISOString()?.split('T')?.[0],
      reverse: false,
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      filters: filtersParam,
      ...metric_name,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const fetchChart2 = (abortController = new AbortController()) => {
    if (selectedMetric) {
      setChartLoading(true);

      let url;
      if (!window.location.hostname.match('localhost')) {
        if (pageValue === 'position' && categoryPosition) {
          url = `${AndreySkuHttps}/api/v1/ecomru/sku/${pageValue}/${categoryPosition}/graph/`;
        } else {
          url = `${AndreySkuHttps}/api/v1/ecomru/sku/${pageValue}/graph/`;
        }
      } else {
        if (pageValue === 'position' && categoryPosition) {
          url = `${AndreySkuHttp}/api/v1/ecomru/sku/${pageValue}/${categoryPosition}/graph/`;
        } else {
          url = `${AndreySkuHttp}/api/v1/ecomru/sku/${pageValue}/graph/`;
        }
      }

      const [date_from, date_to] = date;

      const queryName =
        pageValue === 'advertisin_place' || pageValue === 'position'
          ? { query_name: selectedSearchQuery }
          : {};

      const metricName =
        (pageValue === 'position' || pageValue === 'advertisin_place' || pageValue == 'general') &&
          selectedMetric
          ? { metric_name: selectedMetric }
          : {};

      const body = JSON.stringify({
        nm_id_filter: nmid,
        brand_id_filter: brandId,
        fbofbs_filter: checkFbs,
        date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
        date_to: new Date(date_to)?.toISOString().split('T')?.[0],
        initial: !(chartData?.selectedLegend?.length > 0),
        metrics: chartData?.selectedLegend,
        ...metricName,
        ...queryName,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setChartData(json);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setChartLoading(false);
        });
    }
  };

  const fetchChart = (abortController = new AbortController()) => {
    if (selectedMetric) {
      setChartLoading(true);

      const fbofbs_filter = `?fbs=${checkFbs}`
      const api_id = `&api_id=${apiId}`
      const offer_id = `&offer_id=${nmid}`

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${OlegHttps}/api/v1/analysis/product/graph/${selectedMetric}${fbofbs_filter + api_id + offer_id}`;
      } else {
        url = `${OlegHttp}/api/v1/analysis/product/graph/${selectedMetric}${fbofbs_filter + api_id + offer_id}`;
      }

      const [date_from, date_to] = date;

      const queryName =
        pageValue === 'advertisin_place' || pageValue === 'position'
          ? { query_name: selectedSearchQuery }
          : {};

      const metricName =
        (pageValue === 'position' || pageValue === 'advertisin_place' || pageValue == 'general') &&
          selectedMetric
          ? { metric_name: selectedMetric }
          : {};

      const body = JSON.stringify({
        // nm_id_filter: nmid,
        // brand_id_filter: brandId,
        // fbofbs_filter: checkFbs,
        date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
        date_to: new Date(date_to)?.toISOString().split('T')?.[0],
        initial: !(chartData?.selectedLegend?.length > 0),
        metrics: chartData?.selectedLegend,
        // ...metricName,
        // ...queryName,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setChartData(json);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setChartLoading(false);
        });
    }
  };

  const fetchCards2 = (path, abortController = new AbortController()) => {
    if (searchValue?.length >= 3) {
      let wasted_revenue =
        pageValue == 'wasted_revenue' ? `wasted_revenue/indicator/${path}` : '';
      let information =
        pageValue == 'information' ? `information/${path}/` : '';

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sku/${wasted_revenue}${information}`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/keywords/sku/${wasted_revenue}${information}`;
      }

      const body = JSON.stringify({
        nmid_filter: searchValue,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const data = Array.isArray(json) ? [...json] : [json];
          setFetchedMetrics((prev) => [...prev, ...data]);
        })
        .catch((err) => {
          if (abortController.signal.aborted) return;
          console.error(err);
        });
    }
  };

  const fetchCards = (path, abortController = new AbortController()) => {

    const api_id = `?api_id=${apiId}`
    const offer_id = `&offer_id=${nmid}`
    const fbs = `&fbs=${checkFbs}`

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analysis/product/card/${pageValue}${api_id + offer_id + fbs}`;
    } else {
      url = `${OlegHttp}/api/v1/analysis/product/card/${pageValue}${api_id + offer_id + fbs}`;
    }

    const [date_from, date_to] = date;

    const body = JSON.stringify({
      limit: paginatorRef.current?.limit || 10,
      page: paginatorRef.current?.page || 1,
      date_from: new Date(date_from + 10)?.toISOString()?.split('T')?.[0],
      date_to: new Date(date_to)?.toISOString()?.split('T')?.[0],
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const data = Array.isArray(json) ? [...json] : [json];
        setFetchedMetrics((prev) => [...prev, ...data]);
      })
      .catch((err) => {
        if (abortController.signal.aborted) return;
        console.error(err);
      });

  };

  const fetchGeneralData2 = (abortController = new AbortController()) => {
    if (searchValue?.length >= 3) {
      setGeneralDataLoading(true);
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreySkuHttps}/api/v1/ecomru/keywords/sku/sidebar/general_data/`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/keywords/sku/sidebar/general_data/`;
      }

      const body = JSON.stringify({
        nmid_filter: searchValue,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          if (json) {
            setGeneralData(json);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (abortController.signal.aborted) return;
          setGeneralDataLoading(false);
        });
    }
  };

  const fetchGeneralData = (abortController = new AbortController()) => {

    setGeneralDataLoading(true);
    const api_id = apiId ? `&api_id=${apiId}` : ''
    const offer_id = `?offer_id=${nmid}`

    // content == 'mp_product' 
    // content == 'master-product'
    const isMater = content == 'master-product' ? '/master' : ''

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegHttps}/api/v1/analysis${isMater}/product/general${offer_id + api_id}`;
    } else {
      url = `${OlegHttp}/api/v1/analysis${isMater}/product/general${offer_id + api_id}`;
    }

    fetch(url, {
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (json) {
          setProduct(json);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setGeneralDataLoading(false);
      });

  };

  const fetchDescription = (abortController = new AbortController()) => {
    const api_id = `?api_id=${apiId}`
    const offer_id = `&offer_id=${nmid}`

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/analysis/product/description${api_id + offer_id}`;
    } else {
      url = `${AndreySkuHttp}/api/v1/analysis/product/description${api_id + offer_id}`;
    }

    fetch(url, {
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setDescription(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });

  };

  const fetchMetricsListPosition = () => {
    if (pageValue === 'position') {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${AndreySkuHttps}/api/v1/ecomru/sku/position/${categoryPosition}/metric_list/`;
      } else {
        url = `${AndreySkuHttp}/api/v1/ecomru/sku/position/${categoryPosition}/metric_list/`;
      }

      fetch(url, {
        method: 'GET',
        headers,
      })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setMetricsList(json);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => { });
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (nmid) {
      // fetchProduct();
      fetchProductMetrics();
    }
    return () => abortController.abort();
  }, [nmid, selectedSpace]);

  useEffect(() => {
    const abortController = new AbortController();
    chartActions?.resetChart();
    fetchChart(abortController);
    return () => abortController.abort();
  }, [selectedSearchQuery, selectedMetric, selectedSpace, checkFbs]);

  useEffect(() => {
    const abortController = new AbortController();
    if (nmid) {
      if (
        pageValue === 'ad_campaigns' ||
        pageValue === 'sales' ||
        pageValue === 'product_variants' ||
        pageValue === 'stock_balances' ||
        pageValue === 'feedbacks'
      ) {
        resetTable();
        fetchTableData([], abortController);
      }

      if (pageValue === 'description') {
        fetchProductDescription();
      }

      if (pageValue === 'general') {
        chartActions?.resetChart();
        fetchChart(abortController);
      }
    }
    return () => abortController.abort();
  }, [pageValue, pageValue2, pageValue3, searchValue, date, checkFbs, selectedSpace, selectedTableMetric]);

  useEffect(() => {
    const abortController = new AbortController();
    if (pageValue === 'general') {
      fetchChart(abortController);
    }
    return () => abortController.abort();
  }, [checkedProducts, selectedSpace]);

  useEffect(() => {
    if (nmid) {
      fetchGeneralData();
      fetchProductCardGeneral()
    }
  }, [apiId, nmid]);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    let query = params.get('mp');
    setQuery(query);
  }, [pageValue, new URL(document.location)]);

  useEffect(() => {
    const dataObj = getFiltersByUrlQuery(searchParams);
    addFiltersByDataObj({
      dataObj,
      setDate,
      setQuery,
      setFbofbs: e => setCheckFbs(e)
    })
  }, [searchParams]);

  const setQueryByFilters = ({ current }) => {
    const queryDataObj = getFiltersByUrlQuery(searchParams);
    const dataObj = getDataObjByFilters({
      rest: queryDataObj,
      date,
      fbofbs: `${checkFbs}`,
      ...current
    });

    addFiltersToUrlQuery(dataObj, setSearchParams);
  }


  return (
    <div className="product-card-extended">
      <div className="product-card-extended__main-content">
        <div className="product-card-extended__card-info">
          <div className="product-card-extended__card-info">
            <div className="img-info">
              <div className="img-box">
                {product?.images ? (
                  <img className="img" src={product.images} />
                ) : (
                  <img className="not-img" src={not_img} />
                )}
              </div>
              <div className="header-panel">
                <div className="card-product">
                  {generalDataLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        minHeight: '100%',
                      }}
                    >
                      <MoonLoader />
                    </div>
                  ) : (
                    <>
                      <div className="info-content">

                        <div className="article-container">
                          <div className="article-container__content">
                            <div className="text-box">
                              <span className="text-placeholder">Артикул:</span>
                              <p className="text">
                                {product.nm_id ? product.nm_id : '-'}
                              </p>
                            </div>
                          </div>
                          <ButtonCopy
                            value={product.nm_id ? product.nm_id : ''}
                          />
                        </div>

                        <div className="article-container">
                          <div className="info-content__item">
                            <div className="info-content__label">
                              <img src={brand_icon} />
                              <span className="text-placeholder">Бренд</span>
                            </div>
                            <div className="text-box">
                              <p className="text">
                                {product.brand_name
                                  ? product.brand_name
                                  : 'Нет названия'}
                              </p>
                            </div>
                          </div>
                          <ButtonCopy
                            value={product.brand_name ? product.brand_name : ''}
                          />
                        </div>

                        <div className="article-container">
                          <div className="info-content__item">
                            <div className="info-content__label">
                              <img src={category_icon} />
                              <span className="text-placeholder">Категория</span>
                            </div>
                            <div className="text-box">
                              <p className="text">
                                {product.categories
                                  ? product.categories
                                  : 'Нет названия'}
                              </p>
                            </div>
                          </div>
                          <ButtonCopy
                            value={product.categories ? product.categories : ''}
                          />
                        </div>

                        <div className="info-content__item">
                          <div className="info-content__label">
                            <img src={seller_icon} />
                            <span className="text-placeholder">Продавец</span>
                          </div>
                          <div className="text-box">
                            <p className="text">
                              {product.seller ? product.seller : 'Нет названия'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="cards-container">
              {productMetrics?.length > 0
                ? productMetrics?.map((data, i) => (
                  <CardDynamic key={JSON.stringify(data)} {...data} />
                ))
                : [...Array(5)]?.map((e, i) => (
                  <CardDynamic key={i} title={''} dynamic={''} value={''} />
                ))}
            </div>
          </div>
        </div>

        <div className="product-card-extended__right-content">
          <div className="product-card-extended__content">
            <div className="product-card-extended__toolbar-top">
              {title && (
                <h1 className="title_main">
                  {title}
                  {nmid ? `"${nmid}"` : ''}
                </h1>
              )}

              <div className="range-group-btn-and-range-box">
                {pageValue == 'by_days' && (
                  <div className="drop-box">
                    <p className="text">По</p>
                    <DropDownSelector
                      options_prop={options_period}
                      state={selectedPeriod}
                      setState={(e) => setSelectedPeriod(e)}
                      defaultValue={selectedPeriod}
                      className="connections_page_selector"
                      isClearable={false}
                    />
                  </div>
                )}

                {/* Нужный элемент - не удалять */}

                {/* <div className='checkbox-group-filters'>
                                <p className='text'>Склад:</p>
                                <div className='checkbox-group-filters__checkbox-group'>
                                    <Checkbox
                                        value={checkMp}
                                        onChange={(e) => setCheckMp(e)}
                                    >
                                        Маркетплейс
                                    </Checkbox>

                                    <Checkbox
                                        value={checkSeller}
                                        onChange={(e) => setCheckSeller(e)}
                                    >
                                        Продавца
                                    </Checkbox>
                                </div>
                            </div> */}

                {/* <div className='checkbox-group-filters'>
                                <p className='text'>Товары:</p>
                                <div className='checkbox-group-filters__checkbox-group'>
                                    <Checkbox
                                        value={checkZeroBalance}
                                        onChange={(e) => setCheckZeroBalance(e)}
                                    >
                                        С нулевым остатком
                                    </Checkbox>
                                </div>
                            </div> */}

                <TooltipLightBlue
                  text={
                    <p className="text_tooltip">
                      FBO <span>(продажи со склада площадки)</span> по
                      умолчанию.
                      <br />
                      <br />
                      Нажмите на переключатель чтобы увидеть FBS{' '}
                      <span>(продажи со склада продавца)</span>
                    </p>
                  }
                  top="32px"
                  left="0px"
                  maxWidth="110px"
                  child={
                    <label className="checkbox-container">
                      <CheckboxToggleBlue
                        value={checkFbs}
                        onChange={(e) => {
                          setCheckFbs(!checkFbs)
                          setQueryByFilters({ current: { fbofbs: !checkFbs } })
                        }}
                      />
                      <p className="text_label">FBS</p>
                    </label>
                  }
                />
                <RangeBox setDate={(e) => {
                  setDate(e)
                  setQueryByFilters({ current: { date: e } })
                }} />
              </div>
            </div>
            <Tabs
              tabs={currentTab()}
              query={`?mp=${query}`}
              pageValue={pageValue}
            />

            <div className="changed-content">
              <div className="left-content">
                <DataContext.Provider value={dataState}>
                  <Routes>
                    {/* Общие показатели */}
                    <Route
                      path="/general"
                      element={
                        <ProductCardExtended_GeneralIndicators
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                          setSelectedTableMetric={setSelectedTableMetric}
                          selectedTableMetric={selectedTableMetric}
                          selectedMetric={selectedMetric}
                          setSelectedMetric={setSelectedMetric}
                        />
                      }
                    />
                    {/* Описание и характеристики */}
                    <Route
                      path="/description"
                      element={
                        <ProductCardExtended_Description
                          description={description}
                          {...productDesc}
                        />
                      }
                    />
                    {/* Позиция товара */}
                    <Route
                      path="/position"
                      element={
                        <ProductCardExtended_Position
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                          setPositionPageValue={setPositionPageValue}
                          positionPageValue={positionPageValue}
                          metricsList={metricsList}
                          selectedMetric={selectedMetric}
                          setSelectedMetric={setSelectedMetric}
                          setSelectedSearchQuery={setSelectedSearchQuery}
                        />
                      }
                    />
                    {/* Отзывы */}
                    <Route
                      path="/feedbacks"
                      element={<ProductCardExtended_Feedbacks
                        tableData={{ ...tableProps, tableUrl, tableBody }}
                        tableCallback={fetchTableData}
                        checkFbs={checkFbs}
                        nmid={nmid}
                        apiId={apiId}
                        date={date}
                        paginatorRef={paginatorRef}
                      />}
                    />
                    {/* Варианты товара */}
                    <Route
                      path="/product_variants"
                      element={
                        <ProductCardExtended_ProductVariants
                          paginatorRef={paginatorRef}
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                        />
                      }
                    />
                    {/* Остатки по складам */}
                    <Route
                      path="/stock_balances"
                      element={
                        <ProductCardExtended_StockBalances
                          paginatorRef={paginatorRef}
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                        />
                      }
                    />
                    {/* Маппинг */}
                    <Route
                      path="/mapping"
                      element={<ProductCardExtended_Mapping />}
                    />
                    {/* Улучшение товара */}
                    <Route
                      path="/product_improvement/*"
                      element={<ProductCardExtended_ProductImprovement />}
                    />
                    {/* Конкуренты */}
                    <Route
                      path="/competitors"
                      element={<ProductCardExtended_Competitors />}
                    />
                    {/* Финансовый анализ
                    <Route
                      path="/financial_analysis"
                      element={<ProductCardExtended_FinancialAnalysis />}
                    /> */}
                    {/* Похожие товары */}
                    <Route
                      path="/related_products"
                      element={<ProductCardExtended_RelatedProducts />}
                    />
                    {/* Заказы и продажи */}
                    <Route
                      path="voronka"
                      element={
                        <ProductCardExtended_OrdersAndSales
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          checkFbs={checkFbs}
                          nmid={nmid}
                          apiId={apiId}
                          date={date}
                          paginatorRef={paginatorRef}
                        />
                      }
                    />
                    {/* Рекламное место */}
                    <Route
                      path="advertisin_place"
                      element={
                        <ProductCardExtended_AdvertisingPlace
                          tableData={{ ...tableProps, tableUrl, tableBody }}
                          tableCallback={fetchTableData}
                          chartData={chartData}
                          setSelectedTableMetric={setSelectedTableMetric}
                          selectedTableMetric={selectedTableMetric}
                          selectedSearchQuery={selectedSearchQuery}
                          setSelectedSearchQuery={setSelectedSearchQuery}
                          selectedMetric={selectedMetric}
                          setSelectedMetric={setSelectedMetric}
                        />
                      }
                    />
                    {/* Акции */}
                    <Route
                      path="/sales"
                      element={<ProductCardExtended_Stocks
                        checkFbs={checkFbs}
                        nmid={nmid}
                        apiId={apiId}
                        date={date}
                        paginatorRef={paginatorRef}
                        tableData={{ ...tableProps, tableUrl, tableBody }}
                        tableCallback={fetchTableData}
                      />}
                    />
                    {/* Рекламные компании */}
                    <Route
                      path="/ad_campaigns"
                      element={<ProductCardExtended_AdvertisingCompanies
                        paginatorRef={paginatorRef}
                        tableData={{ ...tableProps, tableUrl, tableBody }}
                        tableCallback={fetchTableData}
                      />}
                    />
                    {/* 404 */}
                    <Route path="/*" element={<Page404 />} />

                    <Route
                      path="/information"
                      element={
                        <ProductCardExtended_Information
                          pageValue3={pageValue3}
                          setPageValue3={(e) => setPageValue3(e)}
                          fetchedMetrics={fetchedMetrics}
                          chartData={chartData}
                          labels={labels}
                          chartDataName={chartDataName}
                          chartLoading={chartLoading}
                        />
                      }
                    />
                  </Routes>
                </DataContext.Provider>
                {/*                     
                        {pageValue == 'information' && <section className='product-info'>
                            <div className='product-info__content'>

                                <div className='card-container'>
                                    {fetchedMetrics?.map(({ labels, data }) =>
                                        <CardStatisticsVer2
                                            label={labels?.header?.[0]}
                                            value={data?.[0] && data?.[0]}
                                            unit={'₽'}
                                            dynamic={data?.[1] && data?.[1]}
                                        />
                                    )}
                                </div>

                                <Tabs tabs={btn_tab_3} pageValue={pageValue3} setPageValue={e => setPageValue3(e)} stopRedirect />
                                <BarCharts
                                    direction='x'
                                    legendPosition='top'
                                    datasets_prop={chartData}
                                    labels_prop={labels}
                                    data_name={chartDataName}
                                    loading={chartLoading}
                                />
                            </div>
                        </section>} */}

                {/* {pageValue == 'position' &&
                            <div className='position-content'>
                                <Tabs tabs={btn_tab_2} pageValue={pageValue2} setPageValue={setPageValue2} stopRedirect />
                                <TableBox
                                    fetchCallback={e => {
                                        resetTable()
                                        fetchTableData(e)
                                    }}
                                    {...tableProps}
                                    ref={paginatorRef}
                                    onCheck={setCheckedProducts}
                                />
                            </div>
                        } */}

                {pageValue == 'wasted_revenue' && (
                  <div className="lost_revenue-content">
                    <div className="card-container">
                      {fetchedMetrics?.map(({ labels, data }) => (
                        <CardStatisticsVer2
                          label={labels?.title[0]}
                          value={data && data[0]}
                          unit={'₽'}
                          dynamic={data && data[1]}
                        />
                      ))}
                    </div>

                    <BarCharts
                      direction="x"
                      legendPosition="top"
                      datasets_prop={chartData}
                      labels_prop={labels}
                      data_name={chartDataName}
                      loading={chartLoading}
                    />
                  </div>
                )}

                {/* {pageValue == 'feedbacks' && <section className='review-table'>
                            <div className='slider-review'>
                                <div className='rating'>
                                    <div className='rating_info'>Оценка
                                        <div className='grey-box'>{`${stateSlider[0]}-${stateSlider[1]}`}
                                            <Rating
                                                precision={0.2}
                                                defaultValue={2.5}
                                                max={1}
                                                readOnly
                                                emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                                                icon={<StarIcon style={{ fill: '#faaf00' }} />}
                                                size="large"
                                            />
                                        </div>
                                    </div>

                                </div>
                                <SliderRange
                                    minValue={1}
                                    maxValue={5}
                                    minMax={false}
                                    step={0.5}
                                    setState={(e) => setStateSlider(e)}
                                    defaultValue={[2, 4]} />
                            </div>

                            <TableBox
                                fetchCallback={e => {
                                    resetTable()
                                    fetchTableData(e)
                                }}
                                {...tableProps}
                                ref={paginatorRef}
                                onCheck={setCheckedProducts}
                            />
                        </section>} */}

                {pageValue == 'options' && (
                  <section className="similar-products">
                    <BarCharts
                      direction="x"
                      legendPosition="top"
                      datasets_prop={chartData}
                      labels_prop={labels}
                      data_name={chartDataName}
                      loading={chartLoading}
                    />

                    <TableBox
                      {...tableProps}
                      fetchCallback={(e) => {
                        resetTable();
                        fetchTableData(e);
                      }}
                      ref={paginatorRef}
                      tableUrl={tableUrl}
                      tableBody={tableBody}
                      onCheck={setCheckedProducts}
                    />
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductCardExtended };
