import React from 'react';
import './TitlePage.scss'

interface TitlePageProps {
  title: string;
  medium?: boolean
}

const TitlePage = ({
  title,
  medium = false
}: TitlePageProps): JSX.Element => {
  return (
    <>
      {
        medium ?
          <h2 className={`title-page_medium`}>{title}</h2>
          :
          <h1 className={`title-page`}>{title}</h1>
      }
    </>
  )
};

export { TitlePage };