import React, { useState } from "react";
import { FC, MouseEvent } from "react";
import { TitlePage } from "../../../components/TitlePage/TitlePage"
import { InputDinamycPlaceholder, ButtonBasic } from '@lk-gtcom/ecomlab-components';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from "../../../store/general";

import "./ModalEditFile.scss";

interface ModalEditFileProps {
  setIsModal: (isOpen: boolean) => void;
  currentFile: object,
  setCurrentFile: (currentFile: object | null) => void
}

const ModalEditFile: FC<ModalEditFileProps> = ({
  setIsModal,
  currentFile,
  setCurrentFile
}) => {

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [name, setName] = useState<string>(currentFile ? currentFile?.['Название']?.[0] : '')

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).classList.contains("blackout")) {
      setCurrentFile(null)
      setIsModal(false);
    }
  };

  const handleChange = (e: string) => {
    setName(e);
  }

  // Изменение файлы
  const fetchChangeFile = () => {
    if (currentFile?.['id']) {
      let url = `https://file.ecomru.ru:4453/api/v1/file/update/${currentFile?.['id']}`

      const body = JSON.stringify({
        access: "public",
        name: name
      });

      fetch(url, { body, method: 'PATCH', headers })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('Ошибка запроса');
          }
        })
        .then((json) => {
          setCurrentFile(null)
          setIsModal(false)
        })
        .catch((err) => console.error(err))
    }
  };

  return (
    <div className="blackout" onClick={handleClick}>
      <div className="modal-edit-file">
        <TitlePage title="Редактирование файла" />

        <InputDinamycPlaceholder
          onChange={handleChange}
          changeValue={name}
          placeholder="Название файла"
        />

        <ButtonBasic
          green
          text='Применить'
          onClick={fetchChangeFile}
        />
      </div>
    </div>
  );
};

export { ModalEditFile };