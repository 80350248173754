import Cookies from 'universal-cookie'
import customHeaders, { getSpace } from '../../../common/headers'
import { RavshanHttps, KonstantinAddImgHttps } from '../../../fetchUrls'
import { useEffect, useState } from 'react'
import { InputDinamycPlaceholder, ButtonBasic, Tabs, Checkbox, InputSearch } from "@lk-gtcom/ecomlab-components"
import { TitlePage } from '../../TitlePage/TitlePage'
import useGeneralStore from '../../../store/general'
import { CardExecutor } from '../../CardExecutor/CardExecutor'
import { ButtonActionDrop } from '../../Buttons/ButtonActionDrop/ButtonActionDrop'
import { TabsGreen } from '../../TabsGreen/TabsGreen'
import { SettingsRow } from './SettingsRow/SettingsRow'
import { ModalAddAccountWebmaster } from './ModalAddAccountWebmaster/ModalAddAccountWebmaster'
import dayjs from 'dayjs'
import './ModalAddNewWiki.scss'

const btn_tab = [
    {
        label: 'Доступные к добавлению',
        value: 'available',
    },
    {
        label: 'Добавленные',
        value: 'added',
    }
];

const btns_tab = [
    { label: 'Участники', value: 'exexutors' },
    { label: 'Настройки для seo', value: 'seo' }
];

const ModalAddNewWiki = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const {
        etIsModal,
        cardInfo,
        setCardInfo,
        fetchWikiList,
        currentWiki,
        setCurrentWiki,
        setIsModal,
        created_at,
        setIsModalDelete,
        onDeleteHandler,
        id,
        setId
    } = props
    const [name, setName] = useState(currentWiki?.name ? currentWiki?.name : '')
    const [urlWebsite, setUrlWebsite] = useState(currentWiki?.base_url ? currentWiki?.base_url : '')
    const [webmasterId, setWebmasterId] = useState(currentWiki?.webmaster?.id ? currentWiki?.webmaster : null)
    const [edit, setEdit] = useState(currentWiki)
    const [createdAt, setCreatedAt] = useState(currentWiki?.created_at ? (dayjs(currentWiki?.created_at).format('DD.MM.YYYY')) : '')
    const cookies = new Cookies()
    const token = cookies.get('auth')
    const [executorAvailable, setExecutorAvailable] = useState([]);
    const [executorAdded, setExecutorAdded] = useState([]);
    const [pageValue, setPageValue] = useState(btn_tab[0]?.value)
    const [status, setStatus] = useState(btns_tab?.[0]?.value)

    const [searchAccount, setSearchAccount] = useState('')
    const [isModalAdd, setIsModalAdd] = useState(false)

    const btns = [
        {
            btn: 'Удалить',
            func: () => {
                onDeleteHandler(id)
                setId(null)
                setIsModal(false)
            },
        },
    ];

    const createWiki = () => {
        const url = `${RavshanHttps}/api/v2/article/wiki/${edit ? 'change' : 'create'}`

        const wiki_id = edit ? { wiki_id: currentWiki?.id } : {}

        const body = JSON.stringify({
            name,
            ...wiki_id
        })

        fetch(url, { body, method: edit ? 'PUT' : 'POST', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                fetchWikiList()
                setIsModal(false)
            })
            .catch(e => console.log(e))
    }

    // Получение участников
    const fetchGetPossibleAttendees = (status) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki/wiki_participants?wiki_id=${currentWiki?.id}&can_be_add=${status}`;
        const newHeaders = {
            ...headers,
        };
        fetch(url, { headers: newHeaders })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error('bad attendees response');
                }
            })
            .then((json) => {
                if (status) {
                    setExecutorAvailable(json);
                } else {
                    setExecutorAdded(json);
                }
            })
            .catch((e) => console.error(e));
    };

    // Добавление участников
    const fetchAddAttendees = (id) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki/add_participants`

        const body = JSON.stringify({
            wiki_id: currentWiki?.id,
            clients: [id]
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                fetchGetPossibleAttendees(true)
            })
            .catch(e => console.log(e))
    }

    // Изменение прав редактирования для участников
    const fetchChangePermission = (client, status) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki/change_wiki_permission`

        const body = JSON.stringify({
            wiki_id: currentWiki?.id,
            client_id: client,
            can_change: status
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                console.log('save attendees')
                fetchGetPossibleAttendees(false)
            })
            .catch(e => console.log(e))
    }

    // Удаление участников
    const fetchDeleteAttendees = (id) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki/remove_participants`

        const body = JSON.stringify({
            wiki_id: currentWiki?.id,
            clients: [id]
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                fetchGetPossibleAttendees(false)
            })
            .catch(e => console.log(e))
    }

    // Изменение базового url у вики
    const fetchChangeUrl = (id) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki/wiki_url`

        const body = JSON.stringify({
            wiki_id: currentWiki?.id,
            base_url: urlWebsite || null
        })

        fetch(url, { body, method: 'PATCH', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                alert(`Ссылка на сайт изменена на ${urlWebsite}`)
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        if (pageValue == 'available') {
            fetchGetPossibleAttendees(true)
        } else {
            fetchGetPossibleAttendees(false)
        }
    }, [pageValue]);

    const current_executors = pageValue == 'available' ? executorAvailable : executorAdded

    return (
        <>
            {isModalAdd &&
                <ModalAddAccountWebmaster
                    currentWiki={currentWiki}
                    setIsModal={setIsModalAdd}
                />}

            <div className='blackout' onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setCurrentWiki(null)
                    setIsModal(false)
                    if (setCardInfo) {
                        setEdit(false)
                    }
                }
            }}>
                <div className='modal-add-new-wiki'>
                    <div className='modal-add-new-wiki__header'>
                        <TitlePage
                            title={edit ? 'Редактировать wiki' : 'Новое wiki'}
                        />
                        <InputDinamycPlaceholder
                            onChange={(e) => setName(e)}
                            textErr={'Неверное название'}
                            placeholder='Название wiki'
                            autoComplete={false}
                            changeValue={name}
                            size='s'
                        />

                        <ButtonBasic
                            grey
                            width="190px"
                            minWidth="190px"
                            text={'Сохранить'}
                            onClick={e => {
                                fetchAddAttendees()
                                createWiki()
                                setCurrentWiki(null)
                            }}
                        />
                        {currentWiki && <p className='modal-add-new-wiki__date-create'>{edit ? `Создано ${createdAt}` : ''}</p>}
                        <ButtonActionDrop id={1} btns={btns} />
                    </div>

                    <TabsGreen
                        btns={btns_tab}
                        currentBtn={status}
                        setCurrentBtn={(e) => setStatus(e)}
                        checkValue={true}
                    />

                    <div className='modal-add-new-wiki__content'>
                        {
                            status === 'seo' &&
                            <>

                                <div className='modal-add-new-wiki__row-input'>
                                    <InputDinamycPlaceholder
                                        onChange={(e) => setUrlWebsite(e)}
                                        textErr={'Неверное название'}
                                        placeholder='Название url'
                                        tooltipStatic='Введите название базового url сайта'
                                        autoComplete={false}
                                        changeValue={urlWebsite}
                                        size='s'
                                    />

                                    <ButtonBasic
                                        green
                                        text='Сохранить'
                                        onClick={(e) => {
                                            fetchChangeUrl()
                                        }}
                                    />
                                </div>

                                <ButtonBasic
                                    green
                                    text="Добавить аккаунт webmaster +"
                                    onClick={(e) => {
                                        setIsModalAdd(true)
                                    }}
                                    width="230px"
                                    size="32px"
                                />

                                <InputSearch
                                    placeholder="Поиск..."
                                    value={searchAccount}
                                    onChange={(e) => setSearchAccount(e)}
                                />

                                {webmasterId && <SettingsRow
                                    key={webmasterId?.id + webmasterId?.name}
                                    id={webmasterId?.id}
                                    name={webmasterId?.name}
                                    btnFunc={() => { }}
                                />}




                                {/* <div className='modal-add-new-wiki__row-input'>
                                <InputDinamycPlaceholder
                                    onChange={(e) => setWebmasterId(e)}
                                    textErr={'Неверный id'}
                                    placeholder='ID аккаунта webmaster'
                                    // tooltipStatic='ID аккаунта webmaster'
                                    autoComplete={false}
                                    changeValue={webmasterId}
                                    size='s'
                                />

                                <ButtonBasic
                                    green
                                    text='Сохранить'
                                    onClick={(e) => {
                                        fetchBindWebmaster()
                                    }}
                                />
                            </div> */}


                            </>

                        }

                        {status !== 'seo' &&
                            <>
                                {currentWiki && <Tabs
                                    tabs={btn_tab}
                                    pageValue={pageValue}
                                    spanTab
                                    setPageValue={(e) => {
                                        setPageValue(e);
                                    }}
                                />}

                                <div className='modal-add-new-wiki__cards'>
                                    {current_executors?.map((props, i) => {
                                        const id = props?.id
                                        return <CardExecutor
                                            key={id + i + props.color + props?.can_change}
                                            {...props}
                                            color={props?.color}
                                            status={pageValue == 'available' ? false : true}
                                            addNewMember={(e) => {
                                                if (pageValue == 'available') {
                                                    fetchAddAttendees(props?.id)
                                                } else {
                                                    fetchDeleteAttendees(props?.id)
                                                }
                                            }}
                                            btn={pageValue == 'added' &&
                                                < Checkbox
                                                    value={props?.can_change}
                                                    onChange={(e) => {
                                                        fetchChangePermission(id, props?.can_change ? false : true)
                                                    }}>
                                                    Права на редактирование
                                                </Checkbox>
                                            }
                                        />
                                    })}
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
}

export { ModalAddNewWiki }