import "./Logo.scss"

const LogoBox = () => {

    return (
        <div className='logo'>
            <h1 className='title_logo'>ECOMRU.RU</h1>         
        </div>
    )
}

export { LogoBox }