import { DynamicElement } from '../DynamicElement/DynamicElement';
import no_image from './img/no_image.svg'
import { TableWithoutData } from '../TableWithoutData/TableWithoutData';
import { MoonLoader } from 'react-spinners';
import './TableTop5.scss';

const TableTop5 = ({
  data,
  title,
  height,
  loading
}) => {
  return (
    <div className="table-top-5"
      style={{
        minHeight: height ? height : ''
      }}
    >
      <h2 className="table-top-5__title">
        {title}
        {/* ТОП-5 товаров, по которым были отрицательные оценки */}
      </h2>
      {loading ?
        <div className="table-top-5__loading">
          <MoonLoader
            color="#7b7b7b"
            speedMultiplier={0.5}
          />
        </div>
        :
        data?.data?.length > 0 ?
          <div className="table-top-5__content">
            < table className='table-top-5__table' >
              <thead>
                <tr className="table-top-5__header-tr">
                  {data?.header?.map((item, ind) => {
                    return (
                      <td key={item + ind} className="table-top-5__header-td">
                        <p className="table-top-5__header-td-text">{item}</p>
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((item, ind) => {
                  return (
                    <tr key={item + ind}>
                      {item?.map((data_row, index_row) => {
                        let type_column = data?.type;

                        if (type_column[index_row] == 'dynamic') {
                          return (
                            <td key={data_row + ind} className="table-top-5__td">
                              <div className="table-top-5__type-dynamyc">
                                <p className="table-top-5__dynamyc-sum">
                                  {data_row[0]}
                                </p>
                                <DynamicElement data={data_row[1]} />
                              </div>
                            </td>
                          );
                        }

                        if (type_column[index_row] == 'imgWithText') {
                          return (
                            <td key={data_row + ind} className="table-top-5__td">
                              <div className="table-top-5__imgWithText">
                                <img
                                  className="table-top-5__imgWithText-img"
                                  src={data_row[0] ? data_row[0] : no_image}
                                />
                                <p className="table-top-5__imgWithText-text">
                                  {data_row[1]}
                                </p>
                              </div>
                            </td>
                          );
                        }

                        if (type_column[index_row] == 'brand') {
                          return (
                            <td key={data_row + ind} className="table-top-5__td">
                              <div className="table-top-5__brand">
                                <img
                                  className="table-top-5__brand-img"
                                  src={data_row[0] ? data_row[0] : no_image}
                                />
                                <p className="table-top-5__brand-text">
                                  {data_row[1]}
                                </p>
                              </div>
                            </td>
                          );
                        }

                        return (
                          <td key={data_row + ind} className="table-top-5__td">
                            <p className="table-top-5__text">{data_row}</p>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          : <TableWithoutData
            text='Выберите параметры для загрузки данных таблицы'
            maxHeight='260px'
          />
      }
    </div >
  );
};

export { TableTop5 };
