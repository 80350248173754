import React, { FC, useCallback } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import err_icon from './img/err_icon.svg';
import { TitlePage } from '../../TitlePage/TitlePage';
import './ModalDelete.scss';

interface ModalDeleteProps {
    title: string;
    setIsModal: (isOpen: boolean) => void;
    fetchDelete: () => void;
    update: () => void;
}

const ModalDelete: FC<ModalDeleteProps> = ({ 
    title, 
    setIsModal,
    fetchDelete,
    update
 }) => {
    const handleCloseModal = useCallback(() => setIsModal(false), [setIsModal]);

    const handleBlackoutClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (e.currentTarget === e.target) {
            update()
            setIsModal(false);
        }
    }, [setIsModal]);

    return (
        <div className="blackout"
            style={{ zIndex: 11 }}
            onClick={handleBlackoutClick}>
            <div className="modal-delete">
                <img src={err_icon} alt="Error icon" className="modal-delete__icon" />
                <TitlePage title={title} />
                <div className="modal-delete__btn-group">
                    <ButtonBasic
                        grey
                        text="Отмена"
                        width="calc(50% - 6px)"
                        onClick={handleCloseModal}
                    />
                    <ButtonBasic
                        red
                        text="Удалить"
                        width="calc(50% - 6px)"
                        onClick={() => {
                            fetchDelete()
                            handleCloseModal()
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export { ModalDelete };