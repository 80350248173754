import React, { forwardRef, useEffect, useState, useRef } from 'react'
import grabZone from './img/bx-grid-vertical.svg';
import "./EditWrapper.scss"
import { ContactSupportOutlined } from '@mui/icons-material';

const EditWrapper = (({
    id,
    articleId,
    type = 'text',
    child,
    changeBlock = () => { },
    deleteFunc = () => { },
    readonly = false,
    draggableElement,
    setDraggableElement,
    fetchDataById,
    before
}) => {

    const [isOver, setIsOver] = useState(false);

    // Возвращаем класс в зависимости от типа блока
    const current_className = () => {
        if (type) return `edit-article-wrapper__${type}`
        return ''
    }

    // Записываем драг элемент
    const onDragStart = (e) => {
        setDraggableElement({
            type: type,
            block_id: id,
        })
        e.dataTransfer.setData("text/plain", e.target.id);
        e.target.style.opacity = "0.3";
    }

    // Вставляем над элементом
    const onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOver(false); // Убираем цвет при отпускании
        if (draggableElement?.block_id !== before) {
            changeBlock(draggableElement?.type, articleId, draggableElement?.block_id, before)
            setTimeout(() => fetchDataById(articleId), 500)
        }
    }

    return (
        <div
            id={`edit-wrapper-${id}`} // Уникальный ID
            draggable={true}
            onDragStart={(e) => {
                onDragStart(e);
            }}
            onDrop={(e) => onDrop(e)}
            onDragEnd={(e) => {
                e.target.style.opacity = "1"; // Восстанавливаем прозрачность  
            }}
            onDragOver={(e) => {
                e.preventDefault(); // Нужно для корректной работы дропа
                setIsOver(true); // Включаем подсветку при наведении
            }}
            onDragEnter={(e) => {
                e.stopPropagation(); // Останавливаем всплытие
                setIsOver(true);
            }}
            onDragLeave={(e) => {
                e.stopPropagation();
                setIsOver(false);
            }}
            className={`edit-article-wrapper ${current_className()}  ${isOver ? "edit-article-wrapper--highlight" : ""}`}>
            {!readonly && <img
                className='edit-article-wrapper__drag'
                draggable={false}
                src={grabZone} />}
            {child}

            {!readonly && <div class="edit-article-wrapper__close"
                // удаление блока
                onClick={(e) => deleteFunc()}
            ></div>}
        </div>
    )
})

export { EditWrapper }