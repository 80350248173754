import customHeaders, { getSpace } from '../../common/headers';
import ModalAddConnection from './ModalAddConnection/';
import { ModalAutorization } from './ModalAutorization/ModalAutorization';
import { ConnectionsContext } from '../../App';
import { ModalFirstConnection } from './ModalFirstConnection/ModalFirstConnection';
import {
  RavshanHttps,
  RavshanHttp,
  OlegAccountHttps,
  OlegAccountHttp,
} from '../../fetchUrls';
import { useState, useEffect, useContext } from 'react';
import yandex_icon from './img/yandex_icon.svg';
import useModal from '../../store/modal';
import { useShallow } from 'zustand/react/shallow';
import useGeneralStore from '../../store/general';
import './Modal.scss';
const imageNotFound = require('../../common/img/imageNotFound.png');

const Modal = ({
  selectedSpace,
  setIsModalActive,
  isSocialNetwork,
  predefinedServiceId,
  showAddConnectionModal,
  setConnectedModal,
  setIsModalFeedback,
  connectionIds = [],
  setConnectionIds,
  setSelectedSpace,
  optionList,
}) => {
  const {
    isServiceSelected,
    setIsServiceSelected,
    isServiceConnected,
    setIsServiceConnected,
    setIsCurrentArticle,
  } = useModal(
    useShallow((state) => ({
      isServiceSelected: state.isServiceSelected,
      setIsServiceSelected: state.setIsServiceSelected,
      isServiceConnected: state.isServiceConnected,
      setIsServiceConnected: state.setIsServiceConnected,
      setIsCurrentArticle: state.setIsCurrentArticle,
    }))
  );

  // const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const client_id = localStorage.getItem('client_id');
  const [loading, setLoading] = useState(false);

  const [fetchedServiceData, setFetchedServiceData] = useState([]);

  const [showBackBtn, setShowBackBtn] = useState(false);
  const [isRequestFailed, setIsRequestFailed] = useState(false);
  const [button, setButton] = useState(null);
  const { dispatch } = useContext(ConnectionsContext);

  const insertStringInUrl = (url, insert) => {
    const target = '&code_challenge';

    if (url.includes(target)) {
      return url.replace(target, insert + target);
    }
    return url; // Если целевая строка не найдена, возвращаем оригинальный URL
  };

  const fetchDetailedServiceData = (service_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegAccountHttp}/api/v2/account/model/${service_id}`;
    } else {
      // url = `${RavshanHttp}/api/service/get_service_attr`;
      url = `${OlegAccountHttps}/api/v2/account/model/${service_id}`;
    }

    fetch(url, {
      method: 'GET',
      headers,
      // headers: { ...headers, 'mp-id': service_id },
    })
      .then((res) => {
        if (!res.ok) {
          setIsRequestFailed(true);
        } else {
          return res.json();
        }
      })
      .then((json) => {
        const keys = Object.keys(json);
        setIsRequestFailed(false);
        setFetchedServiceData(json);
        setIsCurrentArticle(json?.article_id);

        if (json?.button) {
          const link =  json?.button?.link
          const image =  json?.button?.icon
          const name = json?.name;
        
          if (link && image && name) {
            setButton(
              <div className="btn__social-media_box">
                <p className="btn__social-media_text">
                  Подключение с помощью сервиса:
                </p>

                {name == 'Яндекс Маркет' ? (
                  <div
                    className="btn__social-media btn__social-media_yandex"
                    onClick={(e) => {
                      if (link.indexOf('t.me') !== -1) {
                        window.open(`${link}?start=${client_id}`, '_blank');
                      }
                      if (link.indexOf('vk.com') !== -1) {
                        const url = insertStringInUrl(
                          link,
                          `_${client_id}_${selectedSpace?.value}`
                        );
                        window.open(url, '_blank');
                      } else {
                        window.open(`${link}&state=${client_id}`, '_blank');
                      }
                    }}
                  >
                    <img src={yandex_icon} />
                  </div>
                ) : (
                  <div
                    className="btn__social-media"
                    onClick={(e) => {
                      if (selectedSpace?.value) {
                        if (link.indexOf('t.me') !== -1) {
                          window.open(
                            `${link}?start=${client_id}_${selectedSpace?.value}`,
                            '_blank'
                          );
                        }
                        if (link.indexOf('vk.com') !== -1) {
                          const url = insertStringInUrl(
                            link,
                            `_${client_id}_${selectedSpace?.value}`
                          );
                          window.open(url, '_blank');
                        } else {
                          window.open(
                            `${link}&state=${client_id}_${selectedSpace?.value}`,
                            '_blank'
                          );
                        }
                      }
                    }}
                  >
                    <span className="social-media-img">
                      <span
                        style={{
                          display: 'block',
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundImage: image
                            ? `url('${image}')`
                            : `url('${imageNotFound}')`,
                        }}
                      />
                    </span>
                    <p className="text">{name}</p>
                  </div>
                )}
              </div>
            );
          } else {
            setButton(null);
          }
        }

        // else {
        //   setIsRequestFailed(true);
        // }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (predefinedServiceId) {
      fetchDetailedServiceData(predefinedServiceId);
    }
    return () => {
      dispatch({ type: 'reset' });
    };
  }, []);

  useEffect(() => {
    const keys = Object.keys(fetchedServiceData);
    if ((keys.length > 0) & (keys.indexOf('result') === -1)) {
      setIsServiceSelected(true);
    }
  }, [fetchedServiceData]);

  useEffect(() => {
    if (isServiceSelected) {
      setShowBackBtn(isServiceSelected);
    }

    if (isServiceConnected) {
      setShowBackBtn(!isServiceConnected);
    }
  }, [isServiceSelected, isServiceConnected]);

  const backBtnHandler = () => {
    if (isServiceConnected) {
      return setIsServiceConnected(false);
    }

    if (isServiceSelected) {
      return setIsServiceSelected(false);
    }
  };

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModalActive(false);
          setConnectedModal(false);
          setIsModalFeedback(false);
          setConnectionIds([]);
          setIsServiceSelected(false);
        }
      }}
    >
      {!isServiceSelected && !showAddConnectionModal ? (
        <ModalAutorization
          optionList={optionList}
          setIsModalFeedback={(e) => setIsModalFeedback(e)}
          setConnectedModal={(e) => setConnectedModal(e)}
          setIsServiceSelected={(e) => setIsServiceSelected(e)}
          isRequestFailed={isRequestFailed}
          fetchedServiceData={fetchedServiceData}
          fetchDetailedServiceData={fetchDetailedServiceData}
          isSocialNetwork={isSocialNetwork}
          setIsModalActive={(e) => setIsModalActive(e)}
          connectionIds={connectionIds}
          selectedSpace={selectedSpace}
          setSelectedSpace={(e) => setSelectedSpace(e)}
        />
      ) : !isServiceConnected ? (
        <ModalAddConnection
          optionList={optionList}
          setButton={(e) => setButton(e)}
          fetchedServiceData={fetchedServiceData}
          setIsServiceConnected={(e) => setIsServiceConnected(e)}
          loading={loading}
          setLoading={(e) => setLoading(e)}
          button={button}
          setIsModalActive={(e) => setIsModalActive(e)}
          setConnectedModal={(e) => setConnectedModal(e)}
          backBtnHandler={(e) => backBtnHandler(e)}
          dispatch={dispatch}
          selectedSpace={selectedSpace}
          setSelectedSpace={(e) => setSelectedSpace(e)}
        />
      ) : (
        <ModalFirstConnection
          setIsModalActive={(e) => setIsModalActive(false)}
          setConnectedModal={(e) => setConnectedModal(false)}
          setIsServiceConnected={(e) => setIsServiceConnected(e)}
          setIsServiceSelected={(e) => setIsServiceSelected(e)}
        />
      )}
    </div>
  );
};

export { Modal };
