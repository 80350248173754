import Cookies from 'universal-cookie'
import drop_icon from './drop_icon.svg'
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import "./DropZoneDirectory.scss"

const DropZoneDirectory = ({
    setSave,
    accept = false,
    update,
    setLoadList
}) => {
    const cookies = new Cookies()
    const token = cookies.get('auth')
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };

    const fetchPostImg = (file) => {
        if (!(file instanceof File)) {
            console.error('Not a valid file:', file)
            return
        }

        setLoadList((prev) => [...prev, { name: file?.name, status: 'false' }])

        const fd = new FormData()
        fd.append('file', file)  // имя поля строго 'file'

        const url = `https://file.ecomru.ru:4453/api/v1/file/upload`

        const requestHeaders = {
            // ...headers,
            'space': selectedSpace?.value,
            'Accept': 'application/json',
            'access': 'public',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`  // НЕ добавляем 'Content-Type'
        }

        fetch(url, {
            method: 'POST',
            body: fd,
            headers: requestHeaders
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    console.log('err')
                    setLoadList((prev) => {
                        let curr = prev.map((el) => {
                            if(el?.name == file?.name) {
                                return {
                                    name: el?.name,
                                    status: 'err'
                                }
                            } else {
                                return el
                            }
                        })
                        return curr
                    })
                    throw new Error(`Server responded with ${res.status}`)
                }
            })
            .then(json => {
                setSave(json)
                setLoadList((prev) => {
                    let curr = prev.map((el) => {
                        if(el?.name == file?.name) {
                            return {
                                name: el?.name,
                                status: 'true'
                            }
                        } else {
                            return el
                        }
                    })
                    return curr
                })
            })
            .catch(err => {
                setLoadList((prev) => {
                    let curr = prev.map((el) => {
                        if(el?.name == file?.name) {
                            return {
                                name: el?.name,
                                status: 'error'
                            }
                        } else {
                            return el
                        }
                    })
                    return curr
                })
                console.error('Upload failed:', err)
            })
    }

    const handleFileInput = (e) => {
        if (e.target.files.length === 0) {
            e.preventDefault()
            return
        }
        if (e.target.files[0]) {
            fetchPostImg(e.target.files[0])
        }
    }
    const acceptObj = accept ? accept : null
    return (
        <div className='drop-zone'>
            <img src={drop_icon} alt='icon' />
            <p>Перетащите файлы сюда или нажмите, чтобы <span>загрузить.</span></p>
            <input
                onChange={(e) => handleFileInput(e)}
                className='input-file'
                type='file'
                {...acceptObj}
            />
        </div>
    )
}

export { DropZoneDirectory }