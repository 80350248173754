import home_icon from './img/home_icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./Breadcrumb.scss";

const Breadcrumb = ({
    breadcrumbs,
    activeLang = 'ru',
    mainPath = '/wiki/wiki-list',
    onPortalClick,
    setSelectedItemId,
    setTitle,
    directory
}) => {
    const navigate = useNavigate();

    // Функция для перевода "Главная" в зависимости от языка
    const translateMain = () => {
        if (activeLang == 'en') return 'Main';
        if (activeLang == 'tr') return 'Ana Sayfa';
        return 'Главная';
    };

    // Функция для добавления цепочки ID
    const addChainToBreadcrumbs = (breadcrumbs) => {
        let chain = []; // Аккумулятор для хранения цепочки ID

        return breadcrumbs.map((item) => {
            chain = [...chain, item.web_name]; // Добавляем текущий ID в цепочку
            return { ...item, chain: [...chain] }; // Возвращаем новый объект с полем chain
        });
    };

    // Преобразуем breadcrumbs
    const breadcrumbsWithChain = addChainToBreadcrumbs(breadcrumbs);

    return (
        <div className='header-breadcrumb'>
            <nav className="breadcrumb__nav">
                <ul className="breadcrumb">
                    {breadcrumbsWithChain.length > 0 ?
                        breadcrumbsWithChain.map(({ label, value, chain }, ind) => {
                            return (
                                <li className="breadcrumb__link"
                                    key={label + value + ind}
                                >
                                    {ind === 0 && <img className='img-home' src={home_icon} />}
                                    {((ind + 1) === breadcrumbsWithChain?.length) ?
                                        <p>{label}</p>
                                        :
                                        <Link
                                            onClick={(e) => {
                                                onPortalClick(value);
                                                setSelectedItemId(value);
                                                setTitle(label);
                                            }}
                                            to={(ind == 0) ? mainPath : `/${directory ? 'file' : 'wiki'}/${value}/`}
                                        >
                                            {(ind === 0) ? translateMain() : label}
                                        </Link>
                                    }
                                </li>
                            );
                        })
                        :
                        <li className="breadcrumb__link">
                            <img className='img-home' src={home_icon} />
                            <Link to={mainPath}>
                                {translateMain()}
                            </Link>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    );
};

export default Breadcrumb;