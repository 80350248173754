import React from "react";
import "./DownloadHistory.scss"
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { TitlePage } from "../TitlePage/TitlePage";

const DownloadHistory: React.FC = () => {

    return (
        <div className='download-history'>
            <TitlePage
                title="История загрузок"
            />

            <TableBox
                loading={null}
                fetchedData={[]}
                headers={[]}
                paginator={false}
            />
        </div>
    )
}

export { DownloadHistory }