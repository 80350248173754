import React, { forwardRef, useEffect, useState, useRef } from 'react'
import { EditWrapper } from '../EditWrapper/EditWrapper'
import { useDebounce } from '../../../../hooks/useDebounce'
import { Editor } from '../Editor/Editor'
import parse from 'html-react-parser'
import "./TextArticle.scss"


// const TextEditor = () => {
//   const [content, setContent] = useState('<p><a href="https://apps0.ecomru.ru:4453/docs#/" target="_blank" style="color: blue;">Методы</a>&nbsp;для <u><font color="#e0ad2e">редактора</font></u><b style=""><u style=""><font color="#e0ad2e"></font></u></b></p>');
//   const [finalHTML, setFinalHTML] = useState('');
//   const editorRef = useRef(null);

//   // Функция для изменения жирности текста
//   const toggleBold = () => {
//     document.execCommand('bold');
//   };

//   // Функция для изменения курсива текста
//   const toggleItalic = () => {
//     document.execCommand('italic');
//   };

//   // Функция для изменения курсива текста
//   const toggleUnderline = () => {
//     document.execCommand('underline');
//   };

//   // Функция для изменения цвета текста
//   const changeTextColor = () => {
//     const color = prompt('Введите цвет для текста (например, red, #ff5733):', 'rgb(224, 173, 46)');
//     if (color) {
//       document.execCommand('foreColor', false, color); // Изменяем цвет текста
//     }
//   };

//   // Функция для обновления содержимого редактора
//   const handleChange = () => {
//     if (editorRef.current) {
//       // setContent(editorRef.current.innerHTML); // Обновляем состояние содержимого редактора
//     }
//   };

//   // Функция для записи итоговой разметки
//   const saveHTML = () => {
//     setFinalHTML(editorRef.current.innerHTML); // Сохраняем текущую разметку в состояние finalHTML
//   };

//   // Функция для добавления текста и ссылки
//   const insertLink = () => {
//     const url = prompt('Введите URL ссылки:', 'https://example.com');
//     const linkText = prompt('Введите текст ссылки:', 'Текст ссылки');

//     if (url && linkText) {
//       const selection = window.getSelection();
//       const range = selection.getRangeAt(0);
//       const linkNode = document.createElement('a');
//       linkNode.href = url;
//       linkNode.target = '_blank';
//       linkNode.textContent = linkText;
//       linkNode.style.color = 'blue';

//       range.deleteContents(); // Удаляем выбранный текст
//       range.insertNode(linkNode); // Вставляем ссылку
//     }
//   };

//   return (
//     <div>
//       <div>
//         <button onClick={toggleBold}>Жирный</button>
//         <button onClick={toggleItalic}>Курсив</button>
//         <button onClick={toggleUnderline}>Подчеркивание</button>
//         <button onClick={changeTextColor}>Цвет текста</button>
//         <button onClick={saveHTML}>Сохранить разметку</button>
//         <button onClick={insertLink}>Вставить ссылку</button>
//       </div>

//       <div
//         contentEditable="true"
//         ref={editorRef}
//         onInput={handleChange}
//         style={{
//           border: '1px solid #ccc',
//           padding: '10px',
//           minHeight: '200px',
//         }}
//         dangerouslySetInnerHTML={{ __html: content }}
//       />

//       <div>
//         <h3>Результат:</h3>
//         <pre>{parse(finalHTML.toString())}</pre>
//       </div>

//       <div>
//         <h3>Итоговая разметка HTML:</h3>
//         <pre>{finalHTML}</pre>
//       </div>
//     </div>
//   );
// };

// export default TextEditor;



const TextArticle = (({
  id,
  data,
  changeContent,
  setIsModal,
  ind,
  setCurrentIndex,
  before,
  setBeforeBlock,
  setContentArr,
  deleteBlock,
  changeBlock,
  readonly = false,
  draggableElement,
  setDraggableElement,
  articleId,
  fetchDataById
}) => {
  const [content, setContent] = useState(data?.[0]?.value ? data?.[0]?.value : '')
  const ref = useRef()
  const debounceChange = useDebounce(changeContent, 1000)
  const [properties, setProperties] = useState({})

  const handleInput = (e) => {
    //  setContent(e.currentTarget.textContent)
    debounceChange(id,
      [
        {
          value: ref?.current?.innerHTML,
          properties: properties
        }
      ]
    )
    setContentArr((prev) => {
      return prev.map((el) => {
        if (el?.block_id == id) {
          return {
            ...el,
            content: [
              {
                value: ref?.current?.innerHTML
              }
            ]
          }
        } else {
          return el
        }
      })
    })
  }

  useEffect(() => {
    handleInput()
  }, [properties, content]);

  const handleDeleteBlock = (e) => {
    //  setContent(e.currentTarget.textContent)
    setContentArr((prev) => {
      return prev.filter((el) => {
        if (el?.block_id != id) {
          return el
        }
      })
    })
    deleteBlock(id)
  }

  // Регулярное выражение для поиска ссылок, начинающихся с пробела или новой 
  // строки, и заканчивающихся перед пробелом или новой строкой
  function wrapLinksInElement(str) {
    const linkPattern = /(?:\s|\n)(https?:\/\/[^\s\n]+)/g;

    return str.replace(linkPattern, (match, p1) => {
      return ` <a href=${p1} target='blank'  download='download'>${p1}</a>`
    });
  }

  // Вставка кода без форматирования
  // const handlePaste = (event) => {
  //   event.preventDefault(); // Отменяем стандартное поведение вставки
  //   const text = event.clipboardData.getData('text/plain'); // Получаем только текст
  //   document.execCommand('insertText', false, text); // Вставляем текст без форматирования
  // };

  const handlePaste = (event) => {
    event.preventDefault();

    let clipboardData = event.clipboardData || window.clipboardData;
    let pastedHTML = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    if (pastedHTML) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(pastedHTML, 'text/html');

      // Удаляем все теги <br>
      doc.querySelectorAll('br').forEach(el => el.remove());

      // Оставляем только безопасные теги
      const allowedTags = ['p', 'b', 'i', 'u', 'a'];
      doc.body.querySelectorAll('*').forEach(el => {
        if (!allowedTags.includes(el.tagName.toLowerCase())) {
          el.replaceWith(...el.childNodes); // Заменяем на чистый текст
        }
      });

      let cleanHTML = doc.body.innerHTML;

      // Вставляем очищенный HTML без форматирования
      document.execCommand('insertHTML', false, cleanHTML);
    }
  }

  return (
    <EditWrapper
      id={id}
      before={before}
      articleId={articleId}
      changeBlock={changeBlock}
      readonly={readonly}
      draggableElement={draggableElement}
      setDraggableElement={setDraggableElement}
      fetchDataById={fetchDataById}
      deleteFunc={(e) => handleDeleteBlock(id)}
      type='text'
      child={
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}>
          {/* <TextEditor /> */}
          {!readonly && <Editor
            handleInput={handleInput}
          />}
          <p className={`article-text`}
            style={properties}
            tabIndex={0}
            autoFocus={true}
            shouldComponentUpdate
            suppressContentEditableWarning
            contentEditable={!readonly}
            dangerouslySetInnerHTML={{ __html: wrapLinksInElement(content) }}
            ref={ref}
            onInput={(e) => handleInput(e)}
            onContextMenu={(e) => {
              if (!readonly) {
                e.preventDefault()
                setCurrentIndex(ind)
                setIsModal(true)
              }
            }}
            onClick={(e) => {
              setCurrentIndex(ind)
              setBeforeBlock(before)
            }}
            onPaste={handlePaste}
          >
            {/* {parse(wrapLinksInElement(content))} */}
          </p>
        </div>

      }
    />
  )
})

export { TextArticle }