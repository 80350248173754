import customHeaders, { getSpace } from '../../common/headers';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ButtonBasic,
  TableBox,
  DropDownSelector,
  Tabs,
} from '@lk-gtcom/ecomlab-components';
import plus from './img/plus.svg';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { ModalCreateChatBot } from '../../components/Modal/ModalCreateChatBot/ModalCreateChatBot';
import './ChatBot.scss';
import useGeneralStore from '../../store/general';
import { Andrey3Http, Andrey3Https } from '../../fetchUrls';
import ChatWidget from './ChatWidget';
import { TitlePage } from '../../components/TitlePage/TitlePage';

const btn_tab = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Активные',
    value: 'active',
  },
  {
    label: 'Неактивные',
    value: 'disactive',
  },
];

const ChatBot = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const activeTab = useParams()['*'];
  const [pageValue, setPageValue] = useState(btn_tab[0]);
  const [isModal, setIsModal] = useState(false);

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [sort, setSort] = useState({});

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const [botId, setBotId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [isCreateChat, setIsCreateChat] = useState(false);
  const [chatName, setChatName] = useState('');

  const paginatorRef = useRef();

  const chatScript = `
<script
  src="http://localhost:3001/widget.js"
  async
  data-bot-id="${botId}"
  data-session-id="${'3d7ced5e-d29c-4621-8a47-cd8a3cf81b87'}"
></script>
  `;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(chatScript);
  };

  const handleEdit = (Идентификатор) => {
    setBotId(Идентификатор[0]);
    setIsModal(true);
    setIsEdit(true);
  };

  const handleKillChat = () => {
    setIsCreateChat(false);
    setBotId(null);
  };

  const contextMenuArr = [
    {
      label: 'Изменить',
      onClick: ({ Идентификатор, 'Имя бота': botName }) => {
        handleEdit(Идентификатор);
        setChatName(botName);
      },
    },
    {
      label: 'Удалить',
      onClick: ({ Идентификатор }) => {
        fetchDeleteBot(Идентификатор[0]);
      },
    },
    {
      label: 'Скопировать',
      onClick: ({ Идентификатор }) => {
        copyToClipboard();
      },
    },
    {
      label: 'Создать чат',
      onClick: ({ Идентификатор, 'Имя бота': botName }) => {
        if (!isCreateChat) {
          setBotId(Идентификатор[0]);
          setIsCreateChat(true);
          setChatName(botName);
        } else {
          handleKillChat();
          setTimeout(() => {
            setBotId(Идентификатор[0]);
            setIsCreateChat(true);
            setChatName(botName);
          }, 500);
        }
      },
    },
  ];

  //таблица ботов
  const fetchBotList = (params) => {
    let url;
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }
    if (!window.location.hostname.match('localhost')) {
      url = `${Andrey3Https}/bot/bot-list${
        // sort?.length > 0
        // '?order_param=' + ' ' + '&order_param_direction=' + 'DESC'
        // : ''
        ''
        }`;
    } else {
      url = `${Andrey3Https}/bot/bot-list${
        // sort?.length > 0
        // ?
        // '?order_param=' + ' ' + '&order_param_direction=' + 'DESC'
        // : ''
        ''
        }`;
    }

    const body = JSON.stringify({
      page: 1,
      limit: 10,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Error: get_tables_list request');
        }
      })
      .then((json) => {
        // setTable(json);
        // setTableUrl(url);
        // setTableBody(body);
        console.log(json);
        const { data, labels, total, sort, column_size } = json;
        // if (total) paginatorRef.current?.setTotal(total);
        if (data) setTableData(data);
        if (labels) setTableHeaders(labels);
        // if (column_size) setColumnSize(column_size);
        if (sort) setSort(sort);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //удалить чат бот
  const fetchDeleteBot = (bot_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${Andrey3Https}/bot/${bot_id}/delete`;
    } else {
      url = `${Andrey3Https}/bot/${bot_id}/delete`;
    }

    // const body = JSON.stringify({
    //   file_uuid: [],
    // });

    fetch(url, { method: 'DELETE', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
        fetchBotList();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const resetTable = () => {
    setTableData([]);
    setTableHeaders([]);
    setSort({});
    setTableLoading(false);

    if (paginatorRef.current) {
      paginatorRef.current.reset();
    }

    fetchBotList();
  };

  useEffect(() => {
    if (!isModal) {
      fetchBotList();
    }
  }, [isModal]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  return (
    <>
      {isModal && (
        <ModalCreateChatBot
          setIsModal={(e) => setIsModal(e)}
          isEdit={isEdit}
          setIsEdit={(e) => setIsEdit(e)}
          botId={botId}
          setBotId={setBotId}
          chatName={chatName}
          setChatName={setChatName}
        />
      )}

      <div className="chat-bot">
        <TitlePage
          title='Чат-боты'
        />
        <div className="chat-bot__toolbar-top">
          <FilterContainer onReset={(e) => e}>
            <DropDownSelector
              options_prop={[]}
              state={[]}
              setState={(e) => e}
              placeholder="Фильтр 1"
              multi
            />

            <DropDownSelector
              options_prop={[]}
              state={[]}
              setState={(e) => e}
              placeholder="Фильтр 2"
              multi
            />

            <DropDownSelector
              options_prop={[]}
              state={[]}
              setState={(e) => e}
              placeholder="Фильтр 3"
              multi
            />
          </FilterContainer>

          <ButtonBasic
            green
            width="170px"
            onClick={(e) => setIsModal(true)}
            text={'+ Создать бота'}
          />
        </div>

        <Tabs tabs={btn_tab} pageValue={pageValue} />

        <TableBox
          loading={tableLoading}
          fetchedData={tableData}
          headers={tableHeaders}
          sort={sort}
          tableUrl={tableUrl}
          tableBody={tableBody}
          fetchCallback={(e) => {
            fetchBotList(e);
          }}
          ref={paginatorRef}
          paginator={true}
          contextMenuArr={contextMenuArr}
          child={
            <ButtonBasic
              action
              text={'Обновить таблицу'}
              size="32px"
              maxWidth="140px"
              width="140px"
              onClick={() => resetTable()}
            />
          }
        />
      </div>
      {isCreateChat && (
        <ChatWidget
          botId={botId}
          chatName={chatName}
          handleKillChat={handleKillChat}
        />
      )}
    </>
  );
};

export { ChatBot };
