import customHeaders, { getSpace } from '../../common/headers';
import { useNavigate } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { ModalDeleteProduct } from '../Modal/ModalDeleteProduct/ModalDeleteProduct';
import { KonstantinMasterHttps, KonstantinMasterHttp } from '../../fetchUrls';
import { useState, useEffect, useRef } from 'react';
import { TitlePage } from '../TitlePage/TitlePage';
import {
  DropDownSelector,
  ButtonBasic,
  TableBox,
} from '@lk-gtcom/ecomlab-components';
import {
  fetchMPCategoryFilter,
  fetchMPBrandFilter,
  fetchMPApiFilter,
  fetchMPOfferIdFilter,
} from '../../common/filtersFunc';
import useGeneralStore from '../../store/general';
import './ProductsList.scss';

const ProductsList = ({ }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const filtersRef = useRef();

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [rangeOfView, setRangeOfView] = useState('7');

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [offerIdPage, setOfferIdPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [offerIdQuery, setOfferIdQuery] = useState('');

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [offerIdData, setOfferIdData] = useState([]);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;

  const [isModalDeleteProduct, setIsModalDeleteProduct] = useState(false);

  const navigate = useNavigate();

  const fetchAllAccountData = (params) => {
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }
    const abortController = new AbortController();
    setLoading(true);

    let url;

    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/mp-products/tables/product-list${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    } else {
      url = `${KonstantinMasterHttp}/mp-products/tables/product-list${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    }

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let offer_id_list;
    let limit = paginatorRef.current.limit;
    let page = paginatorRef.current.page;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi.map((el) => el.value);
    } else if (selectedApi.value) {
      api_id_list = [selectedApi.value];
    } else {
      api_id_list = apiData.map((el) => el.value);
    }

    if (selectedCategory.length > 0) {
      category_id_list = selectedCategory.map((el) => el.value);
    } else {
      category_id_list = ['all'];
    }

    if (selectedBrand.length > 0) {
      brand_id_list = selectedBrand.map((el) => el.value);
    } else {
      brand_id_list = ['all'];
    }

    if (selectedOfferId.length > 0) {
      offer_id_list = selectedOfferId.map((el) => el.value);
    } else {
      offer_id_list = ['all'];
    }

    const body = JSON.stringify({
      api_id_list,
      category_id_list,
      brand_id_list,
      offer_id_list,
      limit,
      page,
      filters,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then((json) => {
        const { data, labels, total, sort, column_size } = json;
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));

    return abortController;
  };

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  const fetchTable = () => {
    if (
      selectedApi?.label &&
      selectedBrand.length > 0 &&
      selectedCategory.length > 0
    ) {
      fetchAllAccountData();
    } else resetTable();
  };

  const filtersApi = () => {
    fetchMPApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
    });
  };

  const filtersCategory = () => {
    fetchMPCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  };

  const filtersBrand = () => {
    fetchMPBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  };

  const filtersOfferId = () => {
    fetchMPOfferIdFilter({
      selectedSpace: selectedSpace?.value,
      page: offerIdPage,
      apiData,
      categoryData,
      selectedCategory,
      brandData,
      selectedBrand,
      dateFrom,
      dateTo,
      searchParam: offerIdQuery,
      selectedApi,
      setOfferIdData: (e) => setOfferIdData(e),
    });
  };

  const [isClear, setIsClear] = useState(false);

  useEffect(() => {
    filtersApi();
  }, [apiPage, apiQuery]);

  useEffect(() => {
    filtersCategory();
  }, [
    // apiData,
    isClear,
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    filtersBrand();
  }, [
    isClear,
    dateFrom,
    dateTo,
    //categoryData,
    brandPage,
    brandQuery,
  ]);

  useEffect(() => {
    filtersOfferId();
  }, [
    isClear,
    dateFrom,
    dateTo,
    //categoryData,
    // brandData,
    brandPage,
    brandQuery,
  ]);

  useEffect(() => {
    filtersOfferId();
  }, [isClear, brandData, dateFrom, dateTo, offerIdPage, offerIdQuery]);

  const contextMenuArr = [
    {
      label: 'Информация',
      onClick: (e) => {
        const offer_id = e?.['Товар']?.[4];
        window.open(`/analysis_mp_product/general?nmid=${offer_id}&apiId=${selectedApi?.value}`);
      },
      type: '',
    },
    {
      label: 'Список связок',
      onClick: () => window.open('/analysis_mp_product/general'),
      type: '',
    },
    // { label: 'Редактировать', onClick: (e) => onEditProduct(e), type: '' },
    // { label: 'Удалить', onClick: (e) => fetchDelete(e), type: '' },
  ];

  return (
    <>
      {isModalDeleteProduct && (
        <ModalDeleteProduct setIsModal={(e) => setIsModalDeleteProduct(e)} />
      )}

      <div className="products-list">
        <div className="products-list__title-box">
          <TitlePage
            title='Список товаров' />
        </div>

        <div className="products-list__header">
          <FilterContainer
            onReset={(e) => {
              setSelectedApi([]);
              setSelectedCategory([]);
              setSelectedBrand([]);
              setSelectedOfferId([]);
              setIsClear(!isClear);
              resetTable();
            }}
          >
            <DropDownSelector
              options_prop={apiData}
              state={selectedApi}
              setState={(e) => setSelectedApi(e)}
              placeholder="Магазин"
              fetchCallback={(e) => setApiPage(e)}
              setInputChange={(e) => setApiQuery(e)}
              onBlur={(e) => {
                filtersCategory();
                filtersBrand();
                filtersOfferId();
                fetchTable();
              }}
            />

            <DropDownSelector
              options_prop={categoryData}
              state={selectedCategory}
              setState={(e) => setSelectedCategory(e)}
              placeholder="Категория"
              multi={true}
              fetchCallback={(e) => setCategoryPage(e)}
              setInputChange={(e) => setCategoryQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              onBlur={() => {
                filtersBrand();
                filtersOfferId();
                fetchTable();
              }}
            />

            <DropDownSelector
              options_prop={brandData}
              state={selectedBrand}
              setState={(e) => setSelectedBrand(e)}
              placeholder="Бренд"
              multi={true}
              fetchCallback={(e) => setBrandPage(e)}
              setInputChange={(e) => setBrandQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              onBlur={() => {
                filtersCategory();
                filtersOfferId();
                fetchTable();
              }}
            />
            <DropDownSelector
              options_prop={offerIdData}
              state={selectedOfferId}
              setState={(e) => setSelectedOfferId(e)}
              placeholder="OfferId"
              multi={true}
              fetchCallback={(e) => setOfferIdPage(e)}
              setInputChange={(e) => setOfferIdQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              onBlur={() => {
                fetchTable();
              }}
            />
          </FilterContainer>
        </div>

        <TableBox
          {...tableProps}
          paginator={true}
          tableUrl={tableUrl}
          tableBody={tableBody}
          ref={paginatorRef}
          fetchCallback={(e) => fetchAllAccountData(e)}
          onEdit={(e) => navigate('/edit-product/add-information/required')}
          onDelete={(e) => setIsModalDeleteProduct(true)}
          contextMenuArr={contextMenuArr}
        />
      </div>
    </>
  );
};

export { ProductsList };
