import customHeaders, { getSpace } from "../../common/headers";
import add_icon from './img/add.svg'
import AddBalanceModal from '../../components/AddBalanceModal/index';
import { useDataTable } from "../../common/hooks";
import { ButtonBasic, TableBox } from "@lk-gtcom/ecomlab-components";
import { useEffect, useState, useRef } from "react";
import { KonstantinMasterHttps, KonstantinMasterHttp } from '../../fetchUrls';
import useGeneralStore from "../../store/general";
import { TitlePage } from "../../components/TitlePage/TitlePage";
import { MoonLoader } from "react-spinners";
import './Summary.scss'

const Summary = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [isModalBalance, setIsModalBalance] = useState(false)
    const [pivotData, setPivotData] = useState([])
    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs
    const [loadingPivot, setLoadingPivot] = useState(false)

    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort
        let filters

        if (params) {
            [sort, filters] = params
        }

        const orderParam = sort?.[0] ? `?order_param=${sort?.[0]}&order_param_direction=${sort?.[1]}` : ''


        setLoading(true)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/finance/pivot/spending-on-services${orderParam}`
        } else {
            url = `${KonstantinMasterHttp}/finance/pivot/spending-on-services${orderParam}`
        }


        const body = JSON.stringify({
            filters,
            limit: paginatorRef.current?.limit,
            page: paginatorRef.current?.page,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setTable(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })
    }

    const fetchPivotData = (abortController = new AbortController()) => {
        setLoadingPivot(true)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/finance/pivot/summary-for-period`
        } else {
            url = `${KonstantinMasterHttp}/finance/pivot/summary-for-period`
        }

        const body = JSON.stringify({})


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setPivotData(json?.data?.map((el, ind) => ({ ...el, label: json?.headers?.[ind] })))
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoadingPivot(false)
                if (abortController.signal.aborted) return
            })
    }

    useEffect(() => {
        const abortController = new AbortController()
        fetchTableData([], abortController)
        fetchPivotData(abortController)
        return () => abortController.abort()
    }, [selectedSpace])


    const summaryColors = ['#0C2037', '#51CA64', '#F88600', '#365EFF']

    return (
        <>
            {
                isModalBalance &&
                <AddBalanceModal
                    setIsModalActive={e => setIsModalBalance(false)}
                />
            }

            <div className='summary'>

                <div className='summary__header'>
                    <TitlePage
                        title='Сводная'
                    />
                    <ButtonBasic
                        green
                        width='190px'
                        text={<div className="content-btn"><img src={add_icon} alt="icon" />Пополнить баланс</div>}
                        onClick={e => setIsModalBalance(true)}
                    />
                </div>

                <div className="summary__period">
                    <div className="summary__items-period">
                        {
                            loadingPivot ?
                                <MoonLoader
                                    size={50}
                                    color="#7b7b7b"
                                    speedMultiplier={0.5}
                                />
                                :
                                pivotData?.map(({ label, amount, color, symb }, ind) => {
                                    return <div className="summary__item-period">
                                        <span className="summary__text-period">{label}</span>
                                        <p className="summary__text-sum" style={{ color: summaryColors?.[ind] }}>{symb ? symb : ''}{amount?.toLocaleString()} ₽</p>
                                    </div>
                                })}
                    </div>
                </div>

                <TitlePage
                    title='Траты на услуги'
                />
                
                <TableBox
                    ref={paginatorRef}
                    {...tableProps}
                    paginator={true}
                />
            </div>
        </>
    )
}

export { Summary }
