import { useState, useEffect, useRef } from 'react';
import { DropDownSelector, Tabs, DropContainer, TableBox, RangeBox } from '@lk-gtcom/ecomlab-components';
import LineChart from '../../charts/LineChart';
import { fetchFilterMedia } from '../../common/filtersFunc';
import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useDataTable } from '../../common/hooks';
import { useChartData } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { OlegHttp, OlegHttps } from '../../fetchUrls';
import { addFiltersToUrlQuery } from '../../common/utils';
import { addFiltersByDataObj } from '../../common/utils';
import { getFiltersByUrlQuery } from '../../common/utils';
import { getDataObjByFilters } from '../../common/utils';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './ParsingAdvertisingByBanner.scss'

const tabs = [
    {
        label: 'По Баннерам',
        value: 'banner'
    },
    // {
    //     label: 'По брендам',
    //     value: 'brand'
    // },
    // {
    //     label: 'По категориям',
    //     value: 'category'
    // },
    // {
    //     label: 'По товарам',
    //     value: 'product'
    // },
    {
        label: 'По адресам',
        value: 'address'
    },
    {
        label: 'По устройствам',
        value: 'device'
    }
]

const ParsingAdvertisingByBanner = ({

}) => {
    const apiIdRef = useRef();
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const activeTab = useParams()['*'];

    // filters
    const [mpData, setMpData] = useState([]);
    const [linkDescData, setLlinkDescData] = useState([])
    const [bannerDescData, setBannerDescData] = useState([])
    const [advertiserData, setAdvertiserData] = useState([])
    const [categoryData, setCategoryData] = useState([]);
    const [brandData, setBrandData] = useState([]);

    const [selectedMp, setSelectedMp] = useState([]);
    const [selectedLinkDesc, setSelecteLinkDesc] = useState([])
    const [selectedBannerDesc, setSelectedBannerDesc] = useState([])
    const [selectedAdvertiser, setSelectedAdvertiser] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);

    const [mpPage, setMpPage] = useState(1);
    const [linkDescPage, setLinkDescPage] = useState(1)
    const [bannerDescPage, setBannerDescPage] = useState(1)
    const [advertiserPage, setAdvertiserPage] = useState(1)
    const [categoryPage, setCategoryPage] = useState(1);
    const [brandPage, setBrandPage] = useState(1);

    const [mpQuery, setMpQuery] = useState('');
    const [linkDescQuery, setLinkDescQuery] = useState('')
    const [bannerDescQuery, setBannerDescQuery] = useState('')
    const [advertiserQuery, setAdvertiserQuery] = useState('')
    const [categoryQuery, setCategoryQuery] = useState('');
    const [brandQuery, setBrandQuery] = useState('');


    const [pageValue, setPageValue] = useState(tabs[0].value);

    // params
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultDate, setDefaultDate] = useState('');
    const [iniitalQuery, setInitialQuery] = useState(true);

    // charts
    const [showDropBox, setShowDropBox] = useState(false);
    const [tableMetrics, setTableMetrics] = useState([]);
    const [tableMetric, setTableMetric] = useState('');
    const [selectedItems, setSelectedItems] = useState([])

    const [setChartData, chartData, setChartFuncs] = useChartData();
    const { setChartLoading, resetChart } = setChartFuncs;

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters, fetchedData } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

    const [date, setDate] = useState([
        new Date().getTime() - 604800000,
        new Date().getTime(),
    ]);
    const [dateFrom, dateTo] = date;

    useEffect(() => {
        setPageValue(activeTab);
    }, [activeTab]);

    //params
    useEffect(() => {
        if (iniitalQuery) {
            const dataObj = getFiltersByUrlQuery(searchParams);
            addFiltersByDataObj({
                dataObj,
                setSelectedMp,
                setSelectedAdvertiser,
                setSelectedBannerDesc,
                setSelecteLinkDesc,
                setSelectedBrand,
                setSelectedCategory,
                setDate: setDefaultDate,
            });
            setInitialQuery(false);
        }
    }, [searchParams]);

    useEffect(() => {
        if (iniitalQuery) return;
        const dataObj = getDataObjByFilters({
            selectedMp,
            selectedAdvertiser,
            selectedBannerDesc,
            selectedLinkDesc,
            selectedBrand,
            selectedCategory,
            date
        });
        addFiltersToUrlQuery(dataObj, setSearchParams);
    }, [
        selectedMp,
        selectedAdvertiser,
        selectedBannerDesc,
        selectedLinkDesc,
        selectedBrand,
        selectedCategory,
        date
    ]);

    //логика для selected

    const mpParamRef = useRef([]);
    const linkDescParamRef = useRef([])
    const bannerDescParamRef = useRef([])
    const advertiserParamRef = useRef([])
    const categoryParamRef = useRef([]);
    const brandParamRef = useRef([]);


    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        mpParamRef.current = [params.get('mp_id')] || [];
        linkDescParamRef.current = [params.get('linkDesc_id')] || [];
        bannerDescParamRef.current = [params.get('bannerDesc_id')] || [];
        advertiserParamRef.current = [params.get('advertiser_id')] || [];
        categoryParamRef.current = [params.get('category_id')] || [];
        brandParamRef.current = [params.get('brand_id')] || [];

        // if (mpParamRef.current.length > 0) {
        //   const mappedMarketplace = mpParamRef.current.map((mpId) =>
        //     options_prop.find((option) => String(option.value) === mpId)
        //   );
        //   setSelectedMarketplace(mappedMarketplace);
        // }
    }, [location.search]);

    // Площадка
    const mpFilter = () => {
        fetchFilterMedia({
            selected_id_list: mpParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: mpPage,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedAdvertiser,
            selectedBannerDesc,
            selectedLinkDesc,
            date,
            setSelectedFilter: setSelectedMp,
            set: setMpData,
            searchParam: mpQuery,
            filter: 'shop_name',
        });
    };

    useEffect(() => {
        setMpPage(1)
    }, [mpQuery, date]);

    useEffect(() => {
        mpFilter()
    }, [mpPage, mpQuery, date]);

    const resetTable = () => {
        paginatorRef.current.reset();
        paginatorRef.current.setTotal(0);
        setSort({});
        setHeaders([]);
        setFetchedData([]);
      };

    // Расположение баннера
    const linkDescFilter = () => {
        fetchFilterMedia({
            selected_id_list: linkDescParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: linkDescPage,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedAdvertiser,
            selectedBannerDesc,
            selectedLinkDesc,
            date,
            setSelectedFilter: setSelecteLinkDesc,
            set: setLlinkDescData,
            searchParam: linkDescQuery,
            filter: 'link_desc',
        });
    };

    useEffect(() => {
        setLinkDescPage(1)
    }, [linkDescQuery, date]);

    useEffect(() => {
        linkDescFilter()
    }, [linkDescPage, linkDescQuery, date]);

    // Категория баннера
    const bannerDescFilter = () => {
        fetchFilterMedia({
            selected_id_list: bannerDescParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: bannerDescPage,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedAdvertiser,
            selectedBannerDesc,
            selectedLinkDesc,
            date,
            setSelectedFilter: setSelectedBannerDesc,
            set: setBannerDescData,
            searchParam: bannerDescQuery,
            filter: 'banner_desc',
        });
    };

    useEffect(() => {
        setBannerDescPage(1)
    }, [bannerDescQuery, date]);

    useEffect(() => {
        bannerDescFilter()
    }, [bannerDescPage, bannerDescQuery, date]);

    // Рекламодатель
    const advertiserFilter = () => {
        fetchFilterMedia({
            selected_id_list: advertiserParamRef.current,
            selectedSpace: selectedSpace?.value,
            page: advertiserPage,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedAdvertiser,
            selectedBannerDesc,
            selectedLinkDesc,
            date,
            setSelectedFilter: setSelectedAdvertiser,
            set: setAdvertiserData,
            searchParam: advertiserQuery,
            filter: 'advertiser',
        });
    };

    useEffect(() => {
        setAdvertiserPage(1)
    }, [advertiserQuery, date]);

    useEffect(() => {
        advertiserFilter()
    }, [advertiserPage, advertiserQuery, date]);

    // Категория
    const categoryFilter = () => {
        fetchFilterMedia({
            selected_id_list: categoryParamRef.current,
            page: categoryPage,
            selectedSpace: selectedSpace?.value,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedAdvertiser,
            selectedBannerDesc,
            selectedLinkDesc,
            date,
            set: setCategoryData,
            setSelectedFilter: setSelectedCategory,
            searchParam: categoryQuery,
            filter: 'category_name',
        });
    };

    useEffect(() => {
        setCategoryPage(1)
    }, [categoryQuery, date]);

    useEffect(() => {
        categoryFilter();
    }, [categoryPage, categoryQuery, date]);

    // бренд
    const brandFilter = () => {
        fetchFilterMedia({
            selected_id_list: brandParamRef.current,
            page: brandPage,
            selectedSpace: selectedSpace?.value,
            categoryData,
            selectedCategory,
            selectedBrand,
            selectedShop: selectedMp,
            selectedAdvertiser,
            selectedBannerDesc,
            selectedLinkDesc,
            date,
            searchParam: brandQuery,
            setSelectedFilter: setSelectedBrand,
            set: setBrandData,
            filter: 'brand_name',
        });
    };

    useEffect(() => {
        setBrandPage(1)
    }, [brandQuery, date]);

    useEffect(() => {
        brandFilter();
    }, [brandPage, brandQuery, date]);

    //table
    const fetchTableData = (params, abortController = new AbortController()) => {
        let sort
        let filtersParam

        if (params) {
            [sort, filtersParam] = params
        }

        setLoading(true)

        const sort_params = sort?.length > 0
            ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
            : ''

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/multon/media/table/${pageValue}${sort_params}`
        } else {
            url = `${OlegHttp}/api/v1/multon/media/table/${pageValue}${sort_params}`
        }

        let [date_from, date_to] = date;
        date_from = new Date(date_from + 86401000)
            ?.toISOString()
            ?.split('T')?.[0];
        date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

        let shop_name,
            link_desc,
            banner_desc,
            advertiser,
            category_name, 
            brand_name

        if (selectedMp?.length > 0) {
            shop_name = selectedMp?.map((el) => el.value);
        }
        if (selectedLinkDesc?.length > 0) {
            link_desc = selectedLinkDesc?.map((el) => el.value);
        }
        if (selectedBannerDesc.length > 0) {
            banner_desc = selectedBannerDesc?.map((el) => el.value);
        }
        if (selectedAdvertiser?.length > 0) {
            advertiser = selectedAdvertiser?.map((el) => el.value);
        }
        if (selectedBrand?.length > 0) {
            brand_name = selectedBrand?.map((el) => el.value);
        }
        if (selectedCategory?.length > 0) {
            category_name = selectedCategory?.map((el) => el.value);
        }

        const body = JSON.stringify({
            limit: paginatorRef.current.limit,
            page: paginatorRef.current.page,
            shop_name,
            link_desc,
            banner_desc,
            advertiser,
            category_name, 
            brand_name,
            date_from,
            date_to,
        })


        fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                setTable(json)
                setTableUrl(json?.tableUrl)
                setTableBody(json?.tableBody)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                if (abortController.signal.aborted) return
                setLoading(false)
            })

    }

    const handleCheck = (e) => {
        let el_arr = e
        let items = []
        if (pageValue == 'banner') {
            items = el_arr.map((item) => item?.['Баннер'][0])
        }
        if (pageValue == 'brand') {
            items = el_arr.map((item) => item?.['Бренд'][0])
        }
        if (pageValue == 'category') {
            items = el_arr.map((item) => item?.['Категория'][0])
        }
        if (pageValue == 'product') {
            items = el_arr.map((item) => item?.['Товар'][1])
        }
        if (pageValue == 'address') {
            items = el_arr.map((item) => item?.['Адрес'][0])
        }
        if (pageValue == 'device') {
            items = el_arr.map((item) => item?.['Устройство'][0])
        }
        setSelectedItems(items)
    }

    useEffect(() => {
        resetChart()
    }, [pageValue]);

    // useEffect(() => {
    //     if (selectedItems.length > 0) {
    //         fetchChart()
    //     }
    // }, [
    //     pageValue,
    //     tableMetric,
    //     selectedItems,
    //     date
    // ]);

    // useEffect(() => {
    //     fetchChartMetrics()
    // }, []);

    useEffect(() => {
        resetTable()
        const abortController = new AbortController();
        fetchTableData([], abortController)
        return () => abortController.abort()
    }, [
        pageValue,
        date
    ]);

    console.log(tableUrl)
    console.log(tableBody)

    return (
        <div className="parsing-advertising-by-keywords">
            <h1 class="parsing-advertising-by-keywords__title-main">Медиа</h1>

            <div className="parsing-advertising-by-keywords__toolbar-top">
                <FilterContainer
                    onReset={(e) => {
                        setSelectedMp([])
                        setSelecteLinkDesc([])
                        setSelectedAdvertiser([])
                        setSelectedBannerDesc([])
                        setSelectedCategory([])
                        setSelectedBrand([])
                    }}
                >

                    <DropDownSelector
                        options_prop={mpData}
                        state={selectedMp}
                        setState={(e) => setSelectedMp(e)}
                        className="connections_page_selector"
                        placeholder="Площадка"
                        fetchCallback={(e) => setMpPage(e)}
                        setInputChange={(e) => setMpQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={() => {
                            linkDescFilter()
                            bannerDescFilter()
                            advertiserFilter()
                            categoryFilter()
                            brandFilter()
                            resetTable()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={categoryData}
                        state={selectedCategory}
                        setState={(e) => {
                            setSelectedCategory(e);
                        }}
                        className="connections_page_selector"
                        placeholder="Категория"
                        fetchCallback={(e) => {
                            setCategoryPage(e);
                        }}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        multi
                        onBlur={() => {
                            mpFilter()
                            linkDescFilter()
                            bannerDescFilter()
                            advertiserFilter()
                            brandFilter()
                            resetTable()
                            fetchTableData()
                            resetChart()
                        }}
                    />


                    <DropDownSelector
                        options_prop={brandData}
                        state={selectedBrand}
                        setState={(e) => setSelectedBrand(e)}
                        className="connections_page_selector"
                        placeholder="Бренд"
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            linkDescFilter()
                            bannerDescFilter()
                            advertiserFilter()
                            categoryFilter()
                            resetTable()
                            fetchTableData()
                            resetChart()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={linkDescData}
                        state={selectedLinkDesc}
                        setState={(e) => setSelecteLinkDesc(e)}
                        // placeholder="Тип рекламы"
                        placeholder="Расположение баннера"
                        fetchCallback={(e) => setLinkDescPage(e)}
                        setInputChange={(e) => setLinkDescQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            bannerDescFilter()
                            advertiserFilter()
                            categoryFilter()
                            brandFilter()
                            resetTable()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={bannerDescData}
                        state={selectedBannerDesc}
                        setState={(e) => setSelectedBannerDesc(e)}
                        placeholder="Категория баннера"
                        fetchCallback={(e) => setBannerDescPage(e)}
                        setInputChange={(e) => setBannerDescQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            linkDescFilter()
                            advertiserFilter()
                            categoryFilter()
                            brandFilter()
                            resetTable()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={advertiserData}
                        state={selectedAdvertiser}
                        setState={(e) => setSelectedAdvertiser(e)}
                        placeholder="Рекламодатель"
                        fetchCallback={(e) => setAdvertiserPage(e)}
                        setInputChange={(e) => setAdvertiserQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            mpFilter()
                            linkDescFilter()
                            bannerDescFilter()
                            categoryFilter()
                            brandFilter()
                            resetTable()
                            fetchTableData()
                        }}
                        multi
                    />

                </FilterContainer>
                <RangeBox
                    defaultValue={defaultDate}
                    setDate={e => setDate(e)} />
            </div>

            {/* <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
                name={chartData?.title ? chartData?.title : ''}
            >
                <LineChart
                    selectedMetric={tableMetric}
                    setSelectedMetric={setTableMetric}
                    metricsList={tableMetrics}
                    {...chartData}
                />
            </DropContainer> */}

            {/* <Tabs tabs={tabs} pageValue={pageValue} /> */}

            <TableBox
                {...tableProps}
                paginator={true}
                ref={paginatorRef}
                fetchCallback={e => fetchTableData(e)}
                tableUrl={tableUrl}
                tableBody={JSON.stringify(tableBody)}
                onCheck={e => handleCheck(e)}
                onUncheckAllDependence={[pageValue]}
                tableHeaders={headers}
            />
        </div>
    );
};

export { ParsingAdvertisingByBanner };
