import dayjs from 'dayjs'
import customHeaders, {getSpace} from '../../../common/headers'
import round_icon from './img/round-icon.svg'
import close_icon from './img/close_icon.svg'
import round_icon_yellow from './img/round-icon_yellow.svg'
import { useState, useEffect } from 'react'
import { RavshanHttps } from '../../../fetchUrls'
import { SingleDatePicker } from '../../SingleDatePicker/SingleDatePicker'
import { DropZoneProductImg } from '../../DropZoneProductImg/DropZoneProductImg';
import { DropZoneProductVideo } from '../../DropZoneProductVideo/DropZoneProductVideo'
import { DropDownSelector, TextareaDynamicPlaceholder, Checkbox, ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from '../../../store/general'
import './ModalPlanningPost.scss'


const ModalPlanningPost = ({ ...props }) => {
    const {
        setShowModal,
        groups,
        selectedGroupFilter,
        onClose,
        postType,
        status,
        isEdit = false,
        post_id,
        account_id
    } = props
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = {...customHeaders, ...getSpace(selectedSpace)}
    const [postText, setPostText] = useState('')
    const [postDate, setPostDate] = useState(new Date())
    const [selectedGroup, setSelectedGroup] = useState(selectedGroupFilter ? selectedGroupFilter : [])
    const [arrImg, setArrImg] = useState([])
    const [arrVideo, setArrVideo] = useState([])
    const [name, setName] = useState('')
    const [media, setMedia] = useState([])

    const formateDate = (date) => {
        const timeZone = new Date().getTimezoneOffset()
        const formate_date = new Date(new Date(date)?.getTime() + timeZone)?.toUTCString()
        return new Date(formate_date)
    }

    const publishPost = (isEdit, media) => {
        const url = `${RavshanHttps}/api/social/${isEdit ? 'change_post' : 'create_post'}`
        const editData = isEdit ? { post_id, account_id } : {}
        const body = JSON.stringify({
            title: name,
            group_id: selectedGroup?.value,
            type: postType,
            status: status?.value,
            date: formateDate(new Date(postDate).toUTCString()),
            content: [{ place: 0, text: postText }],
            media: media ? media : []
        })


        fetch(url, { body, method: isEdit ? 'PUT' : 'POST', headers })
            .then(res => {
                if (!res.ok) { throw new Error('bad response') }
                onClose()
                setShowModal(false)
            })
            .catch(e => console.log(e))
    }

    const dataContent = () => {

        const arr_img = [...document.querySelectorAll('.image')]
        const arr_src_img = arr_img.map((el) => ({link: el.src, type: 'photo'}))

        const arr_video = [...document.querySelectorAll('.video')]
        const arr_src_video = arr_video.map((el) => ({link: el.src, type: 'video'}))

        let current_arr = arr_src_img.concat(arr_src_video)
        let data;
        if (current_arr?.length > 0) {
            data = current_arr.map((el, ind) => {
                return {
                    place: ind,
                    ...el
                }
            })
        }
        return(data)
    };

 

    return (
        <div className='blackout' >
            <div className='modal-planning-post'>
                <div className='modal-planning-post__header'>
                    <h1 className='title'>Новый пост</h1>
                </div>

                <div className='modal-planning-post__content'>
                    <InputDinamycPlaceholder
                        onChange={(e) => setName(e)}
                        placeholder='Заголовок поста'
                        autoComplete={false}
                    />

                    <div className='toolbar'>
                        <SingleDatePicker
                            date={postDate ?? postDate}
                            setDate={(e) => setPostDate(e)}
                            minDate={new Date()}
                            disabled={isEdit}
                            time
                        />

                        <DropDownSelector
                            options_prop={groups}
                            state={selectedGroup}
                            setState={e => setSelectedGroup(e)}
                            placeholder='Группа'
                            width='100%'
                        />
                    </div>

                    <div className='textarea-and-drop-zone'>
                        <div className='left-content'>
                            <TextareaDynamicPlaceholder
                                width='100%'
                                changeValue={''}
                                placeholder="Описание поста"
                                onChange={(e) => setPostText(e)}
                            />
                        </div>
                        <div className='right-content'>
                            <p className='text_grey'>Фотографии</p>

                            <div className='photo-content'>
                                {[...Array(12)]?.map((e, i) => {
                                    return <DropZoneProductImg
                                        notMainPhoto={false}
                                        key={i + JSON.stringify(e)}
                                        ind={i}
                                        arrImg={arrImg}
                                        setArrImg={(e) => setArrImg(e)}
                                    />
                                })}
                            </div>

                            <p className='text_grey'>Видео</p>

                            <div className='video-content'>
                                {[...Array(6)]?.map((e, i) => {
                                    return <DropZoneProductVideo
                                        notMainPhoto={false}
                                        key={i}
                                        ind={i}
                                        arrVideo={arrVideo}
                                        setArrVideo={(e) => setArrVideo(e)}
                                    />
                                })}
                            </div>
                        </div>
                    </div>

                </div>
                
                <div className='modal-add-new-group__bottom' style={{display: 'flex', gap: '1em'}}>
                    <ButtonBasic
                        grey
                        size='40px'
                        width='162px'
                        text={'Отмена'}
                        onClick={() => setShowModal(false)}
                    />
                    <ButtonBasic
                        green
                        size='40px'
                        width='162px'
                        text={'Cохранить пост'}
                        // onClick={(e) => {
                        //     dataContent()
                        //     publishPost(isEdit)
                        // }}
                        onClick={async (e) => {
                            await dataContent()
                            publishPost(isEdit, dataContent())
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalPlanningPost }