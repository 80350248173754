import Cookies from 'universal-cookie'
import customHeaders, { getSpace } from '../../../../common/headers'
import { useEffect, useState } from 'react'
import { InputDinamycPlaceholder, ButtonBasic, Tabs, Checkbox, InputSearch } from "@lk-gtcom/ecomlab-components"
import { CardItemAccount } from '../CardItemAccount/CardItemAccount'
import { TitlePage } from '../../../TitlePage/TitlePage'
import useGeneralStore from '../../../../store/general'
import './ModalAddAccountWebmaster.scss'

const ModalAddAccountWebmaster = ({
    setIsModal,
    currentWiki
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [accountList, setAccountList] = useState([])
    const [selected, setSelected] = useState(null)

    // Получение всех аккаунтов Yandex Webmaster этом пространстве
    const fetchGetWebmaster = (id) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki/webmaster_accounts`

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                setAccountList(json)
            })
            .catch(e => console.log(e))
    }

    // ID аккаунта webmaster
    const fetchBindWebmaster = (id) => {
        const url = `https://apps0.ecomru.ru:4447/api/v2/article/wiki/wiki_webmaster`

        const body = JSON.stringify({
            wiki_id: currentWiki?.id,
            webmaster_id: parseInt(id) || null
        })

        fetch(url, { body, method: 'PATCH', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                console.log(json)
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        fetchGetWebmaster()
    }, []);


    return (
        <div className='blackout'
            style={{ zIndex: 20 }}
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setIsModal(false)
                }
            }}>
            <div className='modal-add-account-webmaster'>
                <div className='modal-add-account-webmaster__header'>
                    <TitlePage
                        title={'Добавить аккаунт Yandex Webmaster '}
                    />
                </div>

                <div className='modal-add-account-webmaster__content'>
                    {accountList?.map(({ id, name }) => {
                        return <CardItemAccount
                            id={id}
                            name={name}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    })}
                </div>
                <ButtonBasic
                    disabled={!selected}
                    text='Выбрать'
                    onClick={(e) => {
                        fetchBindWebmaster(selected)
                        setIsModal(false)
                    }}
                    size='56px'
                />
            </div>
        </div>
    )
}

export { ModalAddAccountWebmaster }