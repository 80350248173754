import customHeaders, { getSpace } from '../../common/headers';
import add_icon from './img/add.svg';
import { useDataTable } from '../../common/hooks';
import { useState, useRef, useEffect } from 'react';
import { KonstantinMasterHttps, KonstantinMasterHttp } from './../../fetchUrls';
import {
  ButtonBasic,
  TableBox,
  DropDownSelector,
  RangeBox,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './FinanceByPeriod.scss';
import AddBalanceModal from '../../components/AddBalanceModal';

const options_period = [
  {
    label: 'дням',
    value: 'День',
  },
  {
    label: 'неделям',
    value: 'Неделя',
  },

  {
    label: 'месяцам',
    value: 'Месяц',
  },
];

const FinanceByPeriod = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [selectedPeriod, setSelectedPeriod] = useState(options_period[0]);
  const [isModalBalance, setIsModalBalance] = useState(false);
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }

    const [date_from, date_to] = date;
    const orderParam = sort?.[0]
      ? `&order_param=${sort?.[0]}&order_param_direction=${sort?.[1]}`
      : '';

    setLoading(true);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/finance/by-period/reports-by-period?period=${selectedPeriod?.value}${orderParam}`;
    } else {
      url = `${KonstantinMasterHttp}/finance/by-period/reports-by-period?period=${selectedPeriod?.value}${orderParam}`;
    }

    const body = JSON.stringify({
      date_from: new Date(date[0] + 86401000).toISOString().split('T')[0],
      date_to: new Date(date[1]).toISOString().split('T')[0],
      filters,
      limit: paginatorRef.current?.limit,
      page: paginatorRef.current?.page,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchTableData([], abortController);
    return () => abortController.abort();
  }, [selectedPeriod, date, selectedSpace]);

  return (
    <div className="finance-by-period">
      {isModalBalance && (
        <AddBalanceModal setIsModalActive={(e) => setIsModalBalance(false)} />
      )}
      <div className="finance-by-period__header">
        <h1 className="finance-by-period__title">По периодам</h1>
        <ButtonBasic
          green
          width="190px"
          text={
            <div className="content-btn">
              <img src={add_icon} alt="icon" />
              Пополнить баланс
            </div>
          }
          onClick={() => setIsModalBalance(true)}
        />
      </div>

      <div className="finance-by-period__table">
        <div className="finance-by-period__header-table">
          {/* <h2 className="finance-by-period__title_period">Отчеты по периодам</h2> */}

          <div className="range-group-btn-and-range-box">
            {
              <div className="drop-box">
                <p className="text">По</p>
                <DropDownSelector
                  options_prop={options_period}
                  state={selectedPeriod}
                  setState={(e) => setSelectedPeriod(e)}
                  defaultValue={selectedPeriod}
                  className="connections_page_selector"
                  isClearable={false}
                />
              </div>
            }

            <RangeBox setDate={(e) => setDate(e)} />
          </div>
        </div>

        <TableBox ref={paginatorRef} {...tableProps} paginator={true} />
      </div>
    </div>
  );
};

export { FinanceByPeriod };
