import React from 'react';
import { ButtonActionDrop } from '../../../components/Buttons/ButtonActionDrop/ButtonActionDrop';
import calendar from './img/bx-calendar.svg';
import dayjs from 'dayjs';
import { RavshanHttps } from '../../../fetchUrls';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from '../../../store/general';
import './BordCard.scss';

interface Bord {
  name: string;
  sum_task?: number;
  date?: string;
  id?: number | string;
  start_time?: string;
  end_time?: string;
}

interface BordCardProps {
  name: string;
  sum_task?: number;
  date?: string;
  id: number | string;
  start_time?: string;
  end_time?: string;
  fetchGetBord: () => void;
  setIsModal: (value: boolean) => void;
  setCurrentBord: (bord: Bord) => void;
  currentBord: Bord | null;
  setIsEdit: (value: boolean) => void;
}

const BordCard = ({
  name,
  sum_task,
  date,
  id,
  start_time,
  end_time,
  fetchGetBord,
  setIsModal,
  setCurrentBord,
  currentBord,
  setIsEdit,
}: BordCardProps) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const deleteBord = async () => {
    const url = `${RavshanHttps}/api/v3/tasks/board/delete_task_board`;
    const body = JSON.stringify({ task_board_id: id });

    try {
      const res = await fetch(url, { body, method: 'DELETE', headers });
      
      if (!res.ok) {
        const err = await res.json();
        throw new Error(JSON.stringify(err));
      }

      const json = await res.json();
      fetchGetBord();
    } catch (err) {
      console.error(err);
    }
  };

  const btns = [
    {
      btn: 'Открыть',
      func: () => {
        setCurrentBord({ name, sum_task, date, id, start_time, end_time });
        setIsEdit(true);
        setIsModal(true);
      },
    },
    {
      btn: 'Удалить',
      func: deleteBord,
    },
  ];

  const isActive = currentBord?.name === name;

  return ( <div
      className={`bord-card ${isActive ? 'bord-card_active' : ''}`}
      onClick={() => {
        setCurrentBord({ name, sum_task, date, id, start_time, end_time });
      }}
    >
      <div className="bord-card__header">
        <h4 className="bord-card__title">{name}</h4>
        <ButtonActionDrop id={1} btns={btns} size="s" />
      </div>
      <div className="bord-card__info">
        {end_time && (
          <div className="bord-card__date-box">
            <img src={calendar} alt="Calendar icon" />
            <div className="bord-card__date">
              {dayjs(end_time).format('DD-MM-YYYY')}
            </div>
          </div>
        )}
        <div className="bord-card__sum-task">
          {sum_task || 0} задачи
        </div>
      </div>
    </div>
  );
};

export { BordCard };