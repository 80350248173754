import { Link, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import customHeaders, { getSpace } from '../../common/headers';
import { ButtonBasic, TableBox, Tabs } from '@lk-gtcom/ecomlab-components';
import { ReactComponent as Info } from './img/info.svg';
import { OlegHttps } from '../../fetchUrls';
import { useRef } from 'react';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import { MoonLoader } from 'react-spinners';
import './Hint.scss';

const list_recommendation = [
  {
    status_read: false,
    title: 'Реклама',
    solution: 'Советуем увеличить бюджет',
    reason:
      'Среднего бюджета на день или месяц, cреднего бюджета на день или месяц',
    criticality: 'medium',
  },
  {
    status_read: true,
    title: 'Реклама',
    solution: 'Советуем увеличить бюджет',
    reason: 'Среднего бюджета на день или месяц',
    criticality: 'low',
  },
  {
    status_read: false,
    title: 'Реклама',
    reason: 'Среднего бюджета на день или месяц',
    criticality: 'hard',
  },
];

const statusCriticality = (status) => {
  const criticality = {
    'Средне критично': 'orange',
    'Критично': 'red',
    'Не критично': 'green',
  };

  return (
    <div
      className={`card-recommendation__status-criticality ${`status-${criticality?.[status]}`}`}
    >
      <Info />
      {status}
    </div>
  );
};

const CardRecommendation = (props) => {
  const {
    api_count,
    problem_category,
    importance,
    problem_name,
    problem_solution,
    value,
    category,
    setCategory,
  } = props;

  return (
    <div
      className="card-recommendation"
      key={problem_category + problem_solution}
    >
      <header className="card-recommendation__header">
        <div className="card-recommendation__info-panel">
          {statusCriticality(importance)}
          {/* {
                    status_read ?
                        <div className='card-recommendation__status-read read'>Прочитано</div>
                        :
                        <div className='card-recommendation__status-read no-read'>Не прочитано</div>
                } */}
        </div>
        <h3 className="card-recommendation__title">{problem_category}</h3>
      </header>

      <div className="card-recommendation__content">
        <div className="card-recommendation__reason">
          <h6 className="card-recommendation__title-reason">Причина</h6>
          <p className="card-recommendation__text">{problem_name}</p>
        </div>

        {problem_solution && (
          <div className="card-recommendation__solution">
            <h6 className="card-recommendation__title-solution">Решение</h6>
            <p className="card-recommendation__text">{problem_solution}</p>
          </div>
        )}

        {api_count && (
          <p className="card-recommendation__text">
            Магазины с проблемой: {api_count}
          </p>
        )}
        {value && <p className="card-recommendation__text">{value}</p>}
      </div>

      {problem_solution && (
        <ButtonBasic
          green
          width="100%"
          text="Решить проблему"
          style={{
            marginTop: 'auto',
          }}
          onClick={(e) => setCategory(category)}
        />
      )}
    </div>
  );
};

const Hint = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [loading, setLoading] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const [cards, setCards] = useState([]);
  const [category, setCategory] = useState('');

  const tabs = [
    { label: 'Проблемы', value: 'problems' },
    { label: 'Решения', value: 'table' },
  ];
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab = searchParams.get('tab') || tabs[0].value;
  const [pageValue, setPageValue] = useState(initialTab);

  const paginatorRef = useRef();
  // const [setTable, tableProps, setTableFuncs, setFetchedData] = useDataTable(
  //   paginatorRef.current?.setTotal,
  //   paginatorRef
  // );
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { total } = tableProps;
  const { sort } = tableProps;

  const { setFetchedData, setHeaders, setSort } = setTableFuncs;

  const resetTable = () => {
    // paginatorRef.current.reset();
    // paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  const fetchHints = () => {
    setLoadingCard(true);
    const url = `${OlegHttps}/api/v1/hints/cards`;

    fetch(url, { method: 'GET', headers })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setCards(json);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoadingCard(false);
      });
  };

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);
    let url = `${OlegHttps}/api/v1/hints/table/${category}${sort?.length > 0
      ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
      : ''
      }`;

    const body = JSON.stringify({
      limit: paginatorRef?.current?.limit ? paginatorRef?.current?.limit : 10,
      page: paginatorRef?.current?.page ? paginatorRef?.current?.page : 1,
      filters: filtersParam,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchHints();
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [category]);

  useEffect(() => {
    const tabFromUrl = searchParams.get('tab') || tabs[0].value;
    setPageValue(tabFromUrl);

    if (tabFromUrl === 'problems') {
      resetTable();
      setCategory('');
    }
  }, [searchParams]);

  const handleCategorySelect = (category) => {
    setCategory(category);
    setSearchParams({ tab: 'table', category: category });
  };

  useEffect(() => {
    const tab = searchParams.get('tab') || 'problems';
    const urlCategory = searchParams.get('category') || '';
    setPageValue(tab);

    if (tab === 'table' && urlCategory) {
      setCategory(urlCategory);
    } else if (tab === 'problems') {
      setCategory('');
    }
  }, [searchParams]);

  return (
    <div className="hint-page">
      <h1 className="hint-page__title">Рекомендации</h1>
      {/* <Tabs tabs={tabs} pageValue={pageValue} setPageValue={setPageValue} /> */}

      <nav className="breadcrumbs">
        <ul>
          <li>
            <Link
              to={`?tab=problems`}
              onClick={() => setSearchParams({ tab: 'problems' })}
              className={pageValue === 'problems' ? 'active' : ''}
            >
              Рекомендации
            </Link>
          </li>
          {pageValue === 'table' && (
            <>
              <span className="breadcrumb-divider">/</span>
              <li>
                <Link
                  to={`?tab=table&category=${category}`}
                  onClick={() => setSearchParams({ tab: 'table', category })}
                  className={pageValue === 'table' ? 'active' : ''}
                >
                  Решение
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      <>
        {pageValue === 'problems' ? (
          <div className="hint-page__card-content">
            {loadingCard ? (
              <MoonLoader color="#7b7b7b" speedMultiplier={0.5} />
            ) : (
              cards?.map((props, ind) => {
                return (
                  <CardRecommendation
                    {...props}
                    setCategory={(e) => {
                      setCategory(e);
                      handleCategorySelect(e);
                    }}
                  />
                );
              })
            )}
          </div>
        ) : (
          <TableBox
            {...tableProps}
            ref={paginatorRef}
            loading={loading}
            paginator={true}
            fetchCallback={() => fetchTableData()}
            sortingFunc={() => fetchTableData()}
          />
        )}
      </>
    </div>
  );
};

export { Hint };
