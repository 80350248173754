import { useState } from 'react'
import './DropInformation.scss'

const DropInformation = ({ title, text }) => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={isOpen ? 'drop-information_open' : 'drop-information'}>
            <div className='header-content' onClick={(e) => setIsOpen(!isOpen)}>
                {title && <h4 className='title_min'>{title}</h4>}
                <button className={isOpen ? 'btn-drop_active' : 'btn-drop'}></button>
            </div>
            {text && <p className='text'>{text}</p>}
        </div>
    )
}

export { DropInformation }