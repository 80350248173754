import React, { useState } from "react";
import { FC, MouseEvent } from "react";
import { TitlePage } from "../../../components/TitlePage/TitlePage"
import { InputDinamycPlaceholder, ButtonBasic, Checkbox, DropDownSelector } from '@lk-gtcom/ecomlab-components';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from "../../../store/general";
import "./ModalSettingMasterProduct.scss";

const option_prop =
  [
    {
      label: 'Текст',
      value: 'str'
    },
    {
      label: 'Целое число',
      value: 'int'
    },
    {
      label: 'Дробное число',
      value: 'float'
    },
    {
      label: 'Логическое значение (Да / Нет)',
      value: 'bool'
    },
  ]

type CurrentRow = {
  attribute_id: string,
  Название: string,
  Описание: 'string',
  "Принимает список значений": string,
  "Тип значения": string
}

interface ModalSettingMasterProductProps {
  setIsModal: (isOpen: boolean) => void;
  currentRow: CurrentRow,
  setСurrentRow: (currentRow: CurrentRow | null) => void,
}

const ModalSettingMasterProduct: FC<ModalSettingMasterProductProps> = ({
  setIsModal,
  currentRow,
  setСurrentRow
}) => {

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const current_value_prop = option_prop.filter(({label}) => label == currentRow?.["Тип значения"])

  const [name, setName] = useState<string>(currentRow ? currentRow?.['Название'] : '')
  const [description, setDescription] = useState<string>(currentRow ? currentRow?.['Описание'] : '')
  const [isList, setIsList] = useState<boolean>(currentRow?.['Принимает список значений'] == 'Да' ? true : false)
  const [selectedValue, setSelectedValue] = useState(currentRow ? current_value_prop[0] : option_prop[0])
  const [listItem, setListItem] = useState('')

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).classList.contains("blackout")) {
      setСurrentRow(null)
      setIsModal(false);
    }
  };

  const handleChangeListItem = (e: string) => {
    setListItem(e);
  }

  const handleChangeName = (e: string) => {
    setName(e);
  }

  const handleChangeDescription = (e: string) => {
    setDescription(e);
  }

  const handleChangeList = (e: boolean) => {
    setIsList(!isList);
  }

  const handleClickBtn = (e: MouseEvent<HTMLDivElement>) => {
    fetchAddField()
    setСurrentRow(null)
    setIsModal(null)
  }

  // Добавление/изменение характеристики
  const fetchAddField = () => {
    let url = `https://apps1.ecomru.ru:4463/custom_attrs/${currentRow ? 'update' : 'create'}`

    const body = JSON.stringify({
      attribute_id: currentRow ? currentRow.attribute_id : '',
      attribute_name: name,
      description: description,
      is_array: isList,
      data_type: selectedValue?.value
    });

    fetch(url, { body, method: currentRow ? 'PUT' : 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setСurrentRow(null)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="blackout" onClick={handleClick}>
      <div className="modal-add-new-field">
        <TitlePage
          title={currentRow ? "Редактирование характеристики" : "Новая характеристика"}
        />

        {/* <div className="modal-add-new-field__row">
          <InputDinamycPlaceholder
            placeholder='Элемент списка'
            onChange={handleChangeListItem}
            changeValue={listItem}
            size='s'
          />
          <ButtonBasic
            minWidth='40px'
            width='40px'
            green
            text='+'
            onClick={handleClickBtn}
          />
        </div> */}

        <InputDinamycPlaceholder
          placeholder='Название'
          onChange={handleChangeName}
          changeValue={name}
          size='s'
        />

        <InputDinamycPlaceholder
          placeholder='Описание'
          onChange={handleChangeDescription}
          changeValue={description}
          size='s'
        />

        <Checkbox
          value={isList}
          onChange={handleChangeList}
        >Список</Checkbox>

        <DropDownSelector
          options_prop={option_prop}
          placeholder='Формат данных'
          width='100%'
          state={selectedValue}
          setState={(e) => setSelectedValue(e)}
          isClearable={false}
        />

        <ButtonBasic
          green
          text='Применить'
          onClick={handleClickBtn}
        />
      </div>
    </div>
  );
};

export { ModalSettingMasterProduct };