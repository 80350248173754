import customHeaders, { getSpace } from '../../common/headers';
import { Comment } from '../Comment/Comment';
import { useState, useEffect } from 'react';
import { RavshanHttps } from '../../fetchUrls';
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor';
import useGeneralStore from '../../store/general';
import { FileItemComponent } from '../FileItemComponent/FileItemComponent';
import { ButtonActionDrop } from '../Buttons/ButtonActionDrop/ButtonActionDrop';
import answer from './img/answer.png'
import star_icon from './img/star_icon.svg'
import "./CommentItem.scss"
import { InView } from 'react-intersection-observer';

const parse = require('html-react-parser')
const email = localStorage.getItem('email')



const CommentItem = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const { id,
        author,
        event_id,
        text,
        date,
        fetchComments,
        disabled,
        files,
        setCurrentMessageAnswer,
        reply_to,
        setSaveFile,
        fetchAddFiles,
        fetchGetFiles,
        fetchDeleteFiles,
        type,
        saveFile,
        comment_id,
        setSaveFileComment,
        update,
        setIsModalFixed,
        fetchPinComment,
        is_favorite,
        hideTools,
        is_viewed,
        setCommentPage,
        updateComments,
        fetchChangeViewed,
        fetchFavorite,
        is_pinned = false,
        fetchUnpinComment,
        fetchDeleteFavorite
    } = props
    const { name, login, color } = author

    const curr_files = files?.map((el) => {
        return {
            created_at: el?.created_at,
            file_size_kb: el?.file_size_kb,
            new_filename: el?.label,
            url: el?.value,
            extension: el?.extension
        }
    })

    const fetchChangeComment = (is_favorite) => {
        const url = `${RavshanHttps}/api/tasks/change_comment`

        const reply_to_params = reply_to ? { reply_to: reply_to?.id } : {}

        const body = JSON.stringify({
            text: comment,
            event_id,
            id,
            files: filesData,
            // reply_to: 0,
            is_favorite: is_favorite ? is_favorite : false,
            ...reply_to_params
        })


        fetch(url, { body, method: 'PUT', headers: headers })
            .then(res => {
                if (res.ok) {
                    updateComments()
                    //  fetchComments()
                    return res.json()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .catch((e) => console.error(e))
    }

    const btns = [
        {
            btn: is_pinned ? 'Открепить' : 'Закрепить', func: (e) => {
                // setIsModalFixed(true)
                if (is_pinned) {
                    fetchUnpinComment(id)
                } else {
                    fetchPinComment(id)
                }
            }
        },
        {
            btn: is_favorite ? 'Удалить из избранного' : 'В избранное', func: (e) => {
                if (fetchFavorite || fetchDeleteFavorite) {
                    console.log(is_favorite)
                    if (is_favorite) {
                        fetchDeleteFavorite(id)
                    } else {
                        fetchFavorite(id)
                    }
                } else {
                    fetchChangeComment(!is_favorite)
                }

            }
        },
        {
            btn: 'Редактировать', func: (e) => {
                if (!edit) {
                    setEdit(true)
                } else {
                    setEdit(false)
                    fetchChangeComment()
                }
            }
        },
        {
            btn: 'Удалить', func: (e) => { fetchDeleteComment() }
        },
    ];

    const btns_2 = [
        {
            btn: 'Закрепить', func: (e) => {
                // setIsModalFixed(true)
                fetchPinComment(id)
            }
        },
        {
            btn: is_favorite ? 'Удалить из избранного' : 'В избранное', func: (e) => {
                if (fetchFavorite || fetchDeleteFavorite) {
                    console.log(is_favorite)
                    if (is_favorite) {
                        fetchDeleteFavorite(id)
                    } else {
                        fetchFavorite(id)
                    }
                } else {
                    fetchChangeComment(!is_favorite)
                }
            }
        },
    ];

    const [comment, setComment] = useState(text ? text : '')
    const [edit, setEdit] = useState(false)
    const [showImg, setShowImg] = useState('')

    const [filesData, setFilesData] = useState(curr_files)
    const [files1, setFiles1] = useState([])
    const [inViewElement, setInViewElement] = useState(false)


    const fetchDeleteComment = () => {
        const url = `${RavshanHttps}/api/tasks/delete_comment`
        const body = JSON.stringify({
            id
        })

        fetch(url, { body, method: 'DELETE', headers: headers })
            .then(res => {
                if (res.ok) {
                    return
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .then(() => {
                if (fetchComments) {
                    updateComments()
                    //   fetchComments()
                    fetchGetFiles('comments')
                    update()
                }
            })
            .catch((e) => console.error(e))
    }

    const fetchChangeStaticViewed = (comment_id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v3/tasks/mark_viewed/${comment_id}`
        } else {
            url = `${RavshanHttps}/api/v3/tasks/mark_viewed/${comment_id}`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error(res.json())
                }
            })
            .then(json => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    useEffect(() => {
        if (inViewElement && !is_viewed) {
            if (fetchChangeViewed) {
                fetchChangeViewed(id)
            } else {
                fetchChangeStaticViewed(id)
            }
        }
    }, [inViewElement]);

    return (
        <InView as="div" key={text + id} onChange={(inView) => {
            setInViewElement(true)
        }}>
            {showImg && <div className='show-img' onClick={(e) => setShowImg('')}>
                <img src={showImg} />
            </div>}

            <div key={text + id} className={login == email ? 'comment__wrapper_author' : 'comment__wrapper'}>
                {login != email && <div className='comment__executor-content'>
                    <ExecutorPlaceholder name={name} id={author?.id} login={login} color={color} />
                </div>}

                <div className={login == email ? 'comment-item_author' : 'comment-item'}>
                    {reply_to && <div className='comment-item__reply-to'>
                        {reply_to?.author && <p className='comment-item__reply-to-author'>В ответ {parse(reply_to?.author)}</p>}
                        {reply_to?.text && <p>{parse(reply_to?.text)}</p>}
                    </div>}

                    {login != email && <div className='comment-item__header'>
                        <p className='text_name'>{name}</p>
                    </div>
                    }
                    <div className='comment-item__content'>
                        {
                            edit ?
                                <Comment
                                    {...props}
                                    {...author}
                                    email={login}
                                    commentValue={comment}
                                    reply_to={reply_to}
                                    changeCommentValue={e => setComment(e)}
                                    filesDataChange={filesData}
                                    setFilesDataChange={(e) => setFilesData(e)}
                                    setFilesChange={(e) => setFiles1(e)}
                                    isEdit={edit}
                                    hideBtn={true}
                                    setSaveFile={setSaveFile}
                                    type={'comments'}
                                    fetchAddFiles={fetchAddFiles}
                                    fetchGetFiles={fetchGetFiles}
                                    comment_id={id}
                                    setCommentPage={(e) => setCommentPage(e)}
                                />
                                :
                                <>

                                    <p className='text'>{parse(comment, {
                                        replace: (domNode) => {
                                            if (domNode.name === "img") {
                                                return <img src={domNode.attribs.src}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e) => {
                                                        setShowImg(domNode.attribs.src)
                                                    }}
                                                />
                                            }
                                        },
                                    })} </p>



                                    {(filesData)?.map(({ created_at, file_size_kb, new_filename, url }, ind) => {
                                        return <FileItemComponent
                                            eventId={event_id}
                                            created_at={created_at}
                                            file_size_kb={file_size_kb}
                                            new_filename={new_filename}
                                            fetchDeleteFiles={fetchDeleteFiles}
                                            fetchComments={fetchComments}
                                            url={url}
                                            authorEmail={login}
                                            type={'comments'}
                                            comment_id={comment_id}
                                            isEdit={edit}
                                            filesdata={filesData}
                                            setFilesData={(e) => setFilesData(e)}
                                        />
                                    })}
                                </>

                        }
                    </div>

                    <div className='comment-item__date-panel'>
                        <p className='text_date'>
                            {new Date(date).toLocaleDateString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
                        </p>
                        {is_favorite && <img className='comment-item__star' src={star_icon} />}
                    </div>


                    {!disabled && edit && <div className='comment-item__btn-group'>
                        {edit && <a href='#' className='btn-text-grey'
                            onClick={e => {
                                if (!edit) {
                                    setEdit(true)
                                } else {
                                    fetchChangeComment()
                                    fetchAddFiles(null, type, id)
                                    setSaveFile([])
                                    setEdit(false)
                                }
                            }}
                        >
                            Сохранить
                        </a>}
                    </div>}
                </div>

                {!hideTools && <div className='comment-item__group-btn'>
                    <button className='comment-item__btn-answer' onClick={(e) => setCurrentMessageAnswer(props)}>
                        <img src={answer} />
                    </button>

                    {(email == login) && <ButtonActionDrop
                        size='s'
                        btns={btns}
                        id={id}
                        color={'#F3F4F6'}
                    />}

                    {(email != login) && <ButtonActionDrop
                        size='s'
                        btns={btns_2}
                        id={id}
                        color={'#F3F4F6'}
                    />}
                </div>}
            </div>
        </InView>

    )
}

export { CommentItem }