import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { fetchApiFilterVer3 } from '../../common/filtersFunc';
import { useState, useEffect, useRef } from 'react';
import { TableBox, ButtonBasic, DropDownSelector, InputDinamycPlaceholder, Tabs, Checkbox } from "@lk-gtcom/ecomlab-components";
import { TabsGreen } from '../../components/TabsGreen/TabsGreen';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { ModalAddNewField } from './ModalAddNewField/ModalAddNewField';
import './SettingCostPrice.scss'

const btn_tab = [
    {
        label: 'Установка себестоимости',
        value: 'setting'
    },
    {
        label: 'Параметры себестоимости',
        value: 'parameters'
    }
]

const btns = [
    { label: 'Общее для всех', value: 'total' },
    { label: 'По товарам', value: 'product' }
]

const mp_options = [
    {
        label: 'Ozon',
        value: 1,
    },
    {
        label: 'Wildberries',
        value: 3,
    },
    {
        label: 'Yandex',
        value: 2,
    },
];

const SettingCostPrice = ({ }) => {

    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };

    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [status, setStatus] = useState(btns?.[0]?.value)
    const [currentRow, setСurrentRow] = useState(null)

    const [apiData, setApiData] = useState([]);

    const [selectedMp, setSelectedMp] = useState([]);
    const [selectedApi, setSelectedApi] = useState([]);

    const [apiPage, setApiPage] = useState(1);

    const [apiQuery, setApiQuery] = useState('');

    const activeTab = useParams()['*']

    const paginatorRef = useRef();
    const [setTable, tableProps, setTableFuncs] = useDataTable(
        paginatorRef.current?.setTotal,
        paginatorRef
    );
    const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
    const { total, loading } = tableProps;

    const [tableUrl, setTableUrl] = useState('');
    const [tableBody, setTableBody] = useState(null);
    const [isPersent, setIsPersent] = useState(false)

    const [isModalAddNewField, setIsModalAddNewField] = useState(false)

    const apiFilter = () => {
        fetchApiFilterVer3({
            selectedSpace: selectedSpace?.value,
            page: apiPage,
            setApiData: setApiData,
            set: setApiData,
            searchParam: apiQuery,
            selectedApi,
            selectedShop: selectedMp,
            filter: 'api_id',
        });
    };

    useEffect(() => {
        apiFilter();
    }, [apiPage, apiQuery]);

    const handleSaveOnePrice = (e) => {
        const { inputEvent, actionEvent, setStatus } = e;
        const offerId = inputEvent?.id;
        const apiId = actionEvent?.[0]?.api_id;
        let newPrice = +inputEvent?.value;

        if (newPrice) {
            setStatus('loading');
            fetchSetCostPrice(apiId, offerId, newPrice, setStatus);
        } else {
            setStatus('error');
        }
    };

    const onDelete = (e) => {
        const { row_id } = e
        fetchDelete(row_id)
    }

    const onEdit = (e) => {
        setСurrentRow(e)
        setIsModalAddNewField(true)
    }

    const contextMenuArr = [
        { label: 'Редактировать', onClick: onEdit },
        { label: 'Удалить', onClick: onDelete },
    ];


    const fetchTableData = (params, abortController = new AbortController()) => {
        if (selectedApi?.value) {
            let sort
            let filtersParam

            if (params) {
                [sort, filtersParam] = params
            }

            setLoading(true)

            let url_settings = `https://apps0.ecomru.ru:4449/mp-products/tables/cost-price${sort?.length > 0 ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
            let url_parameters = `https://apps0.ecomru.ru:4449/mp-products/tables/get-expenses/${status}`
            let url = pageValue == 'setting' ? url_settings : url_parameters

            let api_id_list = [selectedApi?.value]
            let mp_id_list;

            if (selectedMp?.length > 0) {
                mp_id_list = selectedMp?.map((el) => el.value);
            }

            const body = JSON.stringify({
                limit: paginatorRef?.current?.limit,
                page: paginatorRef?.current?.page,
                offer_id_name_search: "",
                filters: filtersParam,
                mp_id_list,
                api_id_list,
                status: "Все",
                filters: filtersParam,
                offer_id_list: ["all"],
                brand_id_list: ["all"],
                category_id_list: ["all"]
            })

            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    setTable(json)
                    setTableUrl(json?.tableUrl)
                    setTableBody(json?.tableBody)
                })
                .catch(err => console.error(err))
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchSetCostPrice = (apiId, offerId, newPrice, setStatus) => {
        let url = 'https://apps0.ecomru.ru:4449/mp-products/tables/set-cost-price'

        const body = JSON.stringify({
            price: [
                {
                    offer_id: offerId,
                    cost: newPrice,
                    api_id: apiId,
                }
            ]
        });

        fetch(url, { body, method: 'POST', headers })
            .then((res) => {
                if (res.ok) {
                    setStatus('success')
                    return res.json();
                } else {
                    throw Error(JSON.stringify(res.json()));
                }
            })
            .then((json) => {
                console.log(json)
            })
            .catch((err) => {
                setStatus('error');
                console.error(err);
            });
    };

    const fetchDelete = (row_id) => {
        if (selectedApi?.value) {
            let url = `https://apps0.ecomru.ru:4449/mp-products/tables/delete-expenses/${status}`

            const body = JSON.stringify({
                api_id: selectedApi?.value,
                row_uid: row_id
            });

            fetch(url, { body, method: 'POST', headers })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw Error(JSON.stringify(res.json()));
                    }
                })
                .then((json) => {
                    fetchTableData()
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    useEffect(() => {
        fetchTableData()
    }, [status, pageValue]);

    useEffect(() => {
        if (!isModalAddNewField) {
            setTimeout(() => fetchTableData(), 500)
        }
    }, [isModalAddNewField]);

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    return (
        <>
            {isModalAddNewField && <ModalAddNewField
                status={status}
                selectedApi={selectedApi}
                setIsModal={setIsModalAddNewField}
                currentRow={currentRow}
                setСurrentRow={setСurrentRow}
            />}

            <div className='setting-cost-price'>
                <h1 className='setting-cost-price__title-main'>Установка себестоимости</h1>

                <FilterContainer
                    onReset={(e) => {
                        setSelectedMp([])
                        setSelectedApi([])
                    }}
                >
                    <DropDownSelector
                        options_prop={mp_options}
                        state={selectedMp}
                        setState={(e) => setSelectedMp(e)}
                        className="connections_page_selector"
                        placeholder="Площадка"
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={() => {
                            apiFilter()
                            fetchTableData()
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={apiData}
                        state={selectedApi}
                        setState={(e) => setSelectedApi(e)}
                        className="connections_page_selector"
                        placeholder="Магазин"
                        fetchCallback={(e) => setApiPage(e)}
                        setInputChange={(e) => setApiQuery(e)}
                        inputRef={true}
                        onBlur={() => {
                            fetchTableData()
                        }}
                    />
                </FilterContainer>

                <Tabs
                    tabs={btn_tab}
                    pageValue={pageValue} />

                <TableBox
                    tableUrl={tableUrl}
                    tableBody={tableBody}
                    ref={paginatorRef}
                    paginator={true}
                    {...tableProps}
                    fetchCallback={fetchTableData}
                    tableHeaders={headers}
                    onInputAction={(e) => handleSaveOnePrice(e)}
                    contextMenuArr={contextMenuArr}
                    child={
                        <>
                            {pageValue == 'parameters' && <div className='setting-cost-price__table-panel'>
                                <TabsGreen
                                    btns={btns}
                                    currentBtn={status}
                                    setCurrentBtn={(e) => setStatus(e)}
                                    checkValue={true}
                                />

                                <ButtonBasic
                                    disabled={!selectedApi?.value}
                                    whiteGreen
                                    width='auto'
                                    size='32px'
                                    text={'+ Новое поле'}
                                    onClick={(e) => {
                                        setIsModalAddNewField(true)
                                    }}
                                />
                            </div>}
                        </>
                    }
                />
            </div>
        </>
    )
}

export { SettingCostPrice }