import React from 'react';
import { ReactComponent as ArrowDown } from './img/arrow_down.svg'
import './ButtonsSort.scss'

type SortOption = {
    label: string;
};

type ButtonsSortProps = {
    btnSort: SortOption[];
    currentSort: SortOption | null;
    setCurrentSort: (sort: SortOption) => void;
    sort: 'ASC' | 'DESC';
    setSort: (order: 'ASC' | 'DESC') => void;
};

const ButtonsSort: React.FC<ButtonsSortProps> = ({ btnSort, currentSort, setCurrentSort, sort, setSort }) => {
    const handleSortClick = (el: SortOption) => {
        if (currentSort?.label === el.label) {
            setSort(sort === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSort('ASC');
        }
        setCurrentSort(el);
    };

    return (
        <div className='buttons-sort'>
            {btnSort.map((el) => (
                <button
                    className={currentSort?.label === el.label ? 'buttons-sort__btn-active' : 'buttons-sort__btn'}
                    key={el.label}
                    onClick={() => handleSortClick(el)}
                >
                    {el.label}
                    <ArrowDown
                        className={(currentSort?.label == el?.label) ? (sort == 'ASC' ? 'buttons-sort__arrow' : 'buttons-sort__arrow-up') : 'buttons-sort__arrow'}
                    />
                </button>
            ))}
        </div>
    );
};

export { ButtonsSort }
