import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { LogoBox } from '../../components/Logo/Logo'
import { AuthorizationBox } from '../../components/AuthorizationBox/AuthorizationBox'
import { ResetPasswordBox } from '../../components/ResetPasswordBox/ResetPasswordBox'
import { RegistrationBox } from '../../components/RegistrationBox/RegistrationBox'
import { banner_loader } from '../../common/svg_img'
import { BarLoader } from 'react-spinners';
import "./AuthorizationPage.scss"

const AuthorizationPage = () => {
    const [loading, setLoading] = useState(false)
    const [requestError, setRequestError] = useState(false)

    return (
        <div className='autorization__content'>
            {
                !loading ?
                    <>
                        <header className='autorization__header'>
                            < LogoBox />
                        </header>
                        <Routes>
                            <Route path='*' element={< AuthorizationBox setLoading={e => setLoading(e)} requestError={requestError} setRequestError={e => setRequestError(e)} />} />
                            <Route path='/signup/*' element={< RegistrationBox />} />
                            <Route path='reset-password/:id' element={< ResetPasswordBox />} />
                        </Routes>

                        {/* <footer className='autorization__footer'>
                            < CopyrightBox />
                        </footer> */}
                    </>
                    :
                    <div className="main-loader-box">
                        <div className='main-loader-box__content'>
                            <embed type='image/svg+xml' src={banner_loader} />
                            <div className='progress'>
                                < BarLoader
                                    color="#27BC62"
                                    height='24'
                                    width='480'
                                    speedMultiplier={1} />
                            </div>
                            <h1 className='title'>Загружаем данные</h1>
                        </div>
                    </div>
            }
        </div>
    )
}

export { AuthorizationPage }