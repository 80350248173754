import React, { useState, useRef, useCallback, useEffect } from 'react';
import IMask from 'imask';
import './InputListArray.scss';

const InputListArray = ({
    tooltip,
    tooltipStatic,
    placeholder,
    type = 'text',
    onChange,
    err,
    textErr,
    infoHeader,
    info = false,
    autoComplete = true,
    disabled = false,
    showClearIndicator = false,
    autoFocus = false,
    size = 'l',
    required = false,
    onBlur,
    mask = null,
    readOnly = false,
    changeValue = [],
    ...props
}) => {
    const [showInfo, setShowInfo] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);
    const [valueInside, setValueInside] = useState('');
    const [values, setValues] = useState(changeValue?.length > 0 ? changeValue : []); // Массив для хранения значений
    const [isFocus, setIsFocus] = useState(false);
    const inputRef = useRef(null);
    const maskRef = useRef(null);

    // Обработчик изменения значения
    const handleChange = useCallback((e) => {
        const newValue = e.target.value;
        setValueInside(newValue);
    }, []);

    // Обработчик нажатия клавиш
    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter' && valueInside.trim() !== '') {
                // Добавляем значение в массив
                setValues((prevValues) => [...prevValues, valueInside.trim()]);
                setValueInside(''); // Очищаем поле ввода
                if (onChange) onChange([...values, valueInside.trim()]); // Передаем массив в родительский компонент
            }
        },
        [valueInside, onChange, values]
    );

    // Обработчик потери фокуса
    const handleBlur = useCallback(() => {
        if (onBlur) onBlur(values);
    }, [onBlur, values]);

    // Очистка значения
    const handleClear = useCallback(() => {
        setValueInside('');
        setValues([]); // Очищаем массив значений
        if (onChange) onChange([]); // Очищаем значение в родительском компоненте
    }, [onChange]);

    // Переключение видимости пароля
    const togglePasswordVisibility = useCallback(() => {
        setHidePassword((prev) => !prev);
    }, []);

    // Удаление значения по клику на "кирпичик"
    const handleRemoveValue = useCallback(
        (index) => {
            const newValues = values.filter((_, i) => i !== index); // Удаляем элемент по индексу
            setValues(newValues);
            if (onChange) onChange(newValues); // Передаем обновленный массив в родительский компонент
        },
        [values, onChange]
    );

    // Эффект для маски телефона
    useEffect(() => {
        if (type === 'tel' && inputRef.current && mask) {
            const maskOptions = { mask };
            maskRef.current = IMask(inputRef.current, maskOptions);
            maskRef.current.on('accept', () => {
                setValueInside(maskRef.current.value);
            });
            return () => maskRef.current.destroy();
        }
    }, [type, mask]);

    return (
        <>
            <div tabIndex={0}
                className={`input-list-array ${disabled ? 'input-list-array__disabled' : ''} ${err ? 'input-list-array__err-box' : ''}`}
                style={{ 
                    width: '100%', 
                    marginBottom: tooltip ? '24px' : '',
                    paddingRight: info ? '54px' : ''
                 }}
            >
                {values?.map((value, index) => (
                    <div key={index + value}
                        className="input-list-array__value-chip">
                        <span>{value}</span>
                        <button
                            className="input-list-array__close-chip"
                            onClick={() => handleRemoveValue(index)}>×</button>
                    </div>
                ))}

                <div className={`${size === 's' ? 'size-s' : 'size-l'}`}>
                    <input
                        ref={inputRef}
                        className="input-list-array__input"
                        value={valueInside}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown} // Обработчик нажатия клавиш
                        spellCheck="false"
                        onFocus={() => {
                            setShowInfo(true)
                            setIsFocus(true)
                        }}
                        onBlur={() => {
                            setIsFocus(false);
                            setShowInfo(false);
                            handleBlur();
                        }}
                        readOnly={readOnly}
                        autoComplete={autoComplete ? 'on' : 'off'}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        required={required}
                    />

                    {placeholder && (
                        <div
                            className={
                                valueInside.length > 0 || isFocus || values.length > 0
                                    ? 'placeholder-dinamyc_min-blue'
                                    : 'placeholder-dinamyc'
                            }
                        >
                            <label>
                                {placeholder}
                                {required && <span style={{ color: 'red' }}> *</span>}
                            </label>
                        </div>
                    )}

                    {err ? (
                        <div className="input-list-array__err">
                            <p className="input-list-array__text-red">{textErr}</p>
                        </div>
                    ) : (
                        <div className="input-list-array__err">
                            <p className="input-list-array__text-tooltip">{tooltip}</p>
                        </div>
                    )}
                </div>

                <div className="input-list-array__clean-value">
                    {showClearIndicator && values?.length > 0 && (
                        <button
                            className="input-list-array__btn-clean"
                            onClick={handleClear}
                        ></button>
                    )}
                    {(info && showInfo) && (
                        <div className="input-list-array__info">
                            <div className="tooltip-box">
                                <button className="btn__info" disabled></button>
                                <div className="tooltip">
                                    <h6 className="title">{infoHeader}</h6>
                                    <p className="text">{info}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {tooltipStatic && <p className="input-list-array__text-tooltip">{tooltipStatic}</p>}
            </div>
        </>

    );
};

export default React.memo(InputListArray);