import customHeaders, { getSpace } from '../../common/headers';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { ProductData } from '../ProductData/ProductData';
import { CarouselSlick } from '../CarouselSlick/CarouselSlick';
import { ModalCardCreated } from '../Modal/ModalCardCreated/ModalCardCreated';
import { CardVariantProduct } from '../CardVariantProduct/CardVariantProduct';
import { ModalUnsavedProduct } from '../Modal/ModalUnsavedProduct/ModalUnsavedProduct';
import { ModalProductPreview } from '../Modal/ModalProductPreview/ModalProductPreview';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { KonstantinCreateProductHttps } from '../../fetchUrls';
import { useState, useEffect, useReducer, createContext, useCallback } from 'react'
import useGeneralStore from '../../store/general';
import { SkeletonImg } from '../EditProduct/SkeletonImg';
import { TitlePage } from '../TitlePage/TitlePage';
import "./AddProductVer3.scss"

// information state
export const informationActionTypes = Object.seal({
    requirements: 'requirements',
    error: 'error',
    warning: 'warning',
    advice: 'advice',
    showSuccessModal: 'show success modal',
    showUnsavedChangesModal: 'show unsaved changes modal',
    hideUnsavedChangesModal: 'hide unsaved changes modal',
    reset: 'reset'
})

export const InformationContext = createContext()

export const dataActionTypes = Object.seal({
    createProduct: 'create product',
    assignAttributes: 'assign attributes',
    submitImage: 'submit image',
    submitVideo: 'submit video',
    done: 'done'
})

export const DataContext = createContext({})

const AddProductVer3 = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const navigate = useNavigate()
    const productId = sessionStorage.getItem('productId')

    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const activeTab = useParams()['*']
    const [isDropMenu, setIstDropMenu] = useState(false)
    const [pageValue, setPageValue] = useState('required')
    const [selectedCategories, setSelectedCategories] = useState([])
    const [currentProductId, setCurrentProductId] = useState(productId && productId)
    const [currName, setCurrName] = useState('')
    const [currOfferId, setCurrOfferId] = useState('')
    const [errField, setErrField] = useState(false)
    const [isCopy, setIsCopy] = useState(false)


    const [isModalUnsavedProduct, setIsModalUnsavedProduct] = useState(false)
    const [isModalCardCreated, setIsModalCardCreated] = useState(false)
    const [isModalPreview, setIsModalPreview] = useState(false)
    const [previewPhoto, setPreviewPhoto] = useState(null)
    const [productIds, setProductIds] = useState([])
    const [productTemporary, setProductTemporary] = useState({})
    const [productTemporaryList, setProductTemporaryList] = useState([])

    const [isGeneralloading, setIsGeneralloading] = useState(searchParams.get('product_id'))
    const [isRightPanelLoading, setIsRightPanelLoading] = useState(searchParams.get('product_id'))

    const [arrImg, setArrImg] = useState([])
    const [arrImgLoad, setArrImgLoad] = useState([])
    const [initialLoad, setInitialLoad] = useState(true)

    const [currentProduct, setCurrentProduct] = useState(0)

    const dataReducer = (state, action) => {
        switch (action.type) {
            case dataActionTypes.createProduct: {
                const newState = { ...state, product: action.payload }
                return newState
            }
            case dataActionTypes.assignAttributes: {
                const newState = { ...state, attributes: action.payload }
                return newState
            }
            case dataActionTypes.submitImage: {
                const newState = { ...state, images: action.payload }
                return newState
            }
            case dataActionTypes.submitVideo: {
                const newState = { ...state, videos: action.payload }
                return newState
            }
            case dataActionTypes.done: {
                const newState = { ...state, done: true }
                return newState
            }
            default:
                return {}
        }
    }

    const [dataState, dispatchData] = useReducer(dataReducer, {})

    const deleteLastProject = () => {
        setProductTemporary([])
        setSelectedCategories([])
        setArrImg([])
    }

    const createProduct = () => {
        if (productTemporary.product_id) {
            const url = `${KonstantinCreateProductHttps}/master_products/create-or-update-by-template`

            const body = JSON.stringify({
                product_id: productTemporary.product_id,
                category_id: productTemporary.category_id,
                category_name: productTemporary.category_name,
                category_name_list: productTemporary.category_name_list,
                attributes: productTemporary.attributes,
                used_to: productTemporary.used_to,
            })

            const options = { body, method: 'POST', headers }

            fetch(url, options)
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    }
                    else {
                        setErrField(true)
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    sessionStorage.removeItem('productId')
                    setCurrentProductId(null)

                    const current_list = productTemporaryList.filter((el, ind) => ind != currentProduct)
                    if (current_list.length < 1) {
                        deleteLastProject()
                    }
                    setProductTemporaryList(current_list)
                    setCurrentProduct(0)
                    // setArrImg([])
                    // fetchProductIds()
                    setIsModalCardCreated(true)
                    //  setIsCopy(false)
                })
                .catch(err => {
                    console.error(err)
                    alert('Убедитесь что все обязательные поля заполнены')
                })

        }
    }

    const editProductAttr = (product_id) => {
        const url = `${KonstantinCreateProductHttps}/master_products/existing-master-template?product_id=${product_id}&used_to=update`

        const options = { method: 'GET', headers }

        fetch(url, options)
            .then(async res => {
                if (res.ok) {
                    return res.json()
                }
                else {
                    setErrField(true)
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                const { category_name_list } = json
                const categories = category_name_list.map((c, ind) => ({ label: c.category_name, parent_id: ind === 0 ? '' : category_name_list[ind - 1]?.category_id, value: { id: c.category_id, hasChildren: c.has_children } }))
                setSelectedCategories(categories)
                setProductTemporary(json)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setIsGeneralloading(false)
                setIsRightPanelLoading(false)
            })


    }

    const fetchProductIds = (product_id = productId) => {
        const url = `${KonstantinCreateProductHttps}/master_products/temporary?used_to=create`
        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                }
                else {
                    const text = await res.json()
                    throw new Error(text?.detail)
                }
            })
            .then(json => {
                const prod = json?.[0]
                const prodId = prod?.product_id
                // setCurrName(prod?.name)
                // setCurrOfferId(prod?.offer_id)

                sessionStorage.setItem('productId', prodId)
                // setCurrentProductId(prodId)
                // setProductIds(json)
            })
            .catch(err => console.error(err))
            .finally(() => setIsGeneralloading(false))
    }

    const deleteProduct = (product_id) => {
        if (product_id) {
            const url = `${KonstantinCreateProductHttps}/master_products/temporary/${product_id}?used_to=create`

            fetch(url, { method: 'DELETE', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    }
                    else {
                        const text = await res.json()
                        throw new Error(text?.detail)
                    }
                })
                .then(() => {
                    sessionStorage.removeItem('productId')
                    setCurrentProductId(null)
                    setProductTemporary([])
                    setSelectedCategories([])
                    fetchProductIds(false)
                    setArrImg([])
                })
                .catch(err => console.error(err))
        }
    }

    const fetchProductAttr = (product_id) => {
        if (product_id && product_id !== 'undefined') {
            setIsRightPanelLoading(true)
            //   setProductTemporary([])
            //  setArrImg([])
            const url = `${KonstantinCreateProductHttps}/master_products/temporary/${product_id}?used_to=create`


            fetch(url, { method: 'GET', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    }
                    else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    const { category_name_list } = json
                    const categories = category_name_list.map((c, ind) => ({ label: c.category_name, parent_id: ind === 0 ? '' : category_name_list[ind - 1]?.category_id, value: { id: c.category_id, hasChildren: c.has_children } }))
                    setSelectedCategories(categories)
                    setProductTemporary(json)
                })
                .catch(err => console.error(err))
                .finally(() => {
                    setIsRightPanelLoading(false)
                    setIsGeneralloading(false)
                })
        }
    }


    useEffect(() => {
        const onUnload = e => {
            if (Object.keys(dataState.product).length && !dataState.done) {
                e.preventDefault()
                return (e.returnValue = "")
            }
        }

        window.addEventListener('beforeunload', onUnload)

        return () => {
            window.removeEventListener('beforeunload', onUnload)
        }
    }, [location])


    // useEffect(() => {
    //     fetchProductIds()
    // }, [])

    useEffect(() => {
        if (currentProductId) {
            if (isCopy) {
                sessionStorage.setItem('productId', currentProductId)
            } else {
                sessionStorage.setItem('productId', currentProductId)
            }
        }
    }, [currentProductId, activeTab, pageValue])

    useEffect(() => {
        setInitialLoad(true)
    }, [currentProductId]);

    useEffect(() => {
        setErrField(false)
        setProductIds(prev => prev?.map((el) => {
            if (el?.product_id === currentProductId) {
                return { ...el, name: currName, offer_id: currOfferId }
            }
            return el
        }))
    }, [currName, currOfferId])

    useEffect(() => {
        setPageValue(activeTab)

        const product_id = searchParams.get('product_id')
        const isCopy = searchParams.get('copied')
        if (product_id && product_id !== 'undefined') {
            editProductAttr(product_id)
        } else {
            if (productId) {
                editProductAttr(productId)
            }
        }

    }, [activeTab])


    const changeName = useCallback((value) => setCurrName(value), [])
    const changeOffer = useCallback((value) => setCurrOfferId(value), [])

    return (
        <>
            {/* Превью товара */}
            {isModalPreview && < ModalProductPreview
                selectedCategories={selectedCategories}
                setIsModal={(e) => setIsModalPreview(e)}
                currentProductId={currentProductId}
                productTemporary={productTemporary?.attributes}
            />}

            {/* Модальное окно бкз логики "Выйти без сохраниния" */}
            {isModalUnsavedProduct && <ModalUnsavedProduct setIsModal={(e) => setIsModalUnsavedProduct(e)} />}

            {/* Модальное окно бкз логики "Добавление карточки" */}
            {
                isModalCardCreated && <ModalCardCreated
                    setIsModal={(e) => setIsModalCardCreated(e)}
                    fetchProductIds={e => fetchProductIds(e)}
                />
            }

            <DataContext.Provider value={{ dataState, dispatchData }}>
                <div className='add-product-ver3'
                    onClick={(e) => {
                        let el = e.target
                        if (!el.closest('.context-more')) {
                            setIstDropMenu(false)
                        }
                    }}
                >
                    <div className='add-product-ver3__header'>
                        <TitlePage 
                        title={`${productTemporary?.used_to == "update" ? 'Редактирование' : 'Создание'} товара`}
                        />
                        <div className='btn-group'>
                            <ButtonBasic
                                lilac
                                width='92px'
                                size='56px'
                                text='Отмена'
                                disabled={isGeneralloading || isRightPanelLoading}
                                onClick={e => navigate('/products_list_master_product/all')}
                            />

                            <ButtonBasic
                                violet
                                width='130px'
                                size='56px'
                                text={productTemporary?.used_to == "update" ? 'Обновить' : 'Создать'}
                                disabled={isGeneralloading || isRightPanelLoading}
                                onClick={(e) => {
                                    createProduct()
                                }}
                            />
                        </div>
                    </div>

                    <div className='add-product-ver3__content'>
                        <ProductData
                            isEdit={false}
                            arrImg={arrImg}
                            setArrImgLoad={(e) => setArrImgLoad(e)}
                            setArrImg={(e) => setArrImg(e)}
                            setPreviewPhoto={(e) => setPreviewPhoto(e)}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={(e) => setSelectedCategories(e)}
                            setIsModalPreview={(e) => setIsModalPreview(e)}
                            currentProductId={currentProductId}
                            setCurrentProductId={e => setCurrentProductId(e)}
                            fetchProductIds={e => fetchProductIds(e)}
                            productTemporary={productTemporary}
                            setProductTemporary={e => setProductTemporary(e)}
                            setName={e => changeName(e)}
                            name={currName}
                            setCurrOfferId={e => changeOffer(e)}
                            errField={errField}
                            setErrField={(e) => setErrField(e)}
                            fetchProductAttr={(e) => fetchProductAttr(e)}
                            pageValue={pageValue}
                            setPageValue={e => setPageValue(e)}
                            isGeneralloading={isGeneralloading}
                            setIsGeneralloading={setIsGeneralloading}
                            setIsRightPanelLoading={setIsRightPanelLoading}
                            isRightPanelLoading={isRightPanelLoading}
                            initialLoad={initialLoad}
                            setInitialLoad={setInitialLoad}
                            setProductTemporaryList={setProductTemporaryList}
                            currentProduct={currentProduct}
                            productTemporaryList={productTemporaryList}
                        />

                        <div className='side-panel'>
                            {
                                isGeneralloading ?
                                    <SkeletonImg />
                                    :
                                    <div className='side-panel__img-box'>
                                        <CarouselSlick imgArr={arrImg} />
                                    </div>
                            }

                            <div className='side-panel__content'>
                                {/* {
                                    productIds?.map(el => {
                                        return <CardVariantProduct
                                            key={el?.product_id}
                                            {...el}
                                            deleteProduct={e => deleteProduct(e)}
                                            currentProductId={currentProductId}
                                            setCurrentProductId={({ name, product_id }) => {
                                                setCurrentProductId(product_id)
                                                setCurrName(name)
                                            }}
                                            setIsGeneralloading={setIsGeneralloading}
                                        />
                                    })
                                } */}

                                {
                                    productTemporaryList?.map((el, ind) => {
                                        return <CardVariantProduct
                                            key={el?.attributes?.basic?.[0]?.value + el?.attributes?.basic?.[2]?.value + ind}
                                            product_id={el?.product_id}
                                            name={el?.attributes?.basic?.[0]?.value || ''}
                                            offer_id={el?.attributes?.basic?.[2]?.value || ''}
                                            // deleteProduct,
                                            // setCurrentProductId,
                                            // currentProductId,
                                            // setIsGeneralloading
                                            deleteProduct={e => deleteProduct(e)}
                                            currentProductId={currentProductId}
                                            setCurrentProductId={({ name, product_id }) => {
                                                setCurrentProductId(product_id)
                                                setCurrName(name)
                                            }}
                                            ind={ind}
                                            currentProduct={currentProduct}
                                            setCurrentProduct={setCurrentProduct}
                                            productTemporaryList={productTemporaryList}
                                            setProductTemporary={(e) => setProductTemporary(e)}
                                            setProductTemporaryList={setProductTemporaryList}
                                            setIsGeneralloading={setIsGeneralloading}
                                            deleteLastProject={deleteLastProject}
                                        />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </DataContext.Provider>
        </>

    )
}

export { AddProductVer3 }