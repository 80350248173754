import React from 'react'
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls'
import google from './img/google.svg'
import yandex from './img/yandex.svg'
import * as constants from './constants'
import './ExternalAuthentication.scss'

const ExternalAuthentication = () => {
    const handlerGoogle = (e) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/oauth/google/authorize`
        } else {
            url = `${KonstantionAuthHttp}/oauth/test-google-authorize`
        }

        const body = JSON.stringify({
            device_info: window.navigator.userAgent
        })

        const headers = {'Content-Type': 'application/json'}

        fetch(url, {body, method: 'POST', headers})
            .then(res => res.json())
            .then(json => {
                const url = Object.values(json).join('')
                window.location.assign(url)
            })
            .catch(err => console.error(err))
    }

    const handlerYandex = (e) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/oauth/yandex/authorize`
        } else {
            url = `${KonstantionAuthHttp}/oauth/test-yandex-authorize`
        }

        const body = JSON.stringify({
            device_info: window.navigator.userAgent
        })

        const headers = {'Content-Type': 'application/json'}

        fetch(url, {body, method: 'POST', headers})
            .then(res => res.json())
            .then(json => {
                const url = Object.values(json).join('')
                window.location.assign(url)
            })
            .catch(err => console.error(err))
    }

    return (
        <div className='btn-group__social-media'>
            <div className='btn__social-media' onClick={() => handlerGoogle()}>
                <object type='image/svg+xml' data={google} />
                <p className='text'>Google</p>
            </div>

            <div className='btn__social-media' onClick={() => handlerYandex()}>
                <object type='image/svg+xml' data={yandex} />
                <p className='text'>Яндекс</p>
            </div>
        </div>
    )
}

export default ExternalAuthentication