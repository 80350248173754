import React, { forwardRef, useId } from 'react'
import { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { EditWrapper } from '../EditWrapper/EditWrapper'
// import { ModalReplaceLink } from '../../Modal/ModalReplaceLink/ModalReplaceLink'
// import { TextAlignment } from '../TextAlignment/TextAlignment'
// import { TextSize } from '../TextSize/TextSize'
import { Editor } from '../Editor/Editor'
import parse from 'html-react-parser'
import { useDebounce } from '../../../../hooks/useDebounce'
// import { ARTICLE_TYPES } from '../ArticleElement'
import "./TitleText.scss"


const TitleText = (({
    id,
    data, editMode, align, onChange, fontSize,
    onAddNewBlockClick, addNewBlock, position, setIsChangeTypeModal,
    changeContent, deleteContent,
    setIsModal,
    ind,
    setCurrentIndex,
    before,
    setBeforeBlock,
    setContentArr,
    deleteBlock,
    readonly = false,
    draggableElement,
    setDraggableElement,
    articleId,
    fetchDataById,
    changeBlock
}) => {
    const [content, setContent] = useState(data[0]?.value)
    const ref = useRef()

    const debounceChange = useDebounce(changeContent, 1000)

    const handleDeleteBlock = (e) => {
        //  setContent(e.currentTarget.textContent)
        setContentArr((prev) => {
            return prev.filter((el) => {
                if (el?.block_id != id) {
                    return el
                }
            })
        })
        deleteBlock(id)
    }

    const handleInput = (e) => {
        debounceChange(id,
            [
                {
                    value: ref?.current?.innerHTML,
                    properties: {}
                }
            ]
        )
        setContentArr((prev) => {
            return prev.map((el) => {
                if (el?.block_id == id) {
                    return {
                        ...el,
                        content: [
                            {
                                value: ref?.current?.innerHTML
                            }
                        ]
                    }
                } else {
                    return el
                }
            })
        })
    }

    const handlePaste = (event) => {
        event.preventDefault();
    
        let clipboardData = event.clipboardData || window.clipboardData;
        let pastedHTML = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
    
        if (pastedHTML) {
          let parser = new DOMParser();
          let doc = parser.parseFromString(pastedHTML, 'text/html');
    
          // Удаляем все теги <br>
          doc.querySelectorAll('br').forEach(el => el.remove());
    
          // Оставляем только безопасные теги
          const allowedTags = ['p', 'b', 'i', 'u', 'a'];
          doc.body.querySelectorAll('*').forEach(el => {
            if (!allowedTags.includes(el.tagName.toLowerCase())) {
              el.replaceWith(...el.childNodes); // Заменяем на чистый текст
            }
          });
    
          let cleanHTML = doc.body.innerHTML;
    
          // Вставляем очищенный HTML без форматирования
          document.execCommand('insertHTML', false, cleanHTML);
        }
      }

    return (
        <EditWrapper
            id={id}
            before={before}
            articleId={articleId}
            changeBlock={changeBlock}
            readonly={readonly}
            draggableElement={draggableElement}
            setDraggableElement={setDraggableElement}
            fetchDataById={fetchDataById}
            deleteFunc={(e) => handleDeleteBlock(id)}
            type='title'
            child={
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px'
                }}>
                    {/* <TextEditor /> */}
                    {!readonly && <Editor
                        handleInput={handleInput}
                    />}
                    <h2
                        id={id}
                        maxLength={20}
                        suppressContentEditableWarning
                        ref={ref}
                        className={`article__title`}
                        contentEditable={!readonly}
                        dangerouslySetInnerHTML={{ __html: content }}
                        onInput={(e) => handleInput(e)}
                        onPaste={handlePaste}
                        onContextMenu={(e) => {
                            if (!readonly) {
                                e.preventDefault()
                                setCurrentIndex(ind)
                                setIsModal(true)
                            }
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') { }
                            if (e.key === '/') { }
                        }}
                        onClick={(e) => {
                            setCurrentIndex(ind)
                            setBeforeBlock(before)
                        }}
                    ></h2 >
                </div>
            }
        />
    )
})

export { TitleText }