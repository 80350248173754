import { useState } from 'react'
import parse from 'html-react-parser'
import { useRef } from 'react';
import './Editor.scss'

const Editor = (({
    handleInput
}) => {
    //   const [content, setContent] = useState('<p><a href="https://apps0.ecomru.ru:4453/docs#/" target="_blank" style="color: blue;">Методы</a>&nbsp;для <u><font color="#e0ad2e">редактора</font></u><b style=""><u style=""><font color="#e0ad2e"></font></u></b></p>');
    //   const [finalHTML, setFinalHTML] = useState('');
    const editorRef = useRef(null);

    // Функция для изменения жирности текста
    const toggleBold = () => {
        document.execCommand('bold');
    };

    // Функция для изменения курсива текста
    const toggleItalic = () => {
        document.execCommand('italic');
    };

    // Функция для изменения курсива текста
    const toggleUnderline = () => {
        document.execCommand('underline');
    };

    // Функция для изменения цвета текста
    const changeTextColor = () => {
        const color = prompt('Введите цвет для текста (например, red, #ff5733):', '');
        if (color) {
            document.execCommand('foreColor', false, color); // Изменяем цвет текста
        }
    };

    // Функция для обновления содержимого редактора
    const handleChange = () => {
        if (editorRef.current) {
            // setContent(editorRef.current.innerHTML); // Обновляем состояние содержимого редактора
        }
    };

    // Функция для записи итоговой разметки
    const saveHTML = () => {
        // setFinalHTML(editorRef.current.innerHTML); 
        // Сохраняем текущую разметку в состояние finalHTML
    };

    // Функция для добавления текста и ссылки
    const insertLink = () => {
        const url = prompt('Введите URL ссылки:', '');
        const linkText = prompt('Введите текст ссылки:', '');

        if (url && linkText) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const linkNode = document.createElement('a');
            linkNode.href = url;
            linkNode.target = '_blank';
            linkNode.textContent = linkText;
            linkNode.style.color = 'blue';

            range.deleteContents(); // Удаляем выбранный текст
            range.insertNode(linkNode); // Вставляем ссылку
        }
        handleInput()
    };
  

    return (
        <div className='editor-article'>
            <button className='editor-article__btn' onClick={toggleBold}>Жирный</button>
            <button className='editor-article__btn' onClick={toggleItalic}>Курсив</button>
            <button className='editor-article__btn' onClick={toggleUnderline}>Подчеркивание</button>
            <button className='editor-article__btn' onClick={changeTextColor}>Цвет текста</button>
            {/* <button className='editor-article__btn' onClick={saveHTML}>Сохранить разметку</button> */}
            <button className='editor-article__btn' onClick={insertLink}>Вставить ссылку</button>
        </div>
    )
})

export { Editor }