import Cookies from 'universal-cookie'
import { forwardRef, useRef, useState, useImperativeHandle, useEffect } from 'react'
import { EditWrapper } from '../EditWrapper/EditWrapper'
import { useDebounce } from '../../../../hooks/useDebounce'
import './VideoArticle.scss'

const VideoArticle = forwardRef(({
    data,
    editMode,
    onChange,
    changeContent,
    position,
    setContentArr,
    id,
    setCurrentIndex,
    ind,
    setIsModal,
    before,
    setBeforeBlock,
    deleteBlock,
    readonly = false,
    draggableElement,
    setDraggableElement,
    articleId,
    fetchDataById,
    changeBlock
}, ref) => {
    const [extension, setExtension] = useState("")
    const [error, setError] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const fileInputRef = useRef()
    const cookies = new Cookies()
    const activeLang = localStorage.getItem('lang')
    const [objImg, setObjImg] = useState(null)
    const [altValue, setAltValue] = useState(data[0]?.properties?.alt || '')

    const translatePlaceholderSelected = () => {
        if (activeLang == 'ru') return 'Выберите видео'
        if (activeLang == 'en') return 'Select an video'
        if (activeLang == 'tr') return "Bir resim seçin"
        return 'Выберите видео'
    }

    useImperativeHandle(ref, () => ({
        error,
        // прикрутить открытие системного диалога с выбором изображения
        focus: () => { },
        data: objImg
    }), [objImg])

    const debounceChange = useDebounce(changeContent, 1000)

    useEffect(() => {
        debounceChange(id,
            [
                objImg
            ]
        )
        setContentArr((prev) => {
            return prev.map((el) => {
                if (el?.block_id == id) {
                    return {
                        ...el,
                        content: [
                            objImg
                        ]
                    }
                } else {
                    return el
                }
            })
        })
    }, [objImg]);

    const fetchPostVideo = (file) => {

        const fd = new FormData()
        fd.append('videos', file)
        const url = `https://dl.ecomru.ru:6001/clients/videos`
        const token = cookies.get('auth')

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                if (json.videos) {
                    console.log(json.videos?.[0]?.url)
                    setObjImg({ value: json.videos?.[0]?.url, properties: { alt: altValue } })
                    console.log(objImg)
                    // debounceChange({ value: json.videos?.[0]?.url, type: ARTICLE_TYPES.video, place: position }, ARTICLE_TYPES.video)
                }
            })
            .catch(err => {
                console.error(err)
            })

    }

    const handleImageClick = (e) => {
        if (readonly) {
            const html = document.querySelector('html')
            html.style = 'overflow: hidden'
            setOpen(!isOpen)
            return
        }
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const handleFileInput = (e) => {
        console.log(e)
        if (e.target.files.length === 0) {
            e.preventDefault()
            return
        }
        const fileBase64Reader = new FileReader()
        fileBase64Reader.onload = () => {
            const re = /;base64,/
            const extensionAndBase64 = fileBase64Reader.result?.split(re)
            const onlyExtension = extensionAndBase64[0]?.split('image/')[1]
            setExtension(onlyExtension)
            fetchPostVideo(e.target.files[0])
        }
        fileBase64Reader.readAsDataURL(e.target.files[0])
        setError(false)
        onChange()
    }

    useEffect(() => {
        setObjImg({ value: data[0]?.value, properties: { alt: data[0]?.properties?.alt } })
    }, [])

    // удалить
    const handleDeleteBlock = (e) => {
        //  setContent(e.currentTarget.textContent)
        setContentArr((prev) => {
            return prev.filter((el) => {
                if (el?.block_id != id) {
                    return el
                }
            })
        })
        deleteBlock(id)
    }

    useEffect(() => {
        if (!isOpen) {
            const html = document.querySelector('html')
            html.style = 'overflow: unset'
        }
    }, [isOpen])

    useEffect(() => {
        setCurrentIndex(ind)
        setBeforeBlock(before)
    }, []);


    return (
        <EditWrapper
            id={id}
            before={before}
            articleId={articleId}
            changeBlock={changeBlock}
            readonly={readonly}
            draggableElement={draggableElement}
            setDraggableElement={setDraggableElement}
            fetchDataById={fetchDataById}
            deleteFunc={(e) => handleDeleteBlock(id)}
            type='video'
            child={
                <div className='video-container'
                    onClick={(e) => {
                        setCurrentIndex(ind)
                        setBeforeBlock(before)
                    }}
                    onContextMenu={(e) => {
                        if (!readonly) {
                            e.preventDefault()
                            setCurrentIndex(ind)
                            setIsModal(true)
                        }
                    }}
                    tabIndex={0}
                >

                    <button className={!readonly ? 'video-container__button' : 'video-container__button__readonly'}
                        onClick={handleImageClick}
                    >

                        {<video className='video-container__video'
                            src={objImg?.value ? objImg?.value : ''}
                            controls>
                            <source></source>
                        </video>
                        }

                        {!objImg?.value && <div className='hidden-placeholder'>
                            <p className='text'>{translatePlaceholderSelected()}</p>
                        </div>}
                    </button>

                    {!objImg?.value && <input
                        name='uploadedImage'
                        ref={fileInputRef}
                        className='video-container__file-input'
                        type='file'
                        onChange={handleFileInput}
                    // accept='image/png, image/jpeg'
                    />}
                </div>
            }
        />

    )
})

export { VideoArticle }