import React, { useEffect, useState, useRef } from 'react'
import { EditWrapper } from '../EditWrapper/EditWrapper'
// import { ModalReplaceLink } from '../../Modal/ModalReplaceLink/ModalReplaceLink'
import { Editor } from '../Editor/Editor'
import parse from 'html-react-parser'
import { useDebounce } from '../../../../hooks/useDebounce'
import "./InformationBlock.scss"

const InformationBlock = (({
    data,
    editMode,
    changeContent,
    setContentArr,
    id,
    deleteBlock,
    setCurrentIndex,
    ind,
    setIsModal,
    setBeforeBlock,
    before,
    readonly = false,
    draggableElement,
    setDraggableElement,
    articleId,
    fetchDataById,
    changeBlock
}) => {
    const ref = useRef()
    const [content, setContent] = useState(data[0]?.value)
    const [showLinkModal, setShowLinkModal] = useState(false)
    const [target, setTarget] = useState(null)
    const [selectionData, setSelectionData] = useState({})

    const handleInput = (e) => {
        debounceChange(id,
            [
                {
                    value: ref?.current?.innerHTML,
                    properties: {}
                }
            ]
        )
        setContentArr((prev) => {
            return prev.map((el) => {
                if (el?.block_id == id) {
                    return {
                        ...el,
                        content: [
                            {
                                value: ref?.current?.innerHTML,
                            }
                        ]
                    }
                } else {
                    return el
                }
            })
        })
    }

    const getSelectionText = () => {
        let text = "";
        let startIndex
        let endIndex
        const WGS = window.getSelection()
        if (WGS) {
            text = WGS.toString();
            startIndex = WGS.extentOffset
            endIndex = WGS.anchorOffset
        } else if (document.selection && document.selection.type != "Control") {
            text = document.selection.createRange().text;
        }
        return { text, startIndex, endIndex };
    }

    // const replaceWithLink = (linkValue) => {
    //     const fullContent = target.innerText
    //     const { text, startIndex, endIndex } = selectionData
    //     target.innerHTML = `${fullContent?.slice(0, startIndex)}<a class='link' title=${titleValue} href=${linkValue} target='_blank'>${text}</a>${fullContent?.slice(endIndex, fullContent?.length)}`
    //     resetData()
    // }

    const contextMenuHandler = e => {
        e.preventDefault()
        const selectionObj = getSelectionText()
        if (selectionObj?.text?.length < 1) {
            return
        }
        setSelectionData(selectionObj)
        setTarget(e.target)
        setShowLinkModal(true)
    }

    // useEffect(() => {
    //     if (typeof data === 'string') {
    //         setContent(parse(data))
    //     }
    // }, [editMode, data])


    const debounceChange = useDebounce(changeContent, 1000)

    const handleDeleteBlock = (e) => {
        //  setContent(e.currentTarget.textContent)
        setContentArr((prev) => {
            return prev.filter((el) => {
                if (el?.block_id != id) {
                    return el
                }
            })
        })
        deleteBlock(id)
    }

    // Вставка кода без форматирования
    const handlePaste = (event) => {
        event.preventDefault();
    
        let clipboardData = event.clipboardData || window.clipboardData;
        let pastedHTML = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
    
        if (pastedHTML) {
          let parser = new DOMParser();
          let doc = parser.parseFromString(pastedHTML, 'text/html');
    
          // Удаляем все теги <br>
          doc.querySelectorAll('br').forEach(el => el.remove());
    
          // Оставляем только безопасные теги
          const allowedTags = ['p', 'b', 'i', 'u', 'a'];
          doc.body.querySelectorAll('*').forEach(el => {
            if (!allowedTags.includes(el.tagName.toLowerCase())) {
              el.replaceWith(...el.childNodes); // Заменяем на чистый текст
            }
          });
    
          let cleanHTML = doc.body.innerHTML;
    
          // Вставляем очищенный HTML без форматирования
          document.execCommand('insertHTML', false, cleanHTML);
        }
      }

    return (
        <EditWrapper
            id={id}
            before={before}
            deleteFunc={(e) => handleDeleteBlock(e)}
            type='info'
            articleId={articleId}
            changeBlock={changeBlock}
            readonly={readonly}
            draggableElement={draggableElement}
            setDraggableElement={setDraggableElement}
            fetchDataById={fetchDataById}
            child={
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    width: '100%'
                }}>
                    {/* <TextEditor /> */}
                    {!readonly && <Editor
                        handleInput={handleInput}
                    />}
                    <div className='information-article'
                        onClick={(e) => {
                            setCurrentIndex(ind)
                            setBeforeBlock(before)
                        }}
                        onContextMenu={(e) => {
                            if (!readonly) {
                                e.preventDefault()
                                setCurrentIndex(ind)
                                setIsModal(true)
                            }
                        }}>
                        <p
                            onPaste={handlePaste}
                            ref={ref}
                            className={`information-article__text`}
                            suppressContentEditableWarning
                            contentEditable={!readonly}
                            onInput={(e) => handleInput(e)}
                            onContextMenu={contextMenuHandler}
                            dangerouslySetInnerHTML={{ __html: content }}
                        ></p>
                    </div>
                </div>
            }
        />
    )
})

export { InformationBlock }