import React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import banner from './img/banner-404.svg';
import "./Page404.scss";

const Page404: FC = () => {
    return (
        <div className='page-404'>
            <h2 className='title'>Не удалось найти эту страницу</h2>
            <Link to='/landing' className='title_medium'>Вернуться на главную</Link>
            <img src={banner} alt="404 error illustration" />
        </div>
    );
};

export { Page404 };