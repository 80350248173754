import customHeaders, { getSpace } from '../../common/headers';
import { useState, useEffect, useRef } from 'react';
import add_icon from './img/add_icon.svg';
import { Modal } from '../Modal/Modal';
import Cookies from 'universal-cookie';
import {
  OlegHttps,
  OlegRulesHttps,
} from '../../fetchUrls';
import { ModalEditingRules } from '../Modal/ModalEditingRules/ModalEditingRules';
import { ModalRules } from '../Modal/ModalRules/ModalRules';
import {
  CheckboxToggle,
  ButtonBasic,
  TableBox,
} from '@lk-gtcom/ecomlab-components';
import { TitlePage } from '../TitlePage/TitlePage';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import './AccountNotifications.scss';

// Карточка
// status == 'Активно'/ 'Не активно'
const CardDrop = ({ ...props }) => {
  const {
    id,
    name,
    portal_name,
    type,
    has_params,
    is_active,
    service_id,
    onDragStart,
    onDragOver,
    setIsModal,
    onToggle,
  } = props;

  return (
    <div
      className="col-item__card"
      draggable={true}
      onDragStart={(e) => {
        onDragStart(props);
      }}
      onDragOver={(e) => onDragOver(e)}
    >
      <p className="text">{name}</p>
      <div className="status-content">
        {has_params && (
          <button
            onClick={(e) => {
              onToggle(props);
              setIsModal(true);
            }}
            className="btn-text-violet"
          >
            Изменить параметры
          </button>
        )}
        <CheckboxToggle
          value={is_active}
          onChange={(e) => {
            setIsModal(true);
            if (!is_active) {
              onToggle(props);
            }
          }}
        />
        {/* <div className={`status ${statusCard(status)}`}>{status}</div> */}
      </div>
    </div>
  );
};

// Колонка
const ColItemDrop = ({
  title = '',
  arr = [],
  onDragStart,
  onDragOver,
  onDrop,
  loading = false,
  onToggle,
  setIsModal,
}) => {
  return (
    <div className="col-item">
      <div className="col-item__header">
        <h3 className="title">
          {title} <span>{arr?.length ? arr.length : 0}</span>
        </h3>
      </div>
      <div
        className="col-item__list"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => onDrop(e)}
        draggable={true}
      >
        {!loading
          ? arr?.map((props, ind) => {
            // Карточка
            return (
              <CardDrop
                //  key={props.account_id}
                {...props}
                onDragStart={(e) => {
                  onDragStart(e);
                }}
                onDragOver={(e) => onDragOver(e)}
                onToggle={(e) => onToggle(e)}
                setIsModal={(e) => setIsModal(e)}
              />
            );
          })
          : 'Loading...'}
        {arr?.length < 1 && (
          <div className="no-list">
            <img src={add_icon} />
            <p className="text">Добавьте правило</p>
          </div>
        )}
      </div>
    </div>
  );
};

const AccountNotifications = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const auth = new Cookies().get('auth');
  const [active, setActive] = useState([]);
  const [disactive, setDisactive] = useState([]);
  const [currentNotificationItem, setCurrentNotificationItem] = useState(null);
  const [isModalRules, setIsModalRules] = useState(false);
  const [showAddConnectionModal, setShowAddConnectionModal] = useState(false);
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [ruleInfo, setRuleInfo] = useState({});

  const [isEdit, setIsEdit] = useState(false);
  const [rule_id, setRuleId] = useState(1);
  const [ruleName, setRuleName] = useState('');

  const [strategyInfo, setStrategyInfo] = useState({});
  const [strategyParams, setStrategyParams] = useState([]);
  const [strategyParamValue, setStrategyParamValue] = useState([]);
  const [currentStrategyId, setCurrentStrategyId] = useState(-1);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const { total, loading } = tableProps;

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const service_id = 1;

  const fetchTable = (params) => {
    let sort, filters;
    if (params) {
      [sort, filters] = params;
    }

    const url = `${OlegHttps}/api/v1/${service_id}/client_rule/table`;

    const body = JSON.stringify({
      filters,
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchStrategies = () => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/model/${rule_id}/list`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setDisactive(json?.map((el) => ({ ...el, status: false })));
        // setActive(active?.map(el => ({ ...el, status: true })))
        // setDisactive(disactive?.map(el => ({ ...el, status: false })))
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchClientStrategies = () => {
    const url = `${OlegRulesHttps}/api/v1/{service_id}/rule_attrs/client/{rule_id}`;
    // const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/client/${rule_id}/list`;
    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setActive(json?.map((el) => ({ ...el, status: true })));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onGetStrategyParams = (id, name, status) => {
    let url;
    if (status) {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy_attrs/client/${id}`;
    } else {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy_attrs/model/${id}`;
    }

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        if (json?.length > 0) {
          setStrategyParams(json);
          setCurrentStrategyId(id);
        }
      })
      .catch((err) => {
        setStrategyParams([]);
        setStrategyInfo({
          name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
          status,
          id,
        });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCreateClientStrategy = (id, status, edit = false) => {
    const url = `${OlegRulesHttps}/api/v1/${service_id}/strategy`;

    const body = JSON.stringify({
      id: +rule_id,
      client_rule_id: +rule_id,
      strategy_id: +currentStrategyId,
      is_active: true,
      attributes: Object.keys(strategyParamValue).map((el) => {
        return {
          attr_id: +el,
          value: { ...strategyParamValue[el] },
        };
      }),
    });

    fetch(url, {
      body,
      method: edit ? 'PUT' : 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res?.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        fetchStrategies();
        fetchClientStrategies();
      })
      .catch((err) => {
        setStrategyParams([]);
        setStrategyInfo({
          name: 'Для этой стратегии нет параметров, она будет применена для всех товаров',
          status,
          id,
        });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setCurrentStrategyId(-1);
      });
  };

  const fetchChangeStatusNotifications = (id, service_id, status) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/status`;
    } else {
      url = `${OlegRulesHttps}/api/v1/${service_id}/strategy/status`;
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth}`,
    };

    const body = JSON.stringify({
      id,
      is_active: status === false ? true : false,
    });

    fetch(url, { body, method: 'PATCH', headers })
      .then((res) => res.json())
      .then((json) => {
        fetchStrategies();
        fetchClientStrategies();
      })
      .catch((err) => console.error(err));
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
  };

  const dropHandler = (e) => {
    e.preventDefault();
    fetchChangeStatusNotifications(
      currentNotificationItem?.id,
      currentNotificationItem?.service_id,
      currentNotificationItem?.is_active
    );
    setCurrentNotificationItem(null);
  };

  const onToggle = ({ id, service_id, status }) => {
    onGetStrategyParams(id, service_id, status);
  };

  const handleSaveSuccess = () => {
    fetchTable();
  };

  useEffect(() => {
    fetchTable();
    fetchStrategies();
    fetchClientStrategies();
  }, []);

  return (
    <>
      {isModalRules && (
        <ModalEditingRules
          setIsModal={(e) => setIsModalRules(e)}
          strategyParams={strategyParams}
          setStrategyParamValue={(e) => setStrategyParamValue(e)}
          strategyInfo={strategyInfo}
          setStrategyInfo={(e) => setStrategyInfo(e)}
          strategyParamValue={strategyParamValue}
          onSaveHandler={(e) => {
            isEdit
              ? onCreateClientStrategy(e, '', true)
              : onCreateClientStrategy(e);
          }}
          edit={isEdit}
        />
      )}
      {showAddConnectionModal && (
        <Modal
          isSocialNetwork={true}
          setIsModalActive={setShowAddConnectionModal}
        />
      )}
      {isModalCreate && (
        <ModalRules
          isEdit={isEdit}
          setIsEdit={(e) => setIsEdit(false)}
          isCreate={!isEdit}
          {...ruleInfo}
          rule_id={rule_id}
          setRuleIdPar={setRuleId}
          ruleName={ruleName}
          service_id={service_id}
          setRuleInfo={(e) => setRuleInfo(e)}
          setIsModal={setIsModalCreate}
          handleSaveSuccess={handleSaveSuccess}
          notification={true}
        />
      )}
      <div className="account-notifications">
        <div className="account-notifications__available">
          <div className="account-notifications__top-content">
            <div className="account-notifications__header">
              <TitlePage
                medium
                title='Добавьте платформы, куда должны приходить уведомления'
              />

              <div className="account-notifications__btn-group">
                <ButtonBasic
                  green
                  onClick={(e) => {
                    setIsEdit(false);
                    setIsModalCreate(true);
                    setRuleInfo({});
                  }}
                  text={"+ Добавить правило"}
                />

                <ButtonBasic
                  whiteGreen
                  text="+ Добавить подключение"
                  onClick={(e) => setShowAddConnectionModal(true)}
                />
              </div>
            </div>
          </div>
        </div>

        <TableBox
          tableUrl={tableUrl}
          tableBody={tableBody}
          ref={paginatorRef}
          paginator={true}
          fetchCallback={fetchTable}
          callback={(e) => fetchTable(e)}
          {...tableProps}
          statistic={[
            {
              label: 'Правил',
              sum: total,
            },
          ]}
          onAction={(e) => {
            const id = e?.['Идентификатор правила'];
            const ruleName = e?.['Название правила'];
            setIsModalCreate(true);
            setIsEdit(true);
            setRuleId(id);
            setRuleName(ruleName);
            fetchStrategies();
            fetchClientStrategies();
          }}
        />
        {/* <div className='col-group'>
                    <ColItemDrop
                        title='Доступные'
                        loading={loading}
                        arr={disactive}
                        onDragStart={e => {
                            setCurrentNotificationItem(e)
                        }}
                        onDragOver={e => dragOverHandler(e)}
                        onDrop={(e) => {
                            dropHandler(e)
                            fetchStrategies()
                        }}
                        onToggle={e => onToggle(e)}
                        setIsModal={(e) => setIsModalRules(e)}
                    />

                    <ColItemDrop
                        title='Активные'
                        arr={active}
                        loading={loading}
                        onDragStart={e => {
                            setCurrentNotificationItem(e)
                        }}
                        onDragOver={e => dragOverHandler(e)}
                        onDrop={(e) => {
                            dropHandler(e)
                            fetchClientStrategies()
                        }}
                        onToggle={e => onToggle(e)}
                        setIsModal={(e) => setIsModalRules(e)}
                    />
                </div> */}
      </div>
    </>
  );
};

export { AccountNotifications };
