import customHeaders, { getSpace } from '../../common/headers'
import add_icon from './img/add_icon.svg'
import card_img from './img/card_img.svg'
import info_icon from './img/info_icon.svg'
import { createPortal } from 'react-dom'
import { RavshanHttps } from '../../fetchUrls'
import { ModalEditPublic } from '../Modal/ModalAddPublic/ModalAddPublic'
import { FilterContainer } from '../FilterContainer/FilterContainer'
import { ModalAddNewGroup } from '../Modal/ModalAddNewGroup/ModalAddNewGroup';
import { useState, useEffect } from 'react'
import { DropDownSelector, ButtonBasic, InputDynamicWidth } from "@lk-gtcom/ecomlab-components";
import { ModalDeleteGroup } from '../Modal/ModalDeleteGroup/ModalDeleteGroup'
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack'
import { ButtonsSort } from '../Buttons/BurronsSort/ButtonsSort.tsx'
import { TitlePage } from '../TitlePage/TitlePage'
import useGeneralStore from '../../store/general'
import { ButtonActionDrop } from '../Buttons/ButtonActionDrop/ButtonActionDrop'
import "./NetworkGroups.scss"

const CardGroup = ({ ...props }) => {
    const {
        name,
        accounts,
        created_at,
        id,
        image,
        onClick,
        setIsModalDelete,
        setId
    } = props

    const [isShowAllPlatform, setIsShowAllPlatform] = useState(false)

    const btns = [
        {
            btn: 'Удалить', func: () => {
                setIsModalDelete(true)
                setId(id)
            }
        }
    ];

    const mapPlatforms = (arr) => {

        return arr?.map(({ icon, id, name }, ind) => {
            return <img className='network-groups__icon-group' src={icon} alt='platform icon' />
        })
    }

    return (
        <div className='card-item'
            onClick={onClick}
        >
            <div className='card-item__top-content'>
                <div className='card-item__header'>
                    <div className='statistic-tasks'>
                        <div className='statistic-tasks__item'>
                            Дата создания - <span>
                                {created_at ? new Date(created_at)?.toLocaleDateString('ru-RU') : 'неизвестная дата'}
                            </span>
                        </div>
                    </div>

                    <ButtonActionDrop
                        size='s'
                        id={id}
                        btns={btns}
                    />

                </div><div className='img-box'>
                    <img className='img' alt='project img preview' src={image ? image : card_img} onError={e => e.target.src = card_img} />
                </div>
            </div>

            <div className='card-item__medium-content'>
                <h3 className='title'>{name}</h3>
            </div>

            <div className='card-item__bottom-content'>
                <div className='card-item__icons'>
                    {isShowAllPlatform ? mapPlatforms(accounts) : mapPlatforms(accounts?.slice(0, 6))}

                    {(!isShowAllPlatform && accounts?.length > 6) &&
                        <p className='card-item__sum'>+<span>{accounts?.length - 6}</span></p>}
                </div>
            </div>
        </div>
    )
}

const btn_sort2 = [
    {
        label: <span>A<br />Z</span>,
        value: 'name'
    },
    {
        label: 'По дате создания',
        value: 'created_at'
    }
]

const NetworkGroups = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const { platforms = [], statuses = [], fetchFilters } = props
    const portalId = 'add-new-connection-portal'
    const [isModal, setIsModal] = useState(false)
    const [isModalDelete, setIsModalDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [groups, setGroups] = useState([])
    const [cardInfo, setCardInfo] = useState({})
    const [selectedPlatforms, setSelectedPlatforms] = useState([])
    const [selectedStatus, setSelectedStatus] = useState({})
    const [isDrop, setIsDrop] = useState(null)
    const [id, setId] = useState(null)

    const [currentSort, setCurrentSort] = useState(null)
    const [sort, setSort] = useState(null)
    const [searchInput, setSearchInput] = useState('')

    const fetchGroups = (signal, delay = false) => {
        setLoading(true)
        const request = () => {
            const url = `${RavshanHttps}/api/social/groups`

            const account_id = ''
            const status = selectedStatus?.value ? selectedStatus?.value : ''
            const platform = selectedPlatforms?.value ? [selectedPlatforms?.value] : []

            const body = JSON.stringify({
                account_id,
                platform,
                search: searchInput,
                order_param: currentSort?.value,
                order_param_direction: sort
            })

            fetch(url, { body, method: 'POST', headers, signal })
                .then(res => {
                    if (!res?.ok) { throw new Error('bad response') }
                    setGroups([])
                    return res.json()
                })
                .then(json => {
                    if (json) {
                        setGroups(json)
                    } else {
                        setGroups([])
                    }

                })
                .catch(e => console.log(e))
                .finally(() => {
                    if (signal.aborted) return
                    setLoading(false)
                })
        }
        if (delay) {
            setTimeout(() => { request() }, delay)
        } else {
            request()
        }

    }

    const onDeleteHandler = (group_id) => {
        const url = `${RavshanHttps}/api/social/delete_group`

        const body = JSON.stringify({
            group_id
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(() => {
                fetchGroups()
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        if (!isModal) {
            const abortController = new AbortController()

            fetchGroups(abortController.signal, 100)

            return () => {
                abortController.abort()
            }
        }
    }, [isModal, selectedPlatforms, selectedStatus, selectedSpace, sort, currentSort, searchInput])

    useEffect(() => {
        const closeMenu = (e) => {
            let el = e.target
            if (!el.closest('.drop-and-btn')) {
                setIsDrop(null)
            }
        }
        document.addEventListener('click', closeMenu)

        return () => {
            document.addEventListener('click', closeMenu)
        }
    }, [])

    useEffect(() => {
        fetchFilters()
    }, [groups, selectedSpace]);

    return (
        <>
            {isModal && <ModalAddNewGroup
                setIsModal={(e) => setIsModal(e)}
                cardInfo={cardInfo}
                setCardInfo={e => setCardInfo(e)}
            />}

            {isModalDelete && <ModalDeleteGroup
                setIsModal={(e) => setIsModalDelete(e)}
                id={id}
                setId={(e) => setId(e)}
                onDeleteHandler={(e) => onDeleteHandler(e)}
            />}

            <div id={portalId} className='network-groups'>
                <TitlePage
                    title="Мои группы"
                />

                <div className='network-groups__toolbar'>
                    <ButtonBasic
                        onClick={(e) => setIsModal(true)}
                        text={<div className='btn-content'>Новая группа <img src={add_icon} /></div>}
                    />

                    <ButtonsSort
                        currentSort={currentSort}
                        setCurrentSort={setCurrentSort}
                        btnSort={btn_sort2}
                        sort={sort}
                        setSort={setSort}
                    />

                    <InputDynamicWidth
                        position='left'
                        maxWidth='100%'
                        isOpen={true}
                        onChange={value => {
                            setSearchInput(value)
                        }}
                        placeholder='Поиск'
                    />
                </div>


                <div className='network-groups__toolbar'>
                    <FilterContainer
                        onReset={(e) => {
                            setSelectedPlatforms([])
                            setSelectedStatus([])
                        }}
                    >
                        <DropDownSelector
                            options_prop={platforms}
                            state={selectedPlatforms}
                            setState={setSelectedPlatforms}
                            placeholder='Площадка'
                        />

                        <DropDownSelector
                            options_prop={statuses}
                            state={selectedStatus}
                            setState={setSelectedStatus}
                            isClearable={false}
                            defaultValue={statuses?.[0]}
                            placeholder='Статус'
                        />
                    </FilterContainer>
                </div>

                <div className='network-groups__card-group'>
                    {groups?.map((props, ind) => {
                        const { id } = props
                        return (
                            <CardGroup
                                key={id}
                                {...props}
                                onClick={e => {
                                    setIsModal(true)
                                    setCardInfo(props)
                                }}
                                setIsModalDelete={e => setIsModalDelete(true)}
                                setId={(e) => setId(e)}
                            />
                        )
                    }
                    )}
                </div>
            </div >
        </>

    )
}

const Group = ({ name, id, account, description, portalId, onDelete }) => {
    const [showEditingModal, setShowEditingModal] = useState(false)
    const accountsCount = account?.length
    return (
        <div onClick={() => setShowEditingModal(true)} className='select-box-content'>
            {
                accountsCount >= 0 ?
                    <span className='btn__round'>{accountsCount}</span>
                    : null
            }
            <span>{name}</span>

            {showEditingModal && createPortal(
                <ModalEditPublic
                    id={id}
                    name={name}
                    portalId={portalId}
                    account={account}
                    description={description}
                    setShowModal={setShowEditingModal}
                    onCLose={onDelete}
                />,
                document.getElementById(portalId))
            }
        </div>
    )
}

export { Group }
export { NetworkGroups }