import { useState, useEffect, useRef } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ArticleWiki } from '../ArticleComponents/ArticleWiki'
import {
  ViewSwitch,
  DropDownSelector,
  InputDynamicWidth,
  usePagination
} from '@lk-gtcom/ecomlab-components';
import Breadcrumb from '../ArticleComponents/ArticleTypesComponent/Breadcrumb/Breadcrumb';
import { BASE_ROUTE } from '../../App';
import customHeaders, { getSpace } from '../../common/headers';
import { useResetOnBaseURL } from '../../common/useResetOnBaseURL';
import { ModalMiltilevelSidebarAdd } from '../../components/Modal/ModalMiltilevelSidebarAdd/ModalMiltilevelSidebarAdd';
import { ModalMiltilevelSidebarSettings } from '../../components/Modal/ModalMiltilevelSidebarSettings/ModalMiltilevelSidebarSettings';
import useGeneralStore from '../../store/general';
import { FilesPage } from '../FilesPage/FilesPage';
import './MainDirectory.scss';

const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const SORTING_OPTIONS = [
  {
    label: 'По дате: сначала новые',
    headerField: 'Дата создания',
    direction: SORT_DIRECTIONS.DESC,
  },
  {
    label: 'По дате: сначала старые',
    headerField: 'Дата создания',
    direction: SORT_DIRECTIONS.ASC,
   },
   {
    label: 'По названию: От а до я',
    headerField: 'Название',
    direction: SORT_DIRECTIONS.ASC,
  },
  {
    label: 'По названию: От я до а',
    headerField: 'Название',
    direction: SORT_DIRECTIONS.DESC,
  },
];

const MainDirectory = ({
  activeLang,
  pathBread,
  setCurrentArticle,
  currentArticle,
  pathsWebName,
  setPathsWebName,
  // setTitle,
  setDescription,
  // title,
  description,
  paths,
  setPaths,
  directory = false
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const paginatorRef = useRef();
  const paginatorCallback2 = useRef();
  const [title, setTitle] = useState('')

  const wiki_id = localStorage.getItem('wiki_id');
  const wiki_name = localStorage.getItem('wiki_name');

  const [
    page,
    limit,
    total,
    setTotal,
    Paginator,
    resetPaginator,
    paginatorTransition,
  ] = usePagination(
    paginatorCallback2.current,
    [],
    paginatorRef.current?.params
  );

  const navigate = useNavigate();
  const path = useParams()['*'];

  const [selectedTopLevelValue, setSelectedTopLevelValue] = useState(0);
  const [selectedEndLevelValue, setSelectedEndLevelValue] = useState(0);

  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [total2, setTotal2] = useState(0);

  const [selectView, setSelectView] = useState('Tile-long');

  const [sortingOption, setSortingOption] = useState(null);
  const sortingRef = useRef();
  const [search, setSearch] = useState('');

  const [selectedState, setSelectedState] = useState([]);
  const [isArticle, setIsArticle] = useState(false);

  const [isModalAddChatBot, setIsModalAddChatBot] = useState(false);
  const [isModalChatBot, setIsModalChatBot] = useState(false);
  const [pageValue, setPageValue] = useState('article')
  const [statusText, setStatusText] = useState("")

  // Крошки
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const fetchGetBreadcrumbs = (level_id, article_id) => {

    let url = `https://apps2.ecomru.ru:4460/api/v1/directory/breadcrumbs/${level_id}`

    fetch(url, { method: 'GET', headers })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('bad response')
        }
      })
      .then(json => {
        const items = json.map(({ id, name }) => {
          return {
            label: name,
            value: id,
            web_name: null
          }
        })
        setBreadcrumbs([{
          label: 'Главная',
          value: '',
        }, ...items])
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    if (selectedTopLevelValue) {
      fetchGetBreadcrumbs(selectedTopLevelValue)
    } else {
      fetchGetBreadcrumbs(selectedEndLevelValue)
    }

  }, [selectedEndLevelValue, selectedTopLevelValue]);

  useResetOnBaseURL(BASE_ROUTE, () => {
    setSelectedEndLevelValue(0);
    setSelectedTopLevelValue(0);
  });

  useEffect(() => {
    setTotal(null);
    if (typeof +path?.at(-1) === 'number' && !isNaN(+path?.at(-1))) {
      setIsArticle(true);
    } else {
      setIsArticle(false);
    }
  }, [path]);

  const saveWidthColumn = () => {
    if (selectView == 'Table') {
      const colWidth = [...document.querySelectorAll('.col-th-border')];
      if (colWidth.length > 0) {
        const arrWidth = colWidth.map((el) =>
          el.style.width ? el.style.width : '247px'
        );
        sessionStorage.setItem('col-width', arrWidth);
      }
    }
  };

  useEffect(() => {
    saveWidthColumn();
  }, [selectedEndLevelValue]);

  const [isModalAdd, setIsModalAdd] = useState(false);
  const [isModalSettings, setIsModalSettings] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState();

  return (
    <>
      {isModalAdd && (
        <ModalMiltilevelSidebarAdd setIsModal={(e) => setIsModalAdd(e)} />
      )}

      <div className='article-main-container__breadcrumb-and-content'>
        <div className='article-main-container__breadcrumb-and-tabs'>
          <Breadcrumb
            mainPath=''
            breadcrumbs={breadcrumbs}
            setSelectedItemId={setSelectedItemId}
            directory={directory}
            onPortalClick={(id) => {
              setSelectedTopLevelValue(id);
              setSelectedEndLevelValue(null);
            }}
          />
        </div>

        <div className="article-main-container">


          <div className='article-main-container'>
            <ModalMiltilevelSidebarSettings
              directory={directory}
              breadcrumbs={breadcrumbs}
              fetchGetBreadcrumbs={fetchGetBreadcrumbs}
              setTitle={(e) => {
                setTitle(e);
              }}
              setPathsWebName={(e) => setPathsWebName(e)}
              onPortalClick={(id) => {
                setSelectedTopLevelValue(id);
                setSelectedEndLevelValue(null);
              }}
              onLevelClick={(id) => {
                setSelectedEndLevelValue(id);
                setSelectedTopLevelValue(null);
              }}
              setPaths={(e) => setPaths(e)}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
            />


            <div className="flex">
              <div className="main-content">
                <div className="toolbar-info">
                  <h1 className="title_main">{path && title}</h1>
                  <ViewSwitch
                    tile={false}
                    selectView={selectView}
                    setSelectView={(e) => setSelectView(e)}
                  />
                </div>


                <div className="article-main__header">
                  { 
                    <DropDownSelector
                      style={{ maxWidth: '360px' }}
                      isClearable={false}
                      options_prop={SORTING_OPTIONS.map((so) => ({
                        label: so.label,
                        value: {
                          headerField: so.headerField,
                          direction: so.direction,
                        },
                      }))}
                      state={sortingOption}
                      setState={(e) => {
                        setSortingOption(e);
                        sortingRef.current = {
                          param: e.value?.headerField,
                          direction: e.value?.direction,
                        };
                      }}
                      defaultValue={{
                        label: SORTING_OPTIONS[0].label,
                        value: {
                          param: SORTING_OPTIONS[0].headerField,
                          direction: SORTING_OPTIONS[0].direction,
                        },
                      }}
                    />
                  }

                  <InputDynamicWidth
                    onChange={(e) => setSearch(e)}
                    position="left"
                    maxWidth="100%"
                    drop={false}
                    isOpen={true}
                  />
                </div>

                <div
                  className="article-main-container__content"
                  style={{
                    overflow: selectView == 'Table' ? 'hidden' : 'visible'
                  }}
                >
                  <Routes>
                    {!isArticle && (
                      <Route
                        path={`/:level/:sublevel?/:sublevel2?/:sublevel3?/:sublevel4?/:sublevel5?`}
                        element={
                          <FilesPage
                            search={search}
                            selectView={selectView}
                            sortingRef={sortingRef}
                          />
                        }
                      />
                    )}

                    <Route
                      path={`/:level/:sublevel?/:sublevel2?/:sublevel3?/:sublevel4?/:sublevel5?/:cpu?/new`}
                      element={
                        <ArticleWiki
                          // <Article
                          isNew={true}
                          pathBread={wiki_name}
                          activeLang={activeLang}
                          setCurrentArticle={setCurrentArticle}
                          setTags={(e) => setTags(e)}
                          tags={tags}
                          pathsWebName={pathsWebName}
                          setPathsWebName={(e) => setPathsWebName(e)}
                          setTitle={(e) => setTitle(e)}
                          setDescription={(e) => setDescription(e)}
                          selectedItemId={selectedItemId}
                          parent_id={
                            selectedEndLevelValue
                              ? selectedEndLevelValue
                              : selectedTopLevelValue
                          }
                          pageValue={pageValue}
                          setPageValue={setPageValue}
                          statusText={statusText}
                          setStatusText={setStatusText}
                          fetchGetBreadcrumbs={fetchGetBreadcrumbs}
                          setSelectedItemId={setSelectedItemId}
                        />
                      }
                    />

                    <Route
                      path="/:lang/:level/:cpu/:id"
                      // path='/:lang/article/:cpu/:id'
                      element={
                        <ArticleWiki
                          // <Article
                          isNew={false}
                          activeLang={activeLang}
                          pathBread={wiki_name}
                          setCurrentArticle={setCurrentArticle}
                          setTags={(e) => setTags(e)}
                          tags={tags}
                          setPaths={(e) => setPaths(e)}
                          pathsWebName={pathsWebName}
                          setPathsWebName={(e) => setPathsWebName(e)}
                          setTitle={(e) => setTitle(e)}
                          setDescription={(e) => setDescription(e)}
                          pageValue={pageValue}
                          setPageValue={setPageValue}
                          statusText={statusText}
                          setStatusText={setStatusText}
                          fetchGetBreadcrumbs={fetchGetBreadcrumbs}
                          setSelectedItemId={setSelectedItemId}
                        />
                      }
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { MainDirectory };
