import React from 'react';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useState, useEffect, useRef } from 'react';
import { TableBox, ButtonBasic, DropDownSelector, SearchComponent, Tabs } from "@lk-gtcom/ecomlab-components";
import x_icon from './img/X.png';
import y_icon from './img/Y.png';
import setting_icon from './img/setting.png';
import { TitlePage } from '../../components/TitlePage/TitlePage';
import './SummaryMatrices.scss';

interface ButtonConfig {
    label: string;
    icon: string;
}

const btns: ButtonConfig[] = [
    {
        label: 'Товары',
        icon: x_icon
    },
    {
        label: 'Категории',
        icon: y_icon
    },
    {
        label: 'Мин. цена конкурентов',
        icon: setting_icon
    }
];

interface SummaryMatricesProps {
    // Define your props here if needed
}

const SummaryMatrices: React.FC<SummaryMatricesProps> = ({ }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState<[number, number]>([new Date().getTime() - 604800000, new Date().getTime()]);
    const [selectedCategory, setSelectedCategory] = useState<any[]>([]); // Replace 'any' with proper type
    const [selectedBrand, setSelectedBrand] = useState<any[]>([]); // Replace 'any' with proper type

    return (
        <div className='summary-matrices'>
            <TitlePage
                title='Сводные матрицы'
            />

            <div className='summary-matrices__toolbar'>
                <FilterContainer
                    onReset={(e: React.MouseEvent) => {
                        setSelectedCategory([]);
                        setSelectedBrand([]);
                    }}
                >
                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={(e: any) => (e)}
                        placeholder='Ось Y'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={(e: any) => (e)}
                        placeholder='Ось X'
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={(e: any) => (e)}
                        placeholder='Анализируемый показатель'
                    />

                </FilterContainer>

                <ButtonBasic
                    green
                    width='150px'
                    text='Создать товар'
                />
            </div>

            <TableBox
                loading={loading}
                fetchedData={[]}
                headers={[]}
                child={
                    <>
                        {btns.map(({ label, icon }) => {
                            return (
                                <button key={label} className='summary-matrices__btn-table'>
                                    <img src={icon} alt={label} />
                                    {label}
                                </button>
                            );
                        })}
                    </>
                }
            />
        </div>
    );
};

export { SummaryMatrices };