const Telegramm = () => {

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bxl-telegram.svg">
                <path id="Vector" d="M15.4985 2.78786L2.20095 7.91561C1.29345 8.28011 1.2987 8.78636 2.03445 9.01211L5.44845 10.0771L13.3475 5.09336C13.721 4.86611 14.0622 4.98836 13.7817 5.23736L7.38196 11.0131H7.38045L7.38196 11.0139L7.14646 14.5329C7.49146 14.5329 7.6437 14.3746 7.8372 14.1879L9.49545 12.5754L12.9447 15.1231C13.5807 15.4734 14.0375 15.2934 14.1957 14.5344L16.46 3.86336C16.6917 2.93411 16.1052 2.51336 15.4985 2.78786Z" fill="#419FD9" />
            </g>
        </svg>
    )
}

export { Telegramm }
