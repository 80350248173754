import { useState, useEffect, useRef } from 'react';
import { DropDownSelector, RangeBox } from '@lk-gtcom/ecomlab-components';
import {
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
  fetchCampaignFilter,
  fetchWarehouseFilter,
  fetchAdvCampaignFilter,
  fetchOrderSuperStatusFilter,
  fetchAdvCampaignTypeFilter,
  fetchAdvStatusFilter,
  fetchApiFilterVer3,
  fetchFilterData,
} from '../../common/filtersFunc';
import { AnalyticsPageComponent } from '../../components/AnalyticsPageComponent/AnalyticsPageComponents';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { BtnDropCheckbox } from '../../components/BtnDropCheckbox/BtnDropCheckbox';
import routes from './routes';
import useGeneralStore from '../../store/general';

const pageTabsMap = {
  [routes.analytics]: [
    {
      label: 'По датам',
      value: 'date',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    {
      label: 'По складу',
      value: 'warehouse',
    },
    {
      label: 'По компаниям',
      value: 'campaign',
    },
  ],
  [routes.cost]: [
    {
      label: 'По датам',
      value: 'date',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    {
      label: 'По складу',
      value: 'warehouse',
    },
    {
      label: 'По компаниям',
      value: 'campaign',
    },
  ],
  [routes.advertising]: [
    {
      label: 'По датам',
      value: 'date',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    {
      label: 'По рекламным кампаниям',
      value: 'campaign',
    },
    {
      label: 'По типу рекламной кампании',
      value: 'campaign_type',
    },
    {
      label: 'По упущенным продажам',
      value: 'missed_sales',
    },
  ],
  [routes.ordersAndSales]: [
    {
      label: 'Список заказов',
      value: 'list',
    },
    {
      label: 'По складам',
      value: 'warehouse',
    },
    {
      label: 'Товары в заказах',
      value: 'items',
    },
    {
      label: 'По географии',
      value: 'geo',
    },
    {
      label: 'Заказы по статусам',
      value: 'status',
    },
    // {
    //     label: 'По категориям',
    //     value: 'category'
    // },
    // {
    //     label: 'По возвратам',
    //     value: 'returns'
    // },
  ],
  [routes.returnAndCancellations]: [
    {
      label: 'Отмененные и возвращенные заказы',
      value: 'list',
    },
    {
      label: 'По причинам',
      value: 'reason',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    // {
    //     label: 'По возвратам',
    //     value: 'returns'
    // },
  ],
  [routes.prices]: [
    {
      label: 'По датам',
      value: 'date',
    },
    // {
    //     label: 'По актуальным ценам',
    //     value: 'actual_prices'
    // },
    {
      label: 'По маркетплейсам',
      value: 'by_mp',
    },
    // {
    //     label: 'По товарам',
    //     value: 'product',
    // },
    // {
    //     label: 'По площадкам',
    //     value: 'marketplace'
    // },
    // {
    //     label: 'По конкурентам',
    //     value: 'competitors'
    // },
    // {
    //     label: 'По эластичности',
    //     value: 'elasrtic'
    // },
    // {
    //     label: 'По себестоимости',
    //     value: 'net'
    // },
  ],
  [routes.stock]: [
    {
      label: 'По датам',
      value: 'date',
    },
    {
      label: 'По товарам',
      value: 'product',
    },
    // {
    //   label: 'По дате и складам',
    //   value: 'date_and_wh',
    // },
    {
      label: 'По категориям',
      value: 'category',
    },
    {
      label: 'По брендам',
      value: 'brand',
    },
    // {
    //   label: 'По ABC',
    //   value: 'abc',
    // },
  ],
  [routes.abc]: [
    {
      label: 'ABC Анализ (по проданным единицам)',
      value: 'ABC',
    },
    {
      label: 'ABC Анализ (по сумме продаж)',
      value: 'ABC_RUB',
    },
    {
      label: 'XYZ Анализ',
      value: 'XYZ',
    },
    {
      label: 'ABC-XYZ матрица',
      value: 'ABC_XYZ',
    },
    {
      label: 'Оборачиваемость',
      value: 'turnover',
    },
  ],
};

const urlMap = {
  [routes.analytics]: 'general',
  [routes.advertising]: 'advertising',
  [routes.ordersAndSales]: 'orders',
  [routes.prices]: 'prices',
  [routes.stock]: 'stock',
  [routes.cost]: 'cost',
  [routes.returnAndCancellations]: 'returns_cancellations',
  [routes.abc]: 'classification',
};

// const list_select = [
//   {
//     label: 'Площадка',
//   },
//   {
//     label: 'Магазин',
//   },
//   {
//     label: 'Бренд',
//   },
//   {
//     label: 'Кампания',
//   },
//   {
//     label: 'Категория',
//   },
//   {
//     label: 'Склад',
//   },
// ];

const mp_options = [
  {
    label: 'Ozon',
    value: 1,
  },
  {
    label: 'Wildberries',
    value: 3,
  },
  {
    label: 'Yandex',
    value: 2,
  },
];

const group_missed_sales_options = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Недостаточно просмотров',
    value: 'views_not_enough',
  },
  {
    label: 'Недостаточно конверсий',
    value: 'conversion_not_enough',
  },
];

const AnalyticsPage = ({ route, activeSubItems }) => {
  const apiIdRef = useRef();
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [advCampaignData, setAdvCampaignData] = useState([]);
  const [superStautsData, setSuperStatusData] = useState([]);
  const [advCampaignTypeData, setAdvCampaignTypeData] = useState([]);
  const [advStatusData, setAdvStatusData] = useState([]);

  const [selectedMp, setSelectedMp] = useState([]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(['all']);
  const [selectedGroupMissedSales, setSelectedGroupMissedSales] = useState([]);
  const [selectedAdvCampaign, setSelectedAdvCampaign] = useState([]);
  const [selectedSuperStauts, setSelectedSuperStatus] = useState([]);
  const [selectedAdvCampaignType, setSelectedAdvCampaignType] = useState([]);
  const [selectedAdvStatus, setSelectedAdvStatus] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [campaignPage, setCampaignPage] = useState(1);
  const [warehousePage, setWarehousePage] = useState(1);
  const [advCampaignPage, setAdvCampaignPage] = useState(1);
  const [superStatusPage, setSuperStatusPage] = useState(1);
  const [advCampaignTypePage, setAdvCampaignTypePage] = useState(1);
  const [advStatusPage, setAdvStatusPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [campaignQuery, setCampaignQuery] = useState('');
  const [warehouseQuery, setWarehouseQuery] = useState('');
  const [advCampaignQuery, setAdvCampaignQuery] = useState('');
  const [superStatusQuery, setSuperStatusQuery] = useState('');
  const [advCampaignTypeQuery, setAdvCampaignTypeQuery] = useState('');
  const [advStatusQuery, setAdvStatusQuery] = useState('');

  const [checkboxValues, setCheckboxValues] = useState([
    'Площадка',
    'Магазин',
    'Категория',
    'Бренд',
    'Кампания',
    'Склад',
    // 'Причина потерь продаж',
    'Статус заказа',
    'По типу рекламной кампании',
    'По статусу рекламной кампании',
  ]);

  const [defaultDate, setDefaultDate] = useState('');
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const apiFilter = () => {
    fetchApiFilterVer3({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: setApiData,
      set: setApiData,
      searchParam: apiQuery,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      filter: 'api_id',
    });
  };

  useEffect(() => {
    apiFilter();
  }, [apiPage, apiQuery]);

  const superStatusFilter = () => {
    fetchOrderSuperStatusFilter({
      selectedSpace: selectedSpace?.value,
      page: superStatusPage,
      setSuperStatusData: setSuperStatusData,
      searchParam: superStatusQuery,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      apiData,
      selectedApi,
      dateFrom,
      dateTo,
    });
  };

  useEffect(() => {
    superStatusFilter();
  }, [superStatusPage, superStatusQuery]);

  const campaignTypeFilter = () => {
    fetchAdvCampaignTypeFilter({
      selectedSpace: selectedSpace?.value,
      page: advCampaignTypePage,
      setSuperStatusData: setAdvCampaignTypeData,
      searchParam: advCampaignTypeQuery,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      apiData,
      selectedApi,
      dateFrom,
      dateTo,
      setSelectedFilter: setSelectedAdvCampaignType,
      set: setAdvCampaignTypeData
    });
  };

  useEffect(() => {
    campaignTypeFilter();
  }, [campaignPage, campaignQuery]);

  const advStatusFilter = () => {
    fetchAdvStatusFilter({
      selectedSpace: selectedSpace?.value,
      page: advStatusPage,
      setSuperStatusData: setAdvStatusData,
      searchParam: advStatusQuery,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      apiData,
      selectedApi,
      dateFrom,
      dateTo,
      set: setAdvStatusData,
      setSelectedFilter: setSelectedAdvStatus
    });
  };

  useEffect(() => {
    advStatusFilter();
  }, [advStatusQuery, advStatusPage]);

  const advCampaignFilter = () => {
    fetchAdvCampaignFilter({
      selectedSpace: selectedSpace?.value,
      page: advCampaignPage,
      setAdvCampaignData: setAdvCampaignData,
      searchParam: advCampaignQuery,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      apiData,
      selectedApi,
      set: setAdvCampaignData,
      setSelectedFilter: setSelectedAdvCampaign,
    });
  };

  useEffect(() => {
    advCampaignFilter();
  }, [advCampaignPage, advCampaignQuery]);

  const categoryFilter = () => {
    fetchApiFilterVer3({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      set: setCategoryData,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
      filter: 'category',
    });
  };

  useEffect(() => {
    categoryFilter();
  }, [categoryPage, categoryQuery]);

  const brandFilter = () => {
    fetchApiFilterVer3({
      selectedSpace: selectedSpace?.value,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      dateFrom,
      dateTo,
      page: brandPage,
      searchParam: brandQuery,
      set: setBrandData,
      setBrandData: (e) => setBrandData(e),
      filter: 'brand',
    });
  };

  useEffect(() => {
    brandFilter();
  }, [brandPage, brandQuery]);

  const campaignFilter = () => {
    fetchApiFilterVer3({
      selectedSpace: selectedSpace?.value,
      page: campaignPage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      brandData,
      dateFrom,
      dateTo,
      searchParam: campaignQuery,
      set: setCampaignData,
      setCampaignData: (e) => setCampaignData(e),
      filter: 'campaign',
    });
  };

  useEffect(() => {
    campaignFilter();
  }, [campaignPage, campaignQuery]);

  const warehouseFilter = () => {
    fetchApiFilterVer3({
      selectedSpace: selectedSpace?.value,
      page: warehousePage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      selectedProduct,
      selectedShop: selectedMp,
      selectedCampaign,
      brandData,
      dateFrom,
      dateTo,
      searchParam: warehouseQuery,
      setWarehouseData: (e) => setWarehouseData(e),
      filter: 'warehouse',
    });
  };

  useEffect(() => {
    warehouseFilter();
  }, [warehousePage, warehouseQuery]);

  useEffect(() => {
    apiFilter();
    categoryFilter();
    brandFilter();
    superStatusFilter();
    campaignTypeFilter();
    advStatusFilter();
    advCampaignFilter();
    campaignFilter();
    warehouseFilter();
  }, []);

  useEffect(() => {
    apiIdRef.current?.focus();
  }, []);

  const includeCampaign = Array.isArray(selectedMp)
    ? selectedMp?.some((item) => item.label === 'Все') ||
    selectedMp?.some((item) => item.label === 'Yandex')
    : false;

  const list_select = [
    { label: 'Площадка' },
    { label: 'Магазин' },
    { label: 'Бренд' },
    ...(includeCampaign ? [{ label: 'Кампания' }] : []),
    { label: 'Категория' },
    { label: 'Склад' },
  ];

  const [isTableRequest, setIsTableRequest] = useState(false);

  // const handleFetchData = useCallback(
  //   (
  //     filterName,
  //     page,
  //     query,
  //     selectedFilters,
  //     setFilterData,
  //     setSelectedFilter,
  //     append = false
  //   ) => {
  //     fetchFilterData({
  //       filterName,
  //       selectedSpace: selectedSpace?.value,
  //       page,
  //       searchParam: query,
  //       selectedFilters,
  //       setFilterData,
  //       setSelectedFilter,
  //       append,
  //     });
  //   },
  //   [selectedSpace]
  // );


  const filters = (
    <FilterContainer
      onReset={(e) => {
        setSelectedApi([]);
        setSelectedCategory([]);
        setSelectedBrand([]);
        setSelectedCampaign([]);
        setSelectedWarehouse([]);
        setAdvCampaignData([]);
      }}
    >
      {!checkboxValues.includes('Площадка') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={mp_options}
          state={selectedMp}
          setState={(e) => setSelectedMp(e)}
          className="connections_page_selector"
          placeholder="Площадка"
          defaultValue={[{ label: 'Все', value: 'all' }]}
          onBlur={() => {
            apiFilter();
            categoryFilter();
            brandFilter();
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
          multi
        />
      )}

      {!checkboxValues.includes('Магазин') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={apiData}
          state={selectedApi}
          setState={(e) => setSelectedApi(e)}
          className="connections_page_selector"
          placeholder="Магазин"
          fetchCallback={(e) => setApiPage(e)}
          setInputChange={(e) => setApiQuery(e)}
          inputRef={true}
          ref={apiIdRef}
          multi
          onBlur={() => {
            categoryFilter();
            brandFilter();
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
        />
      )}

      {!checkboxValues.includes('Категория') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={categoryData}
          state={selectedCategory}
          setState={(e) => {
            setSelectedCategory(e);
          }}
          className="connections_page_selector"
          placeholder="Категория"
          fetchCallback={(e) => {
            setCategoryPage(e);
          }}
          setInputChange={(e) => setCategoryQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
          onBlur={() => {
            brandFilter();
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
        />
      )}

      {!checkboxValues.includes('Бренд') && route == 'analytics' ? null : (
        <DropDownSelector
          options_prop={brandData}
          state={selectedBrand}
          setState={(e) => setSelectedBrand(e)}
          className="connections_page_selector"
          placeholder="Бренд"
          fetchCallback={(e) => setBrandPage(e)}
          setInputChange={(e) => setBrandQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          onBlur={(e) => {
            brandFilter();
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
          multi
        />
      )}

      {!checkboxValues.includes('Кампания') && route == 'analytics'
        ? null
        : includeCampaign && (
          route != 'ABCXYZ' && <DropDownSelector
            options_prop={campaignData}
            state={selectedCampaign}
            setState={(e) => setSelectedCampaign(e)}
            className="connections_page_selector"
            placeholder="Кампания"
            fetchCallback={(e) => setCampaignPage(e)}
            setInputChange={(e) => setCampaignQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            onBlur={(e) => {
              superStatusFilter();
              campaignTypeFilter();
              advStatusFilter();
              advCampaignFilter();
              campaignFilter();
              warehouseFilter();
              setIsTableRequest(true);
            }}
            multi
          />
        )}

      {route !== 'advertising' &&
        (!checkboxValues.includes('Склад') && (route == 'analytics') ? null : (
          route != 'ABCXYZ' && <DropDownSelector
            options_prop={warehouseData}
            state={selectedWarehouse}
            setState={(e) => setSelectedWarehouse(e)}
            className="connections_page_selector"
            placeholder="Склад"
            fetchCallback={(e) => setWarehousePage(e)}
            setInputChange={(e) => setWarehouseQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            onBlur={(e) => {
              superStatusFilter();
              campaignTypeFilter();
              advStatusFilter();
              advCampaignFilter();
              campaignFilter();
              warehouseFilter();
              setIsTableRequest(true);
            }}
            multi
          />
        ))}

      {checkboxValues.includes('Кампания') && route !== 'advertising' ? null : (
         route != 'ABCXYZ' && <DropDownSelector
          options_prop={advCampaignData}
          state={selectedAdvCampaign}
          setState={(e) => setSelectedAdvCampaign(e)}
          className="connections_page_selector"
          placeholder="Рекламная кампания"
          fetchCallback={(e) => setAdvCampaignPage(e)}
          setInputChange={(e) => setAdvCampaignQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          onBlur={(e) => {
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
          multi
        />
      )}

      {/* {checkboxValues.includes('Причина потерь продаж') &&
      route !== 'advertising' ? null : (
        <DropDownSelector
          options_prop={group_missed_sales_options}
          state={selectedGroupMissedSales}
          setState={(e) => setSelectedGroupMissedSales(e)}
          placeholder="Причина потерь продаж"
          defaultValue={[{ label: 'Все', value: 'all' }]}
        />
      )} */}

      {checkboxValues.includes('Статус заказа') &&
        route !== 'orders-and-sales' ? null : (
        <DropDownSelector
          options_prop={superStautsData}
          state={selectedSuperStauts}
          setState={(e) => setSelectedSuperStatus(e)}
          className="connections_page_selector"
          placeholder="Статус заказа"
          fetchCallback={(e) => setSuperStatusPage(e)}
          setInputChange={(e) => setSuperStatusQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          onBlur={(e) => {
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
          multi
        />
      )}
      {checkboxValues.includes('По типу рекламной кампании') &&
        route !== 'advertising' ? null : (
        <DropDownSelector
          options_prop={advCampaignTypeData}
          state={selectedAdvCampaignType}
          setState={(e) => setSelectedAdvCampaignType(e)}
          className="connections_page_selector"
          placeholder="По типу рекламной кампании"
          fetchCallback={(e) => setAdvCampaignTypePage(e)}
          setInputChange={(e) => setAdvCampaignTypeQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
          onBlur={(e) => {
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
        />
      )}
      {checkboxValues.includes('По статусу рекламной кампании') &&
        (route !== 'advertising') ? null : (
        <DropDownSelector
          options_prop={advStatusData}
          state={selectedAdvStatus}
          setState={(e) => setSelectedAdvStatus(e)}
          className="connections_page_selector"
          placeholder="По статусу рекламной кампании"
          fetchCallback={(e) => setAdvStatusPage(e)}
          setInputChange={(e) => setAdvStatusQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
          onBlur={(e) => {
            superStatusFilter();
            campaignTypeFilter();
            advStatusFilter();
            advCampaignFilter();
            campaignFilter();
            warehouseFilter();
            setIsTableRequest(true);
          }}
        />
      )}
      {route === 'analytics' && (
        <BtnDropCheckbox
          checkboxValues={checkboxValues}
          setCheckboxValues={(e) => setCheckboxValues(e)}
          list={list_select}
        />
      )}
    </FilterContainer>
  );

  const rangeBox = (
    <RangeBox defaultValue={defaultDate} setDate={(e) => setDate(e)} />
  );

  return (
    <AnalyticsPageComponent
      activeSubItems={activeSubItems}
      tabs={pageTabsMap[route]}
      relativeUrl={urlMap[route]}
      rangeBox={rangeBox}
      date={date}
      filters={filters}
      selectedApi={selectedApi}
      selectedBrand={selectedBrand}
      selectedCategory={selectedCategory}
      selectedCampaign={selectedCampaign}
      selectedWarehouse={selectedWarehouse}
      selectedGroupMissedSales={selectedGroupMissedSales}
      selectedAdvCampaign={selectedAdvCampaign}
      selectedSuperStauts={selectedSuperStauts}
      selectedAdvCampaignType={selectedAdvCampaignType}
      selectedAdvStatus={selectedAdvStatus}
      route={route}
      checkboxValues={checkboxValues}
      setCheckboxValues={(e) => setCheckboxValues(e)}
      setSelectedMp={(e) => setSelectedMp(e)}
      setSelectedApi={(e) => setSelectedApi(e)}
      setSelectedCategory={(e) => setSelectedCategory(e)}
      setSelectedBrand={(e) => setSelectedBrand(e)}
      setSelectedWarehouse={(e) => setSelectedWarehouse(e)}
      setDate={(e) => setDate(e)}
      setDefaultDate={(e) => setDefaultDate(e)}
      isTableRequest={isTableRequest}
      setIsTableRequest={(e) => setIsTableRequest(e)}
    />
  );
};

export { AnalyticsPage };
