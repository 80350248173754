import icon_tab from './img/icon_tab.svg';
import star_icon from './img/star_icon.svg';
import customHeaders, { getSpace } from '../../common/headers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { ModalDeleteProduct } from '../Modal/ModalDeleteProduct/ModalDeleteProduct';
import { ModalMassImprovement } from '../Modal/ModalMassImprovement/ModalMassImprovement';
import { ModalContentTransfer } from '../Modal/ModalContentTransfer/ModalContentTransfer';
import { ModalСreatingСonnections } from '../Modal/ModalСreatingСonnections/ModalСreatingСonnections';
import { ModalSuccessfulProductList } from '../Modal/ModalSuccessfulProductList/ModalSuccessfulProductList';
import { useState, useEffect, useRef, useCallback } from 'react';
import {
  DropDownSelector,
  Tabs,
  ButtonBasic,
  SearchComponent,
  TableBox,
  ModalCsv,
} from '@lk-gtcom/ecomlab-components';
import {
  fetchMasterCategoryFilter,
  fetchMasterBrandFilter,
  fetchMasterOfferIdFilter,
} from '../../common/filtersFunc';
import {
  KonstantinMasterHttps,
  KonstantinMasterHttp,
  ArtemTransferTestHttps,
  ArtemTransferTestHttp,
  KonstantinCreateProductHttps,
} from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import './ProductsListMasterProduct.scss';

const btn_tab = [
  {
    label: 'Все',
    value: 'all',
    icon: icon_tab,
  },
  {
    label: 'Готовые',
    // value: 'ready',
    value: '',
    icon: icon_tab,
  },
  {
    label: 'Без маппинга',
    // value: 'without_mapping',
    value: '',
    icon: icon_tab,
  },
  {
    label: 'Удаленные (до 30 дней)',
    // value: 'recently_deleted',
    value: '',
    icon: icon_tab,
  },
];

const ProductsListMasterProduct = ({
  setIsModalActive,
  isModalActive,
  transfer,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState([]);

  const [searchData, setSearchData] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  const [checkedProducts, setCheckedProducts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalDone, setShowModalDone] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;

  const [isModalDeleteProduct, setIsModalDeleteProduct] = useState(false);
  const [isModalСreatingСonnections, setIsModalСreatingСonnections] =
    useState(false);
  const [isModalFilter, setIsModalFilter] = useState(false);
  const [isModalMassEditing, setIsModalMassEditing] = useState(false);

  const [target, setTarget] = useState([]);
  const [selectTargetApi, setSelectTargetApi] = useState([]);
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const navigate = useNavigate();
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [filterValue, setFilterValue] = useState('');

  const [byFilterValue, setByFilterValue] = useState(false);
  const [filterTextValue, setFilterTextValue] = useState('');

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  const filtersMasterCategory = () => {
    const abortController = new AbortController();
    fetchMasterCategoryFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
    return () => abortController.abort();
  };

  const filtersMasterBrand = () => {
    const abortController = new AbortController();
    fetchMasterBrandFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
    return () => abortController.abort();
  };

  useEffect(() => {
    filtersMasterCategory();
  }, [categoryPage, categoryQuery]);

  useEffect(() => {
    filtersMasterBrand();
  }, [dateFrom, dateTo, brandPage, brandQuery]);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/tables/product-list${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/tables/product-list${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    }

    let brand_id_list;
    let category_id_list;
    let offer_id_list;
    let offer_id_name_search = searchValue;

    if (selectedOfferId) {
      offer_id_list = selectedOfferId?.map((el) => el?.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (filterValue?.length > 0) {
      filtersParam = [['offer_id', 'text', '=', filterValue?.join(',')]];
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      // offer_id_list,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam,
      offer_id_name_search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`;
    }

    let brand_id_list;
    let category_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setSearchData(
          json.map(({ id, name, image }) => ({
            label: name,
            extra: id,
            value: id,
            image,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Нужно протестить
  const fetchDelete = (data, abortController = new AbortController()) => {
    const { product_id } = data;
    if (!product_id) return;

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/master_products`;
    } else {
      url = `${KonstantinCreateProductHttps}/master_products`;
    }

    const body = JSON.stringify({
      product_id: product_id,
    });

    fetch(url, {
      body,
      method: 'DELETE',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        resetTable();
        fetchTableData([]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchTransferApi = () => {
    setIsTransfer(true);
    if ((checkedProducts.length > 0) & !isTransfer || filterValue?.length > 0) {
      const abortController = new AbortController();
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${ArtemTransferTestHttps}/avail-stores`;
      } else {
        url = `${ArtemTransferTestHttp}/avail-stores`;
      }

      fetch(url, { method: 'GET', headers, signal: abortController.signal })
        .then((res) => res.json())
        .then((json) => {
          const { avail_stores } = json;
          setShowModal(true);
          setTarget(
            avail_stores.map((el) => ({
              label: el.name,
              value: el.api_id,
              mp_id: el.mp_id,
              icon: el.icon,
            }))
          );
        })
        .catch((err) => console.error(err))
        .finally(() => setIsTransfer(false));

      return abortController;
    }
  };

  const transferApi = () => {
    // setLoading(true)

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemTransferTestHttps}/upload`;
    } else {
      url = `${ArtemTransferTestHttp}/upload`;
    }

    let offer_ids = byFilterValue
      ? filterValue
      : checkedProducts?.map((el) => {
          const offer = el?.offer_id;
          if (typeof offer === 'string') {
            return offer;
          } else {
            return offer?.join('');
          }
        });

    const body = JSON.stringify({
      ozon_api_id: '',
      offer_ids,
      target_api_id: selectTargetApi?.value,
      target_mp_id: selectTargetApi?.mp_id,
      content_mods: [],
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        // setLoading(false)
      })
      .catch((err) => console.error(err));
  };

  const handleTransfer = () => {
    setIsTransfer(true);
    if ((checkedProducts.length > 0) & !isTransfer || filterValue?.length > 0) {
      const abortController = new AbortController();
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${ArtemTransferTestHttps}/avail-stores`;
      } else {
        url = `${ArtemTransferTestHttp}/avail-stores`;
      }

      fetch(url, { method: 'GET', headers, signal: abortController.signal })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const { avail_stores } = json;
          setShowModal(true);
          setTarget(
            avail_stores?.map((el) => ({
              label: el.name,
              value: el.api_id,
              mp_id: el.mp_id,
              icon: el.icon,
            }))
          );
        })
        .catch((err) => console.error(err))
        .finally(() => setIsTransfer(false));

      return abortController;
    }
  };

  useEffect(() => {
    if (showModal) {
      if (!isModalActive) {
        fetchTransferApi();
      }
    }
  }, [showModal, isModalActive]);

  const onEditProduct = (data) => {
    if (data) {
      const { product_id } = data;
      const url = `../add-product/required?isEdit=true&product_id=${product_id}`;
      window.open(url, '_blank');
    }
  };

  const contextMenuArr = [
    {
      label: 'Информация',
      onClick: (e) => {
        const { offer_id, product_id, brand_id } = e;
        window.open(
          `/analysis_sku/product_improvement/emoji?nmid=${offer_id}&product_id=${product_id}&brand_id=${brand_id}`
        );
      },
      type: '',
    },
    {
      label: 'Список связок',
      onClick: (e) => {
        const { offer_id, product_id } = e;
        window.open(
          `/analysis_sku/general?nmid=${offer_id}&product_id=${product_id}`
        );
      },
      type: '',
    },
    { label: 'Редактировать', onClick: (e) => onEditProduct(e), type: '' },
    { label: 'Удалить', onClick: (e) => fetchDelete(e), type: '' },
  ];

  const [isClear, setIsClear] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    if (!isModalFilter) {
      resetTable();
      fetchTableData([], abortController);
    }

    return () => abortController.abort();
  }, [selectedBrand, selectedCategory, selectedOfferId, isModalFilter]);

  return (
    <>
      {showModalDone && (
        <ModalSuccessfulProductList setIsModal={(e) => setShowModalDone(e)} />
      )}

      {showModal ? (
        <ModalContentTransfer
          checkedProducts={checkedProducts}
          setShowModal={(e) => setShowModal(e)}
          targetApiData={target}
          state={selectTargetApi}
          setSelectTargetApi={(e) => setSelectTargetApi(e)}
          transferApi={(e) => transferApi(e)}
          setShowModalDone={(e) => setShowModalDone(e)}
          setIsModalActive={(e) => setIsModalActive(e)}
          filterValue={filterValue}
        />
      ) : null}

      {isModalDeleteProduct && (
        <ModalDeleteProduct setIsModal={(e) => setIsModalDeleteProduct(e)} />
      )}

      {isModalСreatingСonnections && (
        <ModalСreatingСonnections
          setIsModal={(e) => setIsModalСreatingСonnections(e)}
          selectedCategory={selectedCategory}
          selectedBrand={selectedBrand}
          offer_id_list={checkedProducts.map((el) => el?.['Артикул'])}
        />
      )}

      {isModalFilter && (
        <ModalCsv
          setIsModal={(e) => setIsModalFilter(e)}
          filterValue={filterValue}
          setFilterValue={(e) => setFilterValue(e)}
        />
      )}

      {isModalMassEditing && (
        <ModalMassImprovement
          setIsModal={(e) => setIsModalMassEditing(e)}
          checkedProducts={checkedProducts}
        />
      )}

      <div className="products-list-master-products">
        <div className="products-list-master-products__header">
          <h1 className="title_main">
            {transfer ? 'Перенос' : 'Список'} товаров
          </h1>
          <div className="title-tooltip">
            Мастер товары <img src={star_icon} />
          </div>
        </div>

        <div className="toolbar-top">
          <FilterContainer
            onReset={(e) => {
              setSelectedCategory([]);
              setSelectedBrand([]);
              setFilterValue('');
              setFilterTextValue('');
              setByFilterValue(false);
              setIsClear(!isClear);
            }}
          >
            <DropDownSelector
              options_prop={categoryData}
              state={selectedCategory}
              setState={(e) => setSelectedCategory(e)}
              placeholder="Категория"
              multi={true}
              fetchCallback={(e) => setCategoryPage(e)}
              setInputChange={(e) => setCategoryQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              onBlur={() => {
                filtersMasterBrand();
                if (!isModalFilter) {
                  resetTable();
                }
                fetchTableData();
              }}
            />

            <DropDownSelector
              options_prop={brandData}
              state={selectedBrand}
              setState={(e) => setSelectedBrand(e)}
              placeholder="Бренд"
              multi={true}
              fetchCallback={(e) => setBrandPage(e)}
              setInputChange={(e) => setBrandQuery(e)}
              defaultValue={[{ label: 'Все', value: 'all' }]}
              onBlur={() => {
                filtersMasterBrand();
                if (!isModalFilter) {
                  resetTable();
                }
                fetchTableData();
              }}
            />
            <SearchComponent
              searchValue={searchValue}
              errSearch={false}
              placeholder="Поиск по артукулу или названию"
              errText="Неверный артикул или название"
              setSearchValue={setSearchValue}
              fetchData={fetchTableData}
              fetchCallback={fetchSearch}
              data={searchData}
              onClearDependence={[isClear]}
            />
          </FilterContainer>

          {!transfer && (
            <ButtonBasic
              violet
              text={
                <p className="content-btn">
                  Создать товар <img src={star_icon} />
                </p>
              }
              width="auto"
              minWidth="150px"
              size="40px"
              onClick={(e) => navigate('/add-product/required')}
            />
          )}
        </div>

        <Tabs
          tabs={btn_tab}
          pageValue={pageValue}
          setPageValue={(e) => setPageValue(e)}
        />

        <TableBox
          {...tableProps}
          tableUrl={tableUrl}
          tableBody={tableBody}
          paginator={true}
          ref={paginatorRef}
          onEdit={(e) => navigate('/edit-product/add-information/required')}
          onDelete={(e) => setIsModalDeleteProduct(true)}
          onCheck={(e) => setCheckedProducts(e)}
          onAction={(e) => onEditProduct(e)}
          fetchCallback={fetchTableData}
          contextMenuArr={contextMenuArr}
          // showFilter={true}
          // isInsideTable={true}
          child={
            <>
              <ButtonBasic
                action
                disabled={!(checkedProducts?.length > 0)}
                width="auto"
                text={`Перенести`}
                size="32px"
                onClick={(e) => {
                  handleTransfer();
                  setByFilterValue(false);
                }}
              />
              <ButtonBasic
                action
                disabled={!(checkedProducts?.length > 0)}
                width="auto"
                text={`Массовое улучшение`}
                size="32px"
                onClick={(e) => {
                  setIsModalMassEditing(true);
                  navigate('emoji');
                }}
              />

              <ButtonBasic
                width="auto"
                text={`Фильтрация по артикулу`}
                size="32px"
                onClick={(e) => setIsModalFilter(true)}
              />

              <ButtonBasic
                disabled={filterValue?.length < 1}
                size="32px"
                width={'fit-content'}
                text="Перенести все из фильтра по артикулу"
                onClick={(e) => {
                  fetchTransferApi();
                  setByFilterValue(true);
                }}
              />

              {/* <ButtonBasic
                action
                width='auto'
                text={`Список связок`}
                disabled={!(checkedProducts?.length > 0)}
                size='32px'
                onClick={(e) => setIsModalСreatingСonnections(true)}
              /> */}
            </>
          }
        />
      </div>
    </>
  );
};

export { ProductsListMasterProduct };
