import React, { useState } from 'react'
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from '../../../store/general';
import { Telegramm } from './img/Telegramm'
import "./ButtonTelegramm.scss"


const ButtonTelegramm = ({
    title,
    description, 
    id
}) => {

    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };

    const fetchTelegrammAccount = (id) => {
        let url = `https://apps0.ecomru.ru:4447/api/v3/tasks/tg_bot_notify_link?entity_id=${id}&service=task`;

        fetch(url, { method: 'GET', headers })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw Error(JSON.stringify(res.json()));
                }
            })
            .then((json) => {
                if (json) {
                    window?.open(json, '_blunk')
                }
            })
            .catch((err) => {
                console.error(err);
            })

    };

    return (
        <div className='telegramm-block'>
            {title && <h2 className='telegramm-block__title'>{title}</h2>}
            {description && <p className='telegramm-block__description'>{description}</p>}
            <button className='telegramm-block__btn'
                onClick={() => {
                    if (id) {
                        fetchTelegrammAccount(id)
                    }
                }}
            >
                <Telegramm />
                Подписаться
            </button>
        </div>
    )
}

export { ButtonTelegramm }