import React, { useState } from "react";
import { FC, MouseEvent } from "react";
import { TitlePage } from "../../../components/TitlePage/TitlePage"
import { InputDinamycPlaceholder, ButtonBasic, Checkbox } from '@lk-gtcom/ecomlab-components';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from "../../../store/general";
import "./ModalAddNewField.scss";

type CurrentRow = {
  row_id: string,
  Значение: 'string',
  "Количество дней": number,
  Название: string
}

interface ModalEditFileProps {
  setIsModal: (isOpen: boolean) => void;
  selectedApi: { label: string, value: string | number },
  status: string,
  currentRow: CurrentRow ,
  setСurrentRow: (currentRow: CurrentRow | null) => void,
}

const ModalAddNewField: FC<ModalEditFileProps> = ({
  setIsModal,
  selectedApi,
  status,
  currentRow,
  setСurrentRow
}) => {

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [name, setName] = useState<string>(currentRow?.['Название'] || '')
  const [value, setValue] = useState<string>(currentRow ? String(parseFloat(currentRow?.['Значение'])) : '')
  const [day, setDay] = useState<string>(currentRow ?  String(currentRow?.['Количество дней']) : '')
  const [isPercent, setIsPercent] = useState<boolean>(currentRow ? currentRow?.['Значение']?.includes('%') : false)

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).classList.contains("blackout")) {
      setСurrentRow(null)
      setIsModal(false);
    }
  };

  const handleChangeName = (e: string) => {
    setName(e);
  }

  const handleChangeValue = (e: string) => {
    setValue(e);
  }

  const handleChangeDay = (e: string) => {
    setDay(e);
  }

  const handleChangePercent = (e: boolean) => {
    setIsPercent(!isPercent);
  }

  const handleClickBtn = (e: MouseEvent<HTMLDivElement>) => {
    fetchAddExpenses()
    setСurrentRow(null)
    setIsModal(null)
  }

  // Изменение файлы
  const fetchAddExpenses = () => {
    if (selectedApi?.value) {
      let url = `https://apps0.ecomru.ru:4449/mp-products/tables/add-expenses/${status}`

      let day_params = (status === 'product') ? {} : { days: day }

      const body = JSON.stringify({
        api_id: selectedApi?.value,
        params: [
          {
            expense_name: name,
            value: parseInt(value),
            row_uid: currentRow?.row_id,
            is_percent: isPercent,
            ...day_params
          }
        ]
      });

      fetch(url, { body, method: 'POST', headers })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          setСurrentRow(null)
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <div className="blackout" onClick={handleClick}>
      <div className="modal-add-new-field">
        <TitlePage
          title={currentRow ? "Редактирование поля" : "Новое поле"}
        />

        <InputDinamycPlaceholder
          placeholder='Название'
          onChange={handleChangeName}
          changeValue={name}
          size='s'
        />

        <InputDinamycPlaceholder
          placeholder='Значение'
          onChange={handleChangeValue}
          changeValue={value}
          size='s'
        />

        {
          status === 'product' ?
            <Checkbox
              value={isPercent}
              onChange={handleChangePercent}
            >%</Checkbox>
            :
            <InputDinamycPlaceholder
              placeholder='Кол-во дней'
              onChange={handleChangeDay}
              changeValue={day}
              size='s'
            />
        }

        <ButtonBasic
          green
          text='Применить'
          onClick={handleClickBtn}
        />
      </div>
    </div>
  );
};

export { ModalAddNewField };