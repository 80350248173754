import not_img from './img/not_img.svg'
import trash_icon from './img/trash_icon.svg'
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { useEffect } from 'react';
import "./CardVariantProduct.scss"

const CardVariantProduct = ({ ...props }) => {
    const {
        product_id,
        name,
        offer_id,
        deleteProduct,
        setCurrentProductId,
        currentProductId,
        setIsGeneralloading,
        setProductTemporary,
        productTemporaryList,
        ind,
        currentProduct,
        setCurrentProduct,
        setProductTemporaryList,
        deleteLastProject
    } = props
    let img

    useEffect(() => {
        setProductTemporary(productTemporaryList?.[currentProduct])
    }, [currentProduct]);


    return (
        <div className='card-variant-product'
            style={currentProduct === ind ? { border: '1px solid #3900B1' } : {}}
            onClick={e => {
                setCurrentProduct(ind)
                setProductTemporary(productTemporaryList[ind])
                // setCurrentProductId({ name, product_id})
            }}
        >
            <div className='card-variant-product__img-box'>
                <img className={img ? 'img' : 'not_img'} src={img ? img : not_img} />
            </div>
            <div className='card-variant-product__content'>
                <p className='text_article'>
                    {offer_id ? offer_id : 'Не указано'}
                </p>
                <h5 className='title_product'>
                    {name ? name : 'Нет названия'}
                </h5>
            </div>
            <TooltipLightBlue
                child={
                    <button className='btn-delete'
                        onClick={e => {
                            e.stopPropagation()
                            const curr = productTemporaryList.filter((el, index) => ind != index)
                            setProductTemporaryList(curr)
                            setCurrentProduct(0)
                            if(productTemporaryList?.length === 1) {
                                deleteLastProject()
                            }
                        }}
                    >
                        <img src={trash_icon} />
                    </button>}
                text='Удалить товар'
                top='100%'
                right='0px'
            />
        </div>
    )
}

export { CardVariantProduct }