import { Link, useNavigate, useParams } from 'react-router-dom'


const AuthCheck = () => {

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                fontSize: '20px'
            }}
        >
            <span>Почта подтверждена, спасибо!</span>
            <Link to={'/landing'} style={{ color: 'blue' }}>Перейти на главную страницу</Link>
        </div>
    )
}

export { AuthCheck}