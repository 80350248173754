import React from "react";
import { useState, useEffect, useRef } from "react";
import dots_horizontal_rounded from './img/bx-dots-horizontal-rounded.svg';
import "./ButtonActionDrop.scss";

interface ButtonActionDropProps {
    btns?: {
        btn: string;
        func: (id: number | React.MouseEvent) => void;
    }[];
    label?: string;
    size?: 's' | 'm' | '';
    color?: string;
    id?: number | string;
}

const ButtonActionDrop = ({
    btns = [],
    label = '',
    size = '',
    color = '',
    id = 1
}: ButtonActionDropProps) => {
    const [isDrop, setIsDrop] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const ref = useRef<HTMLButtonElement>(null);
    const dropMenuRef = useRef<HTMLDivElement>(null);

    // Handler for clicks outside the element and scroll
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            if (!target.closest(`#button-action-drop-${label + id}`)) {
                setIsDrop(false);
            }
        };

        const handleScroll = () => {
            setIsDrop(false);
        };

        document.addEventListener('click', handleClickOutside);
        window.addEventListener('scroll', handleScroll, true);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [label, id]);

    // Update dropdown position when opened
    useEffect(() => {
        if (isDrop && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setPosition({
                top: rect.bottom,
                left: rect.right - 140
            });
        }
    }, [isDrop]);

    const handleButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsDrop(!isDrop);
    };

    const handleItemClick = (func: (id: number | string | React.MouseEvent) => void, e: React.MouseEvent) => {
        func(id);
        setIsDrop(false);
    };

    return (
        <div 
            className='button-action-drop' 
            id={`button-action-drop-${label + id}`}
            onClick={handleButtonClick}
        >
            <button 
                ref={ref}
                style={{ background: color || '' }}
                className={`
                    button-action-drop__btn 
                    ${size === 'm' ? 'button-action-drop__btn-medium' : ''} 
                    ${size === 's' ? 'button-action-drop__btn-small' : ''}
                `}
            >
                <img src={dots_horizontal_rounded} alt="Actions" />
            </button>

            {isDrop && (
                <div 
                    ref={dropMenuRef}
                    className='button-action-drop__drop-menu_active'
                    style={{
                        left: position.left,
                        top: position.top,
                        position: 'fixed'
                    }}
                >
                    <div 
                        className='button-action-drop__drop-menu-inside'
                        onMouseLeave={() => setIsDrop(false)}
                    >
                        {btns.map(({ btn, func }, index) => (
                            <button 
                                key={index}
                                className='button-action-drop__drop-menu__btn'
                                onClick={(e) => handleItemClick(func, e)}
                            >
                                {btn}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export { ButtonActionDrop };