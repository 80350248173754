import { Link } from 'react-router-dom';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import minus_icon from './img/bx-minus.svg'
import useGeneralStore from '../../../../store/general';
import { ConnectionsContext } from '../../../../App'
import customHeaders, { getSpace } from '../../../../common/headers';
import { ButtonActionDrop } from '../../../Buttons/ButtonActionDrop/ButtonActionDrop';
import { OlegAccountHttps, OlegAccountHttp } from '../../../../fetchUrls';
import { setAccountId, setName, setServiceData } from '../../../SettingsConnection/actions';
import useModal from '../../../../store/modal';
import { useShallow } from 'zustand/react/shallow';
import { useContext } from 'react';
import './SettingsRow.scss'
import { useState } from 'react';

const SettingsRow = ({
    id,
    icon,
    name,
    isAdmin,
    key,
    btnFunc,
}) => {
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };


    const btns = [
        {
            btn: 'Редактировать',
            func: () => { },
        },
    ];

    return (
        <div className='settings-row' key={key}>
            <div className='settings-row__name'>
                {name}
            </div>
            <div className='settings-row__tools'>

                {
                    <div className='settings-row__btns'>
                        <ButtonActionDrop
                            id={2}
                            btns={btns}
                        />

                        <ButtonBasic
                            grey
                            width='40px'
                            minWidth='40px'
                            text={<img src={minus_icon} />}
                            onClick={(e) => btnFunc()}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export { SettingsRow }
